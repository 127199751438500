import isEmpty from "./is-Empty";

export default function TimestampConverter(str) {
    const date = new Date(str);

    const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    if (!isEmpty(str)) return date.toLocaleDateString('en-US', options);
}