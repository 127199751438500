import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { DotIcon } from "../../assets/svg/svg-icons";

import "react-image-gallery/styles/css/image-gallery.css";

import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../utils/is-Empty";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../common/Spinner";
import OrderSummaryCard from "../Cart/OrderSummaryCard";
import CheckOutAddress from "./CheckOutAddress";
import { postOrder } from "../../actions/structure.action";
import Razorpay from "./Razorpay";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { checkoutItems, payment } = useSelector((state) => {
    return state;
  });
  const [uploadFormObj, setUploadFormObj] = useState({});
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSetValue = (key, value) => {
    let updatedObj = {};
    updatedObj[key] = value;

    setUploadFormObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (isEmpty(checkoutItems?.data)) {
      navigate(`/cart`);
    } else {
      setUploadFormObj((previousVlaues) => ({
        ...previousVlaues,
        ...checkoutItems?.data,
      }));
    }

  }, []);

  useEffect(() => {
    if (!isEmpty(payment?.data) && Object.prototype.hasOwnProperty.call(
      payment?.data,
      "razorpayOrder"
    )) {
      setDisplayRazorpay(true);
    }
  }, [payment])

  const cartBanner = (
    <div className="cart-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link>{<DotIcon />} <Link to={`/cart`} >{" Cart"}</Link></p>
      <h2>{"Checkout"}</h2>
    </div>
  );

  const placeOrder = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        // Call your postOrder function here
        await postOrder(uploadFormObj, dispatch, navigate);
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        // Optionally reset submitting state here, or navigate away if successful
        setIsSubmitting(false);
      }
    }
  }
  const SummaryBox = () => {


    return (
      <>
        <p className="m-0">{"Order Summary"}</p>
        <Row className="price-box mx-0 my-2">
          <Col xs={4}></Col>
          <Col xs={5} className="title p-0">
            {"Subtotal:"}
          </Col>
          <Col xs={3} className="amount p-0">
            {parseFloat(checkoutItems?.data.totalAmount).toFixed(2)}&nbsp;{"₹"}
          </Col>
        </Row>
        <Row className="price-box mx-0 my-2">
          <Col xs={4}></Col>
          <Col xs={5} className="title p-0">
            {"Delivery Charges:"}
          </Col>
          <Col xs={3} className="amount p-0">
            {parseFloat(checkoutItems?.data.deliveryCharges).toFixed(2)}&nbsp;{"₹"}
          </Col>
        </Row>
        <Row className="price-box mx-0 my-2">
          <Col xs={4}></Col>
          <Col xs={8} className="title p-0">
            <hr className="mx-0 cutomeHR" />
          </Col>
        </Row>
        <Row className="price-box mx-0 my-2">
          <Col xs={4}></Col>
          <Col xs={5} className="amount p-0">
            {"Payable amount"}
          </Col>
          <Col xs={3} className="amount p-0">
            {parseFloat(checkoutItems?.data.payableAmount).toFixed(2)}&nbsp;{"₹"}
          </Col>
        </Row>
        <div className="text-uppercase button-div mx-0 my-4">
          <Button className="text-uppercase" type="submit"
            disabled={isSubmitting}
          // onClick={(() => postOrder(uploadFormObj, dispatch, navigate))}
          >
            {"Place your Order"}
          </Button>
        </div>
      </>
    )
  }


  const orderSummary = (title) => {
    return (<>
      <div className="cart-order-summary-section  ">
        <Row className="m-0 my-4">

          <Col xs={12} sm={12} md={8} lg={8} className="p-0">
            <CheckOutAddress onSetValue={onSetValue} />
            <div className="order-section-title-div">
              <h4 className="order-section-title m-0">{"Review Items"}</h4>
              {/* <p className="m-0">{"Review Items"}</p> */}
            </div>
            <div id="borderLeft"></div>

            <hr />
            {checkoutItems?.data?.items.map((item) => {
              return <OrderSummaryCard card={item?.item} crud={false} />;
            })}

          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="order-summery-price p-0"
          >
            {SummaryBox()}
          </Col>
        </Row>
        <Row className="m-0 text-start desktop amount-display-row">
          <h1>{`Order Total ₹ ${checkoutItems?.data.payableAmount}`}</h1>
          <p>{"By placing your order, you agree to the “Bharee” Terms and Conditions and “Bharee” privacy notice."}</p>
          <div className="text-uppercase button-div mx-0 my-4">
            <Button className="text-uppercase"
              type="submit"
              disabled={isSubmitting}
            // onClick={(() => postOrder(uploadFormObj, dispatch, navigate))}
            >
              {"Place your Order"}
            </Button>

          </div>
        </Row>
      </div>

    </>)
  };

  return (
    <section className="cart-main-section checkout">
      <Form className="my-2" onSubmit={placeOrder}>
        {checkoutItems?.loading ? <Container style={{ padding: "5rem" }}>
          <Spinner />
        </Container> : <>
          {cartBanner}
          {checkoutItems?.data?.items?.length > 0 ?
            orderSummary("Pay on delivary")
            : <Container style={{ padding: "5rem" }}>
              <Link className='nav-link-color '
                style={{ fontSize: "1rem", color: "#FDC345", padding: "1rem", border: "1px solid #FDC345", borderRadius: "5px", margin: "1rem" }} to={"/brands"}>
                {"Please add Products to cart"}
              </Link>
            </Container>}
        </>}
      </Form>

      {displayRazorpay && (
        <Razorpay />)}
    </section>
  );
}

export default Checkout;
