import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";

import CustomButton from "../../../common/CustomButton";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBrand,
  getApi
} from "../../../../actions/structure.action";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import BrandCrud from "../../common/BrandCrud";
import CustomTable from "../../common/CustomTable";

const Brands = () => {
  const dispatch = useDispatch();
  const [sellerBrandsArray, setSellerBrandsArray] = useState([]);
  const [othersBrandsArray, setOthersBrandsArray] = useState([]);
  const [showBrandCreateMode, setShowBrandCreateMode] = useState(false);
  const [currentBrandObj, setCurrentBrandObj] = useState({});
  const [isViewOnly, setIsViewOnly] = useState(false);
  const { brands, auth } = useSelector(
    (state) => {
      return state;
    }
  );
  useEffect(() => {
    getApi("getBrands", dispatch)
  }, []);

  useEffect(() => {
    if (brands?.data?.length < 1) {
      setShowBrandCreateMode(true);
    } else {
      setShowBrandCreateMode(false);
    }
    if (brands?.data?.length > 0) {
      const filtered = brands?.data.filter(
        (item) => item.createdBy === auth?.user.id
      ); // Replace with your desired filter condition
      setSellerBrandsArray(filtered);
    }
    if (brands?.data?.length > 0) {
      const filtered = brands?.data.filter(
        (item) => item.createdBy !== auth?.user.id
      ); // Replace with your desired filter condition
      setOthersBrandsArray(filtered);
    }
  }, [brands]);


  return (
    <Container className="bhree-admin-brand p-0 ">
      <Row className="mx-0 px-1 mb-4">
        <h1 className="text-uppercase">{"Brands"}</h1>
      </Row>
      {/* {(() => {
        if (!showBrandCreateMode) {
          if (sellerBrandsArray?.length > 0 && !brands.loading) {
            return (
              <Row className="m-0 pb-4">
                <CustomTable
                  columns={tableStructures.brand}
                  data={sellerBrandsArray}
                  enableRowActions={true}
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableBottomToolbar={true}
                  enableFilters={true}
                  renderRowActions={({ row }) => (
                    <div className="table-action-box">
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => {
                          setShowBrandCreateMode(true);
                          setCurrentBrandObj(row.original);
                        }}
                      >
                        <EditIcon />
                      </CustomButton>
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => deleteBrand(row.original.id, dispatch)}
                      >
                        <DeleteIcon />
                      </CustomButton>
                    </div>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <div className="table-heading-wrap">
                      <h4>{"Your Brands"}</h4>

                      <CustomButton
                        buttonClasses="icon-add-btn"
                        onClick={() => {
                          setShowBrandCreateMode(true);
                          setCurrentBrandObj({});
                        }}
                      >
                        {"Add"}
                      </CustomButton>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                />
              </Row>
            );
          } else if (sellerBrandsArray?.length < 1 && !brands.loading) {
            return (
              <Container className="seller-product-create-form px-0  ">
                <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setShowBrandCreateMode} listEmpty={sellerBrandsArray.lengh > 0 ? false : true} setCurrentBrandObj={setCurrentBrandObj} />
              </Container>
            );
          } else if (brands.loading) {
            return <Spinner />;
          }
        } else if (showBrandCreateMode) {
          return (
            <Container className="seller-product-create-form px-0 ">
              <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setShowBrandCreateMode} listEmpty={sellerBrandsArray.lengh > 0 ? false : true} setCurrentBrandObj={setCurrentBrandObj} />
            </Container>
          );
        }
      })()} */}
      {/* <br /> */}

      {brands.loading ? (<Spinner />) : othersBrandsArray?.length > 0 && !isViewOnly ? ((
        <Row className="m-0 pb-4">
          <CustomTable
            columns={tableStructures.brand}
            data={othersBrandsArray}
            enableRowActions={true}
            positionActionsColumn={"last"}
            enableTopToolbar={true}
            emptyTableMsg={''}
            enablePagination={true}
            enableBottomToolbar={true}
            enableFilters={true}
            renderRowActions={({ row }) => (
              <div className="table-action-box">
                {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                <CustomButton
                  buttonClasses="icon-action-btn"
                  onClick={() => {
                    setIsViewOnly(true);
                    setCurrentBrandObj(row.original);
                  }}
                >
                  <ViewIcon />
                </CustomButton>
              </div>
            )}
            renderTopToolbarCustomActions={() => (
              <div className="table-heading-wrap">
                <h4>{"Available Brands"}</h4>

                {/* <CustomButton
                          buttonClasses="icon-add-btn"
                          onClick={() => {
                            setShowBrandCreateMode(true);
                            setCurrentBrandObj({});
                            setIsUpdateMode(false);
                            setCurrentUpdateId("");
                          }}
                        >
                          {"+ Add Brand"}
                        </CustomButton> */}
              </div>
            )}
            initialState={{
              columnPinning: {
                right: ["mrt-row-actions"],
              },
            }}
          //   enableColumnOrdering={false} //enable some features
          //   enableRowSelection={false}
          //   enableColumnResizing={false}
          //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
          //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
          //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
          />
        </Row>
      )) : isViewOnly ? (<Container className="seller-product-create-form px-0   ">
        <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setIsViewOnly} isViewOnly={isViewOnly} setCurrentBrandObj={setCurrentBrandObj} />
      </Container>) : (<Container className="seller-product-create-form px-0  ">
        {"Sorry, no brands are available to display at the moment."}
      </Container>)}
      {/* {(() => {
        if (!isViewOnly) {
          if (othersBrandsArray?.length > 0 && !brands.loading) {
            return (
              <Row className="m-0 pb-4">
                <CustomTable
                  columns={tableStructures.brand}
                  data={othersBrandsArray}
                  enableRowActions={true}
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableBottomToolbar={true}
                  enableFilters={true}
                  renderRowActions={({ row }) => (
                    <div className="table-action-box">
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => {
                          setIsViewOnly(true);
                          setCurrentBrandObj(row.original);
                        }}
                      >
                        <ViewIcon />
                      </CustomButton>
                    </div>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <div className="table-heading-wrap">
                      <h4>{"Available Brands"}</h4>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                />
              </Row>
            );
          } else if (othersBrandsArray?.length < 1 && !brands.loading) {
            return (
              <Container className="seller-product-create-form px-0  ">
                {"Sorry, no brands are available to display at the moment."}
              </Container>
            );
          } else if (brands.loading) {
            return <Spinner />;
          }
        } else if (isViewOnly) {
          return (
            <Container className="seller-product-create-form px-0   ">
              <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setIsViewOnly} isViewOnly={isViewOnly} setCurrentBrandObj={setCurrentBrandObj} />
            </Container>
          );
        }
      })()} */}
    </Container>
  );
};

export default Brands;
