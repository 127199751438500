import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { DeleteIcon, EditIcon } from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProductVariant,
  getApi
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import CustomTable from "../../common/CustomTable";
import VariantsCrud from "../../common/VariantsCrud";

const Variants = () => {
  const dispatch = useDispatch();
  const [showVariantCRUD, setShowVariantCRUD] = useState(false);
  const [selectedFormObj, setSelectedFormObj] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [variantOptions, setVariantOptions] = useState([])
  const [createVariant, setCreateVariant] = useState({});

  const [options, setOptions] = useState([]);

  const { products, currentProduct, bhareeAdminBasicTable } = useSelector(
    (state) => {
      return state;
    }
  );
  useEffect(() => {
    getApi("getProducts", dispatch);
  }, []);
  useEffect(() => {
    let optionsArray = [];
    setIsUpdateMode(false);
    if (products?.data?.length > 0) {
      products.data?.filter((item) => {
        let itemObj = {}
        itemObj["id"] = item.id;
        itemObj["displayName"] = `${item?.brand?.brand_name} ${item?.productCategory?.name} ${item?.product_name != item?.productCategory?.name ? item?.product_name : ""} `
        optionsArray.push(itemObj)
        return item
      })
      setOptions(optionsArray);
      onSelectValue(optionsArray[0], "selectedProduct")
    }
    if (products?.data?.length < 1) {
      setShowVariantCRUD(true);
    } else {
      setShowVariantCRUD(false);
    }

  }, [products]);

  useEffect(() => {
    if (!isEmpty(currentProduct?.data.selectedVariants)
      && currentProduct?.data?.selectedVariants?.length > 0
      && bhareeAdminBasicTable?.data?.variants?.length > 0
    ) {
      const idsInA = currentProduct?.data?.selectedVariants.map(itemA => itemA.variant_id);
      const filtered = bhareeAdminBasicTable?.data?.variants.filter(itemB => !idsInA.includes(itemB.id));
      // console.log("****** filtered", filtered);
      setVariantOptions(filtered)
    } else {
      setVariantOptions(bhareeAdminBasicTable?.data?.variants)
    }
  }, [currentProduct])

  useEffect(() => {
    // getProductById(selectedFormObj?.selectedProduct?.id, dispatch)
    getApi("getProductById", dispatch, selectedFormObj?.selectedProduct?.id)
  }, [selectedFormObj]);


  // const backBackToList = () => {
  //   setShowVariantCRUD(false)
  //   // getProductById(selectedFormObj?.selectedProduct?.id, dispatch)
  //   getApi("getProductById", dispatch, selectedFormObj?.selectedProduct?.id)

  //   setIsUpdateMode(false);
  // }
  const onSelectValue = (value, selectorType) => {
    let updatedObj = {};
    updatedObj[selectorType] = value;
    setSelectedFormObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };


  return (
    <Container className="slller-products p-0 ">
      <Row className="mx-0 px-1 mb-4">
        <h1 className="text-uppercase">{"Product Variants"}</h1>
      </Row>
      {/* <Row className="m-0 w-full  form-row ">

        {options?.length > 0 && (<>
          <Form className="my-0 p-0 modal-custom-class">
            <Row className="m-0">
              <Col xs={12} md={6} >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {"Select Products"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  {options?.length > 0 && (<CustomSelectDropdown
                    style={{
                      multiselectContainer: {
                        backgroundColor: "#f8f8f8",
                      },
                      chips: {
                        margin: 0,
                      },
                      option: {
                        background: "#fbb040",
                        color: "#FFFFFF",
                        borderBottom: "1px solid #FFFFFF",
                      },
                    }}
                    options={options}
                    onSelect={(e) => onSelectValue(e[0], "selectedProduct")}
                    selectedValues={
                      Object.prototype.hasOwnProperty.call(
                        selectedFormObj,
                        "selectedProduct"
                      )
                        ? [selectedFormObj?.selectedProduct]
                        : []
                    }
                    displayValue={"displayName"}
                    disable={isUpdateMode ? true : false}
                    singleSelect
                  />)}

                </Form.Group>
              </Col>
            </Row>
          </Form>
        </>)}
        {!options?.length > 0 && (<Container className="seller-product-create-form text-center  ">
          <h6>{"Please create product first"} </h6>
        </Container>)}

      </Row> */}
      {currentProduct.loading ? (<Container className="seller-product-create-form   ">
        <Spinner />
      </Container>) : options?.length > 0 ? <>
        <Row className="m-0 w-full  form-row ">
          <Form className="my-0 p-0 modal-custom-class">
            <Row className="m-0">
              <Col xs={12} md={6} >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {"Select Products"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  {options?.length > 0 && (<CustomSelectDropdown
                    style={{
                      multiselectContainer: {
                        backgroundColor: "#f8f8f8",
                      },
                      chips: {
                        margin: 0,
                      },
                      option: {
                        background: "#fbb040",
                        color: "#FFFFFF",
                        borderBottom: "1px solid #FFFFFF",
                      },
                    }}
                    options={options}
                    onSelect={(e) => onSelectValue(e[0], "selectedProduct")}
                    selectedValues={
                      Object.prototype.hasOwnProperty.call(
                        selectedFormObj,
                        "selectedProduct"
                      )
                        ? [selectedFormObj?.selectedProduct]
                        : []
                    }
                    displayValue={"displayName"}
                    disable={isUpdateMode ? true : false}
                    singleSelect
                  />)}

                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Row>
        {currentProduct?.data?.selectedVariants?.length > 0 && !showVariantCRUD ? (
          <Row className="variant-options-table-row mt-4">
            <CustomTable
              columns={tableStructures.crud_variant}
              data={currentProduct.data.selectedVariants}
              enableRowActions={true}
              positionActionsColumn={"last"}
              enableTopToolbar={true}
              emptyTableMsg={''}
              enableBottomToolbar={true}
              enablePagination={true}
              enableFilters={true}
              renderTopToolbarCustomActions={() => (
                <div className="table-heading-wrap">
                  <h4>{"Variants"}</h4>
                  <CustomButton
                    buttonClasses="icon-add-btn"
                    onClick={() => {
                      setSelectedVariant({});
                      setCreateVariant({ product_id: selectedFormObj?.selectedProduct?.id })
                      setIsUpdateMode(false);
                      setShowVariantCRUD(true);
                    }}
                  >
                    {"Add"}
                  </CustomButton>
                </div>
              )}
              renderRowActions={({ row }) => (
                <div className="table-action-box">
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      setSelectedVariant(row.original);
                      setIsUpdateMode(true);
                      setShowVariantCRUD(true);
                    }}
                  >
                    <EditIcon />
                  </CustomButton>
                  {currentProduct.data.selectedVariants?.length > 1 && (<CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => deleteProductVariant(row.original.id, dispatch)}
                  >
                    <DeleteIcon />
                  </CustomButton>)}

                </div>
              )}

              initialState={{
                columnPinning: {
                  right: ["mrt-row-actions"],
                },
              }}
            />
          </Row>
        ) : (
          <div className="seller-product-create-form  mt-4 ">
            <VariantsCrud variant={selectedVariant} createVariant={createVariant} createVariantOptions={variantOptions} setShowVariantCRUD={setShowVariantCRUD} />
          </div>
        )}
      </> : <Container className="seller-product-create-form   ">
        <h6>{"Please create product first"} </h6>
      </Container>
      }
      {/* {
        options?.length > 0 && (
          <> {(() => {
            if (!showVariantCRUD) {
              if (currentProduct?.data?.selectedVariants?.length > 0 && !currentProduct.loading) {
                return (
                  <Row className="variant-options-table-row">
                    <CustomTable
                      columns={tableStructures.crud_variant}
                      data={currentProduct.data.selectedVariants}
                      enableRowActions={true}
                      positionActionsColumn={"last"}
                      enableTopToolbar={true}
                      emptyTableMsg={''}
                      enableBottomToolbar={true}
                      enablePagination={true}
                      enableFilters={true}
                      renderTopToolbarCustomActions={() => (
                        <div className="table-heading-wrap">
                          <h4>{"Variants"}</h4>
                          <CustomButton
                            buttonClasses="icon-add-btn"
                            onClick={() => {
                              setSelectedVariant({});
                              setCreateVariant({ product_id: selectedFormObj?.selectedProduct?.id })
                              setIsUpdateMode(false);
                              setShowVariantCRUD(true);
                            }}
                          >
                            {"Add"}
                          </CustomButton>
                        </div>
                      )}
                      renderRowActions={({ row }) => (
                        <div className="table-action-box">
                          <CustomButton
                            buttonClasses="icon-action-btn"
                            onClick={() => {
                              setSelectedVariant(row.original);
                              setIsUpdateMode(true);
                              setShowVariantCRUD(true);
                            }}
                          >
                            <EditIcon />
                          </CustomButton>
                          {currentProduct.data.selectedVariants.length > 1 && (<CustomButton
                            buttonClasses="icon-action-btn"
                            onClick={() => deleteProductVariant(row.original.id, dispatch)}
                          >
                            <DeleteIcon />
                          </CustomButton>)}

                        </div>
                      )}

                      initialState={{
                        columnPinning: {
                          right: ["mrt-row-actions"],
                        },
                      }}
                    />
                  </Row>
                );
              } else if (currentProduct.data.selectedVariants?.length < 1 && !currentProduct.loading) {
                return (
                  <div className="seller-product-create-form   ">
                    <VariantsCrud variant={selectedVariant} createVariant={createVariant} createVariantOptions={variantOptions} setShowVariantCRUD={setShowVariantCRUD} />
                  </div>
                );
              } else if (currentProduct.loading) {
                return <Spinner />;
              }
            } else if (showVariantCRUD) {
              return (
                <div className="seller-product-create-form   ">
                  <VariantsCrud createVariant={createVariant} createVariantOptions={variantOptions} variant={selectedVariant} setShowVariantCRUD={backBackToList} />
                </div>
              );
            }
          })()}</>)
      } */}

    </Container >
  );
};


export default Variants;
