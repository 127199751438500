import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css"
import { Link } from "react-router-dom";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const accountBanner = (
    <div className="FAQ-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"FAQ"}</h2>
    </div>
  );

  return (
    <section className="FAQ-main-section">
      {accountBanner}
      <div className="FAQ-conatiner container py-5">
        <Row className="m-0">
          <h2>{"FAQ"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>

          <h6>{"Q : Why do I see different prices for the same product?"}</h6>
          <p><span>{"A : "}</span>{"You could see different prices for the same product, as it could be listed by many Sellers."}</p>

          <h6>{"Q : Is it necessary to have an account to shop on Bharee?"}</h6>
          <p><span>{"A : "}</span>{"Yes, it's necessary to log into your Bharee account to place product order. You'll have access to a personalised shopping experience including recommendations and fast check-out."}</p>

          <h2>{"Order"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>

          <h6>{"Q : Can I order a product that is 'Out of Stock'?"}</h6>
          <p><span>{"A : "}</span>{"The products listed as 'Out of Stock' are not available for sale."}</p>

          <h6>{"Q : What is Bharee Buyer Protection?"}</h6>
          <p><span>{"A : "}</span>{"Bharee Buyer Protection helps shoppers in case they have concerns with product(s) bought and haven't got a satisfactory solution from the seller."}<br />
            {"Under this program, we look into your concern on a case-by-case basis & do our best to be fair to both parties. Bharee's decision on the resolution will be final for the concern raised."}</p>

          <h6>{"Q : How does 'Instant Cashback' work?"}</h6>
          <p><span>{"A : "}</span>{"Instant Cashback' is applied directly to the product or order value in your cart and you do not have to wait for the cashback to be credited to your bank account/credit or debit card at a later date."}</p>

          <h6>{"Q : Are there any hidden charges when I shop on Bharee?"}</h6>
          <p><span>{"A : "}</span>{" There are NO hidden charges when you shop on Bharee. The price you see on the product page is final and it's exactly what you pay."}<br />
            {"Note: There can be additional delivery charges based on the seller's policy"}</p>


          <h2>{"Payment"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
          <h6>{"You can choose to pay on Bharee with any Visa, MasterCard issued in India."}</h6>
          <h6>{"Q : Can I pay with net-banking?"}</h6>
          <p><span>{"A : "}</span>{"You can choose to pay through net-banking from your saving /current bank account in India."}</p>

          <h6>{"Q :  Can I use any Debit Card to pay for my order?"}</h6>
          <p><span>{"A : "}</span>{"You can choose to pay for your order on Bharee with any Visa, MasterCard or Maestro Debit Card."}</p>

          <h6>{"Q :  What is pay on Delivery?"}</h6>
          <p><span>{"A : "}</span>{"Pay on Delivery is a mode of payment in which you can pay at the time of delivery of your order. You can pay using a credit card/ debit card through Bharee website or paying by cash."}</p>

          <h6>{"Q :  Can I use the pay on Delivery payment option for every product I buy on Bharee?"}</h6>
          <p><span>{"A : "}</span>{"The availability of pay on Delivery option depends on factors like seller payment mode conditions."}<br />
            {"Delivery:"}</p>

          <h6>{"Q :  Can I club my orders from different sellers to be delivered together?"}</h6>
          <p><span>{"A : "}</span>{"Currently, there is no option to club orders from different sellers to be delivered together as sellers could be located in different locations and the delivery timelines would vary based on their partnered logistics service providers. To ensure your items reach you at the earliest, each seller ships their products as per their individual timelines."}</p>

          <h6>{"Q :  Why do I see a 'delivery charge'?"}</h6>
          <p><span>{"A : "}</span>{"It usually costs sellers more to ship some items. So, sometimes they choose to add a delivery charge. The delivery charge is waived off by some sellers if you order with them for a certain amount. For more information, check the individual seller's policy on the product page."}</p>

          <h6>{"Q :  What is Bharee fulfilled?"}</h6>
          <p><span>{"A : "}</span>{"While Bharee fulfilled is the tag for all items listed by sellers who used our partner fulfilment services, the “Bharee fulfilled” badge is only given to select product based on certain criteria such as product quality and delivery timeline."}</p>

          <h6>{"Q :  Is there a filter available through which I can only see items with a Bharee fulfilled?"}</h6>
          <p><span>{"A : "}</span>{"Yes, there is a filter available through which you can shop only for items with the Bharee fulfilled."}</p>

          <h6>{"Q :  The delivery of my order is delayed. What should I do?"}</h6>
          <p><span>{"A : "}</span>{"On the rare occasion that your order is delayed, please check your email & messages for updates. A new delivery timeframe will be shared with you and you can also track its status by visiting My Orders."}</p>

          <h6>{"Q :  What should I do if my order is approved but hasn't been shipped yet?"}</h6>
          <p><span>{"A : "}</span>{"Sellers usually ship orders 1-2 business days before the delivery date so that they reach you on time. In case your order hasn't been shipped within this time please contact our Customer Support so that we can look into it."}</p>

          <h6>{"Q :  How do I know my order has been confirmed?"}</h6>
          <p><span>{"A : "}</span>{"An e-mail or SMS will be sent once you've successfully placed your order. We'll also let you know as soon as the seller ships the item(s) to you along with the tracking number(s) for your shipment(s). You can track your orders from the 'My Orders' section on your Bharee account."}</p>

          <h6>{"Q :  How quickly can I get my order delivered?"}</h6>
          <p><span>{"A : "}</span>{"Orders will be delivered by the date you see on the product page for your location."}</p>

          <h6>{"Q :  What should I do if I don't get the invoice for my order?"}</h6>
          <p><span>{"A : "}</span>{"Bharee/Sellers send a hard copy of the invoice in the shipments. A soft copy is also emailed to you within 24 hours of delivery in the delivery confirmation email sent to your registered email ID."}<br />
            {"You can also visit My Account › My Orders page to get invoices for your orders."}</p>


          <h2>{"Cancellation or Replacement"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>

          <h6>{"Q : If I request for a replacement, when will I get it?"}</h6>
          <p><span>{"A : "}</span>{"Visit My Orders to check the status of your replacement."}<br />
            {"The replacement is initiated with in mentioned time after the originally delivered item is return to seller warehouse."}<br />
            {"Please check the SMS & email we send you for your replacement request for more details."}</p>

          
        </Row>
      </div>
    </section>
  );
};


export default FAQ;
