import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import {
  AcceptIcon,
  CloseIcon,
  CrossIcon,
  EditIcon,
} from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getApi,
  postBrandStatus,
  postProductStatus,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { DummyDataNewOrders, tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import ProductCrud from "../../common/ProductCrud";
import BrandCrud from "../../common/BrandCrud";
import StatCard from "../../../common/StatCard";
import CustomTable from "../../common/CustomTable";
import CustomModalWrap from "../../../common/CustomModalWrap";
import DashbordOrder from "../../common/DashbordOrder/indes";

import TotalUsers from "../../../../assets/images/icons/TotalUsers.png"
import TotalBrands from "../../../../assets/images/icons/TotalBrands.png"
import TotalProducts from "../../../../assets/images/icons/TotalProducts.png"

const Dashbord = () => {
  const dispatch = useDispatch();
  const [filterProductsPendingArray, setFilterProductsPendingArray] = useState(
    []
  );
  const [filterBrandPendingArray, setFilterBrandPendingArray] = useState([]);

  const [showProductUpdatedMode, setShowProductUpdatedMode] = useState(false);

  const [showBrandUpdateMode, setShowBrandUpdateMode] = useState(false);
  const [uploadFormObj, setUploadFormObj] = useState({});


  const [selectedFormObj, setSelectedFormObj] = useState({});
  const [showOrderModal, setOrderModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");

  const { brands, products, adminStats, orders } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    getApi("getBrands",dispatch)
    getApi("getProducts",dispatch);
    getApi("getAdminStats",dispatch);
    getApi("getOrders",dispatch);
  }, []);
  useEffect(() => {
    if (products?.data?.length > 0) {
      const filtered = products?.data.filter(
        (item) => item.product_status === "pending"
      ); // Replace with your desired filter condition
      setFilterProductsPendingArray(filtered);
    }
  }, [products]);
  useEffect(() => {
    if (brands?.data?.length > 0) {
      const filtered = brands?.data.filter(
        (item) => item.brand_status === "pending"
      ); // Replace with your desired filter condition
      setFilterBrandPendingArray(filtered);
    }
  }, [brands]);
  const orderViewToggle = () => setOrderModal(!showOrderModal);


  const pendingProducts = () => {
    if (!showProductUpdatedMode) {
      if (filterProductsPendingArray?.length > 0 && !products.loading) {
        return (
          <Row className="m-0 pb-4">
            <CustomTable
              columns={tableStructures.admin_product}
              data={filterProductsPendingArray}
              enableRowActions={true}
              positionActionsColumn={"last"}
              enableTopToolbar={true}
              emptyTableMsg={''}
              enablePagination={true}
              enableBottomToolbar={true}
              enableFilters={true}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Edit/Accept/Reject",
                },
              }}
              renderRowActions={({ row, table }) => (
                <div className="table-action-box">
                  {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      setShowProductUpdatedMode(true);
                      setSelectedFormObj(row.original);
                    }}
                  >
                    <EditIcon />
                  </CustomButton>
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      postProductStatus(
                        {
                          product_id: row.original.id,
                          product_status: "accept",
                        },
                        dispatch
                      );
                    }}
                  >
                    <AcceptIcon />
                  </CustomButton>
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() =>
                      postProductStatus(
                        {
                          product_id: row.original.id,
                          product_status: "reject",
                        },
                        dispatch
                      )
                    }
                  >
                    <CrossIcon />
                  </CustomButton>
                </div>
              )}
              renderTopToolbarCustomActions={({ table }) => (
                <div className="table-heading-wrap">
                  <h4>{"Products pending requests "}</h4>
                </div>
              )}
              initialState={{
                columnPinning: {
                  right: ["mrt-row-actions"],
                },
              }}
            //   enableColumnOrdering={false} //enable some features
            //   enableRowSelection={false}
            //   enableColumnResizing={false}
            //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
            //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
            //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
            />
          </Row>
        );
      } else if (products.loading) {
        return <Spinner />;
      }
    } else if (showProductUpdatedMode) {
      return (
        <Container className="seller-product-create-form modal-custom-class  py-5">

          <ProductCrud product={selectedFormObj} setShowProductCreateMode={setShowProductUpdatedMode} renderInfo={<> {!isEmpty(selectedFormObj) && (
            <Row className="m-0 px-0">
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller business name"}</Form.Label>
                  <h6>{selectedFormObj?.seller.business_name}</h6>
                </Form.Group>
              </Col>
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller mobile number"}</Form.Label>
                  <h6>{selectedFormObj?.seller.mobile_number}</h6>
                </Form.Group>
              </Col>
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller email"}</Form.Label>
                  <h6>{selectedFormObj?.seller.email}</h6>
                </Form.Group>
              </Col>
            </Row>
          )}</>} />
        </Container>
      );
    }
  };
  const pendingBrands = () => {
    if (!showBrandUpdateMode) {
      if (filterBrandPendingArray?.length > 0 && !brands.loading) {
        return (
          <Row className="m-0 pb-4">
            <CustomTable
              columns={tableStructures.seller_brand}
              data={filterBrandPendingArray}

              enableRowActions={true}
              positionActionsColumn={"last"}
              enableTopToolbar={true}
              emptyTableMsg={''}
              enablePagination={true}
              enableBottomToolbar={true}
              enableFilters={true}
              displayColumnDefOptions={{
                "mrt-row-actions": {
                  header: "Edit/Accept/Reject",
                },
              }}
              renderRowActions={({ row }) => (
                <div className="table-action-box">
                  {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      setShowBrandUpdateMode(true);
                      setUploadFormObj(row.original);
                    }}
                  >
                    <EditIcon />
                  </CustomButton>
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      postBrandStatus(
                        {
                          brand_id: row.original.id,
                          brand_status: "accept",
                        },
                        dispatch
                      );
                    }}
                  >
                    <AcceptIcon />
                  </CustomButton>
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() =>
                      postBrandStatus(
                        {
                          brand_id: row.original.id,
                          brand_status: "reject",
                        },
                        dispatch
                      )
                    }
                  >
                    <CrossIcon />
                  </CustomButton>
                </div>
              )}
              renderTopToolbarCustomActions={({ table }) => (
                <div className="table-heading-wrap">
                  <h4>{"Brands pending requests"}</h4>
                </div>
              )}
              initialState={{
                columnPinning: {
                  right: ["mrt-row-actions"],
                },
              }}
            //   enableColumnOrdering={false} //enable some features
            //   enableRowSelection={false}
            //   enableColumnResizing={false}
            //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
            //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
            //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
            />
          </Row>
        );
      } else if (brands.loading) {
        return <Spinner />;
      }
    } else if (showBrandUpdateMode) {
      return (
        <Container className="seller-product-create-form modal-custom-class  py-5">
          <BrandCrud brand={uploadFormObj} setShowBrandCreateMode={setShowBrandUpdateMode} renderInfo={<> {!isEmpty(uploadFormObj) && (
            <Row className="m-0 px-0">
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller business name"}</Form.Label>
                  <h6>{uploadFormObj?.user?.business_name}</h6>
                </Form.Group>
              </Col>
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller mobile number"}</Form.Label>
                  <h6>{uploadFormObj?.user?.mobile_number}</h6>
                </Form.Group>
              </Col>
              <Col xs={4} className="px-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>{"Seller email"}</Form.Label>
                  <h6>{uploadFormObj?.user?.email}</h6>
                </Form.Group>
              </Col>
            </Row>
          )}</>} />
        </Container>
      );
    }
  };



  const OrderDetailsModal = () => {
    return (
      <CustomModalWrap
        className="modal-custom-class order-popup"
        hedaerContent={
          <>
            <h4>{"Order"}</h4>
            <p
              onClick={() => {
                orderViewToggle();
              }}
            >
              <CloseIcon />
            </p>
          </>
        }
        buttonClasses={"action-button hidden"}
        buttonContant={"view"}
        isModalOpen={showOrderModal}
        showModal={orderViewToggle}
        closeModal={orderViewToggle}
      >
        <DashbordOrder id={currentOrderId} />
      </CustomModalWrap>
    );
  };
  return (
    <Container className="bhree-admin-dashboard py-2">
      <Row className="mx-0 px-1 mb-4">
        <h1 className=" title text-uppercase">{"Dashboard"}</h1>
      </Row>
      {!isEmpty(adminStats?.data) && (
        <Row className="stats-row pb-2  mb-4">
          <StatCard cardTitle={"Total Users"} statNumber={adminStats?.data?.users} statPecentage="37" statIcon={TotalUsers} />
          <StatCard cardTitle={"Total Brands"} statNumber={adminStats?.data?.brands} statPecentage="47" statIcon={TotalBrands} />
          <StatCard cardTitle={"Total products"} statNumber={adminStats?.data?.products} statPecentage="12" statIcon={TotalProducts} />
        </Row>
      )}
      {OrderDetailsModal()}
      <Row className="section-row m-0">
        {/* <h4 className=" title ">{"New Orders"}</h4> */}
        {!isEmpty(orders.data) && (<CustomTable
          enableRowActions
          data={orders?.data}
          enableTopToolbar={true}
          columns={tableStructures.new_orders}
          initialState={{
            columnPinning: {
              right: ["mrt-row-actions"],
            },
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Row
              style={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <h4>{"New Orders"}</h4>
            </Row>
          )}
          renderRowActions={({ row, table }) => (
            <div className="table-action-box">

              {/* {OrderDetailsModal(row.original.id)} */}
              <CustomButton
                buttonClasses="action-button accept"
                onClick={() => {
                  setCurrentOrderId(row.original.id);
                  orderViewToggle()
                }}
              >
                {"View"}
              </CustomButton>
            </div>
          )}
        />)
        }

      </Row>

      {pendingBrands()}
      {pendingProducts()}
    </Container>
  );
};

export default Dashbord;
