import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    patchBrand,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { capitalize } from "lodash"
import { appConstants } from "../../../../helpers/app-constants";

const BasicInformation = ({ title = "BASIC INFORMATION" }) => {
    const dispatch = useDispatch();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, auth } = useSelector(
        (state) => {
            return state;
        }
    );
    useEffect(() => {
        setUploadFormObj(auth.user)
    }, [auth])

    const setUpdatedForm = (key, value) => {
        let updatedObj = {};
        updatedObj[key] = value;

        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };


    const updateBasicInfo = (e) => {
        e.preventDefault();
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "contact_person_name") &&
            !isEmpty(uploadFormObj.contact_person_name)
        ) {
            if (isUpdateMode) {
                patchBrand(auth.user.id, uploadFormObj, dispatch);
            }
        }
    };


    return (
        <Container className="basic-info-container">
            {(() => {
                if (!isUpdateMode) {
                    return (<Row className="basic-info m-0" >
                        <Row className="heading-div ">
                            <h5 className="text-uppercase p-0">{title}</h5>
                            {/* <p style={{ cursor: "pointer" }} onClick={() => { setIsUpdateMode(true); }}>{"Edit"}</p> */}
                        </Row>
                        <hr />
                        <Row className="m-0 p-0 ">
                            {auth.user.role.role_name !== appConstants.BHAREE && (<Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Business Name:"}</span>
                                    <span className="mx-2 bold-span"> {auth.user.business_name}</span>
                                </p>
                            </Col>)}

                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Contact Person:"}</span>
                                    <span className="mx-2 bold-span"> {auth.user.contact_person_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Email:"}</span>
                                    <span className="mx-2 bold-span"> {auth.user.email}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Mobile Number:"}</span>
                                    <span className="mx-2 bold-span">{auth.user.mobile_number}</span>

                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{`${capitalize(auth.user?.role["role_name"].replace(/_/g, " "))} Category`}</span>
                                    <span className="mx-2 bold-span">{capitalize(auth.user.roles_type["role_type_name"].replace(/_/g, " "))}</span>
                                </p>
                            </Col>
                        </Row>

                    </Row>)
                } else if (isUpdateMode) {
                    return (
                        <><Row className="m-0 px-0 w-full px-0 mb-4 title-button-row">
                            <h2>{!isUpdateMode ? `${title}` : `Update ${title}`}</h2>
                            <CustomButton
                                buttonClasses="view-switch-btn"
                                onClick={() => setIsUpdateMode(!isUpdateMode)}
                            >
                                {!isUpdateMode ? "Edit" : "View"}
                            </CustomButton>
                        </Row>
                            {!isEmpty(uploadFormObj) && (<Row className="m-0 w-full  form-row ">
                                <Form className="my-2 modal-custom-class" onSubmit={updateBasicInfo}>

                                    <Row className="m-0">
                                        <Col xs={12} sm={12} md={6} className="form-col left">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{"Bussiness Name"}</Form.Label>
                                                <Form.Control type="txt" value={
                                                    !isEmpty(uploadFormObj)
                                                        ? uploadFormObj["business_name"]
                                                        : "*_*"
                                                } disabled />
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{"Mobile Number"}</Form.Label>
                                                <Form.Control type="text" value={
                                                    !isEmpty(uploadFormObj)
                                                        ? uploadFormObj["mobile_number"]
                                                        : "*_*"
                                                } disabled />
                                            </Form.Group>


                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="form-col right">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{"Email"}</Form.Label>
                                                <Form.Control type="email" value={
                                                    !isEmpty(uploadFormObj)
                                                        ? uploadFormObj["email"]
                                                        : "*_*"
                                                } disabled />
                                            </Form.Group>

                                            <Form.Group className="mb-3" >
                                                <Form.Label>{`${capitalize(uploadFormObj?.role["role_name"].replace(/_/g, " "))} category`}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={
                                                        !isEmpty(uploadFormObj)
                                                            ? capitalize(uploadFormObj.roles_type["role_type_name"].replace(/_/g, " "))
                                                            : "*_*"
                                                    } disabled
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>{"Contact Person"}</Form.Label>
                                                <Form.Control type="text" value={
                                                    !isEmpty(uploadFormObj)
                                                        ? uploadFormObj["contact_person_name"]
                                                        : "*_*"
                                                }
                                                    onChange={(e) => setUpdatedForm("contact_person_name", e.target.value)}
                                                    disabled={!isUpdateMode} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="action-buttons">
                                        {isUpdateMode && (<><CustomButton
                                            variant="primary"
                                            buttonClasses={"close-btn"}
                                            loading={requestCall?.loading}
                                            onClick={() => setIsUpdateMode(false)}
                                        >
                                            {"close"}
                                        </CustomButton>
                                            <CustomButton
                                                variant="primary"
                                                buttonClasses={
                                                    !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                                                }
                                                type="submit"
                                                loading={requestCall?.loading}
                                            >
                                                {"update"}
                                            </CustomButton></>)}
                                    </div>
                                </Form>
                            </Row>)}</>
                    );
                }
            })()}


        </Container>
    );
};



export default BasicInformation;
