import React from "react";
import { Container } from "react-bootstrap";
export default function Spinner() {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        padding: "10%",
      }}
    >
      <div className="loading"></div>
      <p
        style={{
          padding: "2rem 1rem",
          fontFamily: "RobotoRegular",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "1rem",
          lineHeight: "1.25rem",
          color: "#626262",
        }}
      >
        {"please wait while loading..."}
      </p>
    </Container>
  );
}
