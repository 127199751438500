import React from "react";
import { Card, Accordion, useAccordionButton } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../common/CustomButton";
import {
  LaptopIcon,
  ReportIcon,
  SettingsIcon,
  WhiteProfile,
} from "../../../assets/svg/svg-icons";
import { stringConstants } from "../../../helpers/app-constants";

const AdminPlaneMenu = ({ auth, dispatch, handlePanelMenuClose }) => {

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <CustomButton
        // eventKey="1"
        onClick={decoratedOnClick}
        buttonClasses="menu-btn w-100 px-4 py-3 "
      >
        {children}
      </CustomButton>
    );
  };

  const OnMenuClicked = (setState) => {
    dispatch(setState);
    handlePanelMenuClose();
  }
  const bhareeAdminMenu = () => {
    return (
      <>
        <CustomButton
          buttonClasses="menu-btn w-100 px-4 py-3 "
          onClick={() => {
            OnMenuClicked({
              type: stringConstants.SET_CURRENT_VIEW,
              payload: stringConstants.DASHBOAD,
            })
          }}
        >
          <LaptopIcon />
          <span>{"Dashbord"}</span>
        </CustomButton>
        <CustomButton
          buttonClasses="menu-btn w-100 px-4 py-3 "
          onClick={() => {
            OnMenuClicked({
              type: stringConstants.SET_CURRENT_VIEW,
              payload: stringConstants.REPORTS,
            })
          }}
        >
          <ReportIcon />
          <span>{"Report"}</span>
        </CustomButton>
        <Accordion>
          <CustomToggle eventKey="0">
            <WhiteProfile />
            <span>{"Profile"}</span>
          </CustomToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="py-4">
              <CustomButton
                buttonClasses="sub-menu-btn w-100"
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.BASIC_INFORMATION,
                  })
                }}
              >
                <span>{"Basic Info"}</span>
              </CustomButton>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <CustomToggle eventKey="0">
            <SettingsIcon />
            <span>{"Settings"}</span>
          </CustomToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="py-4">
              <CustomButton
                buttonClasses="menu-btn w-100 px-5 py-2 "
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.BRANDS,
                  })
                }}
              >
                <span>{"Brands"}</span>
              </CustomButton>
              <CustomButton
                buttonClasses="menu-btn w-100 px-5 py-2 "
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.PRODUCTS,
                  })
                }}
              >
                <span>{"Products"}</span>
              </CustomButton>
              <CustomButton
                buttonClasses="menu-btn w-100 px-5 py-2 "
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.BASICS,
                  })
                }}
              >
                <span>{"Basics"}</span>
              </CustomButton>
              <CustomButton
                buttonClasses="menu-btn w-100 px-5 py-2 "
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.SERVICE_TAX_DETAILS,
                  })
                }}
              >
                <span>{"Service Tax"}</span>
              </CustomButton>
              <CustomButton
                buttonClasses="menu-btn w-100 px-5 py-2 "
                onClick={() => {
                  OnMenuClicked({
                    type: stringConstants.SET_CURRENT_VIEW,
                    payload: stringConstants.USERS,
                  })
                }}
              >
                <span>{"Users List"}</span>
              </CustomButton>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </>
    );
  };
  const sellerAdminMenu = () => {
    return (
      <>
        <CustomButton
          buttonClasses="menu-btn w-100 px-4 py-3 "
          onClick={() => {
            OnMenuClicked({
              type: stringConstants.SET_CURRENT_VIEW,
              payload: stringConstants.DASHBOAD,
            })
          }}

        >
          <LaptopIcon />
          <span>{"Dashbord"}</span>
        </CustomButton>
        {auth.user.onboarding && (<> <CustomButton
          buttonClasses="menu-btn w-100 px-4 py-3 "
          onClick={() => {
            OnMenuClicked({
              type: stringConstants.SET_CURRENT_VIEW,
              payload: stringConstants.REPORTS,
            })
          }}
        >
          <ReportIcon />
          <span>{"Report"}</span>
        </CustomButton>
          <Accordion>
            <CustomToggle eventKey="0">
              <WhiteProfile />
              <span>{"Business Info"}</span>
            </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="py-4">
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.COMPANY_INFORMATION,
                    })
                  }}
                >
                  <span>{"Company Info"}</span>
                </CustomButton>

                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.BANKING_AND_TAXATION,
                    })
                  }}
                >
                  <span>{"Banking info"}</span>
                </CustomButton>
                {auth.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_MANUFACTURER && (<CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.FACTORY_INFO,
                    })
                  }}
                >
                  <span>{"factory info"}</span>
                </CustomButton>)}
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.ADDRESS,
                    })
                  }}
                >
                  <span>{"Address"}</span>
                </CustomButton>
              </Card.Body>
            </Accordion.Collapse>
          </Accordion>
          <Accordion>
            <CustomToggle eventKey="0">
              <SettingsIcon />
              <span>{"Settings"}</span>
            </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="py-4">
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.BRANDS,
                    })
                  }}
                >
                  <span>{"Brands"}</span>
                </CustomButton>
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.PRODUCTS,
                    })
                  }}
                >
                  <span>{"Products"}</span>
                </CustomButton>
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.VARIANTS,
                    })
                  }}
                >
                  <span>{"VARIANTS"}</span>
                </CustomButton>
                <CustomButton
                  buttonClasses="sub-menu-btn w-100"
                  onClick={() => {
                    OnMenuClicked({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: stringConstants.LOGISTIC_PARTNER,
                    })
                  }}
                >
                  <span>{"LP"}</span>
                </CustomButton>
              </Card.Body>
            </Accordion.Collapse>
          </Accordion></>)}

      </>
    );
  };
  return (
    <div className="admion-plane-menu-div">
      {(() => {
        if (auth.user?.role?.role_name === stringConstants.ROLL_BHAREE) {
          return bhareeAdminMenu();
        }
        if (auth.user?.role?.role_name === stringConstants.ROLL_SELLER) {
          return sellerAdminMenu();
        }
      })()}
    </div>
  );
};


export default AdminPlaneMenu;
