import React, { useState  } from "react";
import { Row, Col, Image, Container,  Popover, OverlayTrigger, Button } from "react-bootstrap";
import "./index.css";
import {  useDispatch, useSelector } from "react-redux";
import BhareeLogo from "./../../assets/images/bhareeLogo.png";

import {
  BellIcon,
  BurgerMenu,
  LogOutIcon,
} from "../../assets/svg/svg-icons";
import CustomButton from "../common/CustomButton";
import AdminPlaneMenu from "./AdminPlaneMenu";
import { useEffect } from "react";
import AdminPanelBharee from "./AdminPanelBharee";
import AdminPanelSeller from "./AdminPanelSeller";
import { Link } from "react-router-dom";
import { appConstants, stringConstants } from "../../helpers/app-constants";
import setAuthToken from "../../utils/setAuthToken";
import { getApi } from "../../actions/structure.action";
import CustomOffCanvas from "../common/CustomOffCanvas";
const AdminPanel = () => {
  const dispatch = useDispatch();

  const popover = (
    <Popover className="notifcations-popper " id="popover-basic">
      <Popover.Header as="h3">Notifications</Popover.Header>
      <Popover.Body>
        <Container >
          <p>There is no new notifcations</p>
        </Container>
      </Popover.Body>
    </Popover>
  );
  const { auth, panleCurrentView } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    getApi("getBhreeAdminBasicTables",dispatch);
  }, [])

  const [showPanelMenu, setShowPanelMenu] = useState(false);
  const handlePanelMenuShow = () => setShowPanelMenu(true);
  const handlePanelMenuClose = () => setShowPanelMenu(false);

  const adminPlane = () => {
    if (auth.user?.role?.role_name === stringConstants.ROLL_BHAREE) {
      return <AdminPanelBharee currentView={panleCurrentView.currentView} />;
    }
    if (auth.user?.role?.role_name === stringConstants.ROLL_SELLER) {
      return <AdminPanelSeller currentView={panleCurrentView.currentView} />;
    }
  };

  const menuColumn = (<><div className="logo-div">
    <Link to="/">
      <Image src={BhareeLogo} alt="bhareeLogo" />
    </Link>
  </div>
    <AdminPlaneMenu handlePanelMenuClose={handlePanelMenuClose} dispatch={dispatch} auth={auth} />
    <CustomButton
      buttonClasses="logout-btn w-100  "
      onClick={() => {
        localStorage.removeItem("jwtToken");
        setAuthToken(false);
        dispatch({
          type: appConstants.LOGIN_SUCCESS,
          user: {},
        });
        window.location.href = "/";
      }}
    >
      <LogOutIcon />
      <span>{"log out"}</span>
    </CustomButton></>)

  return (
    <section className="admin-panel-section">
      <Row className="admin-panel-row m-0">
        <Col className="admin-panel-menu-col  desktop" md={3} lg={2} >
          {menuColumn}
        </Col>

        <Col className="admin-panel-content-col" xs={12} md={9} lg={10} >
          <Row className="m-0 header-row w-100">
            <div className="logo-div ">
              <div className="mobile burger-menu-wrap">
                <CustomOffCanvas
                  buttonContant={<BurgerMenu />}
                  placement={"start"}
                  className="panel-ofcanvas"
                  backdropClassName="panel-backdrop"
                  bodyBsPrefix="panel-ofcanvas-body"
                  isOffCanvasOpen={showPanelMenu}
                  OffcanvasShow={handlePanelMenuShow}
                  OffcanvasClose={handlePanelMenuClose}
                >
                  <div className="admin-panel-menu-col  mobile" >
                    {menuColumn}
                  </div>
                </CustomOffCanvas>
              </div>
              <Link to="/" className="mobile">
                <Image src={BhareeLogo} alt="bhareeLogo" />
              </Link>
              {/* <Image src={BhareeLogo} alt="BhareeLogo" /> */}
            </div>
            <div className="header-menu ">
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <Button><BellIcon /></Button>
              </OverlayTrigger>
              {/* <Image src={ProfileIcon} /> */}
              {/* <TrippleDotIcon  /> */}

            </div>
          </Row>
          <Row className="m-0 content-view w-100">{adminPlane()}</Row>
        </Col>
      </Row>
    </section>
  );
};

export default AdminPanel;
