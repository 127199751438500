import React, { useEffect } from "react";
import { Row, Col, Image, Button, Form } from "react-bootstrap";
import {
    MagnifierIcon,
    SaveIcon,
} from "../../assets/svg/svg-icons";
import { useDispatch, useSelector } from "react-redux";
import plastic1 from "../../assets/images/plastic-1.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "./account.css";
import { getApi } from "../../actions/structure.action";
import isEmpty from "../../utils/is-Empty";
import { Link } from "react-router-dom";




const OrdersList = () => {
    const dispatch = useDispatch();

    const { orders } = useSelector((state) => {
        return state;
    });

    useEffect(() => {
        getApi("getOrders", dispatch);
    }, []);


    const orderSummaryAccount = (item, index) => {
        return (
            <Link key={`${index}_Order_${item?.orderId}`} className="order-link" to={`/order/${item?.id}`}>
                <Row className={`order-summery-card-my-account m-0 mb-4`}>
                    <Col xs={2} sm={2} md={2} className="image-col">
                        <Image
                            src={item?.product_variant_id[0].productImages[0].url}
                            alt={item?.product_variant_id[0].brand_name}
                        />
                    </Col>
                    <Col xs={7} sm={7} md={7} className="description-col">
                        <div className="summary-card-title">
                            {!isEmpty(item?.offerPrice) ?
                                <h5>{`Bisleri ${item?.product_variant_id[0].product_name}   |   Rs. ${item?.offerPrice}/case`} <s>{`Rs. ${item?.price}/case`}</s> </h5>
                                :
                                <h5>{`Bisleri ${item?.product_variant_id[0].product_name}   |   Rs. ${item?.price}/case`}</h5>
                            }
                        </div>
                        <hr style={{ width: "85%" }} />
                        <div className="summary-card-details">
                            <Row className="my-acccount-summary-card-qnt">
                                <Col
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    className="my-acccount-summary-card-item"
                                ></Col>
                                <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                                    <p>{"Size"}</p>
                                    <h6>{"1L"}</h6>
                                </Col>
                                <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                                    <p>{"MOQ"}</p>
                                    <h6>{` ${item?.moq} Case"`}</h6>
                                </Col>
                                <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                                    <p>{"Amount"}</p>
                                    <h6>{"₹  "}
                                        {!isEmpty(item?.offerPrice) ?
                                            (parseInt(item?.moq) * parseInt(item.offerPrice))
                                            :
                                            (parseInt(item?.moq) * parseInt(item.price))
                                        }
                                    </h6>
                                </Col>
                                <Col xs={5} sm={5} md={5} className="my-acccount-summary-card-item">
                                    <p>{"Status"}</p>
                                    <h6>{"Delivered on 25 Dec 2022"}</h6>
                                    <p>{"by Bharee "}</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={3} sm={3} md={3} className="text-uppercase invoice-btn">
                        <p>{`ORDER # ${item?.orderId}`}</p>
                        {/* invoice fuctanalty is on process */}
                        <Button className="text-uppercase btn">
                            {"invoice "}
                            {<SaveIcon />}
                        </Button>
                    </Col>
                </Row>
            </Link>
        );
    };
    const orderPending = () => (
        <Row className="order-summery-card-my-account m-0 mb-4">
            <Col xs={2} sm={2} md={2} className="image-col">
                <Image src={plastic1} alt="plastic1" />
            </Col>
            <Col xs={7} sm={7} md={7} className="description-col">
                <div className="summary-card-title">
                    <h5>{"Bisleri Packaged Drinking Water   |   Rs. 100/case"}</h5>
                </div>
                <hr style={{ width: "85%" }} />
                <div className="summary-card-details">
                    <Row className="my-acccount-summary-card-qnt">
                        <Col
                            xs={1}
                            sm={1}
                            md={1}
                            className="my-acccount-summary-card-item"
                        ></Col>
                        <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                            <p>{"Size"}</p>
                            <h6>{"1L"}</h6>
                        </Col>
                        <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                            <p>{"MOQ"}</p>
                            <h6>{"200 Case"}</h6>
                        </Col>
                        <Col xs={2} sm={2} md={2} className="my-acccount-summary-card-item">
                            <p>{"Amount"}</p>
                            <h6>{"₹  20000"}</h6>
                        </Col>
                        <Col xs={5} sm={5} md={5} className="my-acccount-summary-card-item">
                            <p>{"Status"}</p>
                            <h6>{"Delivered on 25 Dec 2022"}</h6>
                            <p>{"by Bharee "}</p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col xs={3} sm={3} md={3} className="text-uppercase pendiing-order-btn">
                <p>{"ORDER # 403-2879952-6526"}</p>
                <span>{"Due in 5 days"}</span>
                <Button className="text-uppercase btn">{"Pay Now "}</Button>
            </Col>
        </Row>
    );

    return (
        <>

            <div className="my-order-headingbox">
                <h4 className="text-uppercase m-0">{"my orders"}</h4>
                <div className="search-and-dropdown-box">
                    <MagnifierIcon />
                    <Form.Select aria-label="Default select example">
                        <option value="1">{"Last 3 month"}</option>
                        <option value="2">{"Last 6 month"}</option>
                        <option value="3">{"Last 9 month"}</option>
                    </Form.Select>
                </div>
            </div>
            <hr />
            <div style={{
                maxHeight: "36rem",
                overflowY: "auto"
            }}>
                {!isEmpty(orders?.data) && orders?.data?.length > 0 && orders.data.map((item, index) => {
                    return orderSummaryAccount(item, index);
                })}
                {isEmpty(orders?.data) && <p>{"No orders placed"}</p>}
            </div>
        </>);
};

export default OrdersList;
