import React from "react";
import { Button, Modal } from "react-bootstrap";

const CustomModalWrap = ({
  id = "",
  buttonClasses = "",
  hedaerContent = "",
  headerTitle,
  crossButoon = false,
  isModalOpen,
  showModal,
  closeModal,
  buttonContant,
  footerContent,
  children,
  ...restprops
}) => {
  return (
    <>
      {buttonContant && (
        <Button id={id} onClick={showModal} className={buttonClasses}>
          {buttonContant}
        </Button>
      )}
      <Modal show={isModalOpen} onHide={closeModal} {...restprops}>
        {hedaerContent &&
          (crossButoon ? (
            <Modal.Header closeButton>
              {hedaerContent}
              {"header close btn"}
            </Modal.Header>
          ) : (
            <Modal.Header>{hedaerContent}</Modal.Header>
          ))}
        <Modal.Body>{children}</Modal.Body>
        {footerContent && (
          <Modal.Footer>
            {footerContent}
            {"footer"}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default CustomModalWrap;
