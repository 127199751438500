import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import {
  DeleteIcon,
  EditIcon,
} from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteLiveOffer,
  getApi
} from "../../../../actions/structure.action";
import { tableStructures } from "../../../../helpers/table-structures";
import CustomTable from "../../common/CustomTable";
import LiveOfferCard from "../../common/LiveOfferCard";
import "./index.css"

const LiveOffers = ({ mainClass }) => {
  const dispatch = useDispatch();
  const [showOffersMode, setShowOffersMode] = useState(false);
  const [currentOfferObj, setCurrentOfferObj] = useState({});

  const { liveOffers } = useSelector(
    (state) => {
      return state;
    }
  );
  useEffect(() => {
    getApi("getLiveOffer", dispatch)

  }, []);
  useEffect(() => {
    setShowOffersMode(false)
  }, [liveOffers]);

  return (
    <Row className={mainClass}>
      {showOffersMode || liveOffers.data?.length < 1 ? (
        <Container className="seller-live-offer-create-form modal-custom-class  ">
          <LiveOfferCard offer={currentOfferObj} closeMode={setShowOffersMode} listEmpty={liveOffers?.length > 0 ? false : true} />
        </Container>
      ) : (
        <CustomTable
          data={liveOffers.data}
          columns={tableStructures.live_offers}
          enableTopToolbar={true}
          enableRowActions={true}
          initialState={{
            columnPinning: {
              right: ["mrt-row-actions"],
            },
          }}
          positionActionsColumn={"last"}
          renderRowActions={({ row }) => {
            return row.original.status === 1 && (
              < div className="table-action-box" >
                <CustomButton
                  buttonClasses="icon-action-btn"
                  onClick={() => {
                    setShowOffersMode(true);
                    setCurrentOfferObj(row.original);
                  }}
                >
                  <EditIcon />
                </CustomButton>
                <CustomButton
                  buttonClasses="icon-action-btn"
                  onClick={() => deleteLiveOffer(row.original.id, dispatch)}
                >
                  <DeleteIcon />
                </CustomButton>
              </div>
            )
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <h4>{"Live offers"}</h4>

              <CustomButton
                buttonClasses="icon-add-btn"
                onClick={() => {
                  setShowOffersMode(true);
                  setCurrentOfferObj({});
                }}
              >
                {"Create"}
              </CustomButton>
            </>
          )}
        />)
      }
    </Row >
  );
};

export default LiveOffers;
