import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Accordion } from "react-bootstrap";
import { EditIcon, CrossIcon } from "../../assets/svg/svg-icons";
import RefferSection from "../common/RefferSection";

import plastic1 from "../../assets/images/plastic-1.png";
import "react-image-gallery/styles/css/image-gallery.css";

import "./cart.css";
import { useSelector, useDispatch } from "react-redux";
import { getApi, getCart } from "../../actions/structure.action";
import isEmpty from "../../utils/is-Empty";
import { Link } from "react-router-dom";
import Spinner from "../common/Spinner";
import PaymentOrderSummary from "./OrderSummary";

const Cart = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // getApi("getBhreeAdminBasicTables",dispatch);
    getApi("getCart", dispatch)
  }, []);

  const cartBanner = (
    <div className="cart-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Cart"}</h2>
    </div>
  );

  return (
    <section className="cart-main-section">
      {cartBanner}

      {cart.loading ? <Container style={{ padding: "5rem" }}>
        <Spinner />
      </Container> : !isEmpty(cart?.data) && cart?.data?.cartCount > 0 ? Object.keys(cart?.data).map((key, value) => {
        if (Array.isArray(cart?.data[key]) && !isEmpty(cart?.data[key])) {
          return <PaymentOrderSummary title={key} index={value} />
        }
      }) : <Container style={{ padding: "5rem" }}>
        <Link className='nav-link-color '
          style={{ fontSize: "1rem", color: "#FDC345", padding: "1rem", border: "1px solid #FDC345", borderRadius: "5px", margin: "1rem" }} to={"/brands"}>
          {"Please add Products to cart"}
        </Link>
      </Container>}
      <RefferSection />
    </section>
  );
}

export default Cart;
