import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Image, Button, Form, Offcanvas } from "react-bootstrap";
import {
  CloseBoxIcon,
  WallateIcon,
  CaseIcon,
  MagnifierIcon,
  SaveIcon,
  CrossBoxIcon,
  SideMenuIcon,
} from "../../assets/svg/svg-icons";
import { useDispatch, connect, useSelector } from "react-redux";
import plastic1 from "../../assets/images/plastic-1.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "./account.css";
import Address from "../AdminPanel/common/Address/index"
import BasicInformation from "../AdminPanel/common/BasicInformation";
import { getApi } from "../../actions/structure.action";
import OrdersList from "./OrdersList";


const Account = () => {
  const dispatch = useDispatch();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const ref = useRef([]);
   useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getApi("getBhreeAdminBasicTables", dispatch)
    getApi("getOrders", dispatch);
  }, []);
  const handleScrollView = (index) => {
    ref.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const handleCloseSideMenu = () => setShowSideMenu(false);
  const handleShowSideMenu = () => setShowSideMenu(true);
  const accountBanner = (
    <div className="account-banner-main-div text-uppercase desktop">
      <p>{"HOME "}</p>
      <h2>{"My Account"}</h2>
    </div>
  );
  const accountSideMenu = (
    <>
      <div className="account-profile-side-menu-icon text-uppercase  mobile">
        <Button onClick={handleShowSideMenu}>
          <SideMenuIcon />
        </Button>
        <Offcanvas
          show={showSideMenu}
          onHide={handleCloseSideMenu}
          start="true"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <div className="account-side-menu-option ">
              <div
                className="heading-menu active"
                style={{ borderRadius: "10px 10px 0px 0px" }}
              >
                <CloseBoxIcon />
                <h5 className="my-0 mx-2">{"my account"}</h5>
              </div>
              <div className="submenu ">
                <p className="mt-2 pointer" onClick={() => handleScrollView(0)}>
                  {"Business Information"}
                </p>
                <p className="mt-2 pointer" onClick={() => handleScrollView(1)}>
                  {"Address"}
                </p>
              </div>
            </div>
            <div className="account-side-menu-option ">
              <div className="heading-menu ">
                <WallateIcon />
                <h5 className="my-0 mx-2">{"payment"}</h5>
              </div>
              <div className="submenu ">
                <p className="mt-2 pointer" onClick={() => handleScrollView(3)}>
                  {"saved upi"}
                </p>
                <p className="mt-2 pointer" onClick={() => handleScrollView(4)}>
                  {"saves cards"}
                </p>
              </div>
            </div>
            <div className="account-side-menu-option ">
              <div className="heading-menu ">
                <CaseIcon />
                <h5 className="my-0 mx-2">{"my stuffs"}</h5>
              </div>
              <div className="submenu ">
                <p className="mt-2 pointer" onClick={() => handleScrollView(5)}>
                  {"my coupons"}
                </p>
                <p className="mt-2 pointer" onClick={() => handleScrollView(6)}>
                  {"my reviews & Ratings"}
                </p>
                <p className="mt-2 pointer" onClick={() => handleScrollView(7)}>
                  {"all notification"}
                </p>
              </div>
            </div>
            <div className="account-side-menu-option ">
              <Button>{"Log Out"}</Button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className="account-side-menu text-uppercase desktop">
        <div className="account-side-menu-option ">
          <div
            className="heading-menu active"
            style={{ borderRadius: "10px 10px 0px 0px" }}
          >
            <CloseBoxIcon />
            <h5 className="my-0 mx-2">{"my account"}</h5>
          </div>
          <div className="submenu ">
            <p className="mt-2 pointer" onClick={() => handleScrollView(0)}>
              {"Business Information"}
            </p>
            <p className="mt-2 pointer" onClick={() => handleScrollView(1)}>
              {"Address"}
            </p>
          </div>
        </div>
        <div className="account-side-menu-option ">
          <div className="heading-menu ">
            <WallateIcon />
            <h5 className="my-0 mx-2">{"payment"}</h5>
          </div>
          <div className="submenu ">
            <p className="mt-2 pointer" onClick={() => handleScrollView(3)}>
              {"saved upi"}
            </p>
            <p className="mt-2 pointer" onClick={() => handleScrollView(4)}>
              {"saves cards"}
            </p>
          </div>
        </div>
        <div className="account-side-menu-option ">
          <div className="heading-menu ">
            <CaseIcon />
            <h5 className="my-0 mx-2">{"my stuffs"}</h5>
          </div>
          <div className="submenu " style={{ borderRadius: "0px 0px 10px 10px"}}>
            <p className="mt-2 pointer" onClick={() => handleScrollView(5)}>
              {"my coupons"}
            </p>
            <p className="mt-2 pointer" onClick={() => handleScrollView(6)}>
              {"my reviews & Ratings"}
            </p>
            <p className="mt-2 pointer" onClick={() => handleScrollView(7)}>
              {"all notification"}
            </p>
          </div>
        </div>
        {/* <div className="account-side-menu-option ">
          <Button>{"Log Out"}</Button>
        </div> */}
      </div>
    </>
  );

  return (
    <section className="account-main-section">
      {accountBanner}
      <div className="container py-5">
        <Row className="m-0">
          <Col xs={12} sm={12} md={3} className="py-2">
            {accountSideMenu}
          </Col>
          <Col xs={12} sm={12} md={9} className="py-2">
            <OrdersList />
            <div className="mt-4" ref={(el) => (ref.current[0] = el)}><BasicInformation title="Business information"  /></div>
            <div className="mt-4" ref={(el) => (ref.current[1] = el)}><Address  /></div>
            
            {/* <div
              className="save-card-secvtion "
              ref={(el) => (ref.current[4] = el)}
            >
              <div className="heading-div">
                <h5 className="text-uppercase">{"Saved Cards"}</h5>
                <p>{"+ Add"}</p>
              </div>
              <hr />
              <Row className="m-0 ">
                <p>{"Your saved credit and debit cards"}</p>
                <Col xs={12} sm={12} md={6} className="form-col left mb-3">
                  <div className="cred-details-box">
                    <h6 className="m-0 text-uppercase">
                      {"SBI CREDIT CARD    XXXX2568"}
                    </h6>
                    <CrossBoxIcon />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} className="form-col right mb-3">
                  <div className="cred-details-box">
                    <h6 className="m-0 text-uppercase">
                      {"HDFC DEBIT CARD    XXXX2586"}
                    </h6>
                    <CrossBoxIcon />
                  </div>
                </Col>
              </Row>
            </div> */}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Account;
