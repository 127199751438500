import React from "react";
import { Row } from "react-bootstrap";
import MaterialReactTable from "material-react-table";

import "./index.css"


const CustomTable = ({ CustomTopClass = "", columns = [], data = [], emptyTableMsg = "There is no recode to show", enableTopToolbar = false, enableRowActions = false, enableBottomToolbar = false, enableColumnActions = false, enableDensityToggle = false, enableFullScreenToggle = false, enablePagination = false, enableHiding = false, enableColumnFilters = false, enableFilters = false, ...restProps }) => {

  return (
    <Row className={" bhree-custom-table m-0 p-0 " + CustomTopClass}>
      {(() => {
        if (columns.length > 0 && data.length > 0) {
          return (<MaterialReactTable
            columns={columns}
            data={data}
            enableRowActions={enableRowActions}
            enableTopToolbar={enableTopToolbar}
            enableBottomToolbar={enableBottomToolbar}
            enableDensityToggle={enableDensityToggle} // remove density action
            enableColumnActions={enableColumnActions} // remove column action
            enableFullScreenToggle={enableFullScreenToggle} // emove full screen option
            enablePagination={enablePagination} //disable a default feature
            enableHiding={enableHiding} // show hid colmuns action
            enableColumnFilters={enableColumnFilters}
            enableFilters={enableFilters}
            layoutMode="grid-no-grow"
            // defaultColumn={{
            //   minSize: 5, //allow columns to get smaller than default
            //   maxSize: 10, //allow columns to get larger than default
            //   size: 10, //make columns wider by default
            // }}
            // positionActionsColumn={"last"}

            //   enableColumnOrdering={false} //enable some features
            //   enableRowSelection={false}
            //   enableColumnResizing={false}
            //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
            //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
            //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
            {...restProps}
          />)
        } else {
          return <p>{emptyTableMsg}</p>
        }
      })()}

    </Row>
  );
};

export default CustomTable;
