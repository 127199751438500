import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "./statCard.css";
import {  QuestionIcon } from "../../assets/svg/svg-icons";
import RupeePng from "./../../assets/images/rupee-png.png";

const StatCard = ({ cardTitle = "Total sale", statNumber = " Rs 16000", statPecentage = "34", statIcon = RupeePng }) => {
    return (
        <Row className="stat-card-row">
            <Col xs={8} className="text-column">
                <h6>{cardTitle}</h6>
                <p className="stat-nummber">{statNumber}</p>
                <div className="stat-percent-div">
                    <QuestionIcon /><span>{statPecentage}{"% Last Month"}</span>
                </div>
            </Col>
            <Col xs={4} className="img-column">
                <div className="img-round-box">
                    <Image src={statIcon} alt="icon" />
                </div>
            </Col>
        </Row>
    )
};



export default StatCard;
