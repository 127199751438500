import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../../../utils/is-Empty";
import "./index.css"
import { calVarinats, perBottlecostList, perBoxList, } from "../../../../helpers/app-constants";
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import TimestampConverter from "../../../../utils/TimestampConverter";
const CalculationPage = () => {
    const navigate = useNavigate();
    const { auth } = useSelector(
        (state) => {
            return state;
        }
    );
    const [calcualtionObj, setCalcualtionObj] = useState(JSON.parse(localStorage[`newCalucation_${auth?.user?.id}`]));

    const doc = new jsPDF({

        orientation: 'landscape',
        format: 'a0',
        unit: 'px',
    });
    useEffect(() => {
        // setCalcualtionObj(JSON.parse(localStorage[`newCalucation_${auth?.user?.id}`]));
        doc.html(document.getElementById('content-id2'), {
            async callback(doc) {
                await doc.save(`Cost Calculations-${TimestampConverter(new Date().toISOString())}`);
            },
        });
        navigate("/seller/profile-panel");
    }, [])

    return (
        <Container className="m-4 new-calculation text-start" id="content-id2" >
            <Row className="mx-0 px-1 mb-4 ">
                <h4 className="p-0 ">{"Cost Calculations"}</h4>
            </Row>
            <Form className="my-2 calculation-form" >
                <Row className="m-0">
                    {/* <Col xs={12} className="scrollXsection">
                        <Row className="m-0 pb-2 coverRow">
                            <Col xs={6} className="p-0">
                                <h4 className="px-2">{"Production"}</h4>
                                <Row className="m-0" style={{ flexWrap: "nowrap" }}>
                                    {
                                        !isEmpty(calcualtionObj) && calVarinats.length > 0 && calVarinats.map((variant) => {
                                            return <Col xs={3} lg={2} ><h6 className="text-start">{variant.displayName}</h6>
                                                {
                                                    expensesList.map((expenses) => {
                                                        if (expenses.key === "electricity") {
                                                            return (<Form.Group className="mb-3 input-grop-calculation " >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="0"
                                                                    className="w-100"
                                                                    required
                                                                    disabled={expenses.key !== "electricity"}
                                                                    value={!isEmpty(calcualtionObj.variants) && calcualtionObj.variants[variant.quantity]["production"]["electricity"] ? calcualtionObj.variants[variant.quantity]["production"]["electricity"] : ""}
                                                                />
                                                            </Form.Group>)
                                                        }
                                                    })
                                                }
                                            </Col>

                                        })}
                                </Row>
                            </Col>


                        </Row>
                    </Col>
                    <Col xs={12} className="mobile" >
                        <h4 className="mb-3">{"Expenses"}</h4>
                        <Row className="m-0">
                            <Col xs={6}></Col>
                            <Col xs={6} className="p-0" ><h6 className="text-start">{"Cost"}</h6></Col>
                        </Row>
                        {
                            expensesList.map((expenses) => {
                                return (<Form.Group className="mb-3 input-grop-calculation" >
                                    <Form.Label>
                                        {expenses.name}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="0"
                                        required
                                        value={!isEmpty(calcualtionObj?.expenses) && calcualtionObj?.expenses[expenses.key] !== "" ? calcualtionObj?.expenses[expenses.key] : ""}
                                    />
                                </Form.Group>)
                            })
                        }
                    </Col>
                    <Col xs={12} className="col-padding scrollXsection" >
                        <Row className="m-0 coverRow">
                            <Col xs={3} className="desktop" style={{ order: 1 }}>
                                <h4 className="mb-3">{"Expenses"}</h4>
                                <Row className="m-0">
                                    <Col xs={6}></Col>
                                    <Col xs={6} className="p-0" ><h6 className="text-start">{"Cost"}</h6></Col>
                                </Row>
                                {
                                    expensesList.map((expenses) => {
                                        return (<Form.Group className="mb-3 input-grop-calculation" >
                                            <Form.Label>
                                                {expenses.name}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                required
                                                value={!isEmpty(calcualtionObj?.expenses) && calcualtionObj?.expenses[expenses.key] !== "" ? calcualtionObj?.expenses[expenses.key] : ""}
                                            />
                                        </Form.Group>)
                                    })
                                }

                            </Col>
                            <Col xs={9} lg={6} className="p-0">
                                <Row className="m-0 ">
                                    <Row className="m-0 mb-3 p-0 raw-matrial-cost-cover">
                                        <h4 className="m-0">{"Raw Matrial Cost"}</h4>
                                        <Form.Group className=" input-grop-calculation" >
                                            <Form.Label>
                                                {"Preform Per Kg"}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="0"
                                                required
                                                value={!isEmpty(calcualtionObj?.expenses) && calcualtionObj?.totalCalucation["preformPerKg"] ? calcualtionObj?.totalCalucation["preformPerKg"] : ""}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Col xs={12} className=" p-0">
                                        <Row className="m-0 pb-3 coverRow" style={{ flexWrap: "nowrap" }}>
                                            <Col xs={2} sx={1} md={2} lg={1}>
                                                <h6 className="text-start">{"Varinats"}</h6>

                                                {calVarinats.length > 0 && calVarinats.map((variant) => {
                                                    return (<Form.Group className="mb-3 input-grop-calculation " >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="0"
                                                            className="w-100 m-0"
                                                            value={variant.displayName}

                                                            disabled
                                                        // onChange={(e) => onChangeTotalCalculation(e.target.value, "preformPerKg")}
                                                        />
                                                    </Form.Group>
                                                    )
                                                })}
                                            </Col>
                                            {
                                                rowMatrialList.map((matrial) => {
                                                    return (<Col xs={2} md={1}><h6 className="text-start">{matrial.name}</h6>
                                                        {
                                                            calVarinats.length > 0 && calVarinats.map((variant) => {
                                                                return (<Form.Group className="mb-3 input-grop-calculation " >
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="0"
                                                                        className="w-100 m-0"
                                                                        required
                                                                        value={!isEmpty(calcualtionObj.variants) && calcualtionObj.variants[variant.quantity].variantRowMatrialCost[matrial.key] ? calcualtionObj.variants[variant.quantity].variantRowMatrialCost[matrial.key] : ""}
                                                                    />
                                                                </Form.Group>)
                                                            })}
                                                    </Col>)
                                                })
                                            }
                                        </Row>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className=" p-0 my-4" >

                        <Row className="m-0 p-2 " style={{ flexWrap: "nowrap" }}>
                            <h4 className="m-0 p-0 " style={{ width: "max-content" }}>{"Selling Price :"}</h4>

                            {
                                calVarinats.length > 0 && calVarinats.map((variant) => {

                                    return (<Col xs={3} lg={2} ><Form.Group className=" input-grop-calculation " >
                                        <Form.Label >
                                            {variant.displayName}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="0"
                                            className="m-0"
                                            required
                                            value={!isEmpty(calcualtionObj.variants) && calcualtionObj.variants[variant.quantity].finalCostTableByVariants["salePrice"] ? calcualtionObj.variants[variant.quantity].finalCostTableByVariants["salePrice"] : ""}
                                        />
                                    </Form.Group></Col>)

                                })}

                        </Row>
                    </Col> */}
                    <Col xs={12} className=" p-0" >
                        <Row className="m-0  " >
                            <Col xs={12} className="col-padding">
                                <Row className="m-0 coverRow" style={{ flexWrap: "nowrap" }}>
                                    <Col xs={2} md={1} className="setColInputs" >
                                        <h4 style={{ width: "max-content" }}>{"Per Bottle cost"}</h4>
                                        <h6 className="text-start">{"Varinats"}</h6>

                                        {calVarinats?.length > 0 && calVarinats.map((variant) => {
                                            return (<Form.Group className="mb-3 input-grop-calculation " >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="0"
                                                    className="w-100 m-0"
                                                    value={variant.displayName}
                                                    disabled
                                                />
                                            </Form.Group>
                                            )
                                        })}
                                    </Col>
                                    {
                                        perBottlecostList.map((perBox) => {
                                            return (<Col xs={2} md={1} className="setColInputs"><h6 className="text-start">{perBox.name}</h6>
                                                {
                                                    calVarinats?.length > 0 && calVarinats.map((variant) => {
                                                        return (<Form.Group className="mb-3 input-grop-calculation " >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="0"
                                                                className="w-100 m-0"
                                                                value={!isEmpty(calcualtionObj.variants) && calcualtionObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] ? calcualtionObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] : ""}
                                                                disabled
                                                            // onChange={(e) => onChangeMonthlyExpenses(e.target.value, expenses.key)}
                                                            />
                                                        </Form.Group>)
                                                    })}
                                            </Col>)
                                        })
                                    }
                                    {
                                        perBoxList.map((perBox, index) => {
                                            return (<Col xs={3} lg={1} className="setColInputs">
                                                {index === 0 && (<h4>{"Per Box"}</h4>)}

                                                <h6 className="text-start">{perBox.name}</h6>
                                                {
                                                    calVarinats?.length > 0 && calVarinats.map((variant) => {
                                                        return (<Form.Group className="mb-3 input-grop-calculation " >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="0"
                                                                className="w-100 m-0"
                                                                required
                                                                value={!isEmpty(calcualtionObj.variants) && calcualtionObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] ? calcualtionObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] : ""}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>)
                                                    })}
                                            </Col>)
                                        })
                                    }
                                    <Col xs={3} lg={2} className="setColInputs">
                                        <h6>{"GROSS PROFIT"}</h6>
                                        <div style={{
                                            display: "flex",
                                            minHeight: "12rem"
                                        }}>
                                            <Form.Group className="mb-3 input-grop-calculation " >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="0"
                                                    className="w-100 m-0"
                                                    value={calcualtionObj?.totalCalucation?.totalGrossProfit}
                                                    disabled
                                                // onChange={(e) => onChangeMonthlyExpenses(e.target.value, expenses.key)}
                                                />

                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>


                </Row>
            </Form >
        </Container >
    );
};


export default CalculationPage;
