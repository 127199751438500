import { appConstants } from "../helpers/app-constants";

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const initialState = {
  data: {},
  loading: false,
  errors: {},
};

export function serviceTaxDetails(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_SERVICET_TAX_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case appConstants.GET_SERVICET_TAX_DETAILS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        errors: {}
      };

    case appConstants.ERRORS_SERVICET_TAX_DETAILS:
      return {
        ...state,
        data: {},
        errorr: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}

const userListInitialState = {
  loading: false,
  data: {},
  errors: {},
};

export function userList(state = userListInitialState, action) {
  switch (action.type) {
    case appConstants.LOADING_USER_LIST:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_USER_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_USER_LISTN:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
      };

    default:
      return state;
  }
}
