import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    getLiveOfferProductsList,
    patchLiveOffer,
    postLiveOffer,
    getApi,
    postGetCalculatedprice
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { appConstants, regex, stringConstants } from "../../../../helpers/app-constants";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import Spinner from "../../../common/Spinner";
import { dateTime } from "../../../../helpers/table-structures";
import { OnMenuClicked } from "../../AdminPlaneMenu";
import debounce from "lodash/debounce";

const LiveOfferCard = ({ offer = {}, closeMode, renderInfo = {}, listEmpty = false }) => {
    const dispatch = useDispatch();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedFormObj, setSelectedFormObj] = useState({});

    const { requestCall, auth, liveOfferProductsList, bhareeAdminBasicTable, liveOffers, calculatedPrice } = useSelector(
        (state) => {
            return state;
        }
    );

    useEffect(() => {
        getApi("getBhreeAdminBasicTables", dispatch);
        getApi("getLiveOfferProductsList", dispatch)

        if (!isEmpty(offer)) {
            setIsUpdateMode(true);
            onSelectValue(
                offer.id,
                "id",
            );
            onSelectValue(
                offer.moq,
                "moq",
            );

            // onSelectValue(offer.paymentTypes, "paymentTypes")
            onSelectValue(offer.offerPeriod, "offerPeriod")
            onSelectValue(offer.offerPrice, "offerPrice")

            // onSelectValue(
            //     parseFloat(offer.offerPrice),
            //     "offerPrice",
            // )
            let optionsArray = []
            let itemObj = {};
            itemObj["id"] = offer.product_variant_id;
            itemObj["displayName"] = offer.details?.brandName + " " + offer.details?.productCategory + " " + offer.details?.variant;
            itemObj["moqs"] = [offer.moq];
            itemObj["price"] = offer.productVariant.price;
            itemObj["startDate"] = offer.startDate;
            itemObj["endDate"] = offer.endDate;
            optionsArray.push(itemObj);
            setOptions(optionsArray)
            onSelectValue(itemObj, "productVariant");
        }
        dispatch({
            type: appConstants.CLEAR_CALCULATED_PRICE,
        });
    }, []);


    useEffect(() => {
        // let filterArray = []
        let optionsArray = []
        if (liveOfferProductsList?.data?.length > 0) {
            // filterArray =
            liveOfferProductsList.data?.filter((item) => {
                if (item.seller_id === auth.user.id) {
                    let itemObj = {}
                    itemObj["id"] = item.id;
                    itemObj["displayName"] = `${item?.brand_name} ${item?.product_category}  ${item?.variant} `
                    itemObj["moqs"] = item.moq_id;
                    itemObj["price"] = item.price;
                    itemObj["seller_price"] = item.seller_price;
                    optionsArray.push(itemObj)
                    return item
                }
            })
        }
        // setVariantsArray(filterArray);
        setOptions(optionsArray);

    }, [liveOfferProductsList]);


    const createUpdateLiveOffer = (e) => {
        e.preventDefault();
        if (
            !isEmpty(selectedFormObj) &&
            isChecked &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "productVariant") &&
            !isEmpty(selectedFormObj.productVariant) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "moq") &&
            !isEmpty(selectedFormObj.moq) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "offerPeriod") &&
            !isEmpty(selectedFormObj.offerPeriod) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "offerPrice") &&
            !isEmpty(selectedFormObj.offerPrice)
        ) {

            let updatedObj = {};
            updatedObj["productVariantId"] = selectedFormObj.productVariant.id;
            updatedObj["currentPrice"] = selectedFormObj.productVariant.price;
            updatedObj["moqId"] = selectedFormObj.moq.id;
            updatedObj["offerPrice"] = selectedFormObj.offerPrice;
            updatedObj["offerPeriod"] = selectedFormObj.offerPeriod.value;
            // updatedObj["paymentTypes"] = selectedFormObj.paymentTypes;
            if (!isUpdateMode) {
                postLiveOffer(updatedObj, dispatch);
            } else {
                updatedObj["id"] = selectedFormObj.id;
                patchLiveOffer(updatedObj, dispatch)
            }
        }
    };


    // useEffect(() => {
    //     console.log("****** selectedFormObj", selectedFormObj)
    // }, [selectedFormObj]);


    const DateRange = ({ selectedFormObj }) => {
        // Extracting startDate and endDate from the selectedFormObj
        const startDate = selectedFormObj?.productVariant?.startDate;
        const endDate = selectedFormObj?.productVariant?.endDate;

        // Check if start and end dates are available, if not use current date
        const displayStartDate = startDate ? new Date(startDate) : new Date();

        // Calculate the end date: add 'daysToAdd' days to the current date if endDate is not available
        const displayEndDate = endDate
            ? new Date(endDate)
            : selectedFormObj?.offerPeriod
                ? new Date(new Date().setDate(new Date().getDate() + selectedFormObj?.offerPeriod))
                : null;

        return (
            <strong>
                {`(From: ${dateTime(displayStartDate)}`}
                {displayEndDate ? ` to: ${dateTime(displayEndDate)})` : ')'}
            </strong>
        );
    };
    const onSelectValue = (value, selectorType) => {
        let updatedObj = {};
        updatedObj[selectorType] = value;
        setSelectedFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };

    const debouncedUpdatePrice = useCallback(
        debounce((id, value) => {
            let obj = {}
            obj["variantId"] = id;
            obj["price"] = value
            postGetCalculatedprice(obj, dispatch);
        }, 1000), // Adjust delay as needed
        []
    );
    const handlePriceChange = (id, value, selectorType) => {
        console.log("*******", id)
        onSelectValue(value, selectorType);
        debouncedUpdatePrice(id, value);
    };

    return (
        <>
            {liveOfferProductsList.loading ? (<Container className="py-5">
                <Spinner />
            </Container>) : options?.length > 0 ? (<Row className="m-0 w-full  form-row ">
                <Row className="m-0 px-0 w-full px-0 title-button-row">
                    <h2 className="m-0">{!isUpdateMode ? "Create Offer" : "Update Offer"}</h2>
                    {listEmpty && (<CustomButton
                        buttonClasses="view-switch-btn"
                        onClick={() => closeMode(false)}
                    >
                        {"List"}
                    </CustomButton>)}

                </Row>
                <Form className="my-2 px-0 modal-custom-class" onSubmit={createUpdateLiveOffer}>
                    {!isEmpty(renderInfo) && (renderInfo)}
                    <Row className="m-0">
                        <Col xs={12} md={6} >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Products"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>

                                <CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            margin: 0,
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={!isUpdateMode ? options : [selectedFormObj?.productVariant]}
                                    onSelect={(e) => onSelectValue(e[0], "productVariant")}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "productVariant"
                                        )
                                            ? [selectedFormObj?.productVariant]
                                            : []
                                    }
                                    displayValue={"displayName"}
                                    disable={isUpdateMode ? true : false}
                                    singleSelect
                                />
                            </Form.Group>
                        </Col>


                        {!isEmpty(selectedFormObj) && Object.prototype.hasOwnProperty.call(selectedFormObj, "productVariant") && !isEmpty(selectedFormObj?.productVariant) && selectedFormObj?.productVariant?.moqs?.length > 0 && (<><Col xs={12} md={6} >
                            <Form.Group className="mb-3" controlId="formBasicEmail"><Form.Label>
                                {"MOQ's"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                                <CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            margin: 0,
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    // options={selectedFormObj?.productVariant?.moqs}
                                    options={!isUpdateMode ? selectedFormObj?.productVariant?.moqs : [selectedFormObj?.moq]}
                                    onSelect={(e) => {
                                        onSelectValue(
                                            e[0],
                                            "moq",
                                        );
                                    }
                                    }
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "moq"
                                        )
                                            ? [
                                                selectedFormObj[
                                                "moq"
                                                ],
                                            ]
                                            : []
                                    }
                                    displayValue={"quantity"}
                                    disable={isUpdateMode ? true : false}
                                    singleSelect
                                /></Form.Group>
                        </Col></>)}


                        <Col xs={12} md={6} >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Offer Price"}
                                    <span className="redStreSpan">{"*"}</span>
                                    {!isEmpty(selectedFormObj) && Object.prototype.hasOwnProperty.call(selectedFormObj, "productVariant") && !isEmpty(selectedFormObj?.productVariant) && <span><strong>&nbsp;{"(Seller's price: "} {selectedFormObj?.productVariant?.seller_price}</strong>&nbsp;<strong>&nbsp;{"Final price: "} {selectedFormObj?.productVariant?.price}{")"}</strong></span>}
                                    {!isEmpty(calculatedPrice?.data) && Object.prototype.hasOwnProperty.call(
                                        selectedFormObj,
                                        "productVariant"
                                    ) && Object.prototype.hasOwnProperty.call(
                                        calculatedPrice?.data, selectedFormObj?.productVariant.id) &&
                                        <span className="positiveMsg"> {"New Final Price : " + calculatedPrice?.data[selectedFormObj?.productVariant?.id].offer_price}  </span>}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    maxLength={10}
                                    value={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "offerPrice"
                                        )
                                            ? selectedFormObj[
                                            "offerPrice"
                                            ]
                                            : ""
                                    }
                                    // onChange={(e) => {
                                    //     return regex.test(e.target.value)
                                    //         ? onSelectValue(
                                    //             e.target.value,
                                    //             "offerPrice",
                                    //         )
                                    //         : null;
                                    // }}
                                    onChange={(e) => {
                                        return regex.test(e.target.value)
                                            ? handlePriceChange(selectedFormObj?.productVariant?.id, e.target.value, "offerPrice")
                                            : null;
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Offer period"}
                                    <span className="redStreSpan">{"*"}</span>
                                    {!isEmpty(selectedFormObj) && Object.prototype.hasOwnProperty.call(selectedFormObj, "productVariant") && !isEmpty(selectedFormObj?.productVariant?.startDate) && !isEmpty(selectedFormObj?.productVariant?.endDate) && <span>
                                        {/* {DateRange(selectedFormObj)} */}
                                        { }
                                        <strong>
                                            {"(From: "}{dateTime(selectedFormObj?.productVariant?.startDate)}&nbsp;{"to:"}{dateTime(selectedFormObj?.productVariant?.endDate)}{")"}
                                        </strong>
                                    </span>}
                                </Form.Label>

                                {/* <CustomDateRange /> */}
                                <CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            margin: 0,
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={[
                                        { displayName: "7 days", value: "7" },
                                        { displayName: "15 days", value: "15" },
                                        { displayName: "30 days", value: "30" },
                                    ]}
                                    onSelect={(e) =>
                                        onSelectValue(e[0], "offerPeriod")
                                    }
                                    // onRemove={(e) => onSelectValue(e[0], "selfLife")}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "offerPeriod"
                                        )
                                            ? [{ displayName: selectedFormObj["offerPeriod"].displayName, value: selectedFormObj["offerPeriod"].value }]
                                            : []
                                    }
                                    singleSelect
                                    displayValue={"displayName"}
                                />

                            </Form.Group>
                        </Col>
                        {/* <Col xs={12} md={6} >
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            {"Payments"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <CustomSelectDropdown
                                            style={{
                                                multiselectContainer: {
                                                    backgroundColor: "#f8f8f8",
                                                },
                                                chips: {
                                                    background: "#fbb040",
                                                },
                                                option: {
                                                    background: "#fbb040",
                                                    color: "#FFFFFF",
                                                    borderBottom: "1px solid #FFFFFF",
                                                },
                                            }}
                                            options={bhareeAdminBasicTable?.data?.paymentTypes}
                                            onSelect={(e) => onSelectValue(e, "paymentTypes")}
                                            onRemove={(e) => onSelectValue(e, "paymentTypes")}
                                            selectedValues={selectedFormObj?.paymentTypes}
                                            displayValue={"name"}
                                        // singleSelect
                                        />
                                    </Form.Group>
                                </Col> */}

                    </Row>
                    <hr />
                    <Form.Group
                        className="mb-3 terms pb-0 term d-flex"
                        controlId="formBasicCheckbox"
                    >
                        <Form.Check
                            type="checkbox"
                            label="We are sole responsible for price offer, MOQ,& delivery schedule."
                            required
                            onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <span className="redStreSpan">{"*"}</span>
                    </Form.Group>
                    <div className="action-buttons">
                        {!listEmpty && (<CustomButton
                            buttonClasses="close-btn"
                            onClick={() => closeMode(false)}
                            disabled={requestCall?.loading}
                        >
                            {"Back"}
                        </CustomButton>)}

                        <CustomButton
                            variant="primary"
                            buttonClasses={
                                !requestCall?.loading
                                    ? "submit-btn"
                                    : "submit-btn-loading"
                            }
                            type="submit"
                            loading={requestCall?.loading}
                        >
                            {!isUpdateMode ? "create" : "update"}
                        </CustomButton>
                    </div>
                </Form>
            </Row>) : (<Row className="m-0 px-0 w-full px-0 d-flex justify-content-center">
                {!listEmpty && (
                    <CustomButton
                        buttonClasses="redirect-btn"
                        onClick={() => {
                            !listEmpty ? closeMode(false) :
                                dispatch({
                                    type: stringConstants.SET_CURRENT_VIEW,
                                    payload: stringConstants.PRODUCTS,
                                })

                        }}
                    >
                        {!listEmpty ? <>{"No live offers found. Please return to the list."}</> :
                            <>{"Please create products "}</>}

                    </CustomButton>)}

            </Row>)
            }
        </>
    );
};



export default LiveOfferCard;
