import React from "react";
import { Container } from "react-bootstrap";
import Basics from "./Basics";
import Brands from "./Brands";
import Dashbord from "./Dashbord";
import Products from "./Products";
import { stringConstants } from "../../../helpers/app-constants";
import UsersList from "./UsersList";
import ServiceTaxDetails from "./ServiceTaxDetails";
import BasicInformation from "../common/BasicInformation";

const AdminPanelBharee = ({ currentView }) => {
  switch (currentView) {
    case stringConstants.DASHBOAD:
      return <Dashbord />;
    case stringConstants.REPORTS:
      return (
        <Container>
          {
            "Report will appear and also download or export reports will be here"
          }
        </Container>
      );
    case stringConstants.BRANDS:
      return <Brands />;
    case stringConstants.PRODUCTS:
      return <Products />;
    case stringConstants.BASICS:
      return <Basics />;
    case stringConstants.USERS:
      return <UsersList />;
    case stringConstants.SERVICE_TAX_DETAILS:
      return <ServiceTaxDetails />;
    case stringConstants.BASIC_INFORMATION:
      return <BasicInformation />;

    default:
      return <Container>{"work in process.."}</Container>;
  }
};



export default AdminPanelBharee;
