import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    getApi,
    postFactoryInfo,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import Spinner from "../../../common/Spinner";

const FactoryInfo = () => {
    const dispatch = useDispatch();
    const [erroeMsg, setErroeMsg] = useState("");
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);

    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, factoryInfo } = useSelector(
        (state) => {
            return state;
        }
    );
    useEffect(() => {
        getApi("getFactoryInfo", dispatch)
    }, [])
    useEffect(() => {
        setIsUpdateMode(false)
        if (factoryInfo?.data === null) {
            setIsCreateMode(true)
        } else {
            setIsCreateMode(false)
        }
    }, [factoryInfo])

    // useEffect(() => {
    //     console.log("*** uploadFormObj ", uploadFormObj)
    // }, [uploadFormObj])
    const onSelectValue = (key, value) => {
        // console.log("*** change ", key, value)
        let updatedObj = {};
        updatedObj[key] = value;

        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };


    const createUpdateBrand = (e) => {
        e.preventDefault();
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "operation") &&
            !isEmpty(uploadFormObj.operation) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "product_capacity") &&
            !isEmpty(uploadFormObj.product_capacity) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "variant") &&
            !isEmpty(uploadFormObj.variant) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "bis_cml_no") &&
            !isEmpty(uploadFormObj.bis_cml_no) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "packing"
            ) &&
            !isEmpty(uploadFormObj.packing) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "epr_no"
            ) &&
            !isEmpty(uploadFormObj.epr_no) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "packing_size"
            ) &&
            !isEmpty(uploadFormObj.packing_size) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "fsaai_no"
            ) &&
            !isEmpty(uploadFormObj.fsaai_no) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "water_source"
            ) &&
            !isEmpty(uploadFormObj.water_source)
        ) {
            postFactoryInfo(uploadFormObj, dispatch);
        }
    };

    const factoryInfoForm = (<>
        <Row className="m-0 form-row ">
            <Row className="m-0 px-0 w-full px-0 mb-4 title-button-row">
                <h2>{!isUpdateMode ? "ADD Factory Info" : "Update Factory Info"}</h2>
                {factoryInfo?.data !== null && (<CustomButton
                    buttonClasses="view-switch-btn"
                    onClick={() => { setIsCreateMode(false); setUploadFormObj({}) }}
                >
                    {"View"}
                </CustomButton>)}

            </Row >
            <Form className="my-2 modal-custom-class" onSubmit={createUpdateBrand}>
                <Row className="m-0 ">
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Operation"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "Semi" }, { name: "Full Auto" }]}
                                onSelect={(e) => onSelectValue("operation", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "operation"
                                    )
                                        ? [{ name: uploadFormObj?.operation }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Product Capacity"}
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "40 BPM" }, { name: "60 BPM" }, { name: "90 BPM" }, { name: "120 BPM" }]}
                                onSelect={(e) => onSelectValue("product_capacity", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "product_capacity"
                                    )
                                        ? [{ name: uploadFormObj?.product_capacity }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Variant"}
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "250 ML" }, { name: "500 ML" }, { name: "750 ML" }, { name: "1 LTR" }, { name: "2 LTR" }, { name: "5 LTR" }, { name: "10 LTR" }, { name: "20 LTR" }]}
                                onSelect={(e) => onSelectValue("variant", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "variant"
                                    )
                                        ? [{ name: uploadFormObj?.variant }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"BIS CML No."}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>

                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.bis_cml_no}
                                onChange={(e) =>
                                    onSelectValue("bis_cml_no", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Packing"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "Box" }, { name: "Shink" }, { name: "Both" }]}
                                onSelect={(e) => onSelectValue("packing", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "packing"
                                    )
                                        ? [{ name: uploadFormObj?.packing }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"EPR No."}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={uploadFormObj?.epr_no}
                                required
                                onChange={(e) =>
                                    onSelectValue("epr_no", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Packing Size"}
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "12" }, { name: "15" }, { name: "6" }, { name: "24" }, { name: "40" }, { name: "48" },]}
                                onSelect={(e) => onSelectValue("packing_size", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "packing_size"
                                    )
                                        ? [{ name: uploadFormObj?.packing_size }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>





                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"FSAAI NO."}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.fsaai_no}
                                onChange={(e) =>
                                    onSelectValue("fsaai_no", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Row Water Source"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <CustomSelectDropdown
                                style={{
                                    chips: {
                                        margin: 0,
                                    },
                                    multiselectContainer: {
                                        backgroundColor: "#f8f8f8",
                                    },
                                    option: {
                                        background: "#fbb040",
                                        color: "#FFFFFF",
                                        borderBottom: "1px solid #FFFFFF",
                                    },
                                }}
                                options={[{ name: "Well" }, { name: "Bore well" }, { name: "Other" }]}
                                onSelect={(e) => onSelectValue("water_source", e[0].name)}
                                selectedValues={
                                    Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "water_source"
                                    )
                                        ? [{ name: uploadFormObj?.water_source }]
                                        : []
                                }
                                displayValue={"name"}
                                // disable={isUpdateMode ? true : false}
                                singleSelect
                            />
                        </Form.Group>
                    </Col>

                </Row>



                {erroeMsg && <p className="error-msg">{erroeMsg}</p>}
                <div className="action-buttons">
                    {factoryInfo?.data !== null && (<CustomButton
                        buttonClasses="close-btn"
                        onClick={() => setIsCreateMode(false)}
                        disabled={requestCall?.loading}
                    >
                        {"Close"}
                    </CustomButton>)}

                    <CustomButton
                        variant="primary"
                        buttonClasses={
                            !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                        }
                        type="submit"
                        loading={requestCall?.loading}
                    >
                        {!isUpdateMode ? "Add" : "update"}
                    </CustomButton>
                </div>
            </Form>
        </Row></>)

    return (
        <Container className="p-0 factoryInfo-container">
            {
                factoryInfo?.loading ? <Spinner /> : !isEmpty(factoryInfo?.data) && !isCreateMode ? (
                    <Row className="factoryInfo m-0 " >
                        <Row className="heading-div ">
                            <h5 className="text-uppercase p-0 m-0">{"factory Info"}</h5>
                            <p style={{ cursor: "pointer" }} onClick={() => { setIsCreateMode(true); setIsUpdateMode(true); setUploadFormObj(factoryInfo?.data) }}>{"Edit"}</p>
                        </Row>
                        <hr />
                        <Row className="m-0 p-0">
                            {/* <h6 className="text-uppercase">{factoryInfo?.data.factoryInfo_type_id.type_name}</h6> */}
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Operation:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.operation}</span>
                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>

                                    <span>{"BIS CML No:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.bis_cml_no}</span>
                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>

                                    <span>{"EPR No:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.epr_no}</span>

                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"FSAAI NO:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.fsaai_no}</span>
                                </p>
                            </Col>

                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Variant:"}</span>
                                    <span className=" bold-span">{factoryInfo?.data.variant}</span>
                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Packing:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.packing}</span>
                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Packing Size:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.packing_size}</span>
                                </p>
                            </Col>
                            <Col xs={6} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Row Water Source:"}</span>
                                    <span className=" bold-span"> {factoryInfo?.data.water_source}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={4} lg={3} className="p-0" >
                                <p>
                                    <span>{"Product Capacity:"}</span>
                                    <span className=" bold-span">{factoryInfo?.data.product_capacity}</span>

                                </p>
                            </Col>
                        </Row>
                    </Row>
                ) : factoryInfoForm
            }


            {/* {(() => {
                if (!isCreateMode) {
                    if (factoryInfo?.data !== null && !factoryInfo?.loading) {
                        return (<Row className="factoryInfo m-0" >
                            <Row className="heading-div ">
                                <h5 className="text-uppercase p-0 m-0">{"factory Info"}</h5>
                                <p style={{ cursor: "pointer" }} onClick={() => { setIsCreateMode(true); setIsUpdateMode(true); setUploadFormObj(factoryInfo?.data) }}>{"Edit"}</p>
                            </Row>
                            <hr />
                            <Row className="m-0 p-0">
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Operation:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.operation}</span>
                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>

                                        <span>{"BIS CML No:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.bis_cml_no}</span>
                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>

                                        <span>{"EPR No:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.epr_no}</span>

                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"FSAAI NO:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.fsaai_no}</span>
                                    </p>
                                </Col>

                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Variant:"}</span>
                                        <span className=" bold-span">{factoryInfo?.data.variant}</span>
                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Packing:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.packing}</span>
                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Packing Size:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.packing_size}</span>
                                    </p>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Row Water Source:"}</span>
                                        <span className=" bold-span"> {factoryInfo?.data.water_source}</span>
                                    </p>
                                </Col>
                                <Col xs={12} md={4} lg={3} className="p-0" >
                                    <p>
                                        <span>{"Product Capacity:"}</span>
                                        <span className=" bold-span">{factoryInfo?.data.product_capacity}</span>

                                    </p>
                                </Col>
                            </Row>



                        </Row>)
                    } else if (factoryInfo === null && !factoryInfo?.loading) {
                        return factoryInfoForm
                    } else if (factoryInfo?.loading) {
                        return <Spinner />;
                    }

                } else if (isCreateMode) {
                    return factoryInfoForm
                }
            })()} */}

        </Container >
    );
};



export default FactoryInfo;
