import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    getGeoData,
    patchBrand,
    postBrand,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { appConstants, stringConstants } from "../../../../helpers/app-constants";
import inputValidation from "../../../../utils/inputValidation";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import FilterObjectsByKeyValue from "../../../../utils/FilterObjectsByKeyValue";
const BrandCrud = ({ brand = {}, setShowBrandCreateMode, setCurrentBrandObj, renderInfo = {}, isViewOnly = false, listEmpty = false }) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [errorMsg, setErrorMsg] = useState({});
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [currentUpdateId, setCurrentUpdateId] = useState("");
    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, auth, countries } = useSelector(
        (state) => {
            return state;
        }
    );

    const setUpdatedForm = (key, value, maxLenght = 10000, numericOnly = false) => {
        setErrorMsg({});
        let updatedObj = {};
        let validate = inputValidation(value, maxLenght, numericOnly);
        if (validate.pass) {
            updatedObj[key] = validate.finalValue;
            setErrorMsg({ [key]: validate.msg })
            setUploadFormObj((previousVlaues) => ({
                ...previousVlaues,
                ...updatedObj,
            }));
        } else {
            setErrorMsg({ [key]: validate.msg })
        }

    };

    const setUpdatedFormFile = (key, value) => {
        const file = value;
        let updatedObj = {};
        updatedObj[key] = "";
        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));

        // Define dimension constraints
        const minWidth = 0;
        const minHeight = 0;
        const maxWidth = 100000000000;
        const maxHeight = 100000000000;

        const validateImageDimensions = (file) => {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    if (
                        img.width >= minWidth &&
                        img.width <= maxWidth &&
                        img.height >= minHeight &&
                        img.height <= maxHeight
                    ) {

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.onerror = () => {
                    reject(new Error("Invalid image file"));
                };
            });
        };

        const processFiles = async () => {
            const isValid = await validateImageDimensions(file);
            if (isValid) {
                updatedObj[key] = value;
                setUploadFormObj((previousVlaues) => ({
                    ...previousVlaues,
                    ...updatedObj,
                }));
                setErrorMsg({});
            } else {

                setErrorMsg({ brand_logo: `Image ${value.name} does not meet the size requirements.\n\n Min Width = 234px | Min Height = 72px | Max Width = 260px | Max Height = 98px` });
                if (inputRef.current) {
                    inputRef.current.value = ''; // Clear the input
                }
            }
            // updatedObj[key] = value;
            // setUploadFormObj((previousVlaues) => ({
            //     ...previousVlaues,
            //     ...updatedObj,
            // }));
            // setErrorMsg({});
        };

        processFiles();
    };
    useEffect(() => {
        if (!isEmpty(brand)) {
            setUploadFormObj(brand);
            setIsUpdateMode(true);
            setCurrentUpdateId(brand.id);

        }
        dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
        });
        getGeoData(dispatch);


    }, []);
    useEffect(() => {
        if (!isEmpty(countries?.data) && countries?.data?.length > 0) {
            setUpdatedForm("country_of_origin", FilterObjectsByKeyValue(countries?.data, "name", "India")[0].name)
        }
    }, [countries]);
    useEffect(() => {

        if (!isEmpty(requestCall?.errors) && !requestCall?.errors?.success) {
            setErrorMsg(requestCall.errors.message)
        } else {
            setErrorMsg("")
        }
    }, [requestCall]);
    const createUpdateBrand = (e) => {
        e.preventDefault();
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "brand_name") &&
            !isEmpty(uploadFormObj.brand_name) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "description") &&
            !isEmpty(uploadFormObj.description) &&
            // Object.prototype.hasOwnProperty.call(uploadFormObj, "marketed_by") &&
            // !isEmpty(uploadFormObj.marketed_by) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "customer_care") &&
            !isEmpty(uploadFormObj.customer_care) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "country_of_origin"
            ) &&
            !isEmpty(uploadFormObj.country_of_origin)
        ) {
            let formData = new FormData();
            formData.append("brand_name", uploadFormObj.brand_name);
            formData.append("brand_logo", uploadFormObj.brand_logo);
            formData.append("description", uploadFormObj.description);
            // formData.append("marketed_by", uploadFormObj.marketed_by);
            formData.append("customer_care", uploadFormObj.customer_care);
            formData.append("country_of_origin", uploadFormObj.country_of_origin);
            if (isUpdateMode) {
                patchBrand(currentUpdateId, formData, dispatch);
            } else {
                postBrand(formData, dispatch);
            }
        }
    };


    return (
        <>
            <Row className="m-0 w-full form-row ">
                <Form className="my-0 modal-custom-class" onSubmit={createUpdateBrand}>
                    <Row className="m-0 px-0 w-full px-0 mb-2 title-button-row">
                        <h2>
                            {isViewOnly && ("Brand Details")}
                            {!isViewOnly && (!isUpdateMode ? "Add brand" : "Update brand")}
                        </h2>
                        {!listEmpty && (<CustomButton
                            buttonClasses="view-switch-btn"
                            onClick={() => {
                                setShowBrandCreateMode(false);
                                setCurrentBrandObj({});
                            }}
                        >
                            {"List"}
                        </CustomButton>)}

                    </Row>
                    {!isEmpty(renderInfo) && (renderInfo)}
                    <Row className="m-0">
                        <Col md={12} lg={6}>
                            <Form.Group className="mb-3" controlId="brandName">
                                <Form.Label>
                                    {"Brand Name"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.brand_name || ""}
                                    onChange={(e) => setUpdatedForm("brand_name", e.target.value, 50)}
                                    disabled={auth?.user?.role?.role_name === stringConstants.ROLL_SELLER && isUpdateMode ? true : false}
                                />
                                {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "brand_name") && <p className="error-msg">{errorMsg.brand_name}</p>}
                            </Form.Group>
                        </Col>
                        {isUpdateMode && Object.prototype.hasOwnProperty.call(uploadFormObj, "brand_logo") &&
                            (<Col className="logo_input_col" md={12} lg={6}>
                                {!isEmpty(uploadFormObj.brand_logo) && (<img className="small_logo_img " src={uploadFormObj.brand_logo} alt="brand Logo " />)}

                                {!isViewOnly && (<Form.Group className="mb-3  w-100" controlId="updateBrandLogo">
                                    <Form.Label>
                                        {"Update Brand Logo"}
                                        {!isUpdateMode ? (
                                            <span className="redStreSpan">{"*"}</span>
                                        ) : (
                                            ""
                                        )}

                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        required={!isUpdateMode ? true : false}
                                        ref={inputRef}
                                        onChange={(e) =>
                                            setUpdatedFormFile("brand_logo", e.target.files[0])
                                        }
                                    />
                                    {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "brand_logo") &&

                                        <p className="error-msg">{errorMsg?.brand_logo}</p>
                                    }
                                </Form.Group>)}

                            </Col>)
                        }

                        {!isUpdateMode &&
                            (<Col md={12} lg={6}>
                                <Form.Group className="mb-3" controlId="brandLogo">
                                    <Form.Label>
                                        {"Brand Logo"}
                                        {!isUpdateMode ? (
                                            <span className="redStreSpan">{"*"}</span>
                                        ) : (
                                            ""
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        required={!isUpdateMode ? true : false}
                                        ref={inputRef}
                                        onChange={(e) =>
                                            setUpdatedFormFile("brand_logo", e.target.files[0])
                                        }
                                    />
                                    {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "brand_logo") &&

                                        <p className="error-msg">{errorMsg?.brand_logo}</p>
                                    }
                                </Form.Group>
                            </Col>)
                        }

                    </Row>

                    <Row className="m-0">
                        <Col md={12} lg={6}>
                            <Form.Group className="mb-3" controlId="description">
                                <Form.Label>
                                    {"Description"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={5}
                                    required
                                    value={uploadFormObj?.description || ""}
                                    onChange={(e) => setUpdatedForm("description", e.target.value)}
                                    disabled={isViewOnly}
                                />
                                {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "description") && <p className="error-msg">{errorMsg.description}</p>}
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mb-3" controlId="countryOfOrigin">
                                <Form.Label>
                                    {"Country of origin"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                {!isEmpty(countries?.data) && countries.data?.length > 0 && (<CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            background: "#fbb040",
                                            margin: 0,
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={countries?.data}
                                    onSelect={(e) => setUpdatedForm("country_of_origin", e[0].name)}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            uploadFormObj,
                                            "country_of_origin"
                                        )
                                            ? FilterObjectsByKeyValue(countries.data, "name", uploadFormObj?.country_of_origin)
                                            : []
                                    }
                                    displayValue={"name"}
                                    disable={isViewOnly || isUpdateMode ? true : false}
                                    // disable={isUpdateMode ? true : false}
                                    selectionLimit={1}
                                />)}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="customerCare">
                                <Form.Label>
                                    {"Customer care"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.customer_care || ""}
                                    disabled={isViewOnly}
                                    onChange={(e) => setUpdatedForm("customer_care", e.target.value, 50)}
                                />
                                {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "customer_care") && <p className="error-msg">{errorMsg.customer_care}</p>}
                            </Form.Group>
                        </Col>

                    </Row>


                    <div className="action-buttons">
                        {!listEmpty && !isViewOnly && (<CustomButton
                            buttonClasses="close-btn"
                            onClick={() => { setShowBrandCreateMode(false); setCurrentBrandObj({}); }}
                            disabled={requestCall?.loading}
                        >
                            {"Close"}
                        </CustomButton>)}

                        {!isViewOnly && (<CustomButton
                            variant="primary"
                            buttonClasses={
                                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                            }
                            type="submit"
                            loading={requestCall?.loading}
                        >
                            {!isUpdateMode ? "ADD" : "update"}
                        </CustomButton>)}

                    </div>
                </Form >
            </Row >
        </>
    );
};



export default BrandCrud;
