import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fulfill from "../../assets/images/fulfill.png";
import { Rating } from "react-simple-star-rating";
import { Image, Card, Row, Popover, Container, OverlayTrigger, Button } from "react-bootstrap";
import "./brandCard.css";
import { CartIcon } from "../../assets/svg/svg-icons";
import isEmpty from "../../utils/is-Empty";
import CustomButton from "../common/CustomButton"
import convertToLiters from "../../utils/convertToLiters";
import { deliverytypesString, moqsString, paymenttypesString } from "../../helpers/table-structures";
import BuyerAuth from "../auth/BuyerAuth";
import { appConstants } from "../../helpers";
import FilterObjectsByKeyValue from "../../utils/FilterObjectsByKeyValue";
const BrandCard = ({ isLoggedIn, item, addToCart, filterMoq = "", requestCall }) => {

  const [openModal, setOpenModal] = useState(false);
  const [currentPrice, setCurrentPrice] = useState({});
  const [currentMoq, setCurrentMoq] = useState({});


  const changeStateModal = () => setOpenModal(!openModal);

  function findExtremeValue(arrayOfObj, key, type) {
    if (!arrayOfObj || arrayOfObj?.length === 0) {
      return null;
    }

    let extremeValue = arrayOfObj[0];

    arrayOfObj.forEach(obj => {
      let objValue = obj[key];

      // Convert string value to float if necessary
      if (typeof objValue === 'string') {
        objValue = parseFloat(objValue);
      }
      if (type === 'max' && obj[key] > extremeValue[key]) {
        extremeValue = obj;
      } else if (type === 'min' && obj[key] < extremeValue[key]) {
        extremeValue = obj;
      }
    });

    return extremeValue;
  }

  useEffect(() => {
    if (!isEmpty(filterMoq)) {
      setCurrentMoq(FilterObjectsByKeyValue(item?.moq_id, "quantity", parseInt(filterMoq))[0])
      if (item?.offers?.length > 0 && FilterObjectsByKeyValue(item?.offers, "quantity", parseInt(filterMoq))?.length > 0) {
        setCurrentPrice(FilterObjectsByKeyValue(item?.offers, "quantity", parseInt(filterMoq))[0]);
      } 
    } else if (isEmpty(filterMoq) && item?.offers?.length > 0) {
      setCurrentPrice(findExtremeValue(item?.offers, "offerPrice", "min"));
      setCurrentMoq(FilterObjectsByKeyValue(item?.moq_id, "quantity", findExtremeValue(item?.offers, "offerPrice", "min").quantity));
    } else {
      setCurrentMoq(findExtremeValue(item?.moq_id, "quantity", "max"));
    }



  }, [])

  return (
    !isEmpty(item) && (

      <Card className="brand-card-div">
        <Link to={`/product/${item.id}`} className="barand-card-wrap">
          <div className="brand-card-img pb-0">
            <div className="brand-card-image-heading">
              <p>{item?.brand_name}</p>
              {deliverytypesString(item?.delivery_types).toLowerCase().includes(appConstants.BHAREE.toLowerCase()) && <Image
                src={fulfill}
                alt="fulfill"
                style={{ height: "auto", width: "30%" }}
              />}

            </div>
            {item?.productImages?.length > 0 ? (<Image
              className="brand-card-big-img"
              src={item?.productImages[0].url}
              alt={item?.brand_name}
            />) : (<Image
              className="brand-card-big-img"
              src={item?.brand_logo}
              alt={item?.brand_name}
            />)}
          </div>
        </Link>

        <div className="brand-card-body pt-1">
          <Link to={`/product/${item.id}`} >
            <h4>{`${item?.brand_name} `}<br className="mobile" />{`${item?.product_category} ${convertToLiters(item?.variant)}`}</h4>
          </Link>
          <p>{`Pack of ${item?.packaging_size} Bottles`}</p>
          <div className="brand-card-rating">
            <Rating
              className="rating-star-container desktop"
              size="20"
              onClick={() => null}
              // initialValue={item?.productRating}
              initialValue={5}
              readonly
            />
            <Rating
              className="rating-star-container mobile"
              size="12"
              onClick={() => null}
              // initialValue={item?.productRating}
              initialValue={5}
              readonly
            />
            {/* <p>{`(${item?.noOfReviews})`}</p> */}
            <p>{`(1k+)`}</p>
          </div>
          <div className=" brand-card-rating">
            {item?.offers?.length > 0 && !isEmpty(currentPrice)? (<p>
              {"MOQ:  "} <span>{` ${currentPrice?.quantity} Case`}</span>
            </p>) : (<p>
              {"MOQ:  "} <span>{` ${currentMoq?.quantity} Case`}</span>
            </p>)
            }
            {item?.offers?.length > 0 && (<>
              <Link to={`/product/${item.id}`}>{"More Offers"}</Link>
            </>)}
          </div>
          <div className="brand-card-cart">
            {isLoggedIn && (
              <>
                {item?.offers?.length > 0 && !isEmpty(currentPrice) ? (
                  <h3 className="card-offer-price">
                    {`₹  ${currentPrice?.offerPrice} `}<span>{"per case"}</span>
                    <br />
                    <span><s>{`₹  ${item?.price} per case`}</s></span>
                  </h3>
                ) : (<>
                  <h3>
                    {`₹  ${item?.price} `}<span>{"per case"}</span>
                    <br />
                  </h3>
                  <br />


                </>)}
                {/* {`₹  ${item?.price} `}
                  <span>{"per case"}</span> */}
                {item?.moq_id?.length > 1 && (<OverlayTrigger trigger="focus" placement="top" overlay={
                  <Popover className="card-popper " id={`${item?.id}`}>
                    <Popover.Header as="h3">{"Select Moq"}</Popover.Header>
                    <Popover.Body>
                      <Container className="card-popper-body-conatiner">
                        {item?.offers?.length > 0 && (
                          <Row className="offer-row m-0">
                            <p className="m-0 p-0 offer-heading"> {"Available offers"}</p>
                            <ul className="px-3">
                              {item?.offers.map((offer) => {
                                return <li>{`For`}<strong>{` ${offer?.quantity}`}</strong>{` MOQ's Offer Price `}<strong>{`${offer?.offerPrice}/case`}</strong></li>
                              })}
                            </ul>
                          </Row>
                        )}

                        <Row className="moq-row">
                          {item?.moq_id?.length > 0 && (<>{item?.moq_id.map((m) => {
                            return <CustomButton key={m.quantity} buttonClasses="moq-btn" loading={requestCall?.loading} onClick={() => { addToCart(item, m); changeStateModal() }}>
                              {m.quantity}
                            </CustomButton>
                          })}</>)}

                        </Row>
                      </Container>
                    </Popover.Body>
                  </Popover>
                }>
                  <Button><CartIcon />
                    &nbsp;{"ADD"}</Button>
                </OverlayTrigger>)}
                {item?.moq_id?.length === 1 && (
                  <CustomButton loading={requestCall?.loading} onClick={() => { addToCart(item, item?.moq_id[0]); changeStateModal() }}>
                    <CartIcon />
                    &nbsp;{"ADD"}
                  </CustomButton>
                )}
              </>)}
            {!isLoggedIn && (
              <>
                <h3>
                  <span>{"Please Login to Buy..."}</span>
                </h3>
                <BuyerAuth buttonText={"LOGIN"} buttonClasses={"section-link"} />
                {/* <CustomButton onClick={openLoginModal}>
                  <CartIcon />
                  &nbsp;{"LOGIN"}
                </CustomButton> */}
              </>)}
          </div>
        </div>
      </Card >
    )
  );
};

export default BrandCard;
