import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  useLocation, Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { BHAREE_BASE_URL_LOCAL } from '../../config/config.url';

const EmailVerification = () => {
    const [status, setStatus] = useState('loading'); // loading, success, fail
    const [message, setMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Extract 'key' parameter from the URL
        const params = new URLSearchParams(location.search);
        const token = params.get('key');

        if (token) {
            // Call the API with the token
            axios.get(BHAREE_BASE_URL_LOCAL + `/auth/verify/email?token=${token}`)
                .then((response) => {
                    setStatus('success');
                    setMessage(response.data.message); // Show success message from the response
                })
                .catch((error) => {
                    setStatus('fail');
                    setMessage(error.response?.data?.message || 'Email verification failed'); // Show error message if available
                });
        } else if (token === '') {
            setStatus('fail');
            setMessage('Key is empty.');
        } else {
            setStatus('fail');
            setMessage('Invalid or missing verification token.');
        }
    }, [location.search]);

    return (
        <Container>
            <div className="text-center d-flex flex-column align-center" id="info" style={{ height: "600px", alignItems: "center", justifyContent: "center" }}>
                {status !== 'loading' ? 
                   ( <div>
                        <h4 className='mb-4'>{message}</h4>
                        <Link className='nav-link-color mt-4' style={{ fontSize: "1.5rem", color: "#FDC345", padding: "1rem", border: "1px solid #FDC345", borderRadius: "5px", margin: "1rem" }} to={"/"} >{"Go to home"}</Link>
                    </div>)
                : (<>
                    <div className="loading"></div>
                    <h4 className='mb-4'>{"Please wait, verifying your email..."}</h4>
                </>)}
            </div>

        </Container>
    );
};

export default EmailVerification;
