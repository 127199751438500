import { Image } from "react-bootstrap";
import isEmpty from "../utils/is-Empty";
import convertToLiters from "../utils/convertToLiters";

const status = (status) => {
  if (status === "pending") {
    return (
      <div
        style={{
          padding: "0.5rem 1rem ",
          border: "1px solid #ff9900",
          borderRadius: "5px",
          color: "#ff9900",
          width: "100%",
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{"In review"}</span>
      </div>
    );
  } else if (status === "accept") {
    return (
      <div
        style={{
          padding: "0.5rem 1rem ",
          border: "1px solid #039487",
          borderRadius: "5px",
          color: "#039487",
          width: "max-content",
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{"Approved"}</span>
      </div>
    );
  } else if (status === "reject") {
    return (
      <div
        style={{
          padding: "0.5rem 1rem ",
          border: "1px solid #FF4F4F",
          borderRadius: "5px",
          color: "#FF4F4F",
          width: "100%",
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{status}</span>
      </div>
    );
  } else if (status === 1) {
    return <div
      style={{
        padding: "0.5rem 1rem ",
        border: "1px solid #039487",
        borderRadius: "5px",
        color: "#039487",
        width: "max-content",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{"Active"}</span>
    </div>
  } else if (status === 0) {
    return <div
      style={{
        padding: "0.5rem 1rem ",
        border: "1px solid #FF4F4F",
        borderRadius: "5px",
        color: "#FF4F4F",
        width: "max-content",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{"Inactive"}</span>
    </div>
  }
};
export const dateTime = (dateTime) => {
  const date = new Date(dateTime);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  const timeStamp = date.toLocaleDateString('en-GB', options);
  const formattedTime = date.toLocaleTimeString('en-GB', options);
  // let timeStamp = dateTime.split(" ");
  return <>
    <span className="text-uppercase">
      {`${timeStamp}`}
    </span>
    {/* &nbsp;
    <span>
      {`||Time: ${timeStamp[1]}`}
    </span> */}
  </>
};
export const paymenttypesString = (Payments) => {
  // console.log("******* paymenttypesString", Payments)
  // return "in process"
  let str = ""
  Payments.map((type) => {
    // console.log("** str", str)
    if (isEmpty(str)) {
      str = type.name
    } else {
      str = str + " | " + type.name;
    }
    return str
  })
  return str.toUpperCase();
}
export const deliverytypesString = (delivery) => {
  let str = ""
  delivery.map((type) => {
    // console.log("** str", str)
    if (isEmpty(str)) {
      str = type.name
    } else {
      str = str + " | " + type.name;
    }
    return str
  })
  return str.toUpperCase();
}
export const moqsString = (moqs) => {
  let str = ""
  moqs.map((type) => {
    // console.log("** str", type.quantity)
    if (isEmpty(str)) {
      str = type.quantity
    } else {
      str = str + " | " + type.quantity;
    }
    return str
  })
  return str;
}
const areasString = (areas) => {
  let str = ""
  areas.map((type) => {
    // console.log("** str", type.quantity)
    if (isEmpty(str)) {
      str = type.area + "," + type.city + " " + type.pin_code
    } else {
      str = str + " | " + type.area + "," + type.city + " " + type.pin_code;
    }
    return str
  })
  return str;
}
export const deliveryAddressString = (delivery) => {
  return <p> {`${delivery.address_line_1},${delivery.address_line_2},${delivery.address_line_2},`}
    <br />
    {`${delivery.city},${delivery.pin_code}`}
    <br />
    {`${delivery.state},${delivery.country}`}
  </p>

}
export const sellerDetails = (seller_address_id) => {
  return <p className="m-0"> {`${seller_address_id.full_name}`}
    <br />
    {`${seller_address_id.primary_number}`}
  </p>

}
export const tableStructures = {
  user_list: [
    {
      accessorKey: "email", //simple recommended way to define a column
      header: "email",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "mobile_number", //simple recommended way to define a column
      header: "Mobile Number",
      grow: false,
      size: 10,
    },

    {
      accessorKey: "role.role_name", //simple recommended way to define a column
      header: "Role",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "roles_type.role_type_name", //simple recommended way to define a column
      header: "Role type",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  variant: [
    {
      accessorKey: "displayName", //simple recommended way to define a column
      header: "Variant",
      grow: false,
      size: 10,
    },
  ],
  moq: [
    {
      accessorKey: "quantity", //simple recommended way to define a column
      header: "MOQ",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],

  brand: [

    {
      accessorKey: "brand_name", //simple recommended way to define a column
      header: "Brand Name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "brand_logo", //simple recommended way to define a column
      header: "Brand Logo",
      Cell: ({ cell }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={cell.getValue()}
              style={{ maxWidth: "350px", height: "50px" }}
              alt="brand_logo"
            />
          </div>
        );
      }, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "country_of_origin", //simple recommended way to define a column
      header: "Country of origin",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "customer_care", //simple recommended way to define a column
      header: "Customer Care",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    // {
    //   accessorKey: "marketed_by", //simple recommended way to define a column
    //   header: "Marketed By",
    //   // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
    // },
    {
      accessorKey: "brand_status",
      header: "Brand Status",
      Cell: ({ cell }) => status(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  admin_product: [

    {
      accessorKey: "seller.business_name", //simple recommended way to define a column
      header: "Seller business name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 220,
    },
    {
      accessorKey: "seller.mobile_number", //simple recommended way to define a column
      header: "Seller mobile number",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 220,
    },
    {
      accessorKey: "seller.email", //simple recommended way to define a column
      header: "Seller email",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "brand.brand_name", //simple recommended way to define a column
      header: "Brand Name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "productCategory.name", //simple recommended way to define a column
      header: "Product Category",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_name", //simple recommended way to define a column
      header: "Product Name",

      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "marketed_by", //simple recommended way to define a column
      header: "Marketed By",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "shelf_life.displayName", //simple recommended way to define a column
      header: "Shelf Life",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "manufactured_by", //simple recommended way to define a column
      header: "Manufactured By ",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_status",
      header: "Product Status",
      Cell: ({ cell }) => status(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },

  ],
  product: [

    {
      accessorKey: "brand.brand_name", //simple recommended way to define a column
      header: "Brand Name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "productCategory.name", //simple recommended way to define a column
      header: "Product Category",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_name", //simple recommended way to define a column
      header: "Product Name",

      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "shelf_life.displayName", //simple recommended way to define a column
      header: "Shelf Life",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "marketed_by", //simple recommended way to define a column
      header: "Marketed By",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "manufactured_by", //simple recommended way to define a column
      header: "Manufactured By ",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_status",
      header: "Product Status",
      Cell: ({ cell }) => status(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },

  ],
  crud_variant: [

    {
      accessorKey: "displayName", //simple recommended way to define a column
      header: "Variant",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "seller_price", //simple recommended way to define a column
      header: "Seller's Price",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "price", //simple recommended way to define a column
      header: "Final Price",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "packaging_size", //simple recommended way to define a column
      header: "Packaging size ",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "weight_per_case", //simple recommended way to define a column
      header: "Weight Per Case",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "moq",
      header: "MOQ's",
      Cell: ({ cell }) => moqsString(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "area_list",
      header: "Areas|Pincode",
      Cell: ({ cell }) => areasString(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "packaging_type.name",
      header: "Packaging Type",
      grow: false,
      size: 10,
    },

  ],
  seller_brand: [
    {
      accessorKey: "brand_status",
      header: "Brand Status",
      Cell: ({ cell }) => status(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "user.email",
      header: "Seller Email",
      grow: false,
      size: 10,
    },
    {
      accessorKey: "user.mobile_number",
      header: "Seller Mobile no.",
      grow: false,
      size: 10,
    },

    {
      accessorKey: "brand_name", //simple recommended way to define a column
      header: "Brand Name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "brand_logo", //simple recommended way to define a column
      header: "Brand Logo",
      Cell: ({ cell }) => {
        return (
          <Image
            src={cell.getValue()}
            style={{ width: "50%", height: "auto" }}
            alt="brand_logo"
          />
        );
      }, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "country_of_origin", //simple recommended way to define a column
      header: "Country of origin",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "customer_care", //simple recommended way to define a column
      header: "Customer Care",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "marketed_by", //simple recommended way to define a column
      header: "Marketed By",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  productCategories: [
    {
      accessorKey: "name", //simple recommended way to define a column
      header: "Name",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  packagingTypes: [
    {
      accessorKey: "name", //simple recommended way to define a column
      header: "Name",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  deliveryTypes: [
    {
      accessorKey: "name", //simple recommended way to define a column
      header: "Name",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  paymentTypes: [
    {
      accessorKey: "name", //simple recommended way to define a column
      header: "Name",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  shelfLife: [
    {
      accessorKey: "displayName", //simple recommended way to define a column
      header: "Name",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  assinged_trips: [
    {
      accessorKey: "OrderNo",
      header: "Order No",
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Variants", //simple recommended way to define a column
      header: "Variants",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Category", //simple recommended way to define a column
      header: "Category",
      //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Brand", //simple recommended way to define a column
      header: "Brand",
      //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "Qty", //simple recommended way to define a column
      header: "Qty",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Load", //simple recommended way to define a column
      header: "Load",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Buyer", //simple recommended way to define a column
      header: "Buyer",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Area", //simple recommended way to define a column
      header: "Area",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "PinCode", //simple recommended way to define a column
      header: "Pin Code",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "Status", //simple recommended way to define a column
      header: "Status",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],
  new_orders: [
    {
      accessorKey: "seller_address_id", //simple recommended way to define a column
      header: "Seller Details",
      Cell: ({ cell }) => <span>{sellerDetails(cell.getValue())}</span>,
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_variant_id",
      header: "Brand",
      Cell: ({ cell }) => <>{cell.getValue()[0].brand_name}</>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_variant_id", //simple recommended way to define a column
      header: "Variants",
      Cell: ({ cell }) => <>{convertToLiters(cell.getValue()[0].quantity)}</>, //optio
      grow: false,
      size: 10,
    },
    {
      accessorKey: "moq", //simple recommended way to define a column
      header: "MOQ",
      //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "delivery_method_id", //simple recommended way to define a column
      header: "Delivery",
      Cell: ({ cell }) => <span>{cell.getValue()[0].name}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "payment_method_id.name", //simple recommended way to define a column
      header: "Pay",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "totalAmount", //simple recommended way to define a column
      header: "Total Amount",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "totalDelivery", //simple recommended way to define a column
      header: "Delivery Charges",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "payableAmount", //simple recommended way to define a column
      header: "Payable Amount",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    // {
    //   accessorKey: "delivery_address_id", //simple recommended way to define a column
    //   header: "Delivery Point",
    //   Cell: ({ cell }) => <span>{deliveryAddressString(cell.getValue())}</span>,
    //   grow: false,
    //   size: 10,
    // },
  ],
  new_orders_seller: [

    {
      accessorKey: "product_variant_id",
      id: "brand",
      header: "Brand",
      Cell: ({ cell }) => <>{cell.getValue()[0]?.brand_name}</>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "product_variant_id", //simple recommended way to define a column
      id: "variants",
      header: "Variants",
      Cell: ({ cell }) => <>{convertToLiters(cell.getValue()[0]?.quantity)}</>, //optio
      grow: false,
      size: 10,
    },
    {
      accessorKey: "moq", //simple recommended way to define a column
      header: "MOQ",
      //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "delivery_method_id", //simple recommended way to define a column
      header: "Delivery",
      Cell: ({ cell }) => <span>{cell.getValue()[0]?.name}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "payment_method_id.name", //simple recommended way to define a column
      header: "Pay",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "totalAmount", //simple recommended way to define a column
      header: "Total Amount",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "totalDelivery", //simple recommended way to define a column
      header: "Delivery Charges",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "payableAmount", //simple recommended way to define a column
      header: "Payable Amount",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    // {
    //   accessorKey: "delivery_address_id", //simple recommended way to define a column
    //   header: "Delivery Point",
    //   Cell: ({ cell }) => <span>{deliveryAddressString(cell.getValue())}</span>,
    //   grow: false,
    //   size: 10,
    // },
  ],
  order_status: [
    {
      accessorKey: "orderNumber",
      header: "order number",
      grow: false,
      size: 10,
    },
    {
      accessorKey: "buyerName", //simple recommended way to define a column
      header: "Buyer Name",
      // Cell: ({ cell }) => <h1 className="bold">{cell.getValue()}</h1>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "tripOwner", //simple recommended way to define a column
      header: "Trip Owner",
      //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "variant", //simple recommended way to define a column
      header: "Variant",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },

    {
      accessorKey: "qty", //simple recommended way to define a column
      header: "Qty",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "brand", //simple recommended way to define a column
      header: "Brand",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "deliveryPoint", //simple recommended way to define a column
      header: "Delivery Point",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "update", //simple recommended way to define a column
      header: "Update",
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
      grow: false,
      size: 10,
    },
  ],

  live_offers: [
    {
      accessorKey: "details",
      header: "Product",
      Cell: ({ cell }) => {
        return cell.getValue()?.brandName + " " + cell.getValue()?.productCategory + " " + convertToLiters(cell.getValue()?.variant)
      },
      grow: false,
      size: 10,
    },
    {
      accessorKey: "moq.quantity", //simple recommended way to define a column
      header: "MOQ",
      grow: false,
      size: 10,
      //optional custom cell render
    },

    // {
    //   accessorKey: "Delivery", //simple recommended way to define a column
    //   header: "Delivery",
    //   // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
    // },
    {
      accessorKey: "productVariant.seller_price", //simple recommended way to define a column
      header: "Seller's Price",
      grow: false,
      size: 10,
      //optional custom cell render
    },
    {
      accessorKey: "productVariant.price", //simple recommended way to define a column
      header: "Final Price",
      grow: false,
      size: 10,
      //optional custom cell render
    },

    {
      accessorKey: "offerPrice", //simple recommended way to define a column
      header: "Offer Price",
      grow: false,
      size: 10,
      // Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
    },
    // {
    //   accessorKey: "paymentTypes", //simple recommended way to define a column
    //   header: "Payment Option",
    //   Cell: ({ cell }) => paymenttypesString(cell.getValue()), //o
    //   // Cell: ({ cell }) => <span>{cell.getValue().map((type)=>{ return type.name})}</span>, //optional custom cell render
    // },
    {
      accessorKey: "startDate", //simple recommended way to define a column
      header: "Offer Started",
      Cell: ({ cell }) => dateTime(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "endDate", //simple recommended way to define a column
      header: "Offer End",
      Cell: ({ cell }) => dateTime(cell.getValue()), //optional custom cell render
      grow: false,
      size: 10,
    },
    {
      accessorKey: "status", //simple recommended way to define a column
      header: "Status",
      Cell: ({ cell }) => status(cell.getValue()),
      grow: false,
      size: 10,
    },
  ],
};


export const DummyDataOrderStatus = [
  {
    orderNumber: "ORD12345",
    buyerName: "John Doe",
    tripOwner: "Alice Smith",
    variant: "Product A",
    qty: 2,
    brand: "Brand X",
    deliveryPoint: "123 Main St",
    update: "2023-08-16"
  },
  {
    orderNumber: "ORD67890",
    buyerName: "Jane Smith",
    tripOwner: "Bob Johnson",
    variant: "Product B",
    qty: 3,
    brand: "Brand Y",
    deliveryPoint: "456 Elm St",
    update: "2023-08-16"
  },
  {
    orderNumber: "ORD54321",
    buyerName: "Michael Brown",
    tripOwner: "Emily Davis",
    variant: "Product C",
    qty: 1,
    brand: "Brand Z",
    deliveryPoint: "789 Oak St",
    update: "2023-08-16"
  },
  {
    orderNumber: "ORD98765",
    buyerName: "Sarah Johnson",
    tripOwner: "David White",
    variant: "Product A",
    qty: 5,
    brand: "Brand X",
    deliveryPoint: "101 Pine St",
    update: "2023-08-16"
  },
  {
    orderNumber: "ORD23456",
    buyerName: "Chris Lee",
    tripOwner: "Olivia Green",
    variant: "Product B",
    qty: 2,
    brand: "Brand Y",
    deliveryPoint: "202 Cedar St",
    update: "2023-08-16"
  }
]
export const DummyDataAssignedTrip = [
  {
    OrderNo: "ORD12345",
    Variants: "Product A",
    Category: "Electronics",
    Brand: "Brand X",
    Qty: 2,
    Load: "Fragile",
    Buyer: "John Doe",
    Area: "Downtown",
    PinCode: "12345",
    Status: "Pending"
  },
  {
    OrderNo: "ORD67890",
    Variants: "Product B",
    Category: "Clothing",
    Brand: "Brand Y",
    Qty: 3,
    Load: "Standard",
    Buyer: "Jane Smith",
    Area: "Suburb",
    PinCode: "67890",
    Status: "Shipped"
  },
  {
    OrderNo: "ORD54321",
    Variants: "Product C",
    Category: "Home Decor",
    Brand: "Brand Z",
    Qty: 1,
    Load: "Delicate",
    Buyer: "Michael Brown",
    Area: "Urban",
    PinCode: "54321",
    Status: "Delivered"
  },
  {
    OrderNo: "ORD98765",
    Variants: "Product A",
    Category: "Appliances",
    Brand: "Brand X",
    Qty: 5,
    Load: "Heavy",
    Buyer: "Sarah Johnson",
    Area: "Rural",
    PinCode: "98765",
    Status: "Processing"
  },
  {
    OrderNo: "ORD23456",
    Variants: "Product B",
    Category: "Beauty",
    Brand: "Brand Y",
    Qty: 2,
    Load: "Standard",
    Buyer: "Chris Lee",
    Area: "City Center",
    PinCode: "23456",
    Status: "Cancelled"
  }]
export const DummyDataNewOrders = [
  {
    Brand: "Brand X",
    Variant: "Product A",
    MOQ: 10,
    Delivery: "2 days",
    Pay: "Credit Card",
    DeliveryPoint: "123 Main St",
    Price: 25.99
  },
  {
    Brand: "Brand Y",
    Variant: "Product B",
    MOQ: 5,
    Delivery: "3-5 days",
    Pay: "PayPal",
    DeliveryPoint: "456 Elm St",
    Price: 19.99
  },
  {
    Brand: "Brand Z",
    Variant: "Product C",
    MOQ: 1,
    Delivery: "Next day",
    Pay: "Cash on Delivery",
    DeliveryPoint: "789 Oak St",
    Price: 34.95
  },
  {
    Brand: "Brand X",
    Variant: "Product D",
    MOQ: 20,
    Delivery: "1 week",
    Pay: "Bank Transfer",
    DeliveryPoint: "101 Pine St",
    Price: 12.49
  },
  {
    Brand: "Brand Y",
    Variant: "Product E",
    MOQ: 8,
    Delivery: "4-6 days",
    Pay: "Credit Card",
    DeliveryPoint: "202 Cedar St",
    Price: 9.99
  }
];
export const DummyDataLiveOffers = [
  {
    Brand: "Brand X",
    Variant: "Product A",
    MOQ: 10,
    Delivery: "2 days",
    OfferPrice: 20.99,
    PaymentOption: "Credit Card",
    Status: "In Stock"
  },
  {
    Brand: "Brand Y",
    Variant: "Product B",
    MOQ: 5,
    Delivery: "3-5 days",
    OfferPrice: 15.99,
    PaymentOption: "PayPal",
    Status: "Out of Stock"
  },
  {
    Brand: "Brand Z",
    Variant: "Product C",
    MOQ: 1,
    Delivery: "Next day",
    OfferPrice: 29.95,
    PaymentOption: "Cash on Delivery",
    Status: "In Stock"
  },
  {
    Brand: "Brand X",
    Variant: "Product D",
    MOQ: 20,
    Delivery: "1 week",
    OfferPrice: 9.99,
    PaymentOption: "Bank Transfer",
    Status: "In Stock"
  },
  {
    Brand: "Brand Y",
    Variant: "Product E",
    MOQ: 8,
    Delivery: "4-6 days",
    OfferPrice: 7.49,
    PaymentOption: "Credit Card",
    Status: "In Stock"
  }
];

export const BandFilterSorting = [
  {
    sort: "price,desc",
    dispaly_name: "Price High to Low"
  },
  {
    sort: "price,asc",
    dispaly_name: "Price Low to High"
  },
]

// calucation objects 
export const monthlyExpensesColumn = [{
  name: "Electricity",
  filterButton: false,
  key: 'electricity',
  width: 150
}, {
  name: "Wages",
  filterButton: false,
  key: 'wages',
  width: 150
}, {
  name: "Transportation",
  filterButton: false,
  key: 'transportation',
  width: 150
}, {
  name: "Mics. Exps.",
  filterButton: false,
  key: 'micsExps',
  width: 150
}, {
  name: "Laon Int",
  filterButton: false,
  key: 'laonInt',
  width: 150
}, {
  name: "Total Gross Profit",
  filterButton: false,
  key: 'totalGrossProfit',
  width: 150
}]
export const VariantColumn = [{
  displayName: "Preform Cost",
  name: "preformCost",
  range: 'A1:C2',
  tableColumns: ["amount", "gmr", "kg", "qty", "perPrice"],
  tableColumnsNames: ["Amount", "GMR", "KG", "QTY", "Per Price"]
}, // preformCostTable
{
  displayName: "Box Cost",
  name: "boxCost",
  range: 'A4:C5',
  tableColumns: ["numberOfBoxes", "rate", "bottles", "perBottle"],
  tableColumnsNames: ["Number Of Boxes", "Rate", "Bottles", "Per Bottle"]
},
{
  displayName: "Total Production",
  name: "totalProduction",
  range: 'A7:H8',
  tableColumns: ["electricity", "wages", "transportation", "micsExps", "laonInt", "totalNoBoxes", "totalOfBottles"],
  tableColumnsNames: ["Electricity", "Wages", "Transportation", "Mics. Exps.", "Laon Int.", "Total No Boxes", "totalOfBottles"]
},
{
  displayName: "Per Bottle Cost",
  name: "perBottleCost",
  range: 'A10:K11',
  tableColumns: ["electricity", "wages", "transportation", "micsExps", "laonInt", "label", "cap", "ink", "rejection", "total"],
  tableColumnsNames: ["Electricity", "Wages", "Transportation", "Mics. Exps.", "Laon Int.", "Label", "Cap", "Ink", "Rejection", "Total"]
},
{
  displayName: "Per Box Cost",
  name: "perBoxCost",
  range: 'A13:F14',
  tableColumns: ["noOfBottels", "perBox", "actualPrice", "grossProfit"],
  tableColumnsNames: ["No Of Bottels", "Per Box", "Actual Price", "GrossProfit"]
},
];



// card item 
export const cardItem = {
  id: "d2917248-d86e-41a4-a924-05ce936ea315",
  product_variant_id: "941ddbf5-5f18-4b6f-aca3-a881797fe3ff",
  buyer_id: "ec1d3640-4254-4c07-abf2-78156bd8a859",
  moq: "50",
  payment_types_id: "d1c21939-d5c6-4ff4-be96-f7bcf1f1a8d2",
  delivery_types_id: [
    {
      id: "52e72ad7-cfe4-469d-a177-60c57d63c714",
      name: "Bharee",
      subTitle: "within 48 Hrs",
      order: 1,
      slug: "bharee"
    }
  ],
  variant_id: "e644f0ce-7024-4e72-82bb-e1246e2f7886",
  product_id: "ae135387-ae90-4878-8621-ce3c88f09098",
  price: "220.00",
  moq_id: [
    {
      id: "0a5193ea-0ed7-41a4-b5cb-1f1f900a9076",
      quantity: 50,
      order: null
    },
    {
      id: "36e352e0-7b83-4ef2-8423-2d187f0780f2",
      quantity: 200,
      order: null
    },
    {
      id: "f01eacdf-11bc-4259-a448-7096d06207ae",
      quantity: 10,
      order: null
    },
    {
      id: "f6d9dec4-96fd-4ff4-a008-1486db435300",
      quantity: 20,
      order: null
    },
    {
      id: "8e487c66-c76f-42d2-92eb-85813167f527",
      quantity: 100,
      order: null
    }
  ],
  packaging_id: "0699dd5b-67e4-46e5-b364-bbad77470a54",
  packaging_size: "40",
  weight_per_case: "15",
  packaging_type: "Box",
  variant: 250,
  product_name: "PACKAGED DRINKING  WATER",
  brand_id: "adf271ad-03dd-440e-aedf-11e32a1a3e6b",
  seller_id: "3efca5d6-fe7d-4054-ad0b-5c6c879034dc",
  marketed_by: "WATEREDGE HYDRATION",
  product_category_id: "c9d950f1-ba35-4d17-8888-9581de9c488c",
  product_slug: "mountedge_packaged-drinking-water_premium-water_3efca5d6-fe7d-4054-ad0b-5c6c879034dc",
  shelf_life: "d7b7d592-86e3-4c30-8da4-db9861b573b6",
  manufactured_by: "WATEREDGE HYDRATION",
  hns: "2201",
  product_category: "Premium Water",
  brand_name: "MOUNTEDGE",
  brand_logo: "https://bharee-s3.s3.ap-south-1.amazonaws.com/brands/930c3439-8a44-4071-846c-b2245d28aeec-1698996430285.jpg",
  country_of_origin: "India",
  cart_id: "d2917248-d86e-41a4-a924-05ce936ea315",
  bhareeDeliveryCharge: "1",
  sellerDeliveryCharge: "5",
  delivery_types: [
    {
      id: "52e72ad7-cfe4-469d-a177-60c57d63c714",
      name: "Bharee",
      subTitle: "within 48 Hrs",
      order: 1,
      slug: "bharee"
    },
    {
      id: "ba71208a-8026-41c0-87a9-bcab1d01d76a",
      name: "Standard",
      subTitle: "In 4-5 Days",
      order: 2,
      slug: "standard"
    }
  ],
  productImages: [
    {
      url: "https://bharee-s3.s3.ap-south-1.amazonaws.com/products/8b6c94a1-0889-4732-aec4-bd06fd3e263c-1711101019870.png"
    }
  ],
  offers: [
    {
      id: "7a5f0a9b-b229-4d64-97f9-8b25a045b261",
      offerPrice: "218",
      moq_id: "0a5193ea-0ed7-41a4-b5cb-1f1f900a9076",
      quantity: 50
    },
    {
      id: "ed72f3e0-e98b-4c70-8836-7ead60e5da0c",
      offerPrice: "210",
      moq_id: "36e352e0-7b83-4ef2-8423-2d187f0780f2",
      quantity: 200
    }
  ]
}



// Banner structure
