import {  useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { permission } from "./permissions";
// import { Children } from "react";

const PrivateRoutes = ({ path, children, redirectRoute = "/", ...rest }) => {
  const { auth } = useSelector((state) => {
    return state;
  });
  const navigate = useNavigate();
  const hasPermission =
    permission?.[auth.user?.role?.role_name]?.includes(path);

  if (!hasPermission || !auth.loggedIn) {
    navigate("/");
    return <Navigate to={redirectRoute} replace />;
  }

  return children;
};

export default PrivateRoutes;
