import { appConstants } from "../helpers/app-constants";

let head = {
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",

    },
};

export const getApiDetails = {
    getRoles: {
        route: `/roles`,
        loding: appConstants.LOADING_ROLES,
        success: appConstants.GET_ROLES,
        error: appConstants.ERRORS_ROLES,
        headers: head,
    },
    getRolesTypes: {
        route: `/roletypes`,
        loding: appConstants.LOADING_ROLES_TYPES,
        success: appConstants.GET_ROLES_TYPES,
        error: appConstants.ERRORS_ROLES_TYPES,
        headers: head,
    },
    getBhreeAdminBasicTables: {
        route: `/admin/getTableData`,
        loding: appConstants.LOADING_BHAREE_ADMIN_BASIC_TABLE,
        success: appConstants.GET_BHAREE_ADMIN_BASIC_TABLE,
        error: appConstants.ERRORS_BHAREE_ADMIN_BASIC_TABLE,
        headers: head,
    },
    getBrands: {
        route: `/brands`,
        loding: appConstants.LOADING_BRANDS,
        success: appConstants.GET_BRANDS,
        error: appConstants.ERRORS_BRANDS,
        headers: head,
    },
    getBuyerBrands: {
        route: `/api/getBrands`,
        loding: appConstants.LOADING_BRANDS,
        success: appConstants.GET_BRANDS,
        error: appConstants.ERRORS_BRANDS,
        headers: head,
    },
    getDealOfTheDay: {
        route: `/api/dealsoftheday`,
        loding: appConstants.LOADING_DEALS_OF_THE_DAY,
        success: appConstants.GET_DEALS_OF_THE_DAY,
        error: appConstants.ERRORS_DEALS_OF_THE_DAY,
        headers: head,
    },
    getProducts: {
        route: `/products`,
        loding: appConstants.LOADING_PRODUCTS,
        success: appConstants.GET_PRODUCTS,
        error: appConstants.ERRORS_PRODUCTS,
        headers: head,
    },
    getUserList: {
        route: `/admin/getUsersList`,
        loding: appConstants.LOADING_USER_LIST,
        success: appConstants.GET_USER_LIST,
        error: appConstants.ERRORS_USER_LIST,
        headers: head,
    },
    getFilters: {
        route: `/api/getFilters`,
        loding: appConstants.LOADING_FILTERS,
        success: appConstants.GET_FILTERS,
        error: appConstants.ERRORS_FILTERS,
        headers: head,
    },
    getServiceTaxDetails: {
        route: `/api/getServiceTaxDetails`,
        loding: appConstants.LOADING_SERVICET_TAX_DETAILS,
        success: appConstants.GET_SERVICET_TAX_DETAILS,
        error: appConstants.ERRORS_SERVICET_TAX_DETAILS,
        headers: head,
    },
    getAddressess: {
        route: `/users/getAddressByUserId`,
        loding: appConstants.LOADING_ADDRESSES,
        success: appConstants.GET_ADDRESSES,
        error: appConstants.ERRORS_ADDRESSES,
        headers: head,
    },
    getFactoryInfo: {
        route: `/users/getFactoryInfo`,
        loding: appConstants.LOADING_FACTORY_INFO,
        success: appConstants.GET_FACTORY_INFO,
        error: appConstants.ERRORS_FACTORY_INFO,
        headers: head,
    },
    getLiveOffer: {
        route: `/liveoffers`,
        loding: appConstants.LOADING_LIVE_OFFERS,
        success: appConstants.GET_LIVE_OFFERS,
        error: appConstants.ERRORS_LIVE_OFFERS,
        headers: head,
    },
    getBankInfo: {
        route: `/users/getBankInfo`,
        loding: appConstants.LOADING_BANK_INFO,
        success: appConstants.GET_BANK_INFO,
        error: appConstants.ERRORS_BANK_INFO,
        headers: head,
    },
    getAdminStats: {
        route: `/admin/getDashboardData`,
        loding: appConstants.LOADING_ADMIN_STATS,
        success: appConstants.GET_ADMIN_STATS,
        error: appConstants.ERRORS_ADMIN_STATS,
        headers: head,
    },
    getCompanyInfo: {
        route: `/users/companyInfo`,
        loding: appConstants.LOADING_COMPANY_INFO,
        success: appConstants.GET_COMPANY_INFO,
        error: appConstants.ERRORS_COMPANY_INFO,
        headers: head,
    },
    getCart: {
        route: `/cart`,
        loding: appConstants.LOADING_CART,
        success: appConstants.GET_CART,
        error: appConstants.ERRORS_CART,
        headers: head,
    },
    getLiveOfferProductsList: {
        route: `/liveoffers/productlist`,
        loding: appConstants.LOADING_LIVEOFFER_PRODUCTS_LIST,
        success: appConstants.GET_LIVEOFFER_PRODUCTS_LIST,
        error: appConstants.ERRORS_LIVEOFFER_PRODUCTS_LIST,
        headers: head,
    },
    getOrders: {
        route: `/orders`,
        loding: appConstants.LOADING_ORDERS,
        success: appConstants.GET_ORDERS,
        error: appConstants.ERRORS_ORDERS,
        headers: head,
    },
    getOrder: {
        route: `/orders`,
        loding: appConstants.LOADING_CURRENT_ORDER,
        success: appConstants.GET_CURRENT_ORDER,
        error: appConstants.ERRORS_CURRENT_ORDER,
        headers: head,
    },
    getProductById: {
        route: `/products`,
        loding: appConstants.LOADING_CURRENT_PRODUCT,
        success: appConstants.GET_CURRENT_PRODUCT,
        error: appConstants.ERRORS_CURRENT_PRODUCT,
        headers: head,
    },
    getProductDetails: {
        route: `/api/productdetails`,
        loding: appConstants.LOADING_CURRENT_PRODUCT,
        success: appConstants.GET_CURRENT_PRODUCT,
        error: appConstants.ERRORS_CURRENT_PRODUCT,
        headers: head,
    }
} 