import React, { useEffect, useState } from "react";

// import { loginUser } from '../../actions/authActions';
import CustomModalWrap from "../common/CustomModalWrap";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../utils/is-Empty";
import {
  getBuyerLoginOtp,
  getLoginOtp,
  getRegisterOtp,
  loginUser,
  registerUser,
  verifyOtp,
} from "../../actions/auth.actions";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomButton from "../common/CustomButton";
import { appConstants } from "../../helpers";
import inputValidation from "../../utils/inputValidation";
import { VerifiedIcon } from "../../assets/svg/svg-icons";
import { Link } from "react-router-dom";

const BuyerAuth = ({ buttonClasses, buttonText = "Login", registerMode = false }) => {

  const dispatch = useDispatch();


  const {
    auth,
    registration,
    roles,
    roletypes,
    getOtp: getOtpDetails,
    verifyOtp: getVerifyOtpDetails,
    requestCall
  } = useSelector((state) => {
    return state;
  });



  const [showRegisterSecondSetp, setShowRegisterSecondSetp] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const [erroeMsg, setErroeMsg] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [buyerRegisterObj, setBuyerRegisterObj] = useState({});
  const [loginObj, setLoginObj] = useState({});
  const [buyerRoleId, setBuyerRoleId] = useState("");
  const [buyerRoleName, setBuyerRoleName] = useState("");
  const [termAndCondition, setTermAndCondition] = useState(false);


  const loginClose = () => setShowLogin(false);
  const loginShow = () => setShowLogin(true);

  const registerClose = () => setShowRegister(false);
  const registerShow = () => setShowRegister(true);




  useEffect(() => {

    if (getOtpDetails.otpDetails?.success) {
      setShowOtp(true);
      setOtpTimer(59);
    } else {
      setErroeMsg(getOtpDetails.errors?.message);
    }
  }, [getOtpDetails]);
  useEffect(() => {
    if (roles?.data?.length > 0) {
      roles?.data?.map((role) => {
        if (role.role_name === appConstants.BUYER) {
          setBuyerRoleName(role.role_name)
          return setBuyerRoleId(role.id);
        } else {
          return role
        }

      });
    }
  }, [roles]);

  useEffect(() => {
    if (!isEmpty(buyerRegisterObj) && Object.prototype.hasOwnProperty.call(buyerRegisterObj, "mobile_number") &&
      !isEmpty(buyerRegisterObj.mobile_number) && getVerifyOtpDetails.verifyDetails?.success) {
      let updatedObj = {};
      updatedObj["mobile_number_verify_status"] = true;
      setLoginObj((previousVlaues) => ({
        ...previousVlaues,
        ...updatedObj,
      }));
      setShowRegisterSecondSetp(true);
    } else {
      setShowOtp(true);
      setOtpTimer(0);
      setShowRegisterSecondSetp(false);
      setErroeMsg(getVerifyOtpDetails.errors?.message);
    }
  }, [getVerifyOtpDetails]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer > 0) {
        setOtpTimer(otpTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [otpTimer]);


  const onGetRegisterOtp = (e) => {
    setErroeMsg("");
    setBuyerRegisterValues("email", { target: { value: "" } })
    if (
      !isEmpty(buyerRegisterObj) &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "mobile_number") &&
      !isEmpty(buyerRegisterObj.mobile_number)
    ) {
      let obj = {
        mobile_number: buyerRegisterObj.mobile_number,
      };
      getRegisterOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter mobile nmber");
    }
  };
  const onGetLoginOtp = (e) => {
    setErroeMsg("");
    if (
      !isEmpty(loginObj) &&
      Object.prototype.hasOwnProperty.call(loginObj, "userid") &&
      !isEmpty(loginObj.userid)
    ) {
      let obj = {};
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      getBuyerLoginOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter email/mobile nmber");
    }
  };
  const onVerifyOtp = (e) => {
    setErroeMsg("");
    if (
      !isEmpty(buyerRegisterObj) &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "mobile_number") &&
      !isEmpty(buyerRegisterObj.mobile_number) &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "otp") &&
      !isEmpty(buyerRegisterObj.otp)
    ) {
      let obj = {
        mobile_number: buyerRegisterObj.mobile_number,
        otp: buyerRegisterObj.otp,
      };
      // console.log("****  veryfy obj", obj);
      verifyOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter OTP");
    }
  };



  const setLoginValidationValues = (key, value, maxLenght = 1000, numericOnly = false) => {
    setErroeMsg({});
    let updatedObj = {};
    let validate = inputValidation(value, maxLenght, numericOnly);
    if (validate.pass) {
      updatedObj[key] = validate.finalValue;
      setErroeMsg({ [key]: validate.msg })
      setLoginObj((previousVlaues) => ({
        ...previousVlaues,
        ...updatedObj,
      }));
    } else {
      setErroeMsg({ [key]: validate.msg })
    }

  };

  const setBuyerRegisterValues = (key, e) => {
    let updatedObj = {};
    updatedObj[key] = e.target.value;
    setBuyerRegisterObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };
  const setBuyerRegisterValidationValues = (key, value, maxLenght = 1000, numericOnly = false) => {
    let updatedObj = {};
    let validate = inputValidation(value, maxLenght, numericOnly);
    if (validate.pass) {
      updatedObj[key] = validate.finalValue;
      setErroeMsg({ [key]: validate.msg })
      setBuyerRegisterObj((previousVlaues) => ({
        ...previousVlaues,
        ...updatedObj,
      }));
    } else {
      setErroeMsg({ [key]: validate.msg })
    }
  };

  const buyerLogin = (e) => {
    e.preventDefault();
    if (
      !isEmpty(loginObj) &&
      (Object.prototype.hasOwnProperty.call(loginObj, "userid") ||
        Object.prototype.hasOwnProperty.call(loginObj, "otp"))
    ) {
      let obj = {
        otp: loginObj.otp,
      };
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      loginUser(obj, dispatch);
    }
  };
  const buyerRegister = (e) => {
    e.preventDefault();
    if (
      !isEmpty(buyerRegisterObj) &&
      termAndCondition &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "email") &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "mobile_number") &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "otp") &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "business_name") &&
      Object.prototype.hasOwnProperty.call(
        buyerRegisterObj,
        "contact_person_name"
      ) &&
      Object.prototype.hasOwnProperty.call(buyerRegisterObj, "role_type_id")
    ) {
      let obj = {
        business_name: buyerRegisterObj.business_name,
        email: buyerRegisterObj.email,
        email_verify_status: buyerRegisterObj.email_verify_status,
        contact_person_name: buyerRegisterObj.contact_person_name,
        mobile_number: buyerRegisterObj.mobile_number,
        mobile_number_verify_status:
          buyerRegisterObj.mobile_number_verify_status,
        gst_no: buyerRegisterObj.gst_no,
        role_id: buyerRoleId,
        role_type_name: buyerRoleName,
        role_type_id: buyerRegisterObj.role_type_id,
      };
      registerUser(obj, dispatch);
    }
  };

  const loginModal = (buttonClasses, buttonContant = buttonText) => {
    return (
      <CustomModalWrap
        id="loginModal"
        className="modal-custom-class login-from"
        hedaerContent={
          <>
            <h4>{"Login"}</h4>
            <p
              onClick={() => {
                loginClose();
                registerShow();
                setErroeMsg("");
                setShowOtp(false);
                setOtpTimer(0);
                setBuyerRegisterObj({});
                setShowRegisterSecondSetp(false);
              }}
            >
              {"Register Now"}
            </p>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant={buttonContant}
        isModalOpen={showLogin}
        showModal={() => {
          loginShow();
          setErroeMsg("");
          setLoginObj({});
          setBuyerRegisterObj({})
          setShowOtp(false);
          setOtpTimer(0);
        }}
        closeModal={loginClose}
      >
        <Form className="my-2" onSubmit={buyerLogin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              value={loginObj?.userid || ""}
              onChange={(e) => setLoginValidationValues("userid", e.target.value, 50)}
              placeholder="Email or phone number"
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "userid") && <p className="error-msg">{erroeMsg.userid}</p>}

          {showOtp && (
            <div className="otp-timer">
              {otpTimer > 0 ? (
                <p>{`00:${otpTimer}`}</p>
              ) : (
                <p>{"Didn't receive otp?"}</p>
              )}
              <CustomButton
                disabled={otpTimer > 0}
                className="resend-otp-btn"
                style={{ color: otpTimer > 0 ? "#DFE3E8" : "#FF5630", visibility: otpTimer > 0 ? "hidden" : "visible" }}
                onClick={() => {
                  setOtpTimer(59);
                  onGetLoginOtp();
                }}
              >
                {"Resend OTP"}
              </CustomButton>
            </div>
          )}
          {!showOtp && (
            <CustomButton
              variant="primary"
              buttonClasses="login-btn btn-left"
              loading={getOtpDetails.getOtpLoading}
              type="button"
              onClick={() =>
                Object.prototype.hasOwnProperty.call(loginObj, "userid")
                  ? onGetLoginOtp()
                  : setErroeMsg("please enter Phone Number")
              }
            >
              {"GET OTP"}
            </CustomButton>
          )}
          {showOtp && (
            <>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="otp"
                  required
                  value={loginObj?.otp || ""}
                  maxLength={6}
                  onChange={(e) => setLoginValidationValues("otp", e.target.value, 6, true)}

                  placeholder="OTP"
                />
              </Form.Group>
              {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "otp") && <p className="error-msg">{erroeMsg.otp}</p>}
              <div className="action-buttons">
                <Button
                  className="reset"
                  variant="primary"
                  onClick={() => {
                    loginClose(); setLoginObj({}); setShowOtp(false);
                    setOtpTimer(0);
                  }}
                >
                  {"Close"}
                </Button>
                <CustomButton
                  variant="primary"
                  buttonClasses={
                    auth.loginLoading ? "login-loder-btn " : "login"
                  }
                  loading={auth.loginLoading}
                  type="submit"
                >
                  {"Login"}
                </CustomButton>
              </div>
            </>
          )}
        </Form>
      </CustomModalWrap>
    );
  };

  const registerModal = (buttonClasses, buttonContant = buttonText) => {
    return (
      <CustomModalWrap
        className="modal-custom-class "
        hedaerContent={
          <>
            <h4>{"Register"}</h4>
            <p
              onClick={() => {
                registerClose();
                loginShow();
                setErroeMsg("");
                setShowOtp(false);
                setShowRegisterSecondSetp(false);
                setOtpTimer(0);
                setLoginObj({});
              }}
            >
              {"Already have account?"}
            </p>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant={buttonContant}
        isModalOpen={showRegister}
        showModal={registerShow}
        closeModal={registerClose}
      >
        <h5 className="mb-4">{"Buyer"}</h5>
        {showRegisterSecondSetp ? (
          <Form className="my-2" onSubmit={buyerRegister}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {"Business Name"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    buyerRegisterObj,
                    "business_name"
                  )
                    ? buyerRegisterObj.business_name
                    : ""
                }
                onChange={(e) => setBuyerRegisterValidationValues("business_name", e.target.value, 50)}
              />
            </Form.Group>
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "business_name") && <p className="error-msg">{erroeMsg.business_name}</p>}
            <Form.Group
              className="mb-3 terms"
              controlId="formBasicCheckbox"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Form.Label>
                {"Business Category"}
                <span className="redStreSpan">{"*"}</span>
                {":"}
              </Form.Label>
              {roletypes?.data?.length > 0 && (roletypes?.data?.map((roletype, index) => {
                if (roletype.role_id === buyerRoleId) {
                  return (
                    <Form.Check
                      id={roletype.id}
                      key={index}
                      type="radio"
                      name="radio_type"
                      label={roletype.role_type_name}
                      className="square-checkbox mx-2"
                      onChange={(e) =>
                        setBuyerRegisterValues("role_type_id", {
                          target: { value: roletype.id },
                        })
                      }
                    />
                  );
                } else {
                  // for removing waring  Array.prototype.map() expects a value to be returned at the end of arrow function
                  return <></>
                }
              }))}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {"Email ID"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="email"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    buyerRegisterObj,
                    "email"
                  )
                    ? buyerRegisterObj.email
                    : ""
                }
                onChange={(e) => setBuyerRegisterValidationValues("email", e.target.value, 50)}
              />
            </Form.Group>
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "email") && <p className="error-msg">{erroeMsg.email}</p>}
            <Form.Group className="mb-3">
              <Form.Label>
                {"Contact Person"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    buyerRegisterObj,
                    "contact_person_name"
                  )
                    ? buyerRegisterObj.contact_person_name
                    : ""
                }
                onChange={(e) =>
                  setBuyerRegisterValidationValues("contact_person_name", e.target.value, 50)
                }
              />
            </Form.Group>
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "contact_person_name") && <p className="error-msg">{erroeMsg.contact_person_name}</p>}
            <Row className="m-0">
              <Col xs={8} className=" left">
                <Form.Group className="mb-3 ">
                  <Form.Label>
                    {"Mobile"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      Object.prototype.hasOwnProperty.call(
                        buyerRegisterObj,
                        "mobile_number"
                      )
                        ? buyerRegisterObj.mobile_number
                        : ""
                    }
                    disabled
                    onChange={(e) => setBuyerRegisterValues("mobile_number", e)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className=" right d-flex pt-3 " style={{ alignItems: "center" }}>
                <sapn className="me-2 ">{"Verfied"}</sapn>
                <VerifiedIcon />
              </Col>
            </Row>


            <Form.Group
              className="mb-3 terms pb-2 term d-flex"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                type="checkbox"
                label={<p><Link target="_blank" to={"/terms"}>{"Terms of Use"}</Link>{" & "} <Link target="_blank" to={"/privacy-policy"}>{"Privacy Policy"}</Link></p>}
                required
                onChange={(e) => { setTermAndCondition(e.target.checked) }}
              />
              <Form.Label>
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{"GST No."}</Form.Label>
              <Form.Control
                type="text"
                value={
                  Object.prototype.hasOwnProperty.call(
                    buyerRegisterObj,
                    "gst_no"
                  )
                    ? buyerRegisterObj.gst_no
                    : ""
                }
                onChange={(e) => setBuyerRegisterValidationValues("gst_no", e.target.value, 50)}
              />
            </Form.Group>
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "gst_no") && <p className="error-msg">{erroeMsg.gst_no}</p>}
            {registration?.errors?.message && <p className="error-msg">{registration?.errors?.message}</p>}
            <div className="action-buttons">
              <p className="m-0">{"* Mandatory Flied"}</p>
              <Button
                className="reset"
                variant="primary"
                onClick={() => {
                  registerClose();
                  setErroeMsg("");
                  setShowOtp(false);
                  setShowRegisterSecondSetp(false);
                  setOtpTimer(0);
                  setLoginObj({});
                  dispatch({
                    type: appConstants.REGISTER_FAILURE,
                    errors: {},
                  });
                }}
              >
                {"Close"}
              </Button>
              <CustomButton
                variant="primary"
                buttonClasses={"login"}
                loading={requestCall?.loading}
                type="submit"
              >{"Register"}</CustomButton>

            </div>
          </Form>
        ) : (
          <Form className="my-2" onSubmit={buyerRegister}>
            <h6>{"Verify Mobile Number"}</h6>

            <Form.Group className="mb-3 ">
              <Form.Label>
                {"Mobile"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="mobile_number"
                maxLength={10}
                value={
                  Object.prototype.hasOwnProperty.call(
                    buyerRegisterObj,
                    "mobile_number"
                  )
                    ? buyerRegisterObj.mobile_number
                    : ""
                }
                onChange={(e) => setBuyerRegisterValidationValues("mobile_number", e.target.value, 10, true)}
              />
            </Form.Group>
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "mobile_number") && <p className="error-msg">{erroeMsg.mobile_number}</p>}
            {!showOtp && (
              <Form.Group className="mb-3  w-100">
                <Form.Label>{"Generate OTP"}</Form.Label>
                <CustomButton
                  variant="primary"
                  buttonClasses="login-btn btn-left"
                  loading={getOtpDetails.getOtpLoading}
                  type="button"
                  onClick={() =>
                    Object.prototype.hasOwnProperty.call(
                      buyerRegisterObj,
                      "mobile_number"
                    )
                      ? onGetRegisterOtp()
                      : setErroeMsg("please enter Phone Number")
                  }
                >
                  {"GET OTP"}
                </CustomButton>
              </Form.Group>
            )}

            {showOtp && (
              <>
                <Form.Group className="mb-3 ">
                  <Form.Label>
                    {"OTP"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="otp"
                    required
                    value={
                      Object.prototype.hasOwnProperty.call(
                        buyerRegisterObj,
                        "otp"
                      )
                        ? buyerRegisterObj.otp
                        : ""
                    }
                    maxLength={6}
                    onChange={(e) => setBuyerRegisterValidationValues("otp", e.target.value, 6, true)}
                  />
                </Form.Group>
                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "otp") && <p className="error-msg">{erroeMsg.otp}</p>}
                <div className="otp-timer">
                  {otpTimer > 0 ? (
                    <p>{`00:${otpTimer}`}</p>
                  ) : (
                    <p>Didn't receive otp?</p>
                  )}
                  <CustomButton
                    disabled={otpTimer > 0}
                    className="resend-otp-btn"
                    style={{ color: otpTimer > 0 ? "#DFE3E8" : "#FF5630", visibility: otpTimer > 0 ? "hidden" : "visible" }}
                    onClick={() => {
                      setOtpTimer(59);
                      onGetRegisterOtp();
                    }}
                  >
                    {"Resend OTP"}
                  </CustomButton>
                </div>
                <CustomButton
                  variant="primary"
                  buttonClasses="login-btn"
                  loading={getVerifyOtpDetails.verifyOtpLoading}
                  type="button"
                  onClick={() =>
                    Object.prototype.hasOwnProperty.call(
                      buyerRegisterObj,
                      "mobile_number"
                    )
                      ? onVerifyOtp()
                      : setErroeMsg("please enter Phone Number")
                  }
                >
                  {"Verify OTP"}
                </CustomButton>
              </>
            )}

            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "brand_name") && <p className="error-msg">{erroeMsg.brand_name}</p>}
          </Form>
        )}
      </CustomModalWrap>
    );
  };

  return (<>
    {registerModal(registerMode ? buttonClasses : "hidden", buttonText)}
    {loginModal(!registerMode ? buttonClasses : "hidden", buttonText)}
  </>)

};

export default BuyerAuth;
