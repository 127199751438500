import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import bhareeLogo from "../../assets/images/bhareeLogoFooter.png";
import PaymentMethod from "../../assets/images/paymentMethod.png";
import PaymentMethodMobile from "../../assets/images/PaymentCards.png";
import { useDispatch, useSelector } from "react-redux";
import { SocicalMediaIcons } from "../../assets/svg/svg-icons";
import "./layout.css";
import FooterLogins from "./FooterLogins";
import { EXTERNAL_SOCIAL_LINKS } from "../../config/config.url";
import BuyerAuth from "../auth/BuyerAuth";
import { logoutUser } from "../../actions/auth.actions";
function Footer() {
  const dispatch = useDispatch();

  const { auth } = useSelector(
    (state) => {
      return state;
    }
  );
  const footerLogoCol = (
    <div className="text-start footer-logo-col">
      <Image className="footer-logo" src={bhareeLogo} alt="bhareeLogo" />
      <p className="footer-parha">
        {
          "An intelligent platform where you can browse and compare different brands and types of mineral water, from still to sparkling, from natural to flavored."
        }
      </p>
      <div className="socail-icons">
        <Link to={EXTERNAL_SOCIAL_LINKS.facebook} target="_blank">
          <SocicalMediaIcons name={"facebook"} />
        </Link>
        <Link to={EXTERNAL_SOCIAL_LINKS.instagram} target="_blank">
          <SocicalMediaIcons name={"instagram"} />
        </Link>
        <Link to={EXTERNAL_SOCIAL_LINKS.linkedin} target="_blank">
          <SocicalMediaIcons name={"linkedin"} />
        </Link>
        <Link to={EXTERNAL_SOCIAL_LINKS.youtube} target="_blank">
          <SocicalMediaIcons name={"youtube"} />
        </Link>


      </div>
    </div >
  );
  const footerDetailsCol = (
    <Row className="footter-link-row ">
      <Col xs={6} sm={6} md={4} className="footer-link-col">
        <h6 className="text-uppercase">{"Useful"}</h6>
        <ul>
          <li>
            <Link to="/about-us/home">{"About us"}</Link>
          </li>
          <li>
            <Link to="/about-us/seller">{"Become Seller"}</Link>
          </li>
          <li>
            <Link to="/about-us/buyer">{"Become Buyer"}</Link>
          </li>

          {/* <li>
            <Link>{"Disclaimer"}</Link>
          </li> */}
        </ul>
      </Col>
      <Col xs={6} sm={6} md={4} className="footer-link-col">
        <h6 className="text-uppercase">{"Policy"}</h6>
        <ul>
          <li>
            <Link to="/terms">{"Terms & Conditions"}</Link>
          </li>
          <li>
            <Link to="/privacy-policy">{"Privacy Policy"}</Link>
          </li>
          <li>
            <Link to={"/shipping-policy"}>{"Shipping Policy"}</Link>
          </li>

          <li>
            <Link to="/cancellation-policy">{"Return Policy"}</Link>
          </li>
        </ul>
      </Col>
      <Col xs={6} sm={6} md={4} className="footer-link-col">
        <h6 className="text-uppercase">{"Support"}</h6>
        <ul>
          
          <li>
            <Link to={`tel:${+918799841233}`} >{"+91-8799841233"}</Link>
          </li>
          <li>
            <Link to={`mailto:support@bharee.com`} >{"Support@bharee.com"}</Link>
          </li>
          <li>
            <Link to="faq">{"FAQ"}</Link>
          </li>
          {/* <li>
            <Link>{"Help"}</Link>
          </li> */}
        </ul>
      </Col>
    </Row>
  );
  const footerInfoSection = (
    <Container fluid="md" className="mb-3" >
      <Row>
        <Col xs={12} sm={12} lg={3} className="px-0">
          {footerLogoCol}
        </Col>
        <Col xs={12} sm={12} lg={5} className="footer-details-col pt-2">
          {footerDetailsCol}
        </Col>
        {/* {auth.loggedIn && (
          <Col xs={12} sm={12} lg={4} className="footer-details-col pt-2">
            <Row className="footter-link-row ">
              <Col xs={12} className="footer-link-col">
                <h6 className="text-uppercase">{"customer care"}</h6>
                <ul>
                  <li>
                    <Link to={`tel:${+918799841233}`} >{"+91-8799841233"}</Link>
                  </li>

                </ul>
              </Col>
            </Row>
          </Col>
        )} */}
        {!auth.loggedIn && (
          <Col xs={12} sm={12} lg={4} className="px-0 footer-details-col pt-2">
            <FooterLogins />
          </Col>
        )}

      </Row>
    </Container>
  );

  const footerCopyright = (
    <Container className="p-0">
      <div className="container-lg  text-start copyright">
        <p>{"© All Copyrights By Bharee - "}{new Date().getFullYear()}</p>
        <Image
          className="desktop"
          style={{ height: "auto", width: "auto", maxWidth: "100%" }}
          src={PaymentMethod}
          alt="PaymentMethod"
        />
        <Image
          className="mobile"
          style={{ height: "auto", width: "100%", maxWidth: "100%" }}
          src={PaymentMethodMobile}
          alt="PaymentMethod"
        />
      </div>
    </Container>
  );

  return (
    <footer className="footer">
      {footerInfoSection}
      {footerCopyright}
    </footer>
  );
}

export default Footer;
