import React, { useState, useEffect } from "react";
import {
  Image,
  Badge,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import bhareeLogo from "../../assets/images/bhareeLogo.png";
import shopping_img from "../../assets/images/shopping_img.png";
import "./layout.css";
import {
  logoutUser,
} from "../../actions/auth.actions";

import {
  AcountIcon,
  BurgerMenu,
} from "../../assets/svg/svg-icons";
import { getApi, getCart } from "../../actions/structure.action";
import isEmpty from "../../utils/is-Empty";
import CustomOffCanvas from "../common/CustomOffCanvas";
import BuyerAuth from "../auth/BuyerAuth";
import { appConstants } from "../../helpers";

const Navbar = () => {
  const {
    auth,
    cart,
    bhareeAdminBasicTable
  } = useSelector((state) => {
    return state;
  });
  const [showAboutUsMenu, setShowAboutUsMenu] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleAboutUsMenuClose = () => setShowAboutUsMenu(false);
  const handleAboutUsMenuShow = () => setShowAboutUsMenu(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 150) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    getApi("getRoles", dispatch);
    getApi("getRolesTypes", dispatch);
    if (auth.loggedIn && auth?.user?.role.role_name === appConstants.BUYER) {
      getApi("getCart",dispatch);
      getApi("getBhreeAdminBasicTables",dispatch);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (!isEmpty(bhareeAdminBasicTable?.data) && !isEmpty(cart?.data)) {
      let count = 0
      bhareeAdminBasicTable?.data?.paymentTypes.map((payment) => {
        if (!isEmpty(cart?.data[payment?.name]) && cart?.data[payment?.name]?.length > 0) {
          return count = count + cart?.data[payment?.name]?.length
        }
      })
      return setCartCount(cart?.data.cartCount);
    }
  }, [bhareeAdminBasicTable, cart?.data?.cartCount])


  const homeRouteLinks = (
    <>
      {auth.loggedIn && (
        <>
          <Link
            className={`nav-item nav-link-color brand-hover ${pathname.includes("/account") ? "active white" : "no-active"
              }`}
            onClick={() => setShowAboutUsMenu(false)}
            to="/account"
          >
            {"Account"}
          </Link>
          <Link
            className={`nav-item nav-link-color  brand-hover ${pathname.includes("cart") ? "active" : "no-active"
              }`}
            onClick={() => setShowAboutUsMenu(false)}
            to="/cart"
          >
            {"Cart"}
          </Link>
          <Link
            className={`nav-item nav-link-color brand-hover no-active`}
            // onClick={() => { setShowAboutUsMenu(false); logoutUser(dispatch) }}
            onClick={logoutUser(dispatch)}
            to="#"
          >
            {"Logout"}
          </Link>

        </>)}
      {/* {!auth.loggedIn && (<BuyerAuth buttonText={"Login"} buttonClasses={"nav-item nav-link-color modal-login-btn"} />)} */}

      <Link
        className={`nav-item nav-link-color    brand-hover ${pathname.includes("brands") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/brands"
      >
        {"Brands"}
      </Link>
      <Link
        className={`nav-item nav-link-color    brand-hover ${pathname.includes("support") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/support"
      >
        {"Contact"}
      </Link>
      <div className="desktop-nav-item">
        <Link
          className={`nav-item nav-link-color white blackBg`}
          onClick={() => setShowAboutUsMenu(false)}
          to="/about-us/home"
        >
          {"Become a seller"}
        </Link>

      </div>
    </>
  );
  const logoAndSearch = (
    <>
      <nav className="navbar-section py-0">
        <div className="container navbar-container">
          <Link className="navbar-brand" to="/">
            <Image src={bhareeLogo} alt="bhareeLogo" />
          </Link>
          {/* 
          
          **********Search with location Will  launch second launch******* 
          
          
          */}

          {/* <div className="desktop search-wrap">
            <div className="w-full search-container ">
              <Link className="nav-item nav-link-color" to="/">
                <Image height={20} width={20} src={home_img} alt="home_img" />
              </Link>
             

              <InputGroup>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="search-dropdown"
                  >
                    {"Pune"}
                    <DropDownArrowIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">{"Action"}</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {"Another action"}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      {"Something else"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div id="borderRight"></div>
                <Form.Control
                  className="search-box"
                  aria-label="Text input with dropdown button"
                />
                <Button className="px-4 search-btn" variant="outline-secondary">
                  <Image height={20} width={16} src={seacrh_img} alt="social" />
                </Button>

              </InputGroup>
            </div>
          </div> */}
          {/* <div className="mobile link-warp">
            <div className="w-full search-container ">
              <InputGroup>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="search-dropdown"
                  >
                    {"Pune"}
                    <DropDownArrowIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">{"Action"}</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {"Another action"}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      {"Something else"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </div>
          </div> */}



          <div className="nav-icons">
            <div className="  desktop-nav-item">
              <Link
                className="nav-item nav-link-color desktop"
                onClick={() => setShowAboutUsMenu(false)}
                to="/brands"
              >
                {"Brands"}
              </Link>
              <Link
                className="nav-item nav-link-color desktop"
                onClick={() => setShowAboutUsMenu(false)}
                to="/support"
              >
                {"Contact"}
              </Link>
              {auth.loggedIn ? (
                <Link
                  className="nav-item nav-link-color "
                  onClick={logoutUser(dispatch)}
                  to="#"
                >
                  {"Logout"}
                </Link>
              ) : (<BuyerAuth buttonText={"Login"} buttonClasses={"nav-item nav-link-color  modal-login-btn "} />
              )}

              <Link
                className="nav-item nav-link-color blackBg desktop"
                to="/about-us/home"
              >
                {"Become a seller"}
              </Link>
            </div>
            {auth.loggedIn && (
              <>
                <Link className="nav-item nav-link-color" to="/cart">
                  <Image height={20} width={20} src={shopping_img} alt="social" />
                  {!isEmpty(cart?.data) && cart?.data?.cartCount > 0 && (<sup>
                    <Badge bg="warning" className="cartBadge">
                      {cart?.data?.cartCount}
                    </Badge>
                  </sup>)}

                </Link>
                <Link className="nav-item nav-link-color" to="/account">
                  <AcountIcon />
                  {/* <Image height={20} width={20} src={profile_img} alt="social" /> */}
                </Link>

              </>)}
            <div className="mobile burger-menu-wrap">
              <CustomOffCanvas
                buttonContant={<BurgerMenu />}
                placement={"end"}
                className="aboutus-ofcanvas"
                backdropClassName="aboutus-backdrop"
                bodyBsPrefix="aboutus-ofcanvas-body"
                isOffCanvasOpen={showAboutUsMenu}
                OffcanvasShow={handleAboutUsMenuShow}
                OffcanvasClose={handleAboutUsMenuClose}
              >
                <div className="w-full about-mobile-menu ">{homeRouteLinks}</div>
              </CustomOffCanvas>
            </div>
          </div>
        </div>
      </nav>
      <div className="space"></div>
    </>
  );
  const aboutRouteLinks = (
    <>
      <Link
        className={`nav-item nav-link-color brand-hover ${pathname.includes("/about-us/home") ? "active white" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/about-us/home"
      >
        {"Home"}
      </Link>
      <Link
        className={`nav-item nav-link-color  brand-hover ${pathname.includes("about-us/buyer") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/about-us/buyer"
      >
        {"Buyer"}
      </Link>
      <Link
        className={`nav-item nav-link-color brand-hover ${pathname.includes("about-us/seller") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/about-us/seller"
      >
        {"Seller"}
      </Link>

      <Link
        className={`nav-item nav-link-color  brand-hover ${pathname.includes("about-us/become-a-seller") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/about-us/become-a-seller"
      >
        {"Become a seller"}
      </Link>
      <Link
        className={`nav-item nav-link-color    brand-hover ${pathname.includes("support") ? "active" : "no-active"
          }`}
        onClick={() => setShowAboutUsMenu(false)}
        to="/support"
      >
        {"Contact"}
      </Link>
      <div className="desktop-nav-item">
        <Link
          className={`nav-item nav-link-color white blackBg`}
          onClick={() => setShowAboutUsMenu(false)}
          to="/"
        >
          {"Shop Now"}
        </Link>

      </div>
    </>
  );

  const forAboutUs = (
    <nav className={`navbar-section  about-us ${scrolled ? 'scrolled' : ''}`}>
      <div className="container navbar-container">
        <Link className="navbar-brand" to="/">
          <Image src={bhareeLogo} alt="bhareeLogo" />
        </Link>
        <div className="desktop w-full">
          <div className="w-full about-desktop-menu ">{aboutRouteLinks}</div>
        </div>
        <div className="mobile burger-menu-wrap">
          <CustomOffCanvas
            buttonContant={<BurgerMenu />}
            placement={"end"}
            className="aboutus-ofcanvas"
            backdropClassName="aboutus-backdrop"
            bodyBsPrefix="aboutus-ofcanvas-body"
            isOffCanvasOpen={showAboutUsMenu}
            OffcanvasShow={handleAboutUsMenuShow}
            OffcanvasClose={handleAboutUsMenuClose}
          >
            <div className="w-full about-mobile-menu ">{aboutRouteLinks}</div>
          </CustomOffCanvas>
        </div>
      </div>
    </nav>
  );

  return (
    <>
      {!pathname.includes("panel") &&
        (pathname.includes("about") ? forAboutUs : logoAndSearch)}

    </>
  );
};

export default Navbar;
