import axios, { Axios } from "axios";
import { appConstants } from "../helpers/app-constants";
import { BHAREE_BASE_URL_LOCAL } from "../config/config.url";
import isEmpty from "../utils/is-Empty";
import { toast } from 'react-toastify';
import { getApiDetails } from "./apiDetails";

let options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.jwtToken}`,
  },
};

// common get api funtion
export const getApi = async (apiName, dispatch, id = "") => {
  console.log("***** id ", id)
  let details = await getApiDetails[apiName];
  let res = {};
  await dispatch({
    type: details.loding,
  });
  try {
    res = await axios.get(BHAREE_BASE_URL_LOCAL + details.route + (!isEmpty(id) ? id : ""), { headers: { ...details.headers, Authorization: `Bearer ${localStorage.jwtToken}`, } });
    return res.status === 200 ? dispatch({
      type: details.success,
      payload: res?.data?.data,
      rolesloading: false,
    }) : "";

  } catch (err) {
    dispatch({
      type: details.error,
      payload: err.response,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at${details.route}`);
  }

}

//custom get api funtion
export const getProductsList = async (dispatch, filter = {}, skip = 0, limit = 12) => {

  let filterString = ""
  let res = {}

  Object.entries(filter).map(([key, value]) => {
    if (!isEmpty(value) && typeof value !== "object") {
      let id = value.split('|')[1]
      filterString = filterString + "&" + key + "=" + id
    }
    return filterString
  })
  await dispatch({
    type: appConstants.LOADING_PRODUCTS_LIST,
  });

  try {
    res = await axios
      .get(BHAREE_BASE_URL_LOCAL + `/api/productlist?limit=${limit}&skip=${skip}${filterString}${!isEmpty(filter?.sort) ? "&sort=" + filter?.sort?.value : ""}`, options);
    return dispatch({
      type: appConstants.GET_PRODUCTS_LIST,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: appConstants.ERRORS_PRODUCTS_LIST,
      payload: err.response,
    });
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at productlist`);
  }

};
export const getCartAtLogin = async (dispatch, token = localStorage.jwtToken) => {
  await dispatch({
    type: appConstants.LOADING_CART,
  });
  let res = {}
  try {
    res = await axios.get(BHAREE_BASE_URL_LOCAL + "/cart", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
    return dispatch({
      type: appConstants.GET_CART,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: appConstants.ERRORS_CART,
      payload: err.response,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at cart at login`);
  }
};
export const getGeoData = async (dispatch, countryCode = "", stateCode = "") => {

  let res = {}
  try {
    res = await axios.get(BHAREE_BASE_URL_LOCAL + `/api/geoData${!isEmpty(countryCode) ? "?countryCode=" + countryCode : ""}${!isEmpty(countryCode) && !isEmpty(stateCode) ? "&stateCode=" + stateCode : ""}`, options);
    if (res.status === 200) {
      if (!isEmpty(countryCode) && !isEmpty(stateCode)) {
        return dispatch({
          type: appConstants.GET_CITIES,
          payload: res.data.data,
        });
      } else if (!isEmpty(countryCode) && isEmpty(stateCode)) {
        return dispatch({
          type: appConstants.GET_STATES,
          payload: res.data.data,
        });
      } else {
        return dispatch({
          type: appConstants.GET_COUNTRIES,
          payload: res.data.data,
        });
      }
      return true
    }
  } catch (err) {
    if (!isEmpty(countryCode) && !isEmpty(stateCode)) {
      dispatch({
        type: appConstants.ERRORS_CITIES,
        payload: err.response,
      })
      return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at country`);
    } else if (!isEmpty(countryCode) && isEmpty(stateCode)) {
      dispatch({
        type: appConstants.ERRORS_STATES,
        payload: err.response,
      });
      return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at states`);
    } else {
      dispatch({
        type: appConstants.ERRORS_COUNTRIES,
        payload: err.response,
      });
      return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at cities`);
    }
  }
}


//custom get api funtion
// export const postApi = async (apiName, dispatch, id = "") => {
//   let details = await getApiDetails[apiName];
//   let res = {};
//   await dispatch({
//     type: details.loding,
//   });
//   try {
//     res = await axios.get(BHAREE_BASE_URL_LOCAL + details.route + id, details.options);
//     return res.status === 200 ? dispatch({
//       type: details.success,
//       payload: res?.data?.data,
//       rolesloading: false,
//     }) : "";

//   } catch (err) {
//     dispatch({
//       type: details.error,
//       payload: err.response,
//     })
//     return toast.error(err.response.data.message);
//   }

// }


// create api

export const postVariant = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/variants", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
      return err;
    })
};

export const postMoq = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/moqs", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};
export const postProductCategories = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/productcategory", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })

};
export const postPackagingTypes = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/packagingtype", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })

};
export const postDeliveryType = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/deliverytype", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })

};
export const postPaymentTypes = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/paymenttype", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })

};
export const postShelfLife = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/shelflife", userData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBhreeAdminBasicTables", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};


export const postBrand = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/brands", userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBrands", dispatch)
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};

///post product
export const postProduct = (productData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/products", productData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getProducts", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};


export const postProductVariant = (variantData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/products/variants", variantData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getProducts", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};

export const postSaveServiceTaxDetails = (productData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/admin/saveServiceTaxDetails", productData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getServiceTaxDetails", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const postProductStatus = (productData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(
      BHAREE_BASE_URL_LOCAL + "/admin/updateProductStatus",
      productData,
      options
    )
    .then((res) => {
      toast.success(res.data.message);
      getApi("getProducts", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};
export const postBrandStatus = (productData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(
      BHAREE_BASE_URL_LOCAL + "/admin/updateBrandStatus",
      productData,
      options
    )
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBrands", dispatch)
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};
export const postAddresses = (address, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/users/addAddress", address, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken}`,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      // dispatch({
      //   type: appConstants.GET_ADDRESSES,
      //   payload: res.data.data,
      // });
      getApi("getAddressess", dispatch);
      return res.data;
      // set current user data
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    }
    ).finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const postFactoryInfo = (factoryInfoData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/users/updateFactoryInfo", factoryInfoData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getFactoryInfo", dispatch);
      return res.data;
      // set current user data
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    }
    ).finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const postBankInfo = (factoryInfoData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/users/updateBankInfo", factoryInfoData, options)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBankInfo", dispatch);
      return res.data;
      // set current user data
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    }
    ).finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};


export const postLiveOffer = (data, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/liveoffers", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getLiveOffer", dispatch)
      getApi("getLiveOfferProductsList", dispatch)
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const postCart = (data, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/cart", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getCart", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};


export const postSupport = (data, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/support", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${ localStorage.jwtToken } `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      // window.location.href = "/support";
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const postOrder = async (data, dispatch, navigate) => {
  let res = {};
  await dispatch({
    type: appConstants.LOADING_PAYMENT,
  });
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/orders", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    });
    return res.status === 200 ? dispatch({
      type: appConstants.GET_PAYMENT,
      payload: res.data.data,
    }) : "";

  } catch (err) {
    dispatch({
      type: appConstants.ERRORS_PAYMENT,
      payload: err.response,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at orders`);
  }

  // axios
  //   .post(BHAREE_BASE_URL_LOCAL + "/orders", data, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Access-Control-Allow-Origin": "*",
  //       Authorization: `Bearer ${localStorage.jwtToken} `,
  //     },
  //   })
  //   .then((res) => {
  //     toast.success(res.data.message);
  //     // getApi("getOrders",dispatch);
  //     // navigate(`/account`);
  //     // return res.data;
  //     dispatch({
  //       type: appConstants.GET_PAYMENT,
  //       payload: res.data.data
  //     });
  //   })
  //   .catch((err) => {
  //     toast.error(err.response.data.message);
  //     dispatch({
  //       type: appConstants.ERRORS_PAYMENT,
  //     });
  //     return err;
  //   });
};

export const postCalculations = (data, dispatch) => {
  dispatch({
    type: appConstants.LOADING_CALCULATION_FILE,
  });
  axios
    .post(BHAREE_BASE_URL_LOCAL + "/api/getcostcalculation", data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      dispatch({
        type: appConstants.GET_CALCULATION_FILE,
        payload: res.data.data,
      });
      const pdfUrl = res.data.data; // Replace with your PDF URL
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "calculation.pdf"); // Specify the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.ERRORS_CALCULATION_FILE,
        payload: err,
      });
      return err;
    })
};


export const postGetCalculatedprice = async (data, dispatch) => {
  await dispatch({
    type: appConstants.LOADING_CALCULATED_PRICE,
  });
  let res = {}
  if (!isEmpty(data.price)) {
    try {
      res = await axios.post(BHAREE_BASE_URL_LOCAL + "/api/getcalculatedprice", { price: data.price }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.jwtToken} `,
        },
      })
      if (res.status === 200) {
        let obj = {}
        obj[data.variantId] = res.data.data
        return dispatch({
          type: appConstants.GET_CALCULATED_PRICE,
          payload: obj,
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.ERRORS_CALCULATED_PRICE,
        payload: err,
      });
      return err;
    }
  }
  // axios
  //   .post(BHAREE_BASE_URL_LOCAL + "/api/getcostcalculation", data, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //       Authorization: `Bearer ${localStorage.jwtToken} `,
  //     },
  //   })
  //   .then((res) => {
  //     toast.success(res.data.message);
  //     dispatch({
  //       type: appConstants.GET_CALCULATED_PRICE,
  //       payload: res.data.data,
  //     });
  //     const pdfUrl = BHAREE_BASE_URL_LOCAL + res.data.data; // Replace with your PDF URL
  //     const link = document.createElement("a");
  //     link.href = pdfUrl;
  //     link.setAttribute("target", "_blank");
  //     link.setAttribute("download", "calculation.pdf"); // Specify the file name
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   })
  //   .catch((err) => {
  //     toast.error(err.response.data.message);
  //     dispatch({
  //       type: appConstants.ERRORS_CALCULATED_PRICE,
  //       payload: err,
  //     });
  //     return err;
  //   })
};
// export const postGetCalculatedpriceD = async (data) => {
//   let res = {}
//   if (!isEmpty(data)) {
//     try {
//       res = await axios.post(BHAREE_BASE_URL_LOCAL + "/getcostcalculation", { price: data }, {
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//           Authorization: `Bearer ${localStorage.jwtToken} `,
//         },
//       })
//       if (res.status === 200) {
//         return res.data.data
//       }else {
//         return 0
//       }
//     } catch (err) {
//       toast.error(err.response.data.message);
//       return 0
//     }
//   }
// };

// update api



export const updateUserStatus = (usertId, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .get(
      BHAREE_BASE_URL_LOCAL + `/admin/updateUserStatus/${usertId}`,
      options
    )
    .then((res) => {
      toast.success(res.data.message);
      getApi("getUserList", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};
export const patchBrand = (brandtId, userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/brands/${brandtId}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getBrands", dispatch)
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};

export const patchProduct = (brandtId, userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/products/${brandtId}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getProducts", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};


export const patchAddress = (addressId, address, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/users/updateAddress/${addressId}`, address, options,)
    .then((res) => {
      toast.success(res.data.message);
      getApi("getAddressess", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const patchCompanyInfo = (userData, dispatch) => {

  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + "/users/companyInfo", userData, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getCompanyInfo", dispatch);
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({
        type: appConstants.REQUEST_FAIL,
        payload: err.response.data
      });
    })
};


export const patchProductVariant = (variantId, userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + "/products/variants", userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getProducts", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const patchLiveOffer = (userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/liveoffers/${userData.id}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getLiveOffer", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};

export const patchCart = (cartData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/cart/${cartData.id}`, cartData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getCart", dispatch);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};



/// patch Order
export const patchOrder = (data, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/orders/updateOrderStatus/${data.id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken} `,
      },
    })
    .then((res) => {
      toast.success(res.data.message);
      getApi("getOrders", dispatch);
      // navigate(`/account`);
      // return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};





// delete api
export const deleteVariant = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/variants/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  }
};

export const deleteMoq = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/moqs/ ${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
        // dispatch({
        //   type: appConstants.GET_BHAREE_ADMIN_BASIC_TABLE,
        //   payload: res.data.data,
        // });
        // set current user data
      })
      .catch(
        (err) => {
          return err;
        }
        // dispatch({
        //   type: appConstants.ERRORS_BHAREE_ADMIN_BASIC_TABLE,
        //   payload: err.response,
        // })
      );
  }

};

export const deleteProductCategories = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(
        BHAREE_BASE_URL_LOCAL + `/productcategory/${variantId}`,
        options
      )
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
        // dispatch({
        //   type: appConstants.GET_BHAREE_ADMIN_BASIC_TABLE,
        //   payload: res.data.data,
        // });
        // set current user data
      })
      .catch(
        (err) => {
          return err;
        }
        // dispatch({
        //   type: appConstants.ERRORS_BHAREE_ADMIN_BASIC_TABLE,
        //   payload: err.response,
        // })
      );
  }

};
export const deletePackagingTypes = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/packagingtype/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  }

};
export const deleteDeliveryType = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/deliverytype/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  }

};
export const deletePaymentTypes = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/paymenttype/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      });
  }

};
export const deleteShelfLife = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/shelflife/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBhreeAdminBasicTables", dispatch);
        return res.data;

      })
      .catch(
        (err) => {
          return err;
        }
      );
  }
};


export const deleteBrand = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/brands/${variantId} `, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getBrands", dispatch)
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })
      .finally(() => {
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });
      });
  }

};
export const deleteProduct = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/products/${variantId}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getProducts", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })
      .finally(() => {
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });
      });
  }

};

export const deleteAddress = (address, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/users/deleteAddress/${address.id}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getAddressess", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })
      .finally(() => {
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });
      });
  }

};

export const deleteCartItem = (address, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/cart/${address.id} `, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.jwtToken} `,
        }
      },)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getCart", dispatch);
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });

        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })

  }

};

export const deleteLiveOffer = (id, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/liveoffers/${id}`, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getLiveOffer", dispatch);
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });

        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })

  }

};

export const deleteProductVariant = (variantId, dispatch) => {
  const isConfirmed = window.confirm("Are you sure you want to delete?");
  if (isConfirmed) {
    dispatch({
      type: appConstants.REQUEST_CALL,
    });
    axios
      .delete(BHAREE_BASE_URL_LOCAL + `/products/variants/${variantId} `, options)
      .then((res) => {
        toast.success(res.data.message);
        getApi("getProducts", dispatch);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        return err;
      })
      .finally(() => {
        dispatch({
          type: appConstants.REQUEST_COMPLETED,
        });
      });
  }

};