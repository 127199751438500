import { createStore, applyMiddleware, compose } from "redux";
// import thunkMiddleware from 'redux-thunk';
import thunk from "redux-thunk";
// import { createLogger } from "redux-logger";
import rootReducer from "../reducer";
// const loggerMiddleware = createLogger();
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const initialState = {};


const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const middleware = [thunk];
export const store = createStore(
  rootReducer,
  // persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
export const persistor = persistStore(store);
// console.warn('store', store);
