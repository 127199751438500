import { appConstants } from "../helpers/app-constants";

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const initialState = {
  loginLoading: false,
  loggedIn: false,
  user: {},
  errors: {},
};
const initialStateRegistration = {
  registrationLoading: false,
  registrationError: false,
  registration: false,
  errors: {},
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOGIN_REQUEST:
      return {
        loginLoading: true,
        user: {},
      };
    case appConstants.LOGIN_SUCCESS:
      return {
        loginLoading: false,
        loggedIn: true,
        user: action.user,
      };
    case appConstants.LOGIN_FAILURE:
      return {
        loginLoading: false,
        loggedIn: false,
        errors: action.errors,
      };
    case appConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
export function registration(state = initialStateRegistration, action) {
  switch (action.type) {
    case appConstants.REGISTER_REQUEST:
      return {
        registrationLoading: true,
        registrationError: false,
        registration: false,
        errors: {},
      };
    case appConstants.REGISTER_SUCCESS:
      return {
        registrationLoading: false,
        registrationError: false,
        registration: false,
        errors: {},
      };
    case appConstants.REGISTER_FAILURE:
      return {
        registrationLoading: false,
        registrationError: false,
        registration: false,
        errors: action.errors,
      };
    default:
      return state;
  }
}

const getOtpInitialState = {
  getOtpLoading: false,
  otpDetails: {},
  errors: {},
};

export function getOtp(state = getOtpInitialState, action) {
  switch (action.type) {
    case appConstants.LOADING_GET_OTP:
      return {
        getOtpLoading: true,
        otpDetails: {},
        errors: {},
      };
    case appConstants.GET_OTP:
      return {
        getOtpLoading: false,
        otpDetails: action.payload,
        errors: {},
      };
    case appConstants.GET_OTP_ERROR:
      return {
        getOtpLoading: false,
        otpDetails: {},
        errors: action.payload,
      };
    default:
      return state;
  }
}

const verfiyOtpInitialState = {
  verifyOtpLoading: false,
  verifyDetails: {},
  errors: {},
};
export function verifyOtp(state = verfiyOtpInitialState, action) {
  switch (action.type) {
    case appConstants.LOADING_VERIFY_OTP:
      return {
        verifyOtpLoading: true,
        verifyDetails: {},
        errors: {},
      };
    case appConstants.VERIFY_OTP:
      return {
        verifyOtpLoading: false,
        verifyDetails: action.payload,
        errors: {},
      };
    case appConstants.VERIFYT_OTP_ERROR:
      return {
        verifyOtpLoading: false,
        verifyDetails: {},
        errors: action.payload,
      };
    default:
      return state;
  }
}
