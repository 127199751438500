import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import {
  CloseIcon,
  FilterIcon,
  ForwardArrow,
} from "../../assets/svg/svg-icons";
import "./brands.css";
import BrandCard from "./BrandCard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CustomOffCanvas from "../common/CustomOffCanvas";
import { getApi, getProductsList, postCart } from "../../actions/structure.action";
import Spinner from "../common/Spinner";
import isEmpty from "../../utils/is-Empty";
import CustomButton from "../common/CustomButton";
import CustomSelectDropdown from "../common/CustomSelectDropdown";
import FilterObjectsByKeyValue from "../../utils/FilterObjectsByKeyValue";

function Brands() {
  const dispatch = useDispatch();

  const { productsList, filters, requestCall, auth } = useSelector((state) => {
    return state;
  });

  const [showFiterSideMenu, setShowFiterSideMenu] = useState(false);
  const handleFiterSideMenuClose = () => setShowFiterSideMenu(false);
  const handleFiterSideMenuShow = () => setShowFiterSideMenu(true);
  const refFilter = useRef([]);
  const refMobileFilter = useRef([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const [productsList, setProductsList] = useState([...productList]);
  const handleScrollView = () => {
    if (window.innerWidth < 768) {
      refMobileFilter.current?.scrollIntoView({ behavior: "smooth", block: "end" });

    } else {
      refFilter.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const addToCart = (data, moq) => {
    let postData = {}
    postData["product_variant_id"] = data.id;
    postData["buyer_id"] = auth.user.id;
    postData["moq"] = moq.quantity;
    if (!isEmpty(FilterObjectsByKeyValue(data.delivery_types, "name", "Bharee"))) {
      postData["delivery_types_id"] = FilterObjectsByKeyValue(data.delivery_types, "name", "Bharee")[0]?.id;
    } else {
      postData["delivery_types_id"] = FilterObjectsByKeyValue(data.delivery_types, "name", "Standard")[0]?.id;
    }
    postData["payment_types_id"] = FilterObjectsByKeyValue(data.payment_types, "name", "Pay Now")[0]?.id;
    postCart(postData, dispatch)
  }
  const [sortObj, setSortObj] = useState({})
  const [filtered, setFiltered] = useState({
    brand: params.get("brand") ? params.get("brand") : "",
    delivery: params.get("delivery") ? params.get("delivery") : "",
  });

  const [pagination, setPagination] = useState({
    limit: 12,
    skip: 0,
    totalCount: 0,
    nextActive: true,
    previousActive: false,
  });
  useEffect(() => {
    handleScrollView()
    getProductsList(dispatch, filtered, pagination.skip, pagination.limit);
  }, [filtered])
  useEffect(() => {
    if (!isEmpty(productsList.data)) {
      SetPagination("totalCount", productsList?.data?.totalCount);
      if (productsList?.data?.products?.length < pagination.limit) {
        setPagination((setFiltered) => ({
          ...setFiltered,
          ...{
            nextActive: false
          },
        }));
      }
    }
  }, [productsList])

  const SetFilter = (type, value) => {
    if (type === "variant") {
      filtered["variant"] === value
        ? setFiltered((current) => {
          const { variant, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ variant: value },
        }));
    } else if (type === "moq") {
      filtered["moq"] === value
        ? setFiltered((current) => {
          const { moq, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ moq: value },
        }));
    } else if (type === "delivery") {
      filtered["delivery"] === value
        ? setFiltered((current) => {
          const { delivery, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ delivery: value },
        }));
    } else if (type === "category") {
      filtered["category"] === value
        ? setFiltered((current) => {
          const { category, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ category: value },
        }));
    } else if (type === "brand") {
      filtered["brand"] === value
        ? setFiltered((current) => {
          const { brand, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ brand: value },
        }));
    } else if (type === "rating") {
      filtered["rating"] === value
        ? setFiltered((current) => {
          const { rating, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ rating: value },
        }));
    }
    else if (type === "sort") {
      filtered["sort"] === value
        ? setFiltered((current) => {
          const { sort, ...rest } = current;
          return rest;
        })
        : setFiltered((setFiltered) => ({
          ...setFiltered,
          ...{ sort: value },
        }));
    }
    setPagination((setFiltered) => ({
      ...setFiltered,
      ...{
        skip: 0,
        previousActive: false
      },
    }));
  };
  const SetPagination = (type, value) => {
    if (type === "skip") {
      if (value > 0) {
        setPagination((setFiltered) => ({
          ...setFiltered,
          ...{
            skip: value,
            previousActive: true
          },
        }));
        getProductsList(dispatch, filtered, value, pagination.limit)
      } else if (value === 0) {
        setPagination((setFiltered) => ({
          ...setFiltered,
          ...{
            skip: value,
            previousActive: false
          },
        }));
        getProductsList(dispatch, filtered, value, pagination.limit)
      }
    } else if (type === "totalCount") {

      if (value < pagination.limit) {
        setPagination((setFiltered) => ({
          ...setFiltered,
          ...{ nextActive: false, totalCount: value },
        }));
      } else {
        setPagination((setFiltered) => ({
          ...setFiltered,
          ...{ nextActive: true, totalCount: value },
        }));
      }
    }
  };
  useEffect(() => {
    getApi("getFilters", dispatch)
    // getProductsList(dispatch, filtered, pagination.skip, pagination.limit, sortObj?.sort)

  }, []);


  const brandsBanner = (
    <div
      className="brands-banner-main-div text-uppercase desktop"
      ref={refFilter}
    >
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Brands"}</h2>
    </div>
  );
  const filterMenu = () => {

    if (!isEmpty(filters?.data) && !filters.loading) {
      return <>
        <h4 className="filter-heading text-uppercase">{"filter"}</h4>
        <hr />
        <div className="filter-option-div px-4">
          <p className="text-uppercase m-0 filter-options-herading">{"BrandS"}</p>
          <div className="filter-options">
            <br />
            <Form.Select
              onChange={(e) => {
                SetFilter("brand", e.target.value);
                handleScrollView();
                handleFiterSideMenuClose();
              }}
            >
              <option value={""}>{"All"}</option>
              {filters?.data?.brands?.length > 0 && filters?.data?.brands.map((brandItem, index) => (
                <option
                  key={index}
                  value={brandItem.brand_name + "|" + brandItem.id}
                  selected={filtered["brand"] === brandItem.brand_name + "|" + brandItem.id ? true : false}
                >
                  {brandItem.brand_name}
                </option>
              ))}
            </Form.Select>
            <br />
          </div>
        </div>
        <hr />
        <div className="filter-option-div px-4">
          <p className="text-uppercase m-0 filter-options-herading ">
            {"Variant"}
          </p>
          {filters?.data?.variants?.length > 0 && filters?.data?.variants.map((variantItem, index) => {
            return (
              <div
                className="filter-options"
                key={`variant-${index}-${variantItem.displayName + "|" + variantItem.id}`}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      SetFilter("variant", variantItem.displayName + "|" + variantItem.id);
                      handleScrollView();
                      handleFiterSideMenuClose();
                    }}
                  />
                  <span
                    className={`checkbox ${filtered["variant"] === variantItem.displayName + "|" + variantItem.id
                      ? "checkbox--active"
                      : ""
                      }`}
                    aria-hidden="true"
                  />
                  <p>{variantItem.displayName}</p>
                </label>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="filter-option-div px-4">
          <p className="text-uppercase m-0 filter-options-herading">{"MOQ"}</p>
          {filters?.data?.moqs?.length > 0 && filters?.data?.moqs.map((moqItem, index) => {
            return (
              <div className="filter-options" key={`moq-${index}-${moqItem.quantity + "MOQ" + "|" + moqItem.id}`}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      SetFilter("moq", moqItem.quantity + " MOQ" + "|" + moqItem.id);
                      handleScrollView();
                      handleFiterSideMenuClose();
                    }}
                  />
                  <span
                    className={`checkbox ${filtered["moq"] === moqItem.quantity + " MOQ" + "|" + moqItem.id ? "checkbox--active" : ""
                      }`}
                    aria-hidden="true"
                  />
                  <p>{moqItem.quantity}</p>
                </label>
              </div>
            );
          })}
        </div>
        <hr />

        <div className="filter-option-div px-4">
          <p className="text-uppercase m-0 filter-options-herading">
            {"Delivery"}
          </p>
          {filters?.data?.deliveryTypes?.length > 0 && filters?.data?.deliveryTypes.map((deliveryItem, index) => {
            return (
              <div
                className="filter-options"
                key={`delivery-${index}-${deliveryItem.name + "|" + deliveryItem.id}`}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      SetFilter("delivery", deliveryItem.name + "|" + deliveryItem.id);
                      handleScrollView();
                      handleFiterSideMenuClose();
                    }}
                  />
                  <span
                    className={`checkbox ${filtered["delivery"] === deliveryItem.name + "|" + deliveryItem.id
                      ? "checkbox--active"
                      : ""
                      }`}
                    aria-hidden="true"
                  />
                  <p className="text-uppercase">{deliveryItem.name}</p>
                </label>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="filter-option-div px-4">
          <p className="text-uppercase m-0 filter-options-herading">
            {"Category"}
          </p>
          {filters?.data?.productCategories?.length > 0 && filters?.data?.productCategories.map((catagoryItem, index) => {
            return (
              <div
                className="filter-options"
                key={`Category-${index}-${catagoryItem.name + "|" + catagoryItem.id}`}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={() => {
                      SetFilter("category", catagoryItem.name + "|" + catagoryItem.id);
                      handleScrollView();
                      handleFiterSideMenuClose();
                    }}
                  />
                  <span
                    className={`checkbox ${filtered["category"] === catagoryItem.name + "|" + catagoryItem.id
                      ? "checkbox--active"
                      : ""
                      }`}
                    aria-hidden="true"
                  />
                  <p>{catagoryItem.name}</p>
                </label>
              </div>
            );
          })}
        </div>

        {/* <hr />
        <div className="filter-option-div px-4 pb-4">
          <p className="text-uppercase m-0 filter-options-herading">
            {"SELLER Rating"}
          </p>
          <div className="mt-3">
            <Rating
              className="rating-star-container "
              size="20"
              onClick={(value) => SetFilter("rating", `${value} Star`)}
              initialValue={
                filtered["rating"] &&
                  parseInt(filtered["rating"].split(" ")[0]) > 0
                  ? parseInt(filtered["rating"].split(" ")[0])
                  : 0
              }
            />

            <br />
          </div>
        </div> */}
      </>
    } else if (isEmpty(filters?.data) && !filters.loading) {
      return (
        <Container className=" modal-custom-class  py-5">
          <p>{"Something went wrong...."}</p>
        </Container>
      );
    } else if (filters?.loading) {
      return <Spinner />;
    }



  };
  const blandsSections = (
    <Container className="filter-container w-100">

      {productsList.loading ? (<Container className=" modal-custom-class  py-5">
        <Spinner />
      </Container>) : (<Row className="m-0 w-100">
        <Col xs={12} sm={12} md={3} className="filter-section py-2 desktop">
          {filterMenu()}
        </Col>

        <Col xs={12} sm={12} md={9} className="brand-section">
          <Row className="m-0 p-0">
            <Col xs={12} sm={12} md={12} lg={12} className="selector ">
              <div className="desktop">
                <div className="selected-option">
                  <p style={{ marginRight: "5%" }}>{"Filter"}</p>
                  {Object?.keys(filtered).map((key, index) => {

                    if (filtered[key] !== "" && typeof filtered[key] !== "object") {
                      return <Button
                        onClick={() => SetFilter(key, filtered[key])}
                        key={index}
                      >
                        <span>{filtered[key].split('|')[0]}</span>
                        <CloseIcon />
                      </Button>
                    } else if (filtered[key] !== "" && typeof filtered[key] === "object") {
                      return <Button
                        onClick={() => SetFilter(key, filtered[key])}
                        key={index}
                      >
                        <span>{filtered[key].display_name}</span>
                        <CloseIcon />
                      </Button>
                    }
                  })}
                </div>
              </div>
              <div ref={refMobileFilter} className="mobile " style={{ visibility: "hidden", position: "absolute" }}>xyz</div>
              <CustomOffCanvas
                className="filter-offcanvas"
                backdropClassName="filter-backdrop"

                buttonClasses="mobile filter-icon"
                bodyBsPrefix="filter-ofcanvas-body"
                buttonContant={<FilterIcon />}
                isOffCanvasOpen={showFiterSideMenu}
                OffcanvasShow={handleFiterSideMenuShow}
                OffcanvasClose={handleFiterSideMenuClose}
              >
                <Col
                  xs={12}
                  sm={12}
                  md={3}
                  className="filter-section py-2 mobile"
                >
                  {filterMenu()}
                </Col>
              </CustomOffCanvas>
              {!isEmpty(filters.data) && auth?.loggedIn && filters?.data?.sort?.length > 0 && (<CustomSelectDropdown
                style={{
                  multiselectContainer: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                    border: "1px solid #d9d9d9"
                  },
                  chips: {
                    margin: 0,
                  },
                  option: {
                    background: "#fbb040",
                    color: "#FFFFFF",
                    borderBottom: "1px solid #FFFFFF",
                  },
                }}
                options={filters?.data?.sort}
                onSelect={(e) => SetFilter("sort", e[0])}
                selectedValues={
                  !isEmpty(filtered) && !isEmpty(filtered.sort)
                    ? [filtered.sort]
                    : []
                }
                placeholder={"Sort By"}
                displayValue={"display_name"}
                // disable={isUpdateMode ? true : false}
                singleSelect
              />)}

            </Col>
            {!isEmpty(productsList.data) && productsList?.data?.products?.length > 0 && !productsList.loading ? <>
              {productsList?.data?.products.map((item, i) => {
                return (
                  <Col
                    key={i}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    className="brand-card-col"
                  >
                    <BrandCard filterMoq={Object.prototype.hasOwnProperty.call(filtered, "moq") && !isEmpty(filtered["moq"]) ? filtered["moq"].split('&nbsp;')[0] : ""} isLoggedIn={auth?.loggedIn} item={item} requestCall={requestCall} addToCart={addToCart} />
                  </Col>
                );
              })}

              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="pagination-wrap" >
                  <div className="pagination-count-view" >
                    {(() => {
                      if (!isEmpty(productsList.data) && productsList?.data?.products?.length > 0 && !productsList.loading) {
                        return <><p >Showing {pagination.skip * pagination.limit + 1} - {pagination.skip * pagination.limit + productsList?.data?.products?.length}    </p>
                        </>
                      }
                    })()}
                  </div>
                  <div className="pagination-action-warp" >
                    {pagination.previousActive && (<>

                      <CustomButton className="pagination-btn filp" onClick={() => {
                        SetPagination("skip", pagination.skip - 1);
                        handleScrollView();
                      }}><ForwardArrow /></CustomButton>
                      <p >{pagination.skip}</p>
                    </>)}
                    <p className="active">{pagination.skip + 1}</p>
                    {pagination.previousActive && (<>
                      <p >{pagination.skip + 2}</p>
                      <CustomButton className="pagination-btn" onClick={() => {
                        SetPagination("skip", pagination.skip + 1);
                        handleScrollView();

                      }}> <ForwardArrow /></CustomButton></>)}
                  </div>
                </div>
              </Col>
            </> : (<Container className=" modal-custom-class  py-5">
              <p>{"No products available"}</p>
            </Container>)}
          </Row>
        </Col>
      </Row>)}
    </Container>
  );
  return (
    <section className="brands-main-section">
      {brandsBanner}
      {blandsSections}
    </section>
  );
}



export default Brands;
