import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    getApi, postBankInfo,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import Spinner from "../../../common/Spinner";

const BankingAndTaxation = () => {
    const dispatch = useDispatch();
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);

    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, bankInfo } = useSelector(
        (state) => {
            return state;
        }
    );
    useEffect(() => {
        getApi("getBankInfo", dispatch)
    }, [])
    useEffect(() => {
        setIsUpdateMode(false)
        if (bankInfo?.data === null) {
            setIsCreateMode(true)
        } else {
            setIsCreateMode(false)
        }
    }, [bankInfo])

    // useEffect(() => {
    //     console.log("*** uploadFormObj ", uploadFormObj)
    // }, [uploadFormObj])
    const onSelectValue = (key, value) => {
        // console.log("*** change ", key, value)
        let updatedObj = {};
        updatedObj[key] = value;

        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };


    const createUpdateBrand = (e) => {
        e.preventDefault();
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "account_name") &&
            !isEmpty(uploadFormObj.account_name) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "account_number") &&
            !isEmpty(uploadFormObj.account_number) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "bank_name") &&
            !isEmpty(uploadFormObj.bank_name) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "ifsc") &&
            !isEmpty(uploadFormObj.ifsc) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "bank_address") &&
            !isEmpty(uploadFormObj.bank_address)
        ) {
            postBankInfo(uploadFormObj, dispatch);
        }
    };

    const bankInfoForm = (<>
        <Row className="m-0 form-row ">
            <Row className="m-0 px-0 w-full px-0 mb-4 title-button-row">
                <h2>{!isUpdateMode ? "ADD Bank details" : "Update Bank details"}</h2>
                {bankInfo?.data !== null && (<CustomButton
                    buttonClasses="view-switch-btn"
                    onClick={() => { setIsCreateMode(false); setUploadFormObj({}) }}
                >
                    {"View"}
                </CustomButton>)}

            </Row >
            <Form className="my-2 modal-custom-class" onSubmit={createUpdateBrand}>
                <Row className="m-0 ">
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Account Name"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.account_name}
                                onChange={(e) =>
                                    onSelectValue("account_name", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Account Number"}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.account_number}
                                onChange={(e) =>
                                    onSelectValue("account_number", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Bank Name"}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.bank_name}
                                onChange={(e) =>
                                    onSelectValue("bank_name", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"IFSC"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>

                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.ifsc}
                                onChange={(e) =>
                                    onSelectValue("ifsc", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                {"Bank Address"}
                                <span className="redStreSpan">{"*"}</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={uploadFormObj?.bank_address}
                                onChange={(e) =>
                                    onSelectValue("bank_address", e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>



                <div className="action-buttons">
                    {bankInfo?.data !== null && (<CustomButton
                        buttonClasses="close-btn"
                        onClick={() => setIsCreateMode(false)}
                        disabled={requestCall?.loading}
                    >
                        {"Close"}
                    </CustomButton>)}

                    <CustomButton
                        variant="primary"
                        buttonClasses={
                            !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                        }
                        type="submit"
                        loading={requestCall?.loading}
                    >
                        {!isUpdateMode ? "Add" : "update"}
                    </CustomButton>
                </div>
            </Form>
        </Row></>)

    return (
        <Container className="p-0 bankInfo-container">
            {
                bankInfo?.loading ? <Spinner /> : !isEmpty(bankInfo?.data) && !isCreateMode ? (
                    <Row className="bankInfo m-0" >
                        <Row className="heading-div ">
                            <h5 className="text-uppercase p-0  m-0">{"Bank details"}</h5>
                            <p style={{ cursor: "pointer" }} onClick={() => { setIsCreateMode(true); setIsUpdateMode(true); setUploadFormObj(bankInfo?.data) }}>{"Edit"}</p>
                        </Row>
                        <hr />
                        <Row className="m-0 p-0">
                            {/* <h6 className="text-uppercase">{bankInfo?.data.bankInfo_type_id.type_name}</h6> */}
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Account Name:"}</span>
                                    <span className="mx-2 bold-span"> {bankInfo?.data.account_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>

                                    <span>{"Bank Name:"}</span>
                                    <span className="mx-2 bold-span"> {bankInfo?.data.bank_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Bank Name:"}</span>
                                    <span className="mx-2 bold-span"> {bankInfo?.data.bank_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>

                                    <span>{"Account Number:"}</span>
                                    <span className="mx-2 bold-span"> {bankInfo?.data.account_number}</span>

                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"IFSC*"}</span>
                                    <span className="mx-2 bold-span"> {bankInfo?.data.ifsc}</span>
                                </p>
                            </Col>


                        </Row>



                    </Row>
                ) : bankInfoForm
            }
            {/* {(() => {
                if (!isCreateMode) {
                    if (bankInfo?.data !== null && !bankInfo?.loading) {
                        return (<Row className="bankInfo m-0" >
                            <Row className="heading-div ">
                                <h5 className="text-uppercase p-0  m-0">{"Bank details"}</h5>
                                <p style={{ cursor: "pointer" }} onClick={() => { setIsCreateMode(true); setIsUpdateMode(true); setUploadFormObj(bankInfo?.data) }}>{"Edit"}</p>
                            </Row>
                            <hr />
                            <Row className="m-0 p-0">
                                <Col xs={12} md={6} lg={4} className="p-0" >
                                    <p>
                                        <span>{"Account Name:"}</span>
                                        <span className="mx-2 bold-span"> {bankInfo?.data.account_name}</span>
                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="p-0" >
                                    <p>

                                        <span>{"Bank Name:"}</span>
                                        <span className="mx-2 bold-span"> {bankInfo?.data.bank_name}</span>
                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="p-0" >
                                    <p>
                                        <span>{"Bank Name:"}</span>
                                        <span className="mx-2 bold-span"> {bankInfo?.data.bank_name}</span>
                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="p-0" >
                                    <p>

                                        <span>{"Account Number:"}</span>
                                        <span className="mx-2 bold-span"> {bankInfo?.data.account_number}</span>

                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="p-0" >
                                    <p>
                                        <span>{"IFSC*"}</span>
                                        <span className="mx-2 bold-span"> {bankInfo?.data.ifsc}</span>
                                    </p>
                                </Col>


                            </Row>



                        </Row>)
                    } else if (bankInfo === null && !bankInfo?.loading) {
                        return bankInfoForm
                    } else if (bankInfo?.loading) {
                        return <Spinner />;
                    }

                } else if (isCreateMode) {
                    return bankInfoForm
                }
            })()} */}

        </Container >
    );
};



export default BankingAndTaxation;
