import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { appConstants, stringConstants } from "../helpers/app-constants";
import { BHAREE_BASE_URL_LOCAL } from "../config/config.url";
import { getApi, getCart, getCartAtLogin } from "./structure.action";
import { toast } from "react-toastify";
// import url from "../config/url";
// import { GET_ERRORS, SET_CURRENT_USER } from './types';

export const loginUser = async (userData, dispatch) => {
  await dispatch({
    type: appConstants.LOGIN_REQUEST,
    user: {},
  });
  let res = {};
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/login", userData);
    if (res.status === 200) {
      // save to localStorage
      const { token } = res.data.data;
      // set token to LocalStorage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode the token to get user data
      const decoded = jwt_decode(token);
      // set current user data

      dispatch(setCurrentUser(decoded));
      dispatch({
        type: appConstants.GET_OTP,
        payload: {},
      });
      dispatch({
        type: appConstants.VERIFY_OTP,
        payload: {},
      });
      if (decoded?.role?.role_name === stringConstants.ROLL_BHAREE) {
        window.location.href = "/admin/profile-panel";
      } else if (decoded?.role?.role_name === stringConstants.ROLL_SELLER) {
        window.location.href = "/seller/profile-panel";
      } else if (decoded?.role?.role_name === stringConstants.ROLL_BUYER) {
        getCartAtLogin(dispatch, token);
      }
      return toast.success(res.data.message);
    }
  } catch (err) {
    dispatch({
      type: appConstants.LOGIN_FAILURE,
      errors: err.response.data,
    })
    return toast.error(err.response.data.message);
  }
};
export const getRegisterOtp = async (userData, dispatch) => {
  let res = {}
  await dispatch({
    type: appConstants.LOADING_GET_OTP,
  });
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/registerGetOtp", userData)
    return res.status === 200 ? await dispatch({
      type: appConstants.GET_OTP,
      payload: res.data,
    }) : toast.error("We're sorry, something went wrong. Please try again");

  } catch (err) {
    await dispatch({
      type: appConstants.GET_OTP_ERROR,
      payload: err.response.data,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at ..../auth/registerGetOtp`);
  }
};
export const getLoginOtp = async (userData, dispatch) => {
  await dispatch({
    type: appConstants.LOADING_GET_OTP,
  });
  let res = {};
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/loginGetOtp", userData);
    return res.status === 200 ? await dispatch({
      type: appConstants.GET_OTP,
      payload: res.data,
    }) : toast.error("We're sorry, something went wrong. Please try again");

  } catch (err) {
    await dispatch({
      type: appConstants.GET_OTP_ERROR,
      payload: err.response.data,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at ..../auth/loginGetOtp`);
  }
};

export const getBuyerLoginOtp = async (userData, dispatch) => {
  await dispatch({
    type: appConstants.LOADING_GET_OTP,
  });
  let res = {};
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/loginBuyerGetOtp", userData);
    return res.status === 200 ? await dispatch({
      type: appConstants.GET_OTP,
      payload: res.data,
    }) : toast.error("We're sorry, something went wrong. Please try again");

  } catch (err) {
    await dispatch({
      type: appConstants.GET_OTP_ERROR,
      payload: err.response.data,
    })
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at ..../auth/loginBuyerGetOtp`);
  }
};

export const verifyOtp = async (userData, dispatch) => {
  let res = {}
  await dispatch({
    type: appConstants.LOADING_VERIFY_OTP,
  });
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/verifyOtp", userData)
    return res.status === 200 ? await dispatch({
      type: appConstants.VERIFY_OTP,
      payload: res.data,
    }) : toast.error("We're sorry, something went wrong. Please try again");

  } catch (err) {
    await dispatch({
      type: appConstants.VERIFYT_OTP_ERROR,
      payload: err.response.data,
    });
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at ..../auth/verifyOtp`);
  }
};

export const registerUser = async (userData, dispatch) => {
  await dispatch({
    type: appConstants.REQUEST_CALL,
  });
  await dispatch({
    type: appConstants.REGISTER_FAILURE,
    errors: {},
  });
  let res = {}
  try {
    res = await axios.post(BHAREE_BASE_URL_LOCAL + "/auth/register", userData);
    if (res.status === 200) {
      const { token } = res.data.success;
      // set token to LocalStorage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode the token to get user data
      const decoded = jwt_decode(token);
      // set current user data

      await dispatch(setCurrentUser(decoded));
      if (decoded?.role?.role_name === stringConstants.ROLL_BHAREE) {
        window.location.href = "/admin/profile-panel";
      } else if (decoded?.role?.role_name === stringConstants.ROLL_SELLER) {
        window.location.href = "/seller/profile-panel";
      } else if (decoded?.role?.role_name === stringConstants.ROLL_BUYER) {
        await getCartAtLogin(dispatch, token);
      }

      await dispatch({
        type: appConstants.GET_OTP,
        payload: {},
      });
      await dispatch({
        type: appConstants.VERIFY_OTP,
        payload: {},
      });
      await dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
      return toast.success("Registration successful! You are now logged in.")
    } else {
      toast.error("We're sorry, something went wrong. Please try again");
    }

  } catch (err) {
    dispatch({
      type: appConstants.REGISTER_FAILURE,
      errors: err.response.data,
    });
    return toast.error(err.hasOwnProperty("response") ? err?.response?.data?.message : `sever not responding at ..../auth/register`);
  } finally {
    dispatch({
      type: appConstants.REQUEST_COMPLETED,
    });
  }
  // axios
  //   .post(BHAREE_BASE_URL_LOCAL + "/auth/register", userData)
  //   .then((res) => {
  //     // history.push("/")
  //     const { token } = res.data.success;
  //     // set token to LocalStorage
  //     localStorage.setItem("jwtToken", token);
  //     // Set token to Auth header
  //     setAuthToken(token);
  //     // Decode the token to get user data
  //     const decoded = jwt_decode(token);
  //     // set current user data

  //     dispatch(setCurrentUser(decoded));
  //     if (decoded?.role?.role_name === stringConstants.ROLL_BHAREE) {
  //       window.location.href = "/admin/profile-panel";
  //     } else if (decoded?.role?.role_name === stringConstants.ROLL_SELLER) {
  //       window.location.href = "/seller/profile-panel";
  //     } else if (decoded?.role?.role_name === stringConstants.ROLL_BUYER) {
  //       getCartAtLogin(dispatch, token);
  //     }

  //     dispatch({
  //       type: appConstants.GET_OTP,
  //       payload: {},
  //     });
  //     dispatch({
  //       type: appConstants.VERIFY_OTP,
  //       payload: {},
  //     });
  //     dispatch({
  //       type: appConstants.REQUEST_COMPLETED,
  //     });
  //   })
  //   .catch((err) => {


  //   }).finally(() => {
  //     dispatch({
  //       type: appConstants.REQUEST_COMPLETED,
  //     });
  //   });
};



export const patchUsers = (userId, userData, dispatch) => {
  dispatch({
    type: appConstants.REQUEST_CALL,
  });
  axios
    .patch(BHAREE_BASE_URL_LOCAL + `/users/${userId}`, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.jwtToken}`,
      },
    })
    .then((res) => {
      const { token } = res.data.data;
      // set token to LocalStorage
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode the token to get user data
      const decoded = jwt_decode(token);
      // set current user data

      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {

      return err;
    })
    .finally(() => {
      dispatch({
        type: appConstants.REQUEST_COMPLETED,
      });
    });
};
export const setCurrentUser = (decoded) => {
  return {
    type: appConstants.LOGIN_SUCCESS,
    user: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  toast.success("Successfully logged out !!!");

  localStorage.removeItem("jwtToken");
  // remove auth header for future request
  setAuthToken(false);
  // set current user to {} which will set isAuntheticated to false
  dispatch(setCurrentUser({}));
  // push.to("/");
  // window.location.href = '/';
};
