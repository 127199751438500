import { appConstants } from "../helpers/app-constants";

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const initialRolesState = {
  loading: false,
  data: {},
  errors: {},
};

export function products(state = initialRolesState, action) {
  switch (action.type) {
    case appConstants.LOADING_PRODUCTS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_PRODUCTS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}
export function productsList(state = initialRolesState, action) {
  switch (action.type) {
    case appConstants.LOADING_PRODUCTS_LIST:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_PRODUCTS_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_PRODUCTS_LIST:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}
export function liveOfferProductsList(state = initialRolesState, action) {
  switch (action.type) {
    case appConstants.LOADING_LIVEOFFER_PRODUCTS_LIST:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_LIVEOFFER_PRODUCTS_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_LIVEOFFER_PRODUCTS_LIST:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}