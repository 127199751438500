export const appConstants = {
  //BACKEND_URL: "http://127.0.0.1:8000/api/",
  BACKEND_URL: "https://utechnolabs.com/api/api/",
  FRONTEND_URL: "http://localhost:3000",
  // FRONTEND_URL: "https://utechnolabs.com",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  FORGOT_REQUEST: "USERS_FORGOT_REQUEST",
  FORGOT_SUCCESS: "USERS_FORGOT_SUCCESS",
  FORGOT_FAILURE: "USERS_FORGOT_FAILURE",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  LOGOUT: "USERS_LOGOUT",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  GET_HOME_DATA: "GET_HOME_DATA",
  GET_HOME_NEW_DATA: "GET_HOME_NEW_DATA",
  GET_ERRORS: "GET_ERRORS",

  REQUEST_CALL: "REQUEST_CALL",
  REQUEST_COMPLETED: "REQUEST_COMPLETED",
  REQUEST_FAIL: "REQUEST_FAIL",
  GET_ROLES: "GET_ROLES",
  GET_ROLES_TYPES: "GET_ROLES_TYPES",

  ERRORS_ROLES: "ERRORS_ROLES",
  ERRORS_ROLES_TYPES: "ERRORS_ROLES_TYPES",


  LOADING_GET_OTP: "LOADING_GET_OTP",
  GET_OTP: "GET_OTP",
  GET_OTP_ERROR: "GET_OTP_ERROR",

  LOADING_VERIFY_OTP: "LOADING_VERIFY_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFYT_OTP_ERROR: "VERIFYT_OTP_ERROR",

  LOADING_ROLES: "LOADING_ROLES",
  LOADING_ROLES_TYPES: "LOADING_ROLES_TYPES",
  LOADING_FAIL_ROLES: "LOADING_FAIL_ROLES",
  LOADING_FAIL_ROLES_TYPES: "LOADING_FAIL_ROLES_TYPES",

  LOADING_BHAREE_ADMIN_BASIC_TABLE: "LOADING_BHAREE_ADMIN_BASIC_TABLE",
  GET_BHAREE_ADMIN_BASIC_TABLE: "GET_BHAREE_ADMIN_BASIC_TABLE",
  ERRORS_BHAREE_ADMIN_BASIC_TABLE: "ERRORS_BHAREE_ADMIN_BASIC_TABLE",

  LOADING_BRANDS: "LOADING_BRANDS",
  GET_BRANDS: "GET_BRANDS",
  ERRORS_BRANDS: "ERRORS_BRANDS",

  LOADING_DEALS_OF_THE_DAY: "LOADING_DEALS_OF_THE_DAY",
  GET_DEALS_OF_THE_DAY: "GET_DEALS_OF_THE_DAY",
  ERRORS_DEALS_OF_THE_DAY: "ERRORS_DEALS_OF_THE_DAY",

  LOADING_PRODUCTS: "LOADING_PRODUCTS",
  GET_PRODUCTS: "GET_PRODUCTS",
  ERRORS_PRODUCTS: "ERRORS_PRODUCTS",

  // LOADING_CURRENT_PRODUCTS: "LOADING_CURRENT_PRODUCTS",
  // GET_CURRENT_PRODUCTS: "GET_CURRENT_PRODUCTS",
  // ERRORS_CURRENT_PRODUCTS: "ERRORS_CURRENT_PRODUCTS",


  LOADING_PRODUCTS_LIST: "LOADING_PRODUCTS_LIST",
  GET_PRODUCTS_LIST: "GET_PRODUCTS_LIST",
  ERRORS_PRODUCTS_LIST: "ERRORS_PRODUCTS_LIST",

  LOADING_LIVEOFFER_PRODUCTS_LIST: "LOADING_LIVEOFFER_PRODUCTS_LIST",
  GET_LIVEOFFER_PRODUCTS_LIST: "GET_LIVEOFFER_PRODUCTS_LIST",
  ERRORS_LIVEOFFER_PRODUCTS_LIST: "ERRORS_LIVEOFFER_PRODUCTS_LIST",

  LOADING_FILTERS: "LOADING_FILTERS",
  GET_FILTERS: "GET_FILTERS",
  ERRORS_FILTERS: "ERRORS_FILTERS",

  LOADING_USER_LIST: "LOADING_USER_LIST",
  GET_USER_LIST: "GET_USER_LIST",
  ERRORS_USER_LIST: "ERRORS_USER_LIST",

  LOADING_SERVICET_TAX_DETAILS: "LOADING_SERVICET_TAX_DETAILS",
  GET_SERVICET_TAX_DETAILS: "GET_SERVICET_TAX_DETAILS",
  ERRORS_SERVICET_TAX_DETAILS: "ERRORS_SERVICET_TAX_DETAILS",


  LOADING_ADDRESSES: "LOADING_ADDRESSES",
  GET_ADDRESSES: "GET_ADDRESSES",
  ERRORS_ADDRESSES: "ERRORS_ADDRESSES",

  LOADING_FACTORY_INFO: "LOADING_FACTORY_INFO",
  GET_FACTORY_INFO: "GET_FACTORY_INFO",
  ERRORS_FACTORY_INFO: "ERRORS_FACTORY_INFO",

  LOADING_BANK_INFO: "LOADING_BANK_INFO",
  GET_BANK_INFO: "GET_BANK_INFO",
  ERRORS_BANK_INFO: "ERRORS_BANK_INFO",


  LOADING_LIVE_OFFERS: "LOADING_LIVE_OFFERS",
  GET_LIVE_OFFERS: "GET_LIVE_OFFERS",
  ERRORS_LIVE_OFFERS: "ERRORS_LIVE_OFFERS",

  LOADING_COUNTRIES: "LOADING_COUNTRIES",
  GET_COUNTRIES: "GET_CLOADING_COUNTRIES",
  ERRORS_COUNTRIES: "ERRORS_CLOADING_COUNTRIES",

  LOADING_STATES: "LOADING_STATES",
  GET_STATES: "GET_STATES",
  ERRORS_STATES: "ERRORS_STATES",

  LOADING_CITIES: "LOADING_CITIES",
  GET_CITIES: "GET_CITIES",
  ERRORS_CITIES: "ERRORS_CITIES",

  LOADING_ADMIN_STATS: "LOADING_ADMIN_STATS",
  GET_ADMIN_STATS: "GET_ADMIN_STATS",
  ERRORS_ADMIN_STATS: "ERRORS_ADMIN_STATS",

  LOADING_COMPANY_INFO: "LOADING_COMPANY_INFO",
  GET_COMPANY_INFO: "GET_COMPANY_INFO",
  ERRORS_COMPANY_INFO: "ERRORS_COMPANY_INFO",

  LOADING_CART: "LOADING_CART",
  GET_CART: "GET_CART",
  ERRORS_CART: "ERRORS_CART",

  LOADING_CURRENT_PRODUCT: "LOADING_CURRENT_PRODUCT",
  GET_CURRENT_PRODUCT: "GET_CURRENT_PRODUCT",
  ERRORS_CURRENT_PRODUCT: "ERRORS_CURRENT_PRODUCT",

  GET_USER_BUSSINESS_INFORAMTION: "USER_BUSSINESS_INFORAMTION",
  LOADING_USER_BUSSINESS_INFORAMTION: "LOADING_USER_BUSSINESS_INFORAMTION",
  LOADING_FAIL_USER_BUSSINESS_INFORAMTION:
    "LOADING_FAIL_USER_BUSSINESS_INFORAMTION",

  BHAREE: "BHAREE",
  SELLER: "SELLER",
  LOGISTIC_PARTNER: "LOGISTIC_PARTNER",
  BUYER: "BUYER",

  LOADING_CHECKOUT_ITEMS: "LOADING_CHECKOUT_ITEMS",
  GET_CHECKOUT_ITEMS: "GET_CHECKOUT_ITEMS",
  ERRORS_CHECKOUT_ITEMS: "ERRORS_CHECKOUT_ITEMS",

  LOADING_ORDERS: "LOADING_ORDERS",
  GET_ORDERS: "GET_ORDERS",
  ERRORS_ORDERS: "ERRORS_ORDERS",

  LOADING_CURRENT_ORDER: "LOADING_CURRENT_ORDER",
  GET_CURRENT_ORDER: "GET_CURRENT_ORDER",
  ERRORS_CURRENT_ORDER: "ERRORS_CURRENT_ORDER",

  LOADING_CALCULATION_FILE: "LOADING_CALCULATION_FILE",
  GET_CALCULATION_FILE: "GET_CALCULATION_FILE",
  ERRORS_CALCULATION_FILE: "ERRORS_CALCULATION_FILE",

  LOADING_CALCULATED_PRICE: "LOADING_CALCULATED_PRICE",
  GET_CALCULATED_PRICE: "GET_CALCULATED_PRICE",
  ERRORS_CALCULATED_PRICE: "ERRORS_CALCULATED_PRICE",
  CLEAR_CALCULATED_PRICE: "CLEAR_CALCULATED_PRICE",

  LOADING_PAYMENT: "LOADING_PAYMENT",
  GET_PAYMENT: "GET_PAYMENT",
  ERRORS_PAYMENT: "ERRORS_PAYMENT",
  CLEAR_PAYMENT: "CLEAR_PAYMENT",
};

export const stringConstants = {
  ROLL_BHAREE: "BHAREE",
  ROLL_SELLER: "SELLER",
  ROLL_LOGISTIC_PARTNER: "LOGISTIC_PARTNER",
  ROLL_BUYER: "BUYER",
  ROLL_TYPE_WAREHOUSE: "WAREHOUSE",
  USERS: "USERS",


  ROLL_TYPE_DISTRIBUTOR: "DISTRIBUTOR",
  ROLL_TYPE_MANUFACTURER: "MANUFACTURER",
  ROLL_TYPE_LOGISTIC_PARTNER: "LOGISTIC_PARTNER",
  ROLL_TYPE_BUYER: "BUYER",


  SET_CURRENT_VIEW: "SET_CURRENT_VIEW",
  DASHBOAD: "DASHBOAD",
  REPORTS: "REPORTS",
  BRANDS: "BRANDS",
  PRODUCTS: "PRODUCTS",
  VARIANTS: "VARIANTS",
  LOGISTIC_PARTNER: "LOGISTIC_PARTNER",

  BASICS: "BASICS",
  SERVICE_TAX_DETAILS: "SERVICE_TAX_DETAILS",
  BASIC_INFORMATION: "BASIC_INFORMATION",
  COMPANY_INFORMATION: "COMPANY_INFORMATION",

  BANKING_AND_TAXATION: "BANKING_AND_TAXATION",
  ADDRESS: "ADDRESS",
  FACTORY_INFO: "FACTORY_INFO",
  ORDER: "ORDER",

};
export const loaderDispatch = (dispatch, condition, ftype = "") => {
  dispatch({
    type: "LOADER",
    payload: condition,
    ftype: ftype,
  });
};

//   var lang = 1;
//   if (localStorage.getItem("selectedLang") !== null) {
//     var langv = JSON.parse(localStorage.getItem("selectedLang"));
//     lang = langv.id;
//   }
export const regex = /^\d*\.?\d*$/;
export const config = {
  headers: {
    "Content-type": "application/json",
    //   "X-localization": `${lang}`,
  },
};


export const areaList = [
  {
    "city": "Pune",
    "area": "Tahsil Pune City",
    "pin_code": "411002"
  },
  {
    "city": "Pune",
    "area": "Aundh",
    "pin_code": "411007"
  },
  {
    "city": "Pune",
    "area": "Baner",
    "pin_code": "411045"
  },
  {
    "city": "Pune",
    "area": "Kothrud",
    "pin_code": "411038"
  },
  {
    "city": "Pune",
    "area": "Hadapsar",
    "pin_code": "411028"
  },
  {
    "city": "Pune",
    "area": "Wakad",
    "pin_code": "411057"
  },
  {
    "city": "Pune",
    "area": "Hinjewadi",
    "pin_code": "411057"
  }
]

export const expensesList = [
  {
    name: "Electricity",
    key: "electricity"
  },
  {
    name: "Wages",
    key: "wages"
  },
  {
    name: "Transport",
    key: "transport"
  },
  {
    name: "Misc. Exps",
    key: "miscExps"
  },
  {
    name: "Loan Int",
    key: "loanInt"
  }

];

export const rowMatrialList = [

  {
    name: "Weight",
    key: "weight"
  },
  {
    name: "Box",
    key: "box"
  },
  {
    name: "Lable",
    key: "lable"
  },
  {
    name: "Cap",
    key: "cap"
  },
  {
    name: "Ink",
    key: "ink"
  },
  {
    name: "Rejection",
    key: "rejection"
  }

];

export const perBottlecostList = [
  {
    name: "Preform",
    key: "preform"
  },
  {
    name: "Box",
    key: "box"
  },
  {
    name: "Lable",
    key: "lable"
  },
  {
    name: "Cap",
    key: "cap"
  },
  {
    name: "Ink",
    key: "ink"
  },
  {
    name: "Rejection",
    key: "rejection"
  },
  {
    name: "Electricity",
    key: "electricity"
  },
  {
    name: "Wages",
    key: "wages"
  },
  {
    name: "Transport",
    key: "transport"
  },
  {
    name: "Misc. Exps",
    key: "miscExps"
  },
  {
    name: "Loan Int",
    key: "loanInt"
  }, {
    name: "Per Bottle",
    key: "perBottle"
  }
];

export const perBoxList = [
  {
    name: "Per Box",
    key: "perBox"
  },
  {
    name: "Sale price",
    key: "salePrice"
  },
  {
    name: "Gross Profit(Box)",
    key: "grossProfit"
  },
];

export const numberOfbottlesByVariants = [
  {
    name: "250_P",
    bottles: 40
  },
  {
    name: "250_R",
    bottles: 40
  },
  {
    name: "500_P",
    bottles: 24
  },
  {
    name: "500_R",
    bottles: 24
  },
  {
    name: "1000_P",
    bottles: 12
  },
  {
    name: "1000_R",
    bottles: 12
  },
  {
    name: "2000",
    bottles: 6,
  },
];

export const calVarinats = [
  {
    quantity: "1000_R",
    displayName: '1L R'
  },
  {
    quantity: "1000_P",
    displayName: '1L P'
  },
  {
    quantity: "500_R",
    displayName: '500ML R'
  },
  {
    quantity: "500_P",
    displayName: '500ML P'
  },
  {
    quantity: "250_R",
    displayName: '250ML R'
  },
  {
    quantity: "250_P",
    displayName: '250ML P'
  },
  {
    quantity: "2000",
    displayName: '2L'
  }
]

export const calVarinatsNew = [
  {
    quantity: "R_1000",
    displayName: '1L R'
  },
  {
    quantity: "P_1000",
    displayName: '1L P'
  },
  {
    quantity: "R_500",
    displayName: '500ML R'
  },
  {
    quantity: "P_500",
    displayName: '500ML P'
  },
  {
    quantity: "R_250",
    displayName: '250ML R'
  },
  {
    quantity: "P_250",
    displayName: '250ML P'
  },
  {
    quantity: "_2000",
    displayName: '2L'
  }
]

export const India = {
  name: "India",
  isoCode: "IN",
  flag: "🇮🇳",
  phonecode: "91",
  currency: "INR",
  latitude: "20.00000000",
  longitude: "77.00000000",
}