import React from "react";
import { Row, Col, Image, Button, Container } from "react-bootstrap";

import refferImg1 from "../../assets/images/reffer_img_1.png";
import refferImg2 from "../../assets/images/reffer_img_2.png";
import "./refferSection.css";
const RefferSection = () => {
  const sharePrompt = () => {
    navigator.share({ url: "https://www.youtube.com/watch?v=zBlklssMFEo" });
  };

  return (
    <>
      <div className="reffer-container-wrap">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} className="reffer-left-col">
              <Image
                style={{ height: "auto", width: "100%" }}
                src={refferImg1}
                alt="refferImg1"
              />
              <div className="desktop desktop-text">
                <p>
                  {
                    "Invite your contacts and get rewarded for every successful referral!"
                  }
                </p>
                <Button
                  className="text-uppercase"
                  type="button"
                  onClick={() => sharePrompt()}
                >
                  {"Reffer Now"}
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} className="reffer-right-col ">
              <Image
                style={{ height: "auto", width: "100%" }}
                src={refferImg2}
                alt="refferImg2"
              />
            </Col>
            <Col xs={12} sm={12} md={6} className="mobile mobile-text">
              <p>
                {
                  "Invite your contacts and get rewarded for every successful referral!"
                }
              </p>
              <Button
                className="text-uppercase"
                type="button"
                onClick={() => sharePrompt()}
              >
                {"Reffer Now"}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RefferSection;
