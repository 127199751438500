// export const BHAREE_BASE_URL_LOCAL = "http://localhost:5000";
export const BHAREE_BASE_URL_LOCAL = "https://server.bharee.com";
// export const BHAREE_BASE_URL_LOCAL = "https://bhareebackend.vercel.app";


export const EXTERNAL_SOCIAL_LINKS = {
    facebook: "https://www.facebook.com/bhareeonline",
    youtube: "https://www.youtube.com/@bhareeonline",
    instagram: "https://www.instagram.com/bhareeonline",
    linkedin: "https://www.linkedin.com/company/bhareeonline"
};
