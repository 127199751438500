import React, { useEffect, useState } from "react";

import { Badge, Image } from "react-bootstrap";
import "./CustomImagesDrageDrop.css";
import { CloseIcon } from "../../assets/svg/svg-icons";

const CustomImagesDrageDrop = ({ imagesArray, onChange }) => {
  const [tempFiles, setTempFiles] = useState([]);
  const [deleteFiles, setDeletedFiles] = useState([]);

  useEffect(() => {
    if (imagesArray?.length > 0) {
      setTempFiles(imagesArray);
      setDeletedFiles([]);
    }
  }, [imagesArray])

  const handleItem = (value, type = "remove") => {
    if (type === "add") {
      setDeletedFiles(prevItems => prevItems.filter((item) => item.url !== value.url));
      setTempFiles(prevItems => [...prevItems, value]);

    } else {
      setTempFiles(prevItems => prevItems.filter((item) => item.url !== value.url));
      setDeletedFiles(prevItems => [...prevItems, value]);
    }

  };

  useEffect(() => {
    onChange(deleteFiles, "deleteImages")
  }, [deleteFiles])


  return (
    <section className="container url-images-section mb-4">
      {tempFiles?.length > 0 && (
        <div className="uploded-url-file-div ">
          <h2>{"Uploded Images"}</h2>
          <div className="uploded-url-images-div">{tempFiles?.length > 0 && tempFiles.map((item) => <div className="img-container">
            <Image src={item?.url} alt="img" />
            <Badge onClick={() => handleItem(item)} bg="secondary"><CloseIcon /></Badge>
          </div>)} </div>
          <h6>{"click on Cross(x) button to undo"}</h6>
        </div>
      )}
      {deleteFiles?.length > 0 && (
        <div className="uploded-url-file-div ">
          <h2>{"Deleted Images"}</h2>
          <div className="deleted-url-images-div">{deleteFiles?.length > 0 && deleteFiles.map((item) => <div className="img-container"> <Image src={item?.url} alt="img" /><Badge onClick={() => handleItem(item, "add")} bg="secondary">{"+"}</Badge></div>)} </div>
          <h6>{"click on add(+) button to undo"}</h6>
        </div>
      )}
    </section>
  );
};

export default CustomImagesDrageDrop;
