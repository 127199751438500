import { getApiDetails } from "../actions/apiDetails";
import { appConstants, stringConstants } from "../helpers/app-constants";
// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const initialState = {
  loading: false,
  data: {},
  errors: {}
};

// export function stateInitialization(name, state = initialState, action) {
//   switch (action.type) {
//     case getApiDetails[name].loding:
//       return {
//         ...state,
//         loading: true,
//         data: {},
//         errors: {}
//       };

//     case getApiDetails[name].success:
//       return {
//         ...state,
//         loading: false,
//         data: action.payload,
//         errors: {}

//       };

//     case getApiDetails[name].error:
//       return {
//         ...state,
//         loading: false,
//         data: {},
//         errors: action.payload
//       };

//     default:
//       return state;
//   }
// }

export function roles(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_ROLES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {}
      };

    case appConstants.GET_ROLES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {}

      };

    case appConstants.ERRORS_ROLES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload
      };

    default:
      return state;
  }
}

export function roletypes(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_ROLES_TYPES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {}
      };

    case appConstants.GET_ROLES_TYPES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {}
      };

    case appConstants.ERRORS_ROLES_TYPES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload
      };
    default:
      return state;
  }
}

export function filters(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_FILTERS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {}
      };

    case appConstants.GET_FILTERS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {}
      };

    case appConstants.ERRORS_FILTERS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload
      };
    default:
      return state;
  }
}



const initialbRrandsState = {
  loading: false,
  data: {},
};
export function brands(state = initialbRrandsState, action) {
  switch (action.type) {
    case appConstants.LOADING_BRANDS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_BRANDS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_BRANDS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };
    default:
      return state;
  }
}

export function dealOfTheDay(state = initialbRrandsState, action) {
  switch (action.type) {
    case appConstants.LOADING_DEALS_OF_THE_DAY:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_DEALS_OF_THE_DAY:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_DEALS_OF_THE_DAY:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };
    default:
      return state;
  }
}

const initialbhareeAdminBasicTable = {
  loading: false,
  data: {},
  errors: {},
};
export function bhareeAdminBasicTable(
  state = initialbhareeAdminBasicTable,
  action
) {
  switch (action.type) {
    case appConstants.LOADING_BHAREE_ADMIN_BASIC_TABLE:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_BHAREE_ADMIN_BASIC_TABLE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        errors: {},
      };

    case appConstants.ERRORS_BHAREE_ADMIN_BASIC_TABLE:
      return {
        ...state,
        data: {},
        errors: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
const initialAdminViewState = {
  currentView: stringConstants.DASHBOAD
}

export function panleCurrentView(
  state = initialAdminViewState,
  action
) {
  switch (action.type) {
    case stringConstants.SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload,
      };
    default:
      return state;
  }
}


export function factoryInfo(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_FACTORY_INFO:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_FACTORY_INFO:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_FACTORY_INFO:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function bankInfo(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_BANK_INFO:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_BANK_INFO:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_BANK_INFO:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}


export function liveOffers(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_LIVE_OFFERS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_LIVE_OFFERS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_LIVE_OFFERS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function adminStats(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_ADMIN_STATS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_ADMIN_STATS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_ADMIN_STATS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function companyInfo(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_COMPANY_INFO:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_COMPANY_INFO:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_COMPANY_INFO:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}



export function currentProduct(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CURRENT_PRODUCT:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CURRENT_PRODUCT:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CURRENT_PRODUCT:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}
export function cart(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CART:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CART:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CART:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

const initialCountries = {
  loading: false,
  data: [{
    name: "India",
    isoCode: "IN",
    flag: "🇮🇳",
    phonecode: "91",
    currency: "INR",
    latitude: "20.00000000",
    longitude: "77.00000000",
  }],
  errors: {}
};

export function countries(state = initialCountries, action) {
  switch (action.type) {
    case appConstants.LOADING_COUNTRIES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_COUNTRIES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_COUNTRIES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

const initialCountrieStates = {
  loading: false,
  data: [{
    name: "Maharashtra",
    isoCode: "MH",
    countryCode: "IN",
    latitude: "19.75147980",
    longitude: "75.71388840"
  }],
  errors: {}
};
export function countryStates(state = initialCountrieStates, action) {
  switch (action.type) {
    case appConstants.LOADING_STATES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_STATES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_STATES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function countryStateCities(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CITIES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CITIES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CITIES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function checkoutItems(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CHECKOUT_ITEMS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CHECKOUT_ITEMS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CHECKOUT_ITEMS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function orders(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_ORDERS:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_ORDERS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_ORDERS:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function currentOrder(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CURRENT_ORDER:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CURRENT_ORDER:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CURRENT_ORDER:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}
export function calculationsFile(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CALCULATION_FILE:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_CALCULATION_FILE:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_CALCULATION_FILE:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}

export function calculatedPrice(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_CALCULATED_PRICE:
      return {
        ...state,
        loading: true,
        // Keep the previous data if needed
        errors: {},
      };

    case appConstants.GET_CALCULATED_PRICE:
      // console.log("******GET_CALCULATED_PRICE case triggered");
      return {
        ...state,
        loading: false,
        // Merge the existing data with the new values
        data: {
          ...state.data,  // keep the previous values
          ...action.payload,  // update only the new values
        },
        errors: {},
      };

    case appConstants.ERRORS_CALCULATED_PRICE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        // Keep previous data if there's an error
      };

    case appConstants.CLEAR_CALCULATED_PRICE: // New case to forcefully clear the data
      // console.log("******CLEAR_CALCULATION_FILE case triggered");
      return {
        ...state,
        loading: false,
        data: {},  // Forcefully clear the data
        errors: {},  // Optionally reset errors as well
      };
    default:
      return state;
  }
}

export function payment(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_PAYMENT:
      return {
        ...state,
        loading: true,
        // Keep the previous data if needed
        errors: {},
      };

    case appConstants.GET_PAYMENT:
      // console.log("******GET_PAYMENT case triggered");
      return {
        ...state,
        loading: false,
        // Merge the existing data with the new values
        data: {
          ...state.data,  // keep the previous values
          ...action.payload,  // update only the new values
        },
        errors: {},
      };

    case appConstants.ERRORS_PAYMENT:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        // Keep previous data if there's an error
      };
    case appConstants.CLEAR_PAYMENT: // New case to forcefully clear the data
      // console.log("******CLEAR_CALCULATION_FILE case triggered");
      return {
        ...state,
        loading: false,
        data: {},  // Forcefully clear the data
        errors: {},  // Optionally reset errors as well
      };
    default:
      return state;
  }
}
