import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";

const CustomOffCanvas = ({
  backdropClassName,
  bodyBsPrefix = "",
  headBsPrefix = "",
  buttonClasses = "",
  buttonContant,
  headerTitle,
  children,
  isOffCanvasOpen,
  OffcanvasShow,
  OffcanvasClose,
  ...restprops
}) => {
  return (
    <>
      {buttonContant && (
        <Button onClick={OffcanvasShow} className={buttonClasses}>
          {buttonContant}
        </Button>
      )}

      <Offcanvas
        show={isOffCanvasOpen}
        onHide={OffcanvasClose}
        backdropClassName={backdropClassName}
        {...restprops}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title bsPrefix={"offcanvas-header " + headBsPrefix}>
            {headerTitle}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body bsPrefix={"offcanvas-body " + bodyBsPrefix}>
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomOffCanvas;
