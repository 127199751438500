import React from "react";
import Comma from "../../assets/images/Comma.png";
import InvertedComma from "../../assets/images/InvertedComma.png";
import "./quote-section.css";
import { Col, Container, Image, Row } from "react-bootstrap";

export default function QuoteSection({ text }) {
  return (
    <div className="quote-section ">
      <Container className="p-0">
        <Row className="w-100 m-0">
          <Col xs={12} sm={12} md={12} lg={1} className="left-quote-col">
            <Image src={Comma} alt="Comma" />
          </Col>
          <Col xs={12} sm={12} md={12} lg={10} className="text-quote-col">
            <p>{text}</p>
          </Col>

          <Col xs={12} sm={12} md={12} lg={1} className="right-quote-col">
            <Image src={InvertedComma} alt="InvertedComma" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
