import { combineReducers } from "redux";
import { registration, getOtp, verifyOtp, auth } from "./authentication.reducer";
import {
  adminStats,
  bankInfo,
  bhareeAdminBasicTable,
  brands,
  dealOfTheDay,
  cart,
  companyInfo,
  countries,
  countryStates,
  countryStateCities,
  currentProduct,
  factoryInfo,
  filters,
  liveOffers,
  panleCurrentView,
  roles,
  roletypes,
  checkoutItems,
  orders,
  currentOrder,
  calculationsFile,
  payment,
  calculatedPrice,
} from "./structure.reducer";
import { userBussinessInfomation, userList } from "./user.reducer";
import { requestCall } from "./requestCall.reducer";
import { liveOfferProductsList, products, productsList } from "./products.reducer";
import { serviceTaxDetails } from "./service.tax.details";
import { addresses } from "./addresses.reducer";

const rootReducer = combineReducers({
  auth,
  registration,
  addresses,
  requestCall,
  roles,
  roletypes,
  brands,
  dealOfTheDay,
  userBussinessInfomation,
  getOtp,
  verifyOtp,
  bhareeAdminBasicTable,
  products,
  productsList,
  userList,
  serviceTaxDetails,
  filters,
  panleCurrentView,
  factoryInfo,
  bankInfo,
  liveOffers,
  adminStats,
  companyInfo,
  currentProduct,
  cart,
  liveOfferProductsList,
  countries,
  countryStates,
  countryStateCities,
  checkoutItems,
  orders,
  currentOrder,
  calculationsFile,
  calculatedPrice,
  payment,
});

export default rootReducer;
