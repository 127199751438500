import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  Approval,
  CheckIcon,
  Growth,
  Onboarding,
  Registration,
  Sales,
} from "../../assets/svg/svg-icons";
import Bislerilogo from "../../assets/images/Bislerilogo.png";
import Aquafina from "../../assets/images/Aquafina.png";
import Bailley from "../../assets/images/Bailley.png";
import Kinley from "../../assets/images/Kinley.png";
import BhareeTruck from "../../assets/images/bhareeTruck.jpeg";
//dummmy
import Tuck2 from "../../assets/images/Tuck-2.png";
import Laptop from "../../assets/images/laptop.png";
import AboutSellerBannerMobile from "../../assets/images/about-seller-banner-mobile.png";
import SellerToBuyer from "../../assets/images/Seller-to-buyer-banner.png";

import Direction from "../../assets/images/Direction.png";

import "react-image-gallery/styles/css/image-gallery.css";
import BhareeBlack from "../../assets/images/BhareeBlack.png";
import { Link } from "react-router-dom";
import QuoteSection from "../common/QuoteSection";
import SellerAuth from "../auth/SellerAuth";
import { useSelector } from "react-redux";
import "./about-us-become-a-seller.css";


const AboutUsBecomeASeller = () => {
  const {
    auth,
  } = useSelector((state) => {
    return state;
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const AboutUsBanner = (
    <>
      <div className="about-us-become-a-seller"></div>
      <Container className="about-us-become-a-seller-container">
        <Row className="m-0 about-us-become-a-row">
          <Col xs={12} lg={7} className="about-us-become-a-seller-img-col">
            <h4 className="section-title-seller mobile">
              <span>{" Become a"}</span><br className="mobile" />
              <Image src={BhareeBlack} alt="Bharee" />
              <span>{" Seller"}</span>
            </h4>
            <Image
              className="truck-img"
              src={Tuck2}
              alt="SellerAboutUSBanner"
            />
          </Col>
          <Col xs={12} lg={5} className="about-us-become-a-text-col right">
            <h4 className="section-title-seller desktop">
              <span>{" Become a"}</span>
              <Image src={BhareeBlack} alt="Bharee" />
              <span>{" Seller"}</span>
            </h4>

            <h5>{"Join Bharee and sell to thousands of happy retailers"}</h5>
            <p>
              {
                "Become a Bharee seller, and we will focus on the Sales & Distribution of your brand to retail network 24 hours a day, 7 days a week."
              }
            </p>
            {/* <Link to="/" className="section-link">
              {"Start selling"}
            </Link> */}
            {auth?.loggedIn ? <Link to="/seller/profile-panel" className="section-link">
              {"Start selling"}
            </Link> : <SellerAuth buttonText={"Start selling"} registerMode={true} buttonClasses={"section-link"} />}
          </Col>
        </Row>
      </Container>
    </>
  );
  const BrandsSection = (
    <div className="about-become-a-seller-brand py-5">
      <Container>
        <p>{"Some of our sellers"}</p>
        <div className="brands-section-img-div">
          <Image src={Bailley} alt="AquafinaLogo" />
          <Image src={Aquafina} alt="BisleriLogo" />
          <Image src={Kinley} alt="BailleyLogo" />
          <Image src={Bislerilogo} alt="KinleyLogo" />
        </div>
      </Container>
    </div>
  );
  const getTools = (
    <Container className="about-us-become-a-seller-get-tools pt-5">
      <h4 className="get-tools-title ">
        {
          "Get the tools you need to increase sales and grow your business online"
        }
      </h4>
      <p>
        {
          "Simply apply to sell, and you will easily reach retailers across the retail market. Bharee will take care of the entire process – from Sales order, warehouse receiving and customer deliveries, to running promotions and superior customer service."
        }
      </p>
      <Row className="m-0 py-5">
        <Col xs={12} md={6} lg={3} className="get-tools-col">
          <div className="get-tools-card">
            <Image src={Laptop} alt="AquafinaLogo" />
            <h6>{"Smart Data Dashboard"}</h6>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="get-tools-col">
          <div className="get-tools-card">
            <Image src={AboutSellerBannerMobile} alt="AquafinaLogo" />
            <h6>{"Automated Operations"}</h6>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="get-tools-col">
          <div className="get-tools-card">
            <Image src={SellerToBuyer} alt="AquafinaLogo" />
            <h6>{"Real-Time Traceability"}</h6>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="get-tools-col">
          <div className="get-tools-card">
            <Image src={BhareeTruck} alt="AquafinaLogo" />
            <h6>{"Hassle-Free Logistics"}</h6>
          </div>
        </Col>
      </Row>
    </Container>
  );
  const howItsWork = (
    <Container className="about-us-become-a-seller-how-its-work py-3">
      <h4 className="how-its-work-title ">{"How it works"}</h4>
      <Row className="m-0 ">
        {/* <Col xs={12} lg={1} className="desktop"></Col> */}
        <Col xs={12} lg={1} className="how-its-work-col ">
          <div className="how-its-work-card ">
            <div className="svg-wrap">
              <Registration />
            </div>
            <h6>{"Registration"}</h6>
            <p>{"Complete your BHAREE Seller profile & account."}</p>
          </div>
        </Col>
        <Col xs={12} lg={1} className="how-its-work-col desktop">
          <div className="direction-to right ">
            <Image src={Direction} alt="direction-to right" />
          </div>
        </Col>
        <Col xs={12} lg={2} className="how-its-work-col ">
          <div className="how-its-work-card big">
            <div className="svg-wrap">
              <Approval />
            </div>
            <h6>{"Approval"}</h6>
            <p>{"We review your application and get in touch with you."}</p>
          </div>
        </Col>
        <Col xs={12} lg={1} className="how-its-work-col desktop">
          <div className="direction-to right">
            <Image src={Direction} alt="direction-to right" />
          </div>
        </Col>
        <Col xs={12} lg={2} className="how-its-work-col ">
          <div className="how-its-work-card big">
            <div className="svg-wrap">
              <Onboarding />
            </div>
            <h6>{"Onboarding"}</h6>
            <p>{"A BHAREE rep will guide you through for onboarding."}</p>
          </div>
        </Col>
        <Col xs={12} lg={1} className="how-its-work-col desktop">
          <div className="direction-to right ">
            <Image src={Direction} alt="direction-to right" />
          </div>
        </Col>
        <Col xs={12} lg={2} className="how-its-work-col ">
          <div className="how-its-work-card big">
            <div className="svg-wrap">
              <Sales />
            </div>

            <h6>{"Growth"}</h6>
            <p>
              {"Go Live! Watch all your products reach the ultimate buyer!"}
            </p>
          </div>
        </Col>
        <Col xs={12} lg={1} className="how-its-work-col desktop">
          <div className="direction-to right ">
            <Image src={Direction} alt="direction-to right" />
          </div>
        </Col>
        <Col xs={12} lg={1} className="how-its-work-col ">
          <div className="how-its-work-card ">
            <div className="svg-wrap">
              <Growth />
            </div>
            <h6>{"Sales"}</h6>
            <p>{"Boost your performance & your sales with BHAREE!"}</p>
          </div>
        </Col>
        {/* <Col xs={12} lg={1} className="desktop"></Col> */}
      </Row>
      {/* <Row className="m-0 ">
        <Col xs={12} lg={9} className="how-its-work-col desktop"></Col>
        <Col xs={12} lg={2} className="how-its-work-col desktop">
          <div className="direction-to down ">
            <Image src={Direction} alt="direction-to down" />
          </div>
        </Col>
        <Col xs={12} lg={1} className="desktop"></Col>
      </Row>
      <Row className="m-0 ">
        <Col xs={12} lg={3} className="desktop"></Col>
        <Col xs={12} lg={2} className="how-its-work-col">
          <div className="how-its-work-card">
            <div className="svg-wrap">
              <Growth />
            </div>
            <h6>{"Sales"}</h6>
            <p>{"Boost your performance & your sales with BHAREE!"}</p>
          </div>
        </Col>
        <Col xs={12} lg={2} className="how-its-work-col desktop">
          <div className="direction-to left">
            <Image src={Direction} alt="direction-to left" />
          </div>
        </Col>
        <Col xs={12} lg={2} className="how-its-work-col">
          <div className="how-its-work-card">
            <div className="svg-wrap">
              <Sales />
            </div>

            <h6>{"Growth"}</h6>
            <p>
              {"Go Live! Watch all your products reach the ultimate buyer!"}
            </p>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} className="desktop"></Col>
      </Row> */}
    </Container>
  );
  const onboardingAndFeatures = (
    <div className="about-us-become-a-seller-onboarding-features py-4">
      <Container>
        <h4 className="get-tools-title ">
          {
            "Leverage Bharee platform tools to supercharge your sales"
          }
        </h4>

        <Row className="m-0 py-2">
          <Col xs={12} lg={2} className="desktop"></Col>
          <Col xs={12} lg={4} className="onboarding-features-col">
            <div className="onboarding-features-card">
              <h6>{"Products onboarding"}</h6>
              <ul>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"Loading SKUs"}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"Uploading photos"}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"Customized link "}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"Adding products to Promo Category"}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"Digitalization by BHAREE"}</Col>
                  </Row>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} lg={4} className="onboarding-features-col">
            <div className="onboarding-features-card">
              <h6>{"Features"}</h6>
              <ul>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>{"E-commerce enablement"}</Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>
                      {"Secure, product storage in BHAREE Fulfillment Centers"}
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>
                      {
                        "Daily management of online order dispatching, receiving, returns and stock settlement."
                      }
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>
                      {
                        "Collection of payments and processing, inclusive of all fees"
                      }
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>
                      {
                        "Live customer support by phone, 7 days a week for you and your customers."
                      }
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col xs={1}>
                      <CheckIcon />
                    </Col>
                    <Col xs={11}>
                      {
                        "Dynamic e-commerce enablement for your sales force country-wide."
                      }
                    </Col>
                  </Row>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} lg={2} className="desktop"></Col>
        </Row>
      </Container>
    </div>
  );
  return (
    <section className="AboutUs-main-section">
      {AboutUsBanner}
      {BrandsSection}
      {getTools}
      {howItsWork}
      {onboardingAndFeatures}
      <QuoteSection
        text={"Let's explore the limitless online market for your brand."}
      />
    </section>
  );
}

export default AboutUsBecomeASeller;
