import React, { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import bhareeLogo from "../../assets/images/bhareeLogo.png";
import TruckImage from "../../assets/images/Homepagetruck.png";
import DownArrowHeadIcon from "../../assets/images/downArrowHead.png";
import SellerToBuyer from "../../assets/images/Seller-to-buyer-banner.png";
import KinleyLogo from "../../assets/images/KinleyBWLogo.png";
import BisleriLogo from "../../assets/images/BisleriBWLogo.png";
import BailleyLogo from "../../assets/images/BailleyBWLogo.png";
import AquafinaLogo from "../../assets/images/AquafinaBWLogo.png";
import Mobile from "../../assets/images/Mobile.png";
import Laptop from "../../assets/images/LaptopWithCircle.png";
import QuoteSection from "../common/QuoteSection";

import "react-image-gallery/styles/css/image-gallery.css";

import "./about-us.css";
import { useSelector } from "react-redux";
import BuyerAuth from "../auth/BuyerAuth";
import SellerAuth from "../auth/SellerAuth";

const AboutUs = () => {
  const ref = useRef();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const {
    auth,
  } = useSelector((state) => {
    return state;
  });
  const handleScrollView = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const AboutUsBanner = (
    <>
      <div className="about-us-banner">
        <div className="about-us-banner-background"></div>
        <Container>
          <Row className=" about-us-banner-content desktop">
            <Col xs={12} sm={12} md={9} className="about-banner-col">
              <h1>{"India’s First Online B2B Platform For Mineral Water"}</h1>
              {/* <h6>{"your mineral water online platform,"}</h6> */}
              <p>
                {
                  "The ultimate online platform for high-quality mineral water products, connecting buyers and suppliers from around the Nation!"
                }
              </p>
              <span className="arrow-scroll" onClick={() => handleScrollView()}>
                <Image src={DownArrowHeadIcon} alt="DownArrowHeadIcon" /> Scroll
              </span>
              <Image
                src={TruckImage}
                alt="TruckImage"
                className="bharee-truck"
              />
            </Col>
          </Row>
          <Row className=" about-us-banner-content mobile">
            <Col xs={12} className="about-banner-col">
              <h1>{"India’s First Online B2B Platform For Mineral Water"}</h1>
              {/* <h6>{"your mineral water online platform,"}</h6> */}
              <Image
                src={TruckImage}
                alt="TruckImage"
                style={{ width: "65%" }}
              />
            </Col>
            <Col xs={12} className="about-banner-col right">
              <p>
                {
                  "The ultimate online platform for high-quality mineral water products, connecting buyers and suppliers from around the Nation!"
                }
              </p>
              <span className="arrow-scroll" onClick={() => handleScrollView()}>
                <Image src={DownArrowHeadIcon} alt="DownArrowHeadIcon" /> Scroll
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );

  const WeConnect = (
    <>
      <Container className="we-connect-section" ref={ref}>
        <h4 className="section-title-we-connect">
          {"We Connect "}
          <span>
            {"the India’s leading "}
            <br />
            {"brands with largest retailer network"}
          </span>
        </h4>
      </Container>
      <Image
        className="we-connect-section-img"
        src={SellerToBuyer}
        style={{ maxWidth: "100%" }}
      />
    </>
  );

  const BuyerSellerSection = (
    <div className="buyer-seller-section">
      <Container>
        <Row className="m-0 pb-0">
          <Col xs={12} sm={12} md={6} className="seller">
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <h3>{"Sellers"}</h3>
                <p>
                  {
                    "Bharee platform empowers both suppliers and buyers by providing a powerful platform to showcase products, create product listings, manage orders, and receive payments securely, all while offering a range of tools and resources to help seller grow their business."
                  }
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="image-col">
                {/* <iframe src="https://www.youtube.com/embed/0H0g_uy8Ik0?loop=1&iv_load_policy=3&disablekb=1&fs=0&color=white" width="275" height="185" frameborder="0"></iframe> */}
                <iframe width="275" height="185" src="https://www.youtube.com/embed/0H0g_uy8Ik0?si=unMPFS6idFWeW9-v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/* <Image src={Laptop} /> */}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="button-col">
                {/* <Link to="/about-us/become-a-seller" className="section-link">
                  {"Become A Seller"}
                </Link> */}
                {auth?.loggedIn ? <Link to="/seller/profile-panel" className="section-link">
                  {"Become A Seller"}
                </Link> : <SellerAuth buttonText={"Become A Seller"} buttonClasses={"section-link"} registerMode={true} />}
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} className="buyer">
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <h3>{"Buyers"}</h3>
                <p>
                  {
                    "Experience the assurance of genuine and high-quality mineral water products at Bharee, as we verify all our suppliers to bring you transparency and reliability. Discover a vast selection of mineral water products at competitive prices with Bharee's comprehensive product listing, catering to all your preferences and needs."
                  }
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="image-col">
                <iframe width="275" height="185" src="https://www.youtube.com/embed/h-HNSGlbISA?si=PYKt6d0Pk1NTsvPG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="button-col">
                {auth?.loggedIn ? <Link to="/" className="section-link">
                  {"Order Now"}
                </Link> : <BuyerAuth registerMode={true} buttonText={"Register Now"} buttonClasses={"section-link"} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );

  const BrandsSection = (
    <Container className="py-5">
      <Row className="m-0">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="brand-section-title-logo-col"
        >
          <Image src={bhareeLogo} alt="bhareeLogo" />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className="brand-section-title-text-col"
        >
          <h4>{" works with best brands"}</h4>
        </Col>
      </Row>
      <div className="brands-section-img-div">
        <Image src={AquafinaLogo} alt="AquafinaLogo" />
        <Image src={BisleriLogo} alt="BisleriLogo" />
        <Image src={BailleyLogo} alt="BailleyLogo" />
        <Image src={KinleyLogo} alt="KinleyLogo" />
      </div>
    </Container>
  );

  return (
    <section>
      {AboutUsBanner}
      {WeConnect}
      {BuyerSellerSection}
      {BrandsSection}
      <QuoteSection
        text={"Let's explore the limitless online market for your brand."}
      />
    </section>
  );
}

export default AboutUs;
