
import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const PageNotFound = () => {
    return (
        <Container id="wrapper">
            <div className="text-center d-flex flex-column align-center" id="info" style={{ height: "550px", alignItems: "center", justifyContent: "center" }}>
                <h1 className='mb-4'>Sorry, This page could not be found</h1>
                <Link className='nav-link-color mt-4' style={{ fontSize: "2rem", color: "#FDC345", padding: "1rem", border: "1px solid #FDC345", borderRadius: "5px", margin: "1rem" }} to={"/"}>Back to home</Link>
            </div>
        </Container >

    )
}

export default PageNotFound