import React from "react";
import {
  Row,
  Col,
  Image,
  Button,
} from "react-bootstrap";
import {
  CheckIcon,
} from "../../assets/svg/svg-icons";
import RefferSection from "../common/RefferSection";
import TruckImage from "../../assets/images/Truck.png";
import Line from "../../assets/images/line.png";
//dummmy
import OurServicesBanner from "../../assets/images/our-services-banner.png";
import CatalogIcon from "../../assets/images/CatalogIcon.svg";
import   QualityIcon from "../../assets/images/QualityIcon.svg";
import   FulfillmentIcon from "../../assets/images/FulfillmentIcon.svg";


import "react-image-gallery/styles/css/image-gallery.css";

import "./seller.css";

function Seller() {
  const sellerPageBanner = (
    <div className="seller-banner-main-div">
      <Row className="m-0">
        <Col xs={12} sm={12} md={7} lg={7} className="seller-banner-text">
          <h1>{`“Beverages B2B intelligent  platform”`}</h1>
          <p>
            {"Full-service beverages wholesale marketplace "}
            <br />
            {"connecting buyers and sellers with ease."}
          </p>
          <Button>{"Get set-up in few hrs."}</Button>
        </Col>
      </Row>
    </div>
  );
  const sellerPageDelivery = (
    <div className="seller-page-delivery">
      <Row className="m-0">
        <Col
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className="seller-page-delivery-left"
        >
          <Image src={TruckImage} style={{ width: "100%" }}></Image>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={7}
          lg={7}
          className="seller-page-delivery-right"
        >
          <h1>
            {"Sell and distribution solution for"}
            <br />
            {"for growing brands and great retailers"}
          </h1>
          <Image src={Line} className="ml-2" style={{ margin: "2% 0 1% 4%" }} />
          <Image src={Line} />
        </Col>
      </Row>
    </div>
  );
  const services = (
    <>
      <div className="container sevices-container">
        <h4 className="text-uppercase section-title">
          {"Our Services"}
          <div id="borderLeft"></div>
        </h4>
        <p>
          {
            "Optimizing the next generation beverages retails with intelligent platforms"
          }
        </p>
      </div>
      <Row className="m-0 p-0   sevices-description">
        <Col className="p-0" xs={12} sm={12} md={6}>
          <Image src={OurServicesBanner} style={{ maxWidth: "100%" }} />
        </Col>
        <Col className="sevices-description-col " xs={12} sm={12} md={6}>
          <p>
            Consumers want what’s good for them. They are challenging the
            beverages industry’s antiquated corporate stronghold, looking for
            local and emerging brands in stores, and actively supporting the
            makers that respect health, humanity, and the environment.
            Traditional beverages retailing was designed for the mass-market. A
            long, opaque, and expensive journey to the shelf means smaller
            manufacturers cannot grow sustainably.
            <br />
            <br />
            <strong>Bharee</strong> is a beverages retails solution with keeping
            end retailer in mind. When the most innovative brands can reach
            retailers efficiently, good beverages become accessible to all
            consumers who want it.
          </p>
        </Col>
      </Row>
    </>
  );
  const servicesIcons = (
    <Row className="m-0 services-icons">
      <Col xs={4} sm={4} md={4} className="services-icons-col">
        <Image src={CatalogIcon} style={{ maxWidth: "100%" }} />
        <p>{"Exclusive Catalog"}</p>
      </Col>
      <Col xs={4} sm={4} md={4} className="services-icons-col">
        <Image src={QualityIcon} style={{ maxWidth: "100%" }} />
        <p>{"QUALITY SUPPORT"}</p>
      </Col>
      <Col xs={4} sm={4} md={4} className="services-icons-col">
        <Image src={FulfillmentIcon} style={{ maxWidth: "100%" }} />
        <p>{"Reliable Fulfillment"}</p>
      </Col>
    </Row>
  );
  const servicesPoints = (
    <Row className="m-0 services-points">
      <Col xs={12} sm={12} md={6} className="services-points-col left">
        <h6>{"FOR BUYER"}</h6>
        <ul>
          <li>
            <CheckIcon />
            <p>{"Discover best beverages brand for your restaurant/store"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Get best offer price"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Receive on time/faster delivery"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Pay on terms using your prepared payment method"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Order and re-order from our easy-use platform"}</p>
          </li>
        </ul>
      </Col>
      <Col xs={12} sm={12} md={6} className="services-points-col right">
        <h6>{"FOR SELLER"}</h6>
        <ul>
          <li>
            <CheckIcon />
            <p>{"Be discovered by our network of buyer nationwide"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>
              {
                "Manage your sales order, logistics and history in your dashboard"
              }
            </p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Get hassle free payment solutions"}</p>
          </li>
          <li>
            <CheckIcon />
            <p>{"Access an assured support of retails fulfillment"}</p>
          </li>
        </ul>
      </Col>
    </Row>
  );

  return (
    <section className="seller-main-section">
      {sellerPageBanner}
      {sellerPageDelivery}
      {services}
      {servicesIcons}
      {servicesPoints}
      {<RefferSection />}
    </section>
  );
}

export default Seller;
