const inputValidation = (value, maxLenght = 1000, numericOnly = false) => {

  if (value.length <= maxLenght && numericOnly) {
    if (/^\d*\.?\d*$/.test(value)) {
      return { pass: true, msg: "", finalValue: value };
    } else {
      // setErroeMsgText("Enter numbers only")
      return { pass: false, msg: "", finalValue: "" }
    }
  } else if (value.length <= maxLenght && !numericOnly) {
    return { pass: true, msg: "", finalValue: value };
  } else if (value.length > maxLenght) {
    // setErroeMsgText()
    let subStr = value.substring(0, maxLenght);
    return { pass: true, msg: `Maximum length is ${maxLenght} ${numericOnly ? "digits" : "character"}  only`, finalValue: subStr }
  } else {
    // setErroeMsgText(`Somting went wrong `)
    return { pass: false, msg: `Somting went wrong ` }
  }
}
export default inputValidation;


//return inputValidation(e.target.value, 10, true)
// ? setUpdatedForm("brand_name", e.target.value)
// : null;
// }