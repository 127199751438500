import React, { useEffect, useState } from "react";
import CustomModalWrap from "../common/CustomModalWrap";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../utils/is-Empty";
import {
  getLoginOtp,
  getRegisterOtp,
  loginUser,
  registerUser,
  verifyOtp,
} from "../../actions/auth.actions";
import { Button, Col, Form, Row } from "react-bootstrap";
import CustomButton from "../common/CustomButton";
import { appConstants, stringConstants } from "../../helpers";
import { capitalize } from "lodash"
import { VerifiedIcon } from "../../assets/svg/svg-icons";
import { Link } from "react-router-dom";

const SellerAuth = ({ buttonClasses, buttonText = "Login", registerMode = false }) => {

  const dispatch = useDispatch();


  const {
    auth,
    registration,
    roles,
    roletypes,
    getOtp: getOtpDetails,
    verifyOtp: getVerifyOtpDetails,
  } = useSelector((state) => {
    return state;
  });



  const [showRegisterSecondSetp, setShowRegisterSecondSetp] = useState(false);
  const [showRegisterOtp, setShowRegisterOtp] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const [erroeMsg, setErroeMsg] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [registerObj, setRegisterObj] = useState({});

  const [loginObj, setLoginObj] = useState({});
  const [sellerRoleId, setSellerRoleId] = useState("");
  const [sellerRoleName, setSellerRoleName] = useState("");

  const [termAndCondition, setTermAndCondition] = useState(false);

  const loginClose = () => setShowLogin(false);
  const loginShow = () => setShowLogin(true);

  const registerClose = () => setShowRegister(false);
  const registerShow = () => setShowRegister(true);

  useEffect(() => {
    if (getOtpDetails.otpDetails?.success && showRegister) {
      setShowRegisterOtp(true);
      setOtpTimer(59);
    } else if (getOtpDetails.otpDetails?.success && !showRegister) {
      setShowOtp(true);
      setOtpTimer(59);
    } else {
      setErroeMsg(getOtpDetails.errors?.message);
    }
  }, [getOtpDetails]);
  useEffect(() => {
    if (roles?.data?.length > 0) {
      roles?.data?.map((role) => {
        if (role.role_name === stringConstants.ROLL_SELLER) {
          setSellerRoleName(role.role_name);
          return setSellerRoleId(role.id);
        }
      });
    }
  }, [roles]);

  useEffect(() => {
    if (!isEmpty(registerObj) && Object.prototype.hasOwnProperty.call(registerObj, "mobile_number") &&
      !isEmpty(registerObj.mobile_number) && getVerifyOtpDetails.verifyDetails?.success) {
      let updatedObj = {};
      updatedObj["mobile_number_verify_status"] = true;
      setRegisterObj((previousVlaues) => ({
        ...previousVlaues,
        ...updatedObj,
      }));
      setShowRegisterSecondSetp(true);
    } else {
      setShowOtp(false);
      setOtpTimer(0);
      setShowRegisterSecondSetp(false);
      setErroeMsg(getVerifyOtpDetails.errors?.message);
    }
  }, [getVerifyOtpDetails]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer > 0) {
        setOtpTimer(otpTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [otpTimer]);


  const onGetRegisterOtp = (e) => {
    setErroeMsg("");
    if (
      !isEmpty(registerObj) &&
      Object.prototype.hasOwnProperty.call(registerObj, "mobile_number") &&
      !isEmpty(registerObj.mobile_number)
    ) {
      let obj = {
        mobile_number: registerObj.mobile_number,
      };
      getRegisterOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter mobile nmber");
    }
  };
  const onGetLoginOtp = (e) => {
    setErroeMsg("");
    if (
      !isEmpty(loginObj) &&
      Object.prototype.hasOwnProperty.call(loginObj, "userid") &&
      !isEmpty(loginObj.userid)
    ) {
      let obj = {};
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      getLoginOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter email/mobile nmber");
    }
  };
  const onVerifyOtp = (e) => {
    setErroeMsg("");
    setRegisterValues("email", { target: { value: "" } });
    if (
      !isEmpty(registerObj) &&
      Object.prototype.hasOwnProperty.call(registerObj, "mobile_number") &&
      !isEmpty(registerObj.mobile_number) &&
      Object.prototype.hasOwnProperty.call(registerObj, "otp") &&
      !isEmpty(registerObj.otp)
    ) {
      let obj = {
        mobile_number: registerObj.mobile_number,
        otp: registerObj.otp,
      };
      verifyOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter OTP");
    }
  };


  const setLoginValues = (key, e) => {
    let updatedObj = {};
    updatedObj[key] = e.target.value;

    setLoginObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };

  const setRegisterValues = (key, e) => {
    let updatedObj = {};
    updatedObj[key] = e.target.value;
    setRegisterObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };

  const sellerLogin = (e) => {
    e.preventDefault();
    if (
      !isEmpty(loginObj) &&
      (Object.prototype.hasOwnProperty.call(loginObj, "userid") ||
        Object.prototype.hasOwnProperty.call(loginObj, "otp"))
    ) {
      let obj = {
        otp: loginObj.otp,
      };
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      loginUser(obj, dispatch);
    }
  };
  const sellerRegister = (e) => {
    e.preventDefault();
    if (
      !isEmpty(registerObj) &&
      termAndCondition &&
      Object.prototype.hasOwnProperty.call(registerObj, "email") &&
      Object.prototype.hasOwnProperty.call(registerObj, "mobile_number") &&
      Object.prototype.hasOwnProperty.call(registerObj, "otp") &&
      Object.prototype.hasOwnProperty.call(registerObj, "business_name") &&
      Object.prototype.hasOwnProperty.call(
        registerObj,
        "contact_person_name"
      ) &&
      Object.prototype.hasOwnProperty.call(registerObj, "role_type_id")
    ) {
      let obj = {
        business_name: registerObj.business_name,
        email: registerObj.email,
        email_verify_status: registerObj.email_verify_status,
        contact_person_name: registerObj.contact_person_name,
        mobile_number: registerObj.mobile_number,
        mobile_number_verify_status:
          registerObj.mobile_number_verify_status,
        gst_no: registerObj.gst_no,
        role_id: sellerRoleId,
        role_type_name: sellerRoleName,
        role_type_id: registerObj.role_type_id,
      };
      registerUser(obj, dispatch);
    }
  };

  const loginModal = (buttonClasses, buttonContant = buttonText) => {
    return (
      <CustomModalWrap
        id="loginModal"
        className="modal-custom-class login-from"
        hedaerContent={
          <>
            <h4>{"Login"}</h4>
            <p
              onClick={() => {
                loginClose();
                registerShow();
                setErroeMsg("");
                setShowOtp(false);
                setOtpTimer(0);
                setRegisterObj({});
              }}
            >
              {"Register Now"}
            </p>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant={buttonContant}
        isModalOpen={showLogin}
        showModal={() => {
          loginShow();
          setErroeMsg("");
          setLoginObj({})
          setRegisterObj({});
          setShowOtp(false);
          setOtpTimer(0);
        }}
        closeModal={loginClose}
      >
        <Form className="my-2" onSubmit={sellerLogin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              onChange={(e) => setLoginValues("userid", e)}
              placeholder="Email or phone number"
            />
          </Form.Group>
          {erroeMsg && <p className="error-msg">{erroeMsg}</p>}

          {showOtp && (
            <div className="otp-timer">
              {otpTimer > 0 ? (
                <p>{`00:${otpTimer}`}</p>
              ) : (
                <p>Didn't receive otp?</p>
              )}
              <CustomButton
                disabled={otpTimer > 0}
                className="resend-otp-btn"
                style={{ color: otpTimer > 0 ? "#DFE3E8" : "#FF5630", visibility: otpTimer > 0 ? "hidden" : "visible" }}
                onClick={() => {
                  setOtpTimer(59);
                  onGetLoginOtp();
                }}
              >
                {"Resend OTP"}
              </CustomButton>
            </div>
          )}
          {!showOtp && (
            <CustomButton
              variant="primary"
              buttonClasses="login-btn btn-left"
              loading={getOtpDetails.getOtpLoading}
              type="button"
              onClick={() =>
                Object.prototype.hasOwnProperty.call(loginObj, "userid")
                  ? onGetLoginOtp()
                  : setErroeMsg("please enter Phone Number")
              }
            >
              {"GET OTP"}
            </CustomButton>
          )}
          {showOtp && (
            <>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="otp"
                  maxLength={6}
                  required
                  onChange={(e) => setLoginValues("otp", e)}
                  placeholder="OTP"
                />
              </Form.Group>
              <div className="action-buttons">
                <Button
                  className="reset"
                  variant="primary"
                  onClick={() => {
                    loginClose(); setLoginObj({}); setShowOtp(false);
                    setOtpTimer(0);
                  }}
                >
                  {"Close"}
                </Button>
                <CustomButton
                  variant="primary"
                  buttonClasses={
                    auth.loginLoading ? "login-loder-btn " : "login"
                  }
                  loading={auth.loginLoading}
                  type="submit"
                >
                  {"Login"}
                </CustomButton>
              </div>
            </>
          )}
        </Form>
      </CustomModalWrap>
    );
  };

  const registerModal = (buttonClasses, buttonContant = buttonText) => {
    return (
      <CustomModalWrap
        className="modal-custom-class "
        hedaerContent={
          <>
            <h4>{"Register"}</h4>

            <p
              onClick={() => {
                registerClose();
                loginShow();
                setShowOtp(false);
                setRegisterObj({});
                setShowRegisterSecondSetp(false);
                setOtpTimer(0);
                setErroeMsg("");
                // dispatch({
                //   type: appConstants.REGISTER_FAILURE,
                //   errors: {},
                // });
              }}
            >
              {"Already have account?"}
            </p>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant={buttonContant}
        isModalOpen={showRegister}
        showModal={() => { registerShow(); setOtpTimer(0); setShowOtp(false); setShowRegisterSecondSetp(false); setShowRegisterOtp(false); setErroeMsg("") }}
        closeModal={registerClose}
      >
        <h5 className="mb-4">{capitalize(stringConstants.ROLL_SELLER.replace(/_/g, " "))}</h5>
        {showRegisterSecondSetp ? (
          <Form className="my-2" onSubmit={sellerRegister}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {"Business Name"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    registerObj,
                    "business_name"
                  )
                    ? registerObj.business_name
                    : null
                }
                onChange={(e) => setRegisterValues("business_name", e)}
              />
            </Form.Group>
            <>
              <Form.Group
                className="mb-3 terms"
                controlId="formBasicCheckbox"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Form.Label>
                  {"Seller Type"}
                  <span className="redStreSpan">{"*"}</span>
                  {":"}
                </Form.Label>
                {roletypes?.data?.length > 0 && (roletypes?.data?.map((roletype, index) => {
                  if (roletype.role_id === sellerRoleId) {
                    return (
                      <Form.Check
                        id={roletype.id}
                        key={index}
                        type="radio"
                        name="radio_type"
                        label={roletype.role_type_name}
                        className="square-checkbox mx-2"
                        onChange={(e) =>
                          setRegisterValues("role_type_id", {
                            target: { value: roletype.id },
                          })
                        }
                      />
                    );
                  }
                }))}
              </Form.Group>
            </>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {"Email ID"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="email"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    registerObj,
                    "email"
                  )
                    ? registerObj.email
                    : null
                }
                onChange={(e) => setRegisterValues("email", e)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {"Contact Person"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={
                  Object.prototype.hasOwnProperty.call(
                    registerObj,
                    "contact_person_name"
                  )
                    ? registerObj.contact_person_name
                    : null
                }
                onChange={(e) =>
                  setRegisterValues("contact_person_name", e)
                }
              />
            </Form.Group>

            <Row className="m-0">
              <Col xs={8} className=" left">
                <Form.Group className="mb-3 ">
                  <Form.Label>
                    {"Mobile"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={10}
                    value={
                      Object.prototype.hasOwnProperty.call(
                        registerObj,
                        "mobile_number"
                      )
                        ? registerObj.mobile_number
                        : null
                    }
                    disabled
                    onChange={(e) => setRegisterValues("mobile_number", e)}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className=" right d-flex pt-3 " style={{ alignItems: "center" }}>
                <sapn className="me-2 ">{"Verfied"}</sapn>
                <VerifiedIcon />
              </Col>
            </Row>

            <Form.Group
              className="mb-3 terms pb-2 term d-flex"
              controlId="formBasicCheckbox"
            >

              <Form.Check
                type="checkbox"
                label={<p><Link target="_blank" to={"/terms"}>{"Terms of Use"}</Link>{" & "} <Link target="_blank" to={"/privacy-policy"}>{"Privacy Policy"}</Link></p>}
                required
                onChange={(e) => { setTermAndCondition(e.target.checked) }}
              />
              <Form.Label>
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              {/* {`Terms  of use & Privacy Policy `}<span className="redStreSpan">{"*"}</span></Form.Check> */}


            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{"GST No."}</Form.Label>
              <Form.Control
                type="text"
                value={
                  Object.prototype.hasOwnProperty.call(
                    registerObj,
                    "gst_no"
                  )
                    ? registerObj.gst_no
                    : null
                }
                onChange={(e) => setRegisterValues("gst_no", e)}
              />
            </Form.Group>
            {registration?.errors?.message && <p className="error-msg">{registration?.errors?.message}</p>}
            <div className="action-buttons">
              <p className="m-0"><span className="redStreSpan">{"*"}</span>{" Mandatory Flied"}</p>
              <Button
                className="reset"
                variant="primary"
                onClick={() => {
                  registerClose();
                  setShowOtp(false);
                  setRegisterObj({});
                  setShowRegisterSecondSetp(false);
                  setOtpTimer(0);
                  setErroeMsg("");
                  dispatch({
                    type: appConstants.REGISTER_FAILURE,
                    errors: {},
                  });
                }}
              >
                {"Close"}
              </Button>
              <Button
                className="login"
                variant="primary"
                type="submit"
              // disabled={!showOtp ? true : false}
              >
                {"Register"}
              </Button>
            </div>
          </Form>
        ) : (
          <Form className="my-2">
            <h6 >{"Verify Mobile Number"}</h6>
            {showRegisterOtp && (
              <div className="otp-timer">
                {otpTimer > 0 ? (
                  <p>{`00:${otpTimer}`}</p>
                ) : (
                  <p>Didn't receive otp?</p>
                )}
                <Button
                  disabled={otpTimer > 0}
                  className="resend-otp-btn"
                  style={{ color: otpTimer > 0 ? "#DFE3E8" : "#FF5630", visibility: otpTimer > 0 ? "hidden" : "visible" }}
                  onClick={() => {
                    setOtpTimer(59);
                    onGetRegisterOtp();
                  }}
                >
                  {"Resend OTP"}
                </Button>
              </div>
            )}
            <Form.Group className="mb-3 ">
              <Form.Label>
                {"Mobile"}
                <span className="redStreSpan">{"*"}</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength={10}
                value={
                  Object.prototype.hasOwnProperty.call(
                    registerObj,
                    "mobile_number"
                  )
                    ? registerObj.mobile_number
                    : null
                }
                onChange={(e) => setRegisterValues("mobile_number", e)}
              />
            </Form.Group>

            {!showRegisterOtp && (
              <Form.Group className="mb-3  w-100">
                <Form.Label>{"Generate Otp"}</Form.Label>
                <CustomButton
                  variant="primary"
                  buttonClasses="login-btn btn-left"
                  loading={getOtpDetails.getOtpLoading}
                  type="button"
                  onClick={() =>
                    Object.prototype.hasOwnProperty.call(
                      registerObj,
                      "mobile_number"
                    )
                      ? onGetRegisterOtp()
                      : setErroeMsg("please enter Phone Number")
                  }
                >
                  {"GET OTP"}
                </CustomButton>
              </Form.Group>
            )}
            {showRegisterOtp && (
              <>
                <Form.Group className="mb-3 ">
                  <Form.Label>
                    {"OTP"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={6}
                    value={
                      Object.prototype.hasOwnProperty.call(
                        registerObj,
                        "otp"
                      )
                        ? registerObj.otp
                        : null
                    }
                    onChange={(e) => setRegisterValues("otp", e)}
                  />
                </Form.Group>
                <CustomButton
                  variant="primary"
                  buttonClasses="login-btn"
                  loading={getVerifyOtpDetails.verifyOtpLoading}
                  type="button"
                  onClick={() =>
                    Object.prototype.hasOwnProperty.call(
                      registerObj,
                      "mobile_number"
                    )
                      ? onVerifyOtp()
                      : setErroeMsg("please enter Phone Number")
                  }
                >
                  {"Verify OTP"}
                </CustomButton>
              </>
            )}
            {erroeMsg && <p className="error-msg">{erroeMsg}</p>}
          </Form>
        )}
      </CustomModalWrap>
    );
  };

  return (<>
    {registerModal(registerMode ? buttonClasses : "hidden", buttonText)}
    {loginModal(!registerMode ? buttonClasses : "hidden", buttonText)}
  </>)

};

export default SellerAuth;
