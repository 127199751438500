import React, { useEffect, useState } from "react";
import { Row, Form, Tab, Tabs, Col, Container } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../../../utils/is-Empty";
import StatCard from "../../../common/StatCard";
import "./index.css"
import Address from "../../common/Address";
import BankingAndTaxation from "../../common/BankingAndTaxation";
import { regex, stringConstants } from "../../../../helpers/app-constants";
import FactoryInfo from "../FactoryInfo";
import { patchUsers } from "../../../../actions/auth.actions";
import Calculations from "../Calculations";
import LiveOffers from "../LiveOffers";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import { getApi, patchOrder } from "../../../../actions/structure.action";
import FilterObjectsByKeyValue from "../../../../utils/FilterObjectsByKeyValue";
import CustomTable from "../../common/CustomTable";
import { tableStructures } from "../../../../helpers/table-structures";
import { CloseIcon, ViewIcon } from "../../../../assets/svg/svg-icons";
import DashbordOrder from "../../common/DashbordOrder/indes";
import CustomModalWrap from "../../../common/CustomModalWrap";
import RepeatOrders from "../../../../assets/images/icons/RepeatOrders.png"
import TotalSale from "../../../../assets/images/icons/Sales.png"
import NewOrders from "../../../../assets/images/icons/NewOrders.png"
import Customers from "../../../../assets/images/icons/Customers.png"




const Dashbord = () => {
  const dispatch = useDispatch();
  const [newOrder, setNewOrder] = useState([]);
  const [acceptedOrder, setAcceptedOrder] = useState([]);
  const [activeOnboardingTab, setActiveOnboardingTab] = useState("address");

  const [showOrderModal, setOrderModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState("");

  const orderViewToggle = () => setOrderModal(!showOrderModal);

  const { auth, addresses, bankInfo, factoryInfo, requestCall, bhareeAdminBasicTable, orders } = useSelector(
    (state) => {
      return state;
    }
  );
  useEffect(() => {
    getApi("getBhreeAdminBasicTables", dispatch);
    getApi("getOrders", dispatch);
  }, [])
  useEffect(() => {
    if (!auth.user.onboarding && !isEmpty(bhareeAdminBasicTable?.data)) {
      onSelectValue(FilterObjectsByKeyValue(bhareeAdminBasicTable?.data?.deliveryTypes, "name", "Standard"), "delivery_types");
      onSelectValue(FilterObjectsByKeyValue(bhareeAdminBasicTable?.data?.paymentTypes, "name", "Pay Now"), "payment_types");
    }
  }, [bhareeAdminBasicTable])
  const [deliveryFormObj, setDeliveryFormObj] = useState({});

  useEffect(() => {
    if (addresses?.data?.length < 1) {
      setActiveOnboardingTab("address")
    } else if (addresses?.data?.length > 0 && isEmpty(bankInfo?.data)) {
      setActiveOnboardingTab("banking info")

    } else if (addresses?.data?.length > 0 && !isEmpty(bankInfo?.data) && auth?.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_MANUFACTURER && isEmpty(factoryInfo?.data)) {
      setActiveOnboardingTab("factory info")
    }
    else if (addresses?.data?.length > 0 && !isEmpty(bankInfo?.data) && auth?.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_MANUFACTURER && !isEmpty(factoryInfo?.data)) {
      setActiveOnboardingTab("final")
    } else if (addresses?.data?.length > 0 && !isEmpty(bankInfo?.data) && auth?.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_DISTRIBUTOR) {

      setActiveOnboardingTab("final")
    }
  }, [addresses, bankInfo, factoryInfo]);
  // useEffect(() => {
  //   console.log("***** deliveryFormObj ", deliveryFormObj)
  // }, [deliveryFormObj]);

  const onSelectValue = (value, selectorType) => {
    let updatedObj = {};
    updatedObj[selectorType] = value;
    setDeliveryFormObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };


  const completeOnbording = (e) => {
    e.preventDefault();

    if (
      !isEmpty(deliveryFormObj) &&
      Object.prototype.hasOwnProperty.call(deliveryFormObj, "delivery_types") &&
      !isEmpty(deliveryFormObj.delivery_types)
    ) {
      patchUsers(auth?.user?.id, { ...deliveryFormObj, onboarding: true }, dispatch)

    }
  };

  const acceptOrderStatus = (data) => {
    let sendObj = {};
    sendObj["id"] = data.id;
    sendObj["status"] = "accepted";
    patchOrder(sendObj, dispatch);
  };
  useEffect(() => {
    if (!isEmpty(orders?.data) && orders?.data.length > 0) {
      let tempNewOrder = orders?.data.filter(
        (item) => item.status === "placed"
      ); // Replace with your desired filter condition
      let tempAcceptedOrder = orders?.data.filter(
        (item) => item.status === "accepted"
      ); // Replace with your desired filter condition
      setNewOrder(tempNewOrder);
      setAcceptedOrder(tempAcceptedOrder);
    }
    // if (orders?.data?.length > 0) {
    //   let tempAcceptedOrder = orders?.data.filter(
    //     (item) => item.status !== "inprocess"
    //   ); // Replace with your desired filter condition
    //   setAcceptedOrder(tempAcceptedOrder);
    // }
  }, [orders]);

  const OrderDetailsModal = () => {
    return (
      <CustomModalWrap
        className="modal-custom-class order-popup"
        hedaerContent={
          <>
            <h4>{"Order"}</h4>
            <p
              onClick={() => {
                orderViewToggle();
              }}
            >
              <CloseIcon />
            </p>
          </>
        }
        buttonClasses={"action-button  hidden"}
        buttonContant={"view"}
        isModalOpen={showOrderModal}
        showModal={orderViewToggle}
        closeModal={orderViewToggle}
      >
        <DashbordOrder id={currentOrderId} />
      </CustomModalWrap>
    );
  };



  if (auth.user.onboarding) {
    return (

      <>
        <Row className="mx-0 p-0 mb-4">
          <h1 className="p-0 title text-uppercase">{"Dashboard"}</h1>
        </Row>
        <Row className="stats-row  mb-2">
          <StatCard cardTitle={"Total Sale"} statNumber="Rs. 25156" statPecentage="37" statIcon={TotalSale} />
          <StatCard cardTitle={"Repeat Orders"} statNumber="352" statPecentage="47" statIcon={RepeatOrders} />
          <StatCard cardTitle={"New Orders"} statNumber="1523" statPecentage="12" statIcon={NewOrders} />
          <StatCard cardTitle={"Customers"} statNumber="2310" statPecentage="14" statIcon={Customers} />
        </Row>

        {OrderDetailsModal()}
        {/*  After Order plazed by buyers */}

        {/* <h4 className=" title ">{"New Orders"}</h4> */}
        {!isEmpty(newOrder) && (
          <Row className="section-row mb-4">
            <CustomTable
              enableRowActions
              data={newOrder}
              enableTopToolbar={true}
              columns={tableStructures.new_orders_seller}
              initialState={{
                columnPinning: {
                  right: ["mrt-row-actions"],
                },
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Row
                  style={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <h4>{"New Orders"}</h4>
                </Row>
              )}
              renderRowActions={({ row, table }) => (
                <div className="table-action-box">
                  {/* <CustomButton
                  buttonClasses="action-button accept"
                  onClick={() => {
                    dispatch({
                      type: stringConstants.SET_CURRENT_VIEW,
                      payload: `${stringConstants.ORDER}/${row.original.id}`,
                    })
                  }}
                >
                  <ViewIcon />

                </CustomButton> */}
                  <CustomButton
                    buttonClasses="action-button accept"
                    id={row.original.id}
                    onClick={() => {
                      acceptOrderStatus(row.original);
                    }}
                  >
                    {"Accept"}
                  </CustomButton>

                </div>
              )} /> </Row>) }


        {/* <h4 className=" title ">{"New Orders"}</h4> */}
        {!isEmpty(acceptedOrder) && (<Row className="section-row mb-2 ">
          <CustomTable
            enableRowActions
            data={acceptedOrder}
            enableTopToolbar={true}
            columns={tableStructures.new_orders_seller}
            initialState={{
              columnPinning: {
                right: ["mrt-row-actions"],
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Row
                style={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <h4>{"Orders"}</h4>
              </Row>
            )}
            renderRowActions={({ row, table }) => (
              <div className="table-action-box">
                {/* {OrderDetailsModal(row.original.id)} */}
                <CustomButton
                  buttonClasses="action-button"
                  id={row.original.id}
                  onClick={() => {
                    setCurrentOrderId(row.original.id);
                    orderViewToggle()
                  }}
                >
                  <ViewIcon />
                </CustomButton>

                {/* <CustomButton
                  buttonClasses="action-button accept"
                  onClick={() => {
                    acceptOrderStatus(row.original);
                  }}
                >
                  {"Accept"}
                </CustomButton> */}

              </div>
            )} />
        </Row>)}

        {/* <Row className="section-row">
          <h4 className=" title ">{"Assigned Trip"}</h4>
          <Col xs={12} >
            <CustomTable data={DummyDataAssignedTrip} columns={tableStructures.assinged_trips} />
          </Col>
        </Row>
        <Row className="section-row">
          <h4 className=" title ">{"Oder status"}</h4>
          <Col xs={12} >
            <CustomTable data={DummyDataOrderStatus} columns={tableStructures.order_status} />
          </Col>
        </Row> */}
        <br />
        <LiveOffers mainClass="section-row my-4" />
        {auth?.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_MANUFACTURER && (<Row className="section-row ">
          <Calculations />
        </Row>)}
      </>
    );
  } else {
    return (<>
      <Row className="mx-0 p-0 mb-4">
        <h1 className="p-0 title text-uppercase">{"Complete your onboarding"}</h1>
      </Row>
      <Row className="mx-0 p-0 mb-4 onboarding modal-custom-class" >
        <Tabs
          defaultActiveKey={activeOnboardingTab}
          id="uncontrolled-tab-example"
          className="mb-3 "
        >
          <Tab eventKey="address" title="Address">
            <Address />
            {addresses?.data?.length > 0 ? (<h4 className="py-4 px-3 title ">{`Move to  ${activeOnboardingTab} tab`}</h4>) : (
              <h4 className="py-4 px-3 title ">{`Please fill the deatils then move to  next tab`}</h4>
            )}

          </Tab>
          <Tab eventKey="banking info" title="Banking info">
            <BankingAndTaxation />
            {!isEmpty(bankInfo?.data) ? (<h4 className="py-4 px-3 title ">{`Move to  ${activeOnboardingTab} tab`}</h4>) : (
              <h4 className="py-4 px-3 title ">{`Please fill the deatils then move to  next tab`}</h4>
            )}
          </Tab>
          {auth?.user?.roles_type?.role_type_name === stringConstants.ROLL_TYPE_MANUFACTURER && (
            <Tab eventKey="factory info" title="Factory info">
              <FactoryInfo />
              {!isEmpty(bankInfo?.data) ? (<h4 className="py-4 px-3 title ">{`Move to  ${activeOnboardingTab} tab`}</h4>) : (
                <h4 className="py-4 px-3 title ">{`Please fill the deatils then move to  next tab`}</h4>
              )}
            </Tab>
          )}
          <Tab eventKey="final" title="Final" >
            <Row className="m-0 w-full  form-row ">
              <Row className="heading-div ">
                <h5 className="text-uppercase">{"Final Step"}</h5>
              </Row>
              <Form
                className="modal-custom-class  px-0"
                onSubmit={completeOnbording}
              >
                <Row className="m-0 px-0">
                  {bhareeAdminBasicTable?.data?.deliveryTypes?.length > 0 && (
                    <Col xs={12} md={6} className="px-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          {"Select Delivery Types"}
                          <span className="redStreSpan">{"*"}</span>
                        </Form.Label>

                        <CustomSelectDropdown
                          style={{
                            multiselectContainer: {
                              backgroundColor: "#f8f8f8",
                            },
                            chips: {
                              margin: 0,
                            },
                            option: {
                              background: "#fbb040",
                              color: "#FFFFFF",
                              borderBottom: "1px solid #FFFFFF",
                            },
                          }}
                          options={bhareeAdminBasicTable?.data?.deliveryTypes}
                          onSelect={(e) =>
                            onSelectValue(e, "delivery_types")
                          }
                          onRemove={(e) =>
                            onSelectValue(e, "delivery_types")
                          }
                          selectedValues={
                            Object.prototype.hasOwnProperty.call(
                              deliveryFormObj,
                              "delivery_types"
                            )
                              ? deliveryFormObj["delivery_types"]
                              : []
                          }
                          displayValue={"name"}
                          disable
                          singleSelect
                        />
                      </Form.Group>
                    </Col>)}
                  {bhareeAdminBasicTable?.data?.paymentTypes?.length > 0 && (
                    <Col xs={12} md={6} className="px-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          {"Select Payment Types"}
                          <span className="redStreSpan">{"*"}</span>
                        </Form.Label>

                        <CustomSelectDropdown
                          disablePreSelectedValues
                          style={{
                            multiselectContainer: {
                              backgroundColor: "#f8f8f8",
                            },
                            chips: {
                              background: "#fbb040",
                              margin: 0,
                            },
                            option: {
                              background: "#fbb040",
                              color: "#FFFFFF",
                              borderBottom: "1px solid #FFFFFF",
                            },
                          }}
                          options={bhareeAdminBasicTable?.data?.paymentTypes}
                          onSelect={(e) =>
                            onSelectValue(e, "payment_types")
                          }
                          onRemove={(e) =>
                            onSelectValue(e, "payment_types")
                          }
                          selectedValues={
                            FilterObjectsByKeyValue(bhareeAdminBasicTable?.data?.paymentTypes, "name", "Pay Now")
                          }
                          displayValue={"name"}
                        />
                      </Form.Group>
                    </Col>)}

                  <Col xs={12} md={6} lg={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        {"Delivery Charges (Per Case) "}
                        <span className="redStreSpan">{"*"}</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={deliveryFormObj?.deliveryCharge}
                        onChange={(e) => {
                          return regex.test(e.target.value)
                            ? onSelectValue(e.target.value, "deliveryCharge")
                            : null;
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="action-buttons">
                  {addresses?.data?.length > 0 && !isEmpty(bankInfo?.data) ? (<>

                    <h4 className="py-4 px-3 m-0 title ">{`Please Check the deatils and complete the  ${activeOnboardingTab}  step`}</h4><CustomButton
                      variant="primary"
                      buttonClasses={
                        !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                      }
                      type="submit"
                      // onClick={() => }
                      loading={requestCall?.loading}
                    >
                      {"Submit"}
                    </CustomButton></>) : <>
                    <h4 className="py-4 px-3 m-0 title ">{`Please complete the  ${activeOnboardingTab}  step`}</h4>
                  </>}

                </div>
              </Form>
            </Row>
          </Tab>
        </Tabs>
      </Row>
    </>)
  }
};

export default Dashbord;
