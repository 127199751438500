import React, { useEffect, useState } from "react";
import { Image, Button, Form, Container } from "react-bootstrap";
import BecomeASeller from "../../assets/images/Become-a-seller.png";
import "./layout.css";
import { stringConstants } from "../../helpers/app-constants";
import isEmpty from "../../utils/is-Empty";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../common/CustomButton";
import { getLoginOtp, loginUser } from "../../actions/auth.actions";
import SellerAuth from "../auth/SellerAuth";
const FooterLogins = () => {
  const {
    auth,
    getOtp: getOtpDetails,
  } = useSelector((state) => {
    return state;
  });
  const dispatch = useDispatch();

  const [erroeMsg, setErroeMsg] = useState("");
  const [loginType, setLoginType] = useState(stringConstants.ROLL_SELLER);
  const [showOtp, setShowOtp] = useState(false);
  const [footerOtpTimer, setFooterOtpTimer] = useState(0);
  const [loginObj, setLoginObj] = useState({});



  const onLoginChange = (type) => {
    setLoginType(type);
  };
  const setLoginValues = (key, e) => {
    let updatedObj = {};
    updatedObj[key] = e.target.value;

    setLoginObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (footerOtpTimer > 0) {
        setFooterOtpTimer(footerOtpTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [footerOtpTimer]);
  useEffect(() => {
    if (getOtpDetails.otpDetails?.success && Object.prototype.hasOwnProperty.call(loginObj, "userid") && !isEmpty(loginObj.userid)) {
      setShowOtp(true);
      setFooterOtpTimer(59);
    } else {
      setErroeMsg(getOtpDetails.errors?.message);
    }
  }, [getOtpDetails]);

  const onGetLoginOtp = (e) => {
    setErroeMsg("");
    if (
      !isEmpty(loginObj) &&
      Object.prototype.hasOwnProperty.call(loginObj, "userid") &&
      !isEmpty(loginObj.userid)
    ) {
      let obj = {};
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      getLoginOtp(obj, dispatch);
    } else {
      setErroeMsg("Please enter email/mobile nmber");
    }
  };

  const userLogin = (e) => {
    e.preventDefault();
    setErroeMsg("")
    if (
      !isEmpty(loginObj) &&
      (Object.prototype.hasOwnProperty.call(loginObj, "userid") ||
        Object.prototype.hasOwnProperty.call(loginObj, "otp"))
    ) {
      let obj = {
        otp: loginObj.otp,
      };
      obj[!isNaN(loginObj.userid) ? "mobile_number" : "email"] =
        loginObj.userid;
      loginUser(obj, dispatch);
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p
          className={`footer-login-tab pointer ${loginType === stringConstants.ROLL_SELLER && "login-active"
            }`}
          onClick={() => onLoginChange(stringConstants.ROLL_SELLER)}
        >
          {"SELLER LOGIN"}
        </p>
        <div id="borderSeprater"></div>
        <p
          className={`footer-login-tab pointer ${loginType === stringConstants.ROLL_LOGISTIC_PARTNER &&
            "login-active"
            }`}
          onClick={() => onLoginChange(stringConstants.ROLL_LOGISTIC_PARTNER)}
        >
          {"LOGISTIC LOGIN"}
        </p>
        <div id="borderSeprater"></div>
        <p
          className={`footer-login-tab pointer ${loginType === stringConstants.ROLL_TYPE_WAREHOUSE && "login-active"
            }`}
          onClick={() => onLoginChange(stringConstants.ROLL_TYPE_WAREHOUSE)}
        >
          {"WAREHOUSE LOGIN"}
        </p>
      </div>
      {loginType === stringConstants.ROLL_SELLER ? <Form className="py-4" onSubmit={userLogin}>
        {showOtp && (
          <div className="otp-timer" style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
            <CustomButton
              className="resend-otp-btn"
              style={{ color: "#FF5630" }}
              onClick={() => {
                setErroeMsg("");
                setShowOtp(false);
                setFooterOtpTimer(0);
                setLoginObj({});
              }}
            >
              {"Change Number"}
            </CustomButton>
          </div>
        )}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            className="footer-input"
            type="text"
            placeholder="Mobile/Email"
            onChange={(e) => setLoginValues("userid", e)}
          />
        </Form.Group>
        {/* {erroeMsg && <p className="error-msg">{erroeMsg}</p>} */}
        {showOtp && (
          <div className="otp-timer">
            {footerOtpTimer > 0 ? (
              <p>{`00:${footerOtpTimer}`}</p>
            ) : (
              <p>Didn't receive otp?</p>
            )}
            <Button
              disabled={footerOtpTimer > 0}
              className="resend-otp-btn"
              style={{ color: footerOtpTimer > 0 ? "#DFE3E8" : "#FF5630", visibility: footerOtpTimer > 0 ? "hidden" : "visible" }}
              onClick={() => {
                setFooterOtpTimer(59);
                onGetLoginOtp();
              }}
            >
              {"Resend OTP"}
            </Button>
          </div>
        )}
        {showOtp && (
          <>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                className="footer-input"
                type="password"
                maxLength={6}
                placeholder="OTP"
                onChange={(e) => setLoginValues("otp", e)}
              />
            </Form.Group>
            <CustomButton
              variant="primary"
              className="login-btn w-100 mb-2"
              type="button"
              loading={auth.loginLoading}
              lodaerDark={true}
              onClick={userLogin}
            >
              {"LOGIN"}
            </CustomButton>
          </>
        )}
        {!showOtp && (
          <CustomButton
            variant="primary"
            loading={getOtpDetails.getOtpLoading}
            lodaerDark={true}
            className="login-btn w-100 mb-2"
            type="button"
            onClick={() => onGetLoginOtp()}
          >
            {"GET OTP"}
          </CustomButton>
        )}
        <div className="seller-tag-div">
          <SellerAuth buttonText={"REGISTER"} registerMode={true} buttonClasses={"footer-item-register link-color mt-2"} />
          <Image
            style={{ height: "auto", width: "auto", maxWidth: "100%" }}
            src={BecomeASeller}
            alt="BecomeASeller"
          />
        </div>

        {/* <p>{"REGISTER"}</p> */}
      </Form > :
        <Container className="p-5" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <p className="m-0 text-center" style={{ fontSize: "2rem" }}>{"Coming Soon"}</p>
        </Container>
      }

    </>
  );
};

export default FooterLogins;
