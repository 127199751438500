import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import "./home.css";
import HomeBannerCarousel from "./HomeBannerCarousel.js";
import RefferSection from "../common/RefferSection";
import { useDispatch, useSelector } from "react-redux";
import deliveryImg1 from "../../assets/images/delivery_img_1.png";
import deliveryImg2 from "../../assets/images/delivery_img_2.png";
import plastic1 from "../../assets/images/plastic-1.png";
import fulfill from "../../assets/images/fulfill.png";

import {
  CarouselLeftArrow,
  CarouselRightArrow,
} from "../../assets/svg/svg-icons";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { getApi } from "../../actions/structure.action";
import { appConstants, stringConstants } from "../../helpers";
import isEmpty from "../../utils/is-Empty.js";
import convertToLiters from "../../utils/convertToLiters.js";
import BuyerAuth from "../auth/BuyerAuth.js";
import { deliverytypesString } from "../../helpers/table-structures.js";

function Home() {
  const dispatch = useDispatch();
  const { brands, auth, dealOfTheDay } = useSelector((state) => {
    return state;
  });
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const refDelivery = useRef([]);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };


  const scrollToDelivery = () => {
    refDelivery.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  }
  window.addEventListener("resize", handleResize);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (auth?.loggedIn) {
      if (auth?.user?.role?.role_name === stringConstants.ROLL_BHAREE) {
        navigate("/admin/profile-panel");
      } else if (auth?.user?.role?.role_name === stringConstants.ROLL_SELLER) {
        navigate("/seller/profile-panel");
      }
    }
    getApi("getBuyerBrands",dispatch)
    getApi("getDealOfTheDay",dispatch)
  }, []);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2.5,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1.5,
    },
  };

  const deliveryOptions = (
    <Container className="container delivery-container" ref={refDelivery}>
      <h4 className="text-uppercase section-title">
        {"Delivery Options"}
        <div id="borderLeft"></div>
      </h4>
      {/* <hr className="small-heading-underline"/> */}
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          className="mb-4"
          onClick={() => navigate("brands?delivery=standard")}
        >
          <Image
            style={{ height: "auto", width: "100%", cursor: "pointer" }}
            src={deliveryImg2}
            alt="refferImg2"
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          className="mb-4"
          onClick={() => navigate("brands?delivery=bharee")}
        >
          <Image
            style={{ height: "auto", width: "100%", cursor: "pointer" }}
            src={deliveryImg1}
            alt="refferImg1"
          />
        </Col>
      </Row>
    </Container>
  );
  const promptSeller = (
    <>
      <Container className="container delivery-container pt-0">
        <h4 className="text-uppercase section-title">
          {"Prompt Seller"}
          <div id="borderLeft"></div>
        </h4>
        <Row className="promotion">
          <Col xs={12} sm={12} md={4} className="promotion-col">
            <Row className="prompt-card" style={{ backgroundColor: "#FFE3C2" }}>
              <Col xs={8} sm={8} md={8} className="">
                <div className="prompt-card-details">
                  <div className="prompt-card-heading desktop-heding">
                    <h1>{"1"}</h1>
                    <span className="text-uppercase">{"L"}</span>
                  </div>
                  <div className="prompt-card-title">
                    <h5 className="text-uppercase">{"BISLERI"}</h5>
                    <p>{"Packaged Drinking Water"}</p>
                  </div>
                  <Button className="text-uppercase">{"Shop Now"}</Button>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} className="p-0 prompt-card-img">
                <div className="prompt-card-heading mobile-heding">
                  <h1>{"1"}</h1>
                  <span className="text-uppercase">{"L"}</span>
                </div>
                <Image
                  style={{ height: "auto", width: "100%" }}
                  src={plastic1}
                  alt="plastic1"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={4} className="promotion-col">
            <Row className="prompt-card" style={{ backgroundColor: "#FFE8F6" }}>
              <Col xs={8} sm={8} md={8} className="">
                <div className="prompt-card-details">
                  <div className="prompt-card-heading desktop-heding">
                    <h1>{"500"}</h1>
                    <span className="text-uppercase">{"ML"}</span>
                  </div>
                  <div className="prompt-card-title">
                    <h5 className="text-uppercase">{"BISLERI"}</h5>
                    <p>{"Packaged Drinking Water"}</p>
                  </div>
                  <Button className="text-uppercase">{"Shop Now"}</Button>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} className="p-0 prompt-card-img">
                <div className="prompt-card-heading mobile-heding">
                  <h1>{"500"}</h1>
                  <span className="text-uppercase">{"ML"}</span>
                </div>
                <Image
                  style={{ height: "auto", width: "100%" }}
                  src={plastic1}
                  alt="plastic1"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={4} className="promotion-col">
            <Row className="prompt-card" style={{ backgroundColor: "#D8F1FF" }}>
              <Col xs={8} sm={8} md={8} className="">
                <div className="prompt-card-details">
                  <div className="prompt-card-heading desktop-heding">
                    <h1 className="desktop-heding">{"250"}</h1>
                    <span className="text-uppercase">{"ml"}</span>
                  </div>
                  <div className="prompt-card-title">
                    <h5 className="text-uppercase">{"BISLERI"}</h5>
                    <p>{"Packaged Drinking Water"}</p>
                  </div>
                  <Button className="text-uppercase">{"Shop Now"}</Button>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} className="p-0 prompt-card-img">
                <div className="prompt-card-heading mobile-heding">
                  <h1 className="desktop-heding">{"250"}</h1>
                  <span className="text-uppercase">{"ml"}</span>
                </div>
                <Image
                  style={{ height: "auto", width: "100%" }}
                  src={plastic1}
                  alt="plastic1"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );

  const CustomArrow = (e, variant, value) => {
    if (value === "left") {
      document
        .querySelector(`._${variant} .react-multiple-carousel__arrow--left`)
        .click();
    } else if (value === "right") {
      document.querySelector(`._${variant} .react-multiple-carousel__arrow--right`).click();
    }
  };
  const DealOfTheDay = (variant, bgColor = "#f1f8ff", cardBgColor, deals) => {
    function fillArrayToLength5(arr) {
      // Calculate the number of items needed to fill the array to length 5
      const itemsNeeded = 4 - arr?.length;

      if (itemsNeeded > 0) {
        for (let i = 0; i < itemsNeeded; i++) {
          arr.push(arr[i % arr?.length]); // Use modulo operator to cycle through input array
        }
        // Return the modified array
      }
      return arr;
    }
    let newDeals = fillArrayToLength5(deals);
    return (
      <div
        className="dealOfTheDay-container-wrap"
        style={{ backgroundColor: bgColor }}
        key={`_${variant.replace(/\s/g, "")}_${bgColor}`}
      >
        <Container className="headring-container">
          <h className="text-uppercase section-title">
            {`Deal of the Day - `}
            <span style={{ color: "#F58220" }}>{`${variant}`}</span>
            <div id="borderLeft"></div>
          </h>
        </Container>

        <Row className="dealOfTheday-row m-0 pt-3" style={{ width: "100%" }}>
          <Col xs={0} sm={0} md={1} className="dealOfTheDay-carousel-arrow">
            {/* {deals?.length >= 4 && ( */}
            <Button
              className="carousel-arrow-btn"
              onClick={(e) => {
                CustomArrow(e, variant.replace(/\s/g, ""), "left");
              }}
            >
              <CarouselLeftArrow />
            </Button>
            {/* // )} */}

          </Col>
          <Col xs={12} sm={12} md={10} className="p-0">

            <Container fluid="sm" className="p-0">

              <Carousel
                className={`_${variant.replace(/\s/g, "")}`}
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                // rewind={false}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlay={true}
                // autoPlaySpeed={500}
                keyBoardControl={true}
                // partialVisible={true}
                customTransition="transform 0.5s ease-in-out 0s"
                // transitionDuration={500}
                // containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              >
                {newDeals?.length > 0 && newDeals.map((deal, index) => {
                  return <Card
                    className="deal-card"
                    style={{ backgroundColor: cardBgColor }}
                    key={`_${variant.replace(/\s/g, "")}_${index}`}
                  >
                    <Card.Img variant="top" src={deal?.productImages[0]?.url} />
                    <div className="card-image-heading">
                      {/* <p>{variant} </p> */}
                      <p>{deal?.brand_name}</p>

                      {deliverytypesString(deal?.delivery_types).toLowerCase().includes(appConstants.BHAREE.toLowerCase()) && <Image
                        src={fulfill}
                        alt="fulfill"
                        style={{ width: "30%" }}
                      />}

                    </div>
                    <Card.Body>
                      <Card.Text>{deal?.product_category}</Card.Text>
                      {/* <Card.Text>{deliverytypesString(deal?.delivery_types).toLowerCase()}{appConstants.BHAREE.toLowerCase()}</Card.Text> */}

                      {auth.loggedIn && (
                        <>
                          <div className="card-price-title">
                            <Card.Title className="text-uppercase m-0">
                              {`₹ ${deal.offerPrice}`}
                            </Card.Title>
                            <span>{"/case"}</span>


                          </div>
                          <p className="old-price">
                            <s>{`₹ ${deal.price}/case`}</s>
                          </p>
                        </>
                      )}
                      <Card.Text className="deal-card-small-text">
                        {"MOQ:"}{deal.moq_id[0].quantity}
                        {/* {`(${deal?.packaging_size} Bottles per case)`} */}
                      </Card.Text>
                      {auth.loggedIn ? (
                        <>
                          <Link to={`/product/${deal?.product_variant_id}?moq=${deal.moq_id[0].quantity}`} className="barand-card-wrap text-uppercase ref-btn" variant="primary"  >
                            {"GET DEAL"}
                          </Link>
                        </>
                      ) : <BuyerAuth buttonText={"LOGIN"} buttonClasses={"text-uppercase ref-btn"} />}
                      {/* <Button variant="primary" className="text-uppercase">
                          {"GET DEAL"}
                        </Button> */}
                    </Card.Body>
                  </Card>
                })}

              </Carousel>
            </Container>
          </Col>
          <Col xs={0} sm={0} md={1} className="dealOfTheDay-carousel-arrow">
            {/* {deals?.length >= 4 && ( */}
            <Button
              className="carousel-arrow-btn"
              onClick={(e) => {
                CustomArrow(e, variant.replace(/\s/g, ""), "right");
              }}
            >
              <CarouselRightArrow />
            </Button>
            {/* // )} */}

          </Col>
        </Row>
      </div>
    );
  };

  const brandSection = (
    <>
      <Container fluid="md" className="delivery-container">
        <div className="text-uppercase section-title-div">
          <h4 className="text-uppercase section-title">
            {"Pick your Brand "}
            <div id="borderLeft" style={{ width: "14%" }}></div>
          </h4>
          <Link to="/brands">{"SEE ALL"}</Link>
        </div>
        {/* <hr className="small-heading-underline"/> */}
        {brands?.data?.length > 0 && brands?.data?.length < 5 && (<Row className="brand-row m-0 w-100" >
          {brands?.data.map((brand, index) => {
            return index < 4 && <Col key={brand.id + "_" + index} className="mb-4" xs={6} sm={6} md={3}>
              <div
                className="brand-card"
                onClick={() => navigate(`/brands?brand=${brand.brand_name + "|" + brand.id}`)}
              >
                <Image src={brand.brand_logo} alt={brand.brand_name} />
                <p className="text-uppercase">{brand.brand_name}</p>
              </div>
            </Col>
          })}
        </Row>)}
      </Container>
      {brands?.data?.length > 0 && brands?.data?.length > 4 && (<Row className="brand-row m-0 w-100" >
        <Row className="dealOfTheday-row m-0 " style={{ width: "100%" }}>
          <Col xs={0} sm={0} md={1} className="dealOfTheDay-carousel-arrow">
            <Button
              className="carousel-arrow-btn"
              onClick={(e) => {
                CustomArrow(e, "brands_carousel", "left");
              }}
            >
              <CarouselLeftArrow />
            </Button>
          </Col>
          <Col xs={12} sm={12} md={10} className="p-0">
            <Container fluid="sm" className="p-0">
              <Carousel
                className="_brands_carousel"
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                // ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlay={true}
                // autoPlaySpeed={2000}
                keyBoardControl={true}
                customTransition="transform 0.5s ease-in-out 0s"
                // transitionDuration={500}
                // containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              >
                {brands?.data.map((brand, index) =>
                  <div key={brand.id + "_" + index + 1} className="me-4 h-100">
                    <div
                      className="brand-card"
                      onClick={() => navigate(`/brands?brand=${brand.brand_name + "|" + brand.id}`)}
                    >
                      <Image src={brand.brand_logo} alt={brand.brand_name} />
                      <p className="text-uppercase">{brand.brand_name}</p>
                    </div>
                  </div>

                )}
              </Carousel>
            </Container>
          </Col>
          <Col xs={0} sm={0} md={1} className="dealOfTheDay-carousel-arrow">
            <Button
              className="carousel-arrow-btn"
              onClick={(e) => {
                CustomArrow(e, "brands_carousel", "right");
              }}
            >
              <CarouselRightArrow />
            </Button>
          </Col>
        </Row>
      </Row>)}
    </>
  );
  return (
    <>
      <HomeBannerCarousel onClickBtn={scrollToDelivery} />
      {brands?.data?.length > 0 && brandSection}

      {!isEmpty(dealOfTheDay?.data) && Object.prototype.hasOwnProperty.call(dealOfTheDay.data, "1000") && dealOfTheDay.data["1000"]?.length > 0 && DealOfTheDay(convertToLiters(1000).toString(), "#F1F8FF", "#FFFFFF", dealOfTheDay.data["1000"])}
      {!isEmpty(dealOfTheDay?.data) && Object.prototype.hasOwnProperty.call(dealOfTheDay.data, "500") && dealOfTheDay.data["500"]?.length > 0 && DealOfTheDay(convertToLiters(500).toString(), "#FFFFFF", "#FFFDE8", dealOfTheDay.data["500"])}
      {!isEmpty(dealOfTheDay?.data) && Object.prototype.hasOwnProperty.call(dealOfTheDay.data, "250") && dealOfTheDay.data["250"]?.length > 0 && DealOfTheDay(convertToLiters(250).toString(), "#F1F8FF", "#FFFFFF", dealOfTheDay.data["250"])}
      {/* {!isEmpty(dealOfTheDay?.data) && Object.keys(dealOfTheDay?.data).map((key, index) => {
        if (!isEmpty(dealOfTheDay.data[key]) && dealOfTheDay.data[key].length > 0) {
          return ((index + 1) % 2 === 0 ? DealOfTheDay(convertToLiters(key).toString(), "#F1F8FF", "#FFFFFF", dealOfTheDay.data[key]) : DealOfTheDay(convertToLiters(key).toString(), "#FFFFFF", "#FFFDE8", dealOfTheDay.data[key]));
        } else {
          return <></>
        }
      })} */}
      {/* {DealOfTheDay("1L", "#F1F8FF", "#FFFFFF")}
      {DealOfTheDay("250ML", "#F1F8FF", "#FFFFFF")} */}

      {deliveryOptions}
      {/* {promptSeller} */}
      <RefferSection />
    </>
  );
}



export default Home;
