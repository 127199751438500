import React, { useEffect, useState } from "react";
import { Row, Col, Button, Accordion } from "react-bootstrap";

import "react-image-gallery/styles/css/image-gallery.css";

import "./cart.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FilterObjectsByKeyValue from "../../utils/FilterObjectsByKeyValue";
import OrderSummaryCard from "./OrderSummaryCard";
import { appConstants } from "../../helpers/app-constants";
import isEmpty from "../../utils/is-Empty";

const PaymentOrderSummary = ({ title, index }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { cart } = useSelector((state) => {
        return state;
    });

    const [OrderSummary, setOrderSummary] = useState({ deliveryCharges: 0.0, totalAmount: 0.00, payableAmount: 0.00 })

    useEffect(() => {

        let updatedObj = {};
        let totalAmount = 0.0;
        let totalDeliveryCharges = 0.0;
        let items = []
        cart?.data[title].map((item) => {
            // totalAmount = (((parseFloat(item.moq) * parseFloat(item.price)) + parseFloat(totalAmount)).toFixed(2));
            let currentTempObj = {} // card , moq , price , offerPrice ,
            currentTempObj["item"] = item
            currentTempObj["price"] = item?.price;
            currentTempObj["moq"] = item?.moq;
            if (!isEmpty(item?.offers) && item?.offers?.length > 0 && !isEmpty(FilterObjectsByKeyValue(item?.offers, "quantity", parseInt(item?.moq)))) {
                currentTempObj["offerPrice"] = FilterObjectsByKeyValue(item?.offers, "quantity", parseInt(item?.moq))[0]?.offerPrice;
                totalAmount = parseFloat(totalAmount) + (parseFloat(FilterObjectsByKeyValue(item?.offers, "quantity", parseInt(item?.moq))[0]?.offerPrice) * parseInt(item?.moq))
            } else {
                currentTempObj["offerPrice"] = "";
                totalAmount = parseFloat(totalAmount) + (parseFloat(item?.price) * parseInt(item?.moq));
            }
            if (!isEmpty(FilterObjectsByKeyValue(item?.delivery_types_id, "name", "Standard"))) {
                currentTempObj["deliveryCharge"] = (parseFloat(item?.sellerDeliveryCharge) * parseInt(item?.moq))
                totalDeliveryCharges = parseFloat(totalDeliveryCharges) + (parseFloat(item?.sellerDeliveryCharge) * parseInt(item?.moq))
            } else {
                currentTempObj["deliveryCharge"] = (parseFloat(item?.bhareeDeliveryCharge) * parseInt(item?.moq))
                totalDeliveryCharges = parseFloat(totalDeliveryCharges) + (parseFloat(item?.bhareeDeliveryCharge) * parseInt(item?.moq))
            }
            items.push(currentTempObj);
            console.log("*****call ", currentTempObj)

            // addObjectToArray(currentTempObj)
        })
        updatedObj["items"] = items;
        // console.log("******* items", items);
        updatedObj["totalAmount"] = totalAmount;
        updatedObj["paymentType"] = title;
        updatedObj["deliveryCharges"] = totalDeliveryCharges;
        updatedObj["payableAmount"] = (parseFloat(totalAmount) + parseFloat(totalDeliveryCharges)).toFixed(2);
        setOrderSummary((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    }, [])


    const processToCheckOut = () => {
        dispatch({
            type: appConstants.GET_CHECKOUT_ITEMS,
            payload: OrderSummary,
        });
        navigate(`/checkout`);

    }
    const SummaryBox = () => {
        return (
            <>

                <Row className="price-box mx-0 my-2">
                    <Col xs={4}></Col>
                    <Col xs={5} className="title p-0">
                        {"Subtotal:"}
                    </Col>
                    <Col xs={3} className="amount p-0">
                        {parseFloat(OrderSummary?.totalAmount).toFixed(2)}&nbsp;{"₹"}
                    </Col>
                </Row>
                <Row className="price-box mx-0 my-2">
                    <Col xs={4}></Col>
                    <Col xs={5} className="title p-0">
                        {"Delivery Charges:"}
                    </Col>
                    <Col xs={3} className="amount p-0">
                        {parseFloat(OrderSummary.deliveryCharges).toFixed(2)}&nbsp;{"₹"}
                    </Col>
                </Row>
                <Row className="price-box mx-0 my-2">
                    <Col xs={4}></Col>
                    <Col xs={8} className="title p-0">
                        <hr className="mx-0 cutomeHR" />
                    </Col>
                </Row>
                <Row className="price-box mx-0 my-2">
                    <Col xs={4}></Col>
                    <Col xs={5} className="amount p-0">
                        {"Payable amount"}
                    </Col>
                    <Col xs={3} className="amount p-0">
                        {parseFloat(OrderSummary?.payableAmount).toFixed(2)}&nbsp;{"₹"}
                    </Col>
                </Row>
                <div className="text-uppercase button-div mx-0 my-4">
                    {/* <Link to={`/checkout`} > */}
                    <Button className="text-uppercase" onClick={processToCheckOut}>
                        {"Proceed to checkout"}
                    </Button>
                    {/* </Link> */}
                </div>
            </>
        )
    }


    return (<>
        <Accordion
            defaultActiveKey="0"
            className="cart-order-summary-section container mobile"
            key={index+"accordin"}
        >
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className="order-section-title-div">
                        <h4 className="order-section-title m-0">{title}</h4>
                        <div id="borderLeft"></div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Row className="m-0 my-4">
                        <Col xs={12} sm={12} md={8} lg={8} className="p-0">
                            {cart?.data[title].map((item) => {
                                return <OrderSummaryCard card={item} />;
                            })}

                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="order-summery-price p-0"
                        >
                            <p className="mb-3">{"Order Summary"}</p>
                            {SummaryBox()}

                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <div className="cart-order-summary-section  desktop" key={index}>
            <div className="order-section-title-div">
                <h4 className="order-section-title m-0">{title}</h4>
                <p className="m-0">{"Order Summary"}</p>
            </div>
            <div id="borderLeft"></div>

            <hr />
            <Row className="m-0 my-4">

                <Col xs={12} sm={12} md={8} lg={8} className="p-0">
                    {cart?.data[title].map((item) => {
                        // console.log("***** ITEM COPY", item)

                        return <OrderSummaryCard card={item} />;
                    })}

                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="order-summery-price p-0"
                >
                    {SummaryBox()}
                </Col>
            </Row>
        </div>
    </>)
}

export default PaymentOrderSummary
