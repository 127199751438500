import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    deleteAddress,
    getApi,
    getGeoData,
    patchAddress,
    postAddresses,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { DeleteIcon, EditIcon } from "../../../../assets/svg/svg-icons";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import Spinner from "../../../common/Spinner";
import FilterObjectsByKeyValue from "../../../../utils/FilterObjectsByKeyValue";
import inputValidation from "../../../../utils/inputValidation";

const Address = () => {
    const dispatch = useDispatch();
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [erroeMsg, setErroeMsg] = useState({});
    const [currentUpdateId, setCurrentUpdateId] = useState("");
    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, auth, bhareeAdminBasicTable, addresses, countries, countryStates, countryStateCities } = useSelector(
        (state) => {
            return state;
        }
    );
    useEffect(() => {
        // getGeoData(dispatch);
        onSelectValue("state", FilterObjectsByKeyValue(countryStates?.data, "name", "Maharashtra")[0].name);
        onSelectValue("country", FilterObjectsByKeyValue(countries?.data, "name", "India")[0].name);
        getApi("getAddressess", dispatch);
    }, [])
    useEffect(() => {
        setIsCreateMode(false)
        setIsUpdateMode(false)
        if (isEmpty(addresses?.data) && addresses?.data?.length < 1) {
            setIsCreateMode(true)
        }
    }, [addresses])
    // useEffect(() => {
    //     if (!isEmpty(countries?.data) && countries?.data.length > 0) {
    //         onSelectValue("country", FilterObjectsByKeyValue(countries?.data, "name", "India")[0].name);
    //         getGeoData(dispatch, FilterObjectsByKeyValue(countries?.data, "name", "India")[0].isoCode);
    //     }
    // }, [countries.data]);
    // useEffect(() => {
    //     if (!isEmpty(uploadFormObj?.country) && !isEmpty(countries?.data)) {
    //         getGeoData(dispatch, FilterObjectsByKeyValue(countries?.data, "name", uploadFormObj?.country)[0].isoCode);
    //     }

    // }, [uploadFormObj.country])
    useEffect(() => {
        if (!isEmpty(uploadFormObj?.state) && !isEmpty(countries?.data) && !isEmpty(countryStates?.data)) {
            getGeoData(
                dispatch,
                FilterObjectsByKeyValue(countries.data, "name", uploadFormObj?.country)[0].isoCode, FilterObjectsByKeyValue(countryStates.data, "name", uploadFormObj?.state)[0].isoCode
            );
        }
    }, [uploadFormObj.state])

    const onSelectValue = (key, value) => {
        // console.log("*** change ", key, value)
        let updatedObj = {};
        updatedObj[key] = value;

        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };

    const onSelectValueValidation = (key, value, maxLenght = 1000, numericOnly = false) => {
        setErroeMsg({});
        let updatedObj = {};
        let validate = inputValidation(value, maxLenght, numericOnly);
        if (validate.pass) {
            updatedObj[key] = validate.finalValue;
            setErroeMsg({ [key]: validate.msg })
            setUploadFormObj((previousVlaues) => ({
                ...previousVlaues,
                ...updatedObj,
            }));
        } else {
            setErroeMsg({ [key]: validate.msg })
        }

    };
    const createUpdateAddress = (e) => {
        e.preventDefault();
        onSelectValue("user_id", auth.user.id)
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "full_name") &&
            !isEmpty(uploadFormObj.full_name) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "city") &&
            !isEmpty(uploadFormObj.city) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "state") &&
            !isEmpty(uploadFormObj.state) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "pin_code") &&
            !isEmpty(uploadFormObj.pin_code) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "address_line_1"
            ) &&
            !isEmpty(uploadFormObj.address_line_1) &&
            Object.prototype.hasOwnProperty.call(
                uploadFormObj,
                "address_type_id"
            ) &&
            !isEmpty(uploadFormObj.address_type_id)
        ) {

            // console.log("**** address ", uploadFormObj)
            if (isUpdateMode) {
                patchAddress(currentUpdateId, uploadFormObj, dispatch);
            } else {

                postAddresses(uploadFormObj, dispatch);
            }
        }
    };


    const onSetUpdateObj = async (address) => {
        // console.log("********** 3");
        // await getGeoData(dispatch, FilterObjectsByKeyValue(countries?.data, "name", address?.country)[0].isoCode);
        await getGeoData(
            dispatch,
            FilterObjectsByKeyValue(countries.data, "name", address?.country)[0].isoCode, FilterObjectsByKeyValue(countryStates.data, "name", address?.state)[0].isoCode
        );
        setUploadFormObj(address);
        setIsCreateMode(true);
        setIsUpdateMode(true);
        setCurrentUpdateId(address?.id);
    }

    const addressForm = (
        <>

            <Row className="m-0 form-row ">
                <Row className="m-0 px-0 w-full px-0  title-button-row">
                    <h2>{!isUpdateMode ? "Create New Address" : "Update Address"}</h2>
                    {addresses?.data?.length > 0 && (<CustomButton
                        buttonClasses="view-switch-btn"
                        onClick={() => { setIsCreateMode(false); setUploadFormObj({}); setErroeMsg({}) }}
                    >
                        {"List"}
                    </CustomButton>)}

                </Row >
                <Form className=" modal-custom-class" onSubmit={createUpdateAddress}>
                    <Row className="m-0">
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Full name"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.full_name}
                                    // onChange={(e) =>
                                    //     onSelectValue("full_name", e.target.value)
                                    // }
                                    onChange={(e) => onSelectValueValidation("full_name", e.target.value, 50)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "full_name") && <p className="error-msg">{erroeMsg.full_name}</p>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Primary number"}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.primary_number || ""}
                                    // onChange={(e) =>
                                    //     onSelectValue("primary_number", e.target.value)
                                    // }
                                    onChange={(e) => onSelectValueValidation("primary_number", e.target.value, 10, true)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "primary_number") && <p className="error-msg">{erroeMsg.primary_number}</p>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Alternate number"}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={uploadFormObj?.alternate_number || ""}
                                    onChange={(e) => onSelectValueValidation("alternate_number", e.target.value, 10, true)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "alternate_number") && <p className="error-msg">{erroeMsg.alternate_number}</p>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            {!bhareeAdminBasicTable.loading && !isEmpty(bhareeAdminBasicTable?.data?.addressTypes) && bhareeAdminBasicTable?.data?.addressTypes?.length > 0 && (<Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Address Type"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>   

                                <CustomSelectDropdown
                                    style={{
                                        chips: {
                                            margin: 0,
                                        },
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={bhareeAdminBasicTable?.data?.addressTypes}
                                    onSelect={(e) => onSelectValue("address_type_id", e[0])}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            uploadFormObj,
                                            "address_type_id"
                                        )
                                            ? [uploadFormObj?.address_type_id]
                                            : []
                                    }
                                    displayValue={"type_name"}
                                    disable={isUpdateMode ? true : false}
                                    singleSelect
                                />
                            </Form.Group>)}
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Address Line 1"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.address_line_1 || ""}
                                    onChange={(e) => onSelectValueValidation("address_line_1", e.target.value, 100)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "address_line_1") && <p className="error-msg">{erroeMsg.address_line_1}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Address Line 2"}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={uploadFormObj?.address_line_2 || ""}
                                    onChange={(e) => onSelectValueValidation("address_line_2", e.target.value, 100)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "address_line_2") && <p className="error-msg">{erroeMsg.address_line_2}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Address Line 3"}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={uploadFormObj?.address_line_3 || ""}
                                    onChange={(e) => onSelectValueValidation("address_line_3", e.target.value, 100)}
                                />
                                {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "address_line_3") && <p className="error-msg">{erroeMsg.address_line_3}</p>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Country"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                {!isEmpty(countries?.data) && countries.data?.length > 0 && (<CustomSelectDropdown
                                    style={{
                                        chips: {
                                            margin: 0,
                                        },
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={countries?.data}
                                    onSelect={(e) => onSelectValue("country", e[0].name)}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            uploadFormObj,
                                            "country"
                                        )
                                            ? FilterObjectsByKeyValue(countries.data, "name", uploadFormObj?.country)
                                            : []
                                    }
                                    displayValue={"name"}
                                    disable
                                    singleSelect
                                />)}

                                {/* <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.country}
                                    onChange={(e) =>
                                        onSelectValue("country", e.target.value)
                                    }
                                /> */}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"State"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.state}
                                    onChange={(e) =>
                                        onSelectValue("state", e.target.value)
                                    }
                                /> */}

                                <CustomSelectDropdown
                                    style={{
                                        chips: {
                                            margin: 0,
                                        },
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}

                                    options={!isEmpty(countryStates?.data) && countryStates.data?.length > 0 ? countryStates?.data : [{ name: "Please select country" }]}
                                    onSelect={(e) => onSelectValue("state", e[0].name)}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            uploadFormObj,
                                            "state"
                                        )
                                            ? FilterObjectsByKeyValue(countryStates?.data, "name", uploadFormObj?.state)
                                            : []
                                    }
                                    displayValue={"name"}
                                    disable
                                    singleSelect
                                // selectionLimit={1}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"City"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            background: "#fbb040",
                                            margin: 0,
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}

                                    options={!isEmpty(countryStateCities?.data) && countryStateCities.data?.length > 0 ? countryStateCities?.data : [{ name: "Please select state" }]}
                                    onSelect={(e) => onSelectValue("city", e[0].name)}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            uploadFormObj,
                                            "city"
                                        )
                                            ? FilterObjectsByKeyValue(countryStateCities?.data, "name", uploadFormObj?.city)
                                            : []
                                    }
                                    displayValue={"name"}
                                    disable={isUpdateMode ? true : false}
                                    // singleSelect
                                    selectionLimit={1}
                                />
                            </Form.Group>
                        </Col>



                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Pin code"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    value={uploadFormObj?.pin_code || ""}
                                    // onChange={(e) =>
                                    //     onSelectValue("pin_code", e.target.value)
                                    // }
                                    disabled={isUpdateMode ? true : false}
                                    onChange={(e) => onSelectValueValidation("pin_code", e.target.value, 6, true)}
                                />
                                {/* {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "pin_code") && <p className="error-msg">{erroeMsg.pin_code}</p>} */}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                            <Form.Group className="mt-3" controlId="formBasicEmail">
                                <Form.Check
                                    type="checkbox"
                                    name="radio_type"
                                    checked={Object.prototype.hasOwnProperty.call(
                                        uploadFormObj,
                                        "set_default"
                                    )
                                        ? uploadFormObj?.set_default
                                        : false}
                                    label={"Set default"}
                                    className="square-checkbox mx-2"
                                    onChange={(e) =>
                                        onSelectValue("set_default", Object.prototype.hasOwnProperty.call(uploadFormObj, "set_default") ? !uploadFormObj.set_default : true)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    <div className="action-buttons">
                        {!addresses?.data?.length < 1 && (<CustomButton
                            buttonClasses="close-btn"
                            onClick={() => setIsCreateMode(false)}
                            disabled={requestCall?.loading}
                        >
                            {"Close"}
                        </CustomButton>)}

                        <CustomButton
                            variant="primary"
                            buttonClasses={
                                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                            }
                            type="submit"
                            loading={requestCall?.loading}
                        >
                            {!isUpdateMode ? "create" : "update"}
                        </CustomButton>
                    </div>
                </Form>
            </Row>

        </>
    )

    return (
        <Container className="p-0 mb-3">
            {(() => {
                if (!isCreateMode) {
                    if (addresses?.data?.length > 0 && !addresses?.loading) {
                        return (<Row className="address m-0" >
                            <Row className="heading-div ">
                                <h5 className="text-uppercase">{"Address"}</h5>
                                <p style={{ cursor: "pointer" }} onClick={() => { setIsCreateMode(true); setIsUpdateMode(false); setUploadFormObj({}); setErroeMsg({}); onSelectValue("country", FilterObjectsByKeyValue(countries?.data, "name", "India")[0].name); onSelectValue("state", FilterObjectsByKeyValue(countryStates?.data, "name", "Maharashtra")[0].name); }}>{"+ Add"}</p>
                            </Row>
                            <hr />
                            {addresses?.data.map((address) => {
                                return <Row className="m-0 p-0">
                                    <Col xs={10} className="form-col left">
                                        <h6 className="text-uppercase">{address.address_type_id.type_name}</h6>
                                        <p>
                                            <span>{"Full Name:"}</span>
                                            <span className="mx-2 bold-span"> {address.full_name}</span>
                                        </p>
                                        <p>
                                            {address.address_line_1}{","}{address.address_line_2}{","}{address.address_line_3}
                                        </p>
                                        <p>
                                            <span>{"Primary number:"}</span>
                                            <span className="mx-2 bold-span">{address.primary_number}</span>
                                            {!isEmpty(address.alternate_number) ? (<>
                                                {"|| "}
                                                <span>{"Alternate number: "}</span>
                                                <span className="mx-2 bold-span">{address.alternate_number}</span>
                                            </>) : null}
                                        </p>


                                        <p>
                                            <span>{"City:"}</span>
                                            <span className="mx-2 bold-span"> {address.city}</span>
                                            <span>{"State:"}</span>
                                            <span className="mx-2 bold-span">{address.state}</span>
                                            <span>{"Country:"}</span>
                                            <span className="mx-2 bold-span">{address.country}</span>
                                        </p>

                                    </Col>
                                    <Col xs={2} className="form-col right">
                                        <div className="action-button-box">
                                            {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                                            <CustomButton
                                                buttonClasses="icon-action-btn"
                                                onClick={() => {
                                                    // setIsCreateMode(true);
                                                    // setIsUpdateMode(true)
                                                    // setCurrentUpdateId(address?.id);
                                                    // setUploadFormObj(address);
                                                    onSetUpdateObj(address);
                                                }}
                                            >
                                                <EditIcon />
                                            </CustomButton>
                                            <CustomButton
                                                buttonClasses="icon-action-btn"
                                                onClick={() => deleteAddress(address, dispatch)}
                                            >
                                                <DeleteIcon />
                                            </CustomButton>
                                        </div>

                                    </Col>
                                </Row>
                            })}
                        </Row>)


                    } else if (addresses?.data?.length < 1 && !addresses?.loading) {
                        return addressForm
                    } else if (addresses?.loading) {
                        return <Spinner />;
                    }

                } else if (isCreateMode) {
                    return addressForm
                }
            })()}

        </Container >
    );
};



export default Address;
