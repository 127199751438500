import React, { useEffect, useState } from "react";
import { Row, Col, Image, Button, OverlayTrigger, Popover, Container, FormLabel, FormGroup, Card } from "react-bootstrap";
import { EditIcon, CrossIcon } from "../../assets/svg/svg-icons";
import plastic1 from "../../assets/images/plastic-1.png";
import "react-image-gallery/styles/css/image-gallery.css";
import "./cart.css";
import { useSelector, useDispatch } from "react-redux";
import { deleteCartItem, patchCart } from "../../actions/structure.action";
import { useNavigate } from "react-router-dom";
import convertToLiters from "../../utils/convertToLiters";
import CustomButton from "../common/CustomButton";
import CustomSelectDropdown from "../common/CustomSelectDropdown";
import isEmpty from "../../utils/is-Empty";
import FilterObjectsByKeyValue from "../../utils/FilterObjectsByKeyValue";
const OrderSummaryCard = ({ card, crud = true, currentOrderView = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState({});
    const { requestCall } = useSelector((state) => {
        return state;
    });
    const [currentObject, setCurrentObject] = useState({});

    const [uploadFormObj, setUploadFormObj] = useState({});

    const [cardImage, setCardImage] = ("");


    useEffect(() => {
        let currentTempObj = {} // card , moq , price , offerPrice ,
        currentTempObj["id"] = card.id  //main 
        currentTempObj["item"] = card  //main 
        currentTempObj["price"] = card?.price; // main
        currentTempObj["moq"] = card?.moq; // main 
        if (!currentOrderView) {
            card.productImages?.length > 0 ? currentTempObj["cardImage"] = card?.productImages[0].url : currentTempObj["cardImage"] = plastic1;
            currentTempObj["product_variant_id"] = card?.product_variant_id;
            currentTempObj["brand_name"] = card?.brand_name;
            currentTempObj["product_category"] = card?.product_category;
            currentTempObj["variant"] = card?.variant;
            currentTempObj["delivery_types_name"] = card.delivery_types_id[0].name;
            currentTempObj["delivery_types_subtitle"] = card.delivery_types_id[0].subTitle;

            // main
            if (!isEmpty(card?.offers) && card?.offers?.length > 0) {
                currentTempObj["offerPrice"] = FilterObjectsByKeyValue(card?.offers, "quantity", parseInt(card?.moq))[0]?.offerPrice;
            } else {
                currentTempObj["offerPrice"] = "";
            }
        } else {
            currentTempObj["offerPrice"] = card?.offerPrice;
            currentTempObj["cardImage"] = card?.product_variant_id[0]?.productImages[0].url;
            currentTempObj["product_variant_id"] = card?.product_variant_id[0]?.id;


            currentTempObj["brand_name"] = card?.product_variant_id[0]?.brand_name;
            currentTempObj["product_category"] = card?.product_variant_id[0]?.product_name;
            
            currentTempObj["variant"] =  card?.product_variant_id[0]?.quantity;
            currentTempObj["delivery_types_name"] = card.delivery_method_id[0].name;
            currentTempObj["delivery_types_subtitle"] = card.delivery_method_id[0].subTitle;
            // setCardImage(card?.product_variant_id[0].productImages[0].url)
        }
        setCurrentObject(currentTempObj);
        // console.log("*****call ",  currentTempObj)
        // addObjectToArray(currentTempObj)
    }, []);


    const handleClick = (e, value) => {
        navigate(`/product/${value}`);
    }
    const closePopeover = () => {
        setOpenModal({});
    }
    const onClickEdit = (cart) => {
        onSelectValue("selected_delivery", cart.delivery_types_id[0]);
        onSelectValue("selected_moq", FilterObjectsByKeyValue(cart.moq_id, "quantity", parseInt(cart.moq))[0]);
        onSelectValue("id", cart.id);
        setOpenModal({ id: cart.id, value: true })
    }
    const updateCart = () => {
        let postData = {}
        // console.log("************ data", FilterObjectsByKeyValue(data.delivery_types, "name", "Bharee"))
        postData["id"] = uploadFormObj?.id;
        postData["moq"] = uploadFormObj?.selected_moq.quantity;
        postData["delivery_types_id"] = uploadFormObj?.selected_delivery.id;
        patchCart(postData, dispatch)

    }
    const onSelectValue = (key, value) => {

        let updatedObj = {};
        updatedObj[key] = value;
        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };
    // useEffect(() => {
    //     console.log("*****currentObject", currentObject)
    // }, [currentObject]);
    return (
        <Row className="m-0 order-summery-card mb-4" key={card.id} >
            <Col xs={3} sm={3} md={2} className="image-col" style={{ cursor: "pointer" }} onClick={(e) => handleClick(e, currentObject?.product_variant_id)}>
                <Image

                    src={currentObject.cardImage}
                    alt="plastic1"
                />
            </Col>
            <Col xs={9} sm={9} md={10} className="description-col">
                <div className="summary-card-title">
                    <h5 onClick={(e) => handleClick(e, currentObject?.product_variant_id)} style={{ cursor: "pointer" }}>
                        {(() => {
                            if (!isEmpty(currentObject?.offerPrice)) {
                                return <>{currentObject?.brand_name}&nbsp;{currentObject?.product_category}&nbsp;{"|"}&nbsp;{"Rs."}{currentObject?.offerPrice}{"/case"} <s>{`Rs.${currentObject?.price}/`}
                                    <span>{"case"}</span>
                                </s></>
                            } else {
                                return <>
                                    {currentObject?.brand_name}&nbsp;{currentObject?.product_category}&nbsp;{"|"}&nbsp;{"Rs."}{currentObject.price}{"/case"}
                                </>
                            }
                        })()}
                    </h5>
                    {crud && !currentOrderView && (<>
                        <OverlayTrigger id={`overlay_${card?.id}`} trigger="click" placement="top" show={!isEmpty(openModal) && openModal?.id === card.id ? openModal?.value : false} overlay={
                            <Popover id={`popover_${card?.id}`} className="card-popper " >
                                <Popover.Header as="h3">{"Update Item "}</Popover.Header>
                                <Popover.Body>
                                    <Container className="card-popper-body-conatiner modal-custom-class">
                                        {card?.offers?.length > 0 && (
                                            <Row className=" m-0">
                                                <p className="m-0 p-0 offer-heading"> {"Available offers"}</p>
                                                <ul className="px-3">
                                                    {card?.offers.map((offer) => {
                                                        return <li>{`For`}<strong>{` ${offer?.quantity}`}</strong>{` MOQ's Offer Price `}<strong>{`${offer?.offerPrice}/case`}</strong></li>
                                                    })}
                                                </ul>
                                            </Row>
                                        )}
                                        <Row className="m-0">
                                            <FormGroup className="mb-2">
                                                <FormLabel>
                                                    {"MOQ"}
                                                    {/* <span className="redStreSpan">{"*"}</span> */}
                                                </FormLabel>
                                                <CustomSelectDropdown
                                                    style={{
                                                        chips: {
                                                            margin: 0,
                                                        },
                                                        multiselectContainer: {
                                                            backgroundColor: "#f8f8f8",
                                                        },
                                                        option: {
                                                            background: "#fbb040",
                                                            color: "#FFFFFF",
                                                            borderBottom: "1px solid #FFFFFF",
                                                        },
                                                    }}
                                                    options={card?.moq_id}
                                                    onSelect={(e) => onSelectValue("selected_moq", e[0])
                                                    }
                                                    selectedValues={
                                                        Object.prototype.hasOwnProperty.call(
                                                            uploadFormObj,
                                                            "selected_moq"
                                                        )
                                                            ? [uploadFormObj?.selected_moq]
                                                            : []
                                                    }
                                                    displayValue={"quantity"}
                                                    singleSelect
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>
                                                    {"Delivery"}
                                                    {/* <span className="redStreSpan">{"*"}</span> */}
                                                </FormLabel>
                                                <CustomSelectDropdown
                                                    style={{
                                                        chips: {
                                                            margin: 0,
                                                        },
                                                        multiselectContainer: {
                                                            backgroundColor: "#f8f8f8",
                                                        },
                                                        option: {
                                                            background: "#fbb040",
                                                            color: "#FFFFFF",
                                                            borderBottom: "1px solid #FFFFFF",
                                                        },
                                                    }}
                                                    options={card?.delivery_types}
                                                    onSelect={(e) => onSelectValue("selected_delivery", e[0])
                                                    }
                                                    selectedValues={
                                                        Object.prototype.hasOwnProperty.call(
                                                            uploadFormObj,
                                                            "selected_delivery"
                                                        )
                                                            ? [uploadFormObj?.selected_delivery]
                                                            : []
                                                    }
                                                    displayValue={"name"}
                                                    singleSelect
                                                />
                                            </FormGroup>
                                        </Row>
                                        <div className="action-buttons">
                                            <CustomButton
                                                buttonClasses="close-btn"
                                                onClick={() => closePopeover()}
                                                disabled={requestCall?.loading}
                                            >
                                                {"Close"}
                                            </CustomButton>

                                            <CustomButton
                                                variant="primary"
                                                buttonClasses={
                                                    !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                                                }
                                                onClick={updateCart}
                                                // type="submit"
                                                loading={requestCall?.loading}
                                            >
                                                {"update"}
                                            </CustomButton>
                                        </div>
                                    </Container>
                                </Popover.Body>
                            </Popover>
                        }>
                            <Button className="edit-item" onClick={() => onClickEdit(card)}>
                                <EditIcon />
                            </Button>
                        </OverlayTrigger>
                        <CustomButton className="delete-item" onClick={() => deleteCartItem(card, dispatch)}>
                            <CrossIcon />
                        </CustomButton></>)
                    }
                    {/*  oarder status  */}




                </div>
                <hr style={{ width: "75%" }} />
                <div className="summary-card-details">
                    <Row className="m-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            className="summary-card-details-col"
                        >
                            <Row className="summary-card-qnt">
                                <Col
                                    xs={4}
                                    sm={2}
                                    md={2}
                                    className="summary-card-item"
                                >
                                    <p>{"Size"}</p>
                                    <h6>{convertToLiters(currentObject.variant)}</h6>
                                </Col>
                                <Col
                                    xs={4}
                                    sm={2}
                                    md={2}
                                    className="summary-card-item"
                                >
                                    <p>{"MOQ"}</p>
                                    <h6>{currentObject.moq}&nbsp;{"Case"}</h6>
                                </Col>
                                <Col
                                    xs={4}
                                    sm={2}
                                    md={3}
                                    className="summary-card-item"
                                >
                                    <p>{"Delivery"}</p>
                                    <h6>{currentObject.delivery_types_name} - {currentObject?.delivery_types_subtitle}</h6>
                                </Col>
                                <Col
                                    xs={4}
                                    sm={2}
                                    md={2}
                                    className="summary-card-item"
                                >
                                    <p>{"Amount"}</p>
                                    {(() => {
                                        if (!isEmpty(currentObject?.offerPrice)) {
                                            return <h6 className="amount strong">{"₹"}{(parseFloat(currentObject.moq) * parseFloat(currentObject.offerPrice)).toFixed(2)}</h6>
                                        } else {
                                            return <h6 className="amount strong">{"₹"}{(parseFloat(currentObject.moq) * parseFloat(currentObject.price)).toFixed(2)}</h6>
                                        }
                                    })()}
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default OrderSummaryCard