import { appConstants } from "../helpers/app-constants";

const intialState = {
  loading: false,
  error: {},
};

export function requestCall(state = intialState, action) {
  switch (action.type) {
    case appConstants.REQUEST_CALL:
      return {
        loading: true,
        errors: {},
      };
    case appConstants.REQUEST_COMPLETED:
      return {
        loading: false,
        errors: {},
      };

    case appConstants.REQUEST_FAIL:
      return {
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
