import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import crypto from 'crypto-js';
import Axios from 'axios';
import { appConstants } from '../../helpers/app-constants';
import { BHAREE_BASE_URL_LOCAL } from '../../config/config.url';
import { getApi } from '../../actions/structure.action';
import { toast } from 'react-toastify';

// Function to load script and append in DOM tree.
const loadScript = () => {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('razorpay-script');
        if (existingScript) {
            resolve(existingScript); // If script already exists, resolve it
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.id = 'razorpay-script';
        script.onload = () => {
            console.log('Razorpay SDK loaded successfully');
            resolve(script);
        };
        script.onerror = () => {
            console.log('Failed to load Razorpay SDK');
            reject(new Error('Razorpay SDK loading failed.'));
        };
        document.body.appendChild(script);
    });
};

// Function to remove the Razorpay script
const removeScript = () => {
    const scriptElement = document.getElementById('razorpay-script');
    const divElements = document.getElementsByClassName('razorpay-container');
    if (scriptElement) {
        scriptElement.remove();
        console.log('Razorpay script removed from DOM');
    }
    // Remove all elements with the class 'razorpay-container'
    while (divElements?.length > 0) {
        divElements[0].remove();
        console.log('Razorpay container removed from DOM');
    }
};


const Razorpay = () => {
    const paymentId = useRef(null);
    const paymentMethod = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { payment, auth } = useSelector((state) => {
        return state;
    });

    // Display Razorpay modal
    const displayRazorpay = async (options) => {
        await loadScript(); // Load the Razorpay script
        const rzp1 = new window.Razorpay(options);

        // RUNS ONLY WHEN PAYMENT FAILS
        rzp1.on('payment.failed', (response) => {
            paymentId.current = response.error.metadata.payment_id;
            handlePayment('failure', {
                orderId: response.error.metadata.order_id,
                paymentId: response.error.metadata.payment_id,
                description: response.error.description,
                reason: response.error.reason
            });
        });
        rzp1.open();
    };

    // informing server about payment
    const handlePayment = async (status, orderDetails = {}) => {
        console.log(status, orderDetails)
        const response = await Axios.post(`${BHAREE_BASE_URL_LOCAL}/orders/verify-payment`,
            { status, ...orderDetails },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${localStorage.jwtToken}`
                }
            }
        );

        // Redirect user based on payment status
        if (status === 'success') {
            dispatch({
                type: appConstants.CLEAR_PAYMENT,
            })
            toast.success("Order placed successfully!");
            getApi("getCart", dispatch);
            navigate(`/account`);
        } else if (status === 'failure') {
            dispatch({
                type: appConstants.CLEAR_PAYMENT,
            })
            navigate(`/account`);
        } else {
            dispatch({
                type: appConstants.CLEAR_PAYMENT,
            })
            navigate(`/cart`);
        }
        removeScript(); // Remove script after handling payment
    };

    const options = {
        key: process.env.REACT_APP_RZRPAY_KEY_ID, // key id from props
        amount: payment?.data?.razorpayOrder?.amount, // Amount in lowest denomination from props
        currency: payment?.data?.razorpayOrder?.currency, // Currency from props.
        name: 'Bharee', // Title for your organization to display in checkout modal
        image: 'https://yt3.googleusercontent.com/tlibvoM4-Y7LCwz0P4boxdPYri3r9hKEKC1nR6zSXX5fX1XQWtEd2_b3yxq4KHeNorsVi-7TZg=s160-c-k-c0x00ffffff-no-rj',
        order_id: payment?.data?.razorpayOrder?.order_id, // order id from props~
        prefill: {
            name: auth?.user?.contact_person_name, // Logged-in user name
            email: auth?.user?.email, // Logged-in user email
            contact: auth?.user?.mobile_number // Prefill user's phone number
        },
        config: {
            display: {
                hide: [
                    { method: 'paylater' },
                    { method: 'emi' },
                    { method: 'card' },
                    { method: 'netbanking' },
                    { method: 'wallet' },
                ],
                preferences: { show_default_blocks: true },
                widget: {
                    main:
                    {
                        "isDarkMode": true //default is false      
                    }
                }
            }
        },
        // This handler menthod is always executed in case of succeeded payment (RUNS ONLY WHEN PAYMENT SUCCESSFUL)
        handler: (response) => {
            paymentId.current = response.razorpay_payment_id;
            handlePayment('success', {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                description: "",
                reason: ""
            });
        },
        modal: {
            confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
            // This function is executed when checkout modal is closed
            // There can be 3 reasons when this modal is closed.
            ondismiss: async (reason) => {
                const {
                    reason: paymentReason, field, step, code,
                } = reason && reason.error ? reason.error : {};
                // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
                if (reason === undefined) {
                    console.log('cancelled');
                    handlePayment('cancelled');
                }
                // Reason 2 - When modal is auto closed because of time out
                else if (reason === 'timeout') {
                    console.log('timedout');
                    handlePayment('timedout');
                }
                // Reason 3 - When payment gets failed.
                else {
                    console.log('failure');
                    handlePayment('failure', {
                        paymentReason, field, step, code,
                    });
                }
                removeScript();
            },
        },
        theme: {
            color: "#fbb040",  // Set this to your brand's primary color
        },
        // This property allows to enble/disable retries.
        // This is enabled true by default. 
        retry: {
            enabled: false,
        },
        timeout: 900, // Time limit in Seconds

    };

    useEffect(() => {
        console.log('in razorpay');
        displayRazorpay(options);

        // Cleanup function to remove the script if the component unmounts
        return () => {
            removeScript();
        };
    }, []);

    return null;
};

export default Razorpay;