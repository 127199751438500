export const monthlyExpenceObj = {
    preformCost: {
        amount: 0,
        gmr: 0,
        kg: 0,
        qty: 0,
        perPrice: 0
    },
    boxCost: {
        numberOfBoxes: 0,
        bottles: 0,
        rate: 0,
        perBottle: 0,

    },
    totalProduction: {
        electricity: 0,
        wages: 0,
        transportation: 0,
        miscExps: 0,
        laonInt: 0,
        totalNoBoxes: 0,
        totalOfBottles: 0,
    },
    perBottleCost: {
        electricity: 0,
        wages: 0,
        transportation: 0,
        miscExps: 0,
        laonInt: 0,
        lable: 0,
        cap: 0,
        ink: 0,
        rejection: 0,
        total: 0
    },
    perBoxCost: {
        noOfBottels: 0,
        perBox: 0,
        dealerPrice: 0,
        actualPrice: 0,
        grossProfit: 0,

    }

}

export const monthlyExpenses = {
    electricity: 0,
    wages: 0,
    transportation: 0,
    miscExps: 0,
    laonInt: 0,
    totalNoBoxes: 0,
    totalGrossProfit: 0
}

export const totalCalculation = {
    preformPerKg: "",
    totalGrossProfit: "",
}
export const expensesProduction = {
    electricity: "",
    wages: "",
    transport: "",
    miscExps: "",
    loanInt: "",
}
export const variantRowMaterialCost = {
    weight: "",
    box: "",
    lable: "",
    cap: "",
    ink: "",
    rejection: "",
}
export const finalCostTableByVariants = {
    perBottleCost: {
        preform: "",
        lable: "",
        cap: "",
        box: "",
        ink: "",
        rejection: "",
        electricity: "",
        wages: "",
        transport: "",
        miscExps: "",
        loanInt: "",
        perBottle: "",
    },
    perBoxCost: {
        perBox: "",
        salePrice: "",
        grossProfit: "",
    }

}


export const calculationObj = {
    totalCalculation: {
        preformPerKg: "117",
        totalGrossProfit: "166675.60",
        electricity: "14501.00",
        wages: "14501.00",
        transport: "14501.00",
        miscExps: "14501.00",
        loanInt: "14501.00"
    },
    expenses: {
        electricity: "55000",
        wages: "150000",
        transport: "150000",
        miscExps: "50000",
        loanInt: "145000"
    },
    variants: {
        "2000": {
            production: {
                electricity: "0",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                weight: "0",
                box: "0",
                lable: "0",
                cap: "0",
                ink: "00",
                rejection: "00"
            },
            finalCostTableByVariants: {
                "preform": "0.00",
                lable: "0",
                cap: "0",
                box: "0.00",
                ink: "00",
                rejection: "00",
                electricity: "0.00",
                wages: "0.00",
                transport: "0.00",
                miscExps: "0.00",
                loanInt: "0.00",
                perBottle: "0.00",
                perBox: "0.00",
                salePrice: "0",
                grossProfit: "0.00"
            }
        },
        "1000_R": {
            production: {
                electricity: "3000",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "19.50",
                box: "11",
                lable: "0.20",
                cap: "0.25",
                ink: "0.02",
                rejection: "0.03"
            },
            finalCostTableByVariants: {
                "preform": "2.28",
                lable: "0.20",
                cap: "0.25",
                box: "0.92",
                ink: "0.02",
                rejection: "0.03",
                electricity: "0.32",
                wages: "0.86",
                transport: "0.86",
                miscExps: "0.29",
                loanInt: "0.83",
                perBottle: "6.86",
                perBox: "82.32",
                salePrice: "65",
                grossProfit: "-17.32"
            }
        },
        "1000_P": {
            production: {
                electricity: "10500",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "31",
                box: "11",
                lable: "0.85",
                cap: "0.25",
                ink: "0.02",
                rejection: "0.03"
            },
            finalCostTableByVariants: {
                "preform": "3.63",
                lable: "0.85",
                cap: "0.25",
                box: "0.92",
                ink: "0.02",
                rejection: "0.03",
                electricity: "0.32",
                wages: "0.86",
                transport: "0.86",
                miscExps: "0.29",
                loanInt: "0.83",
                perBottle: "8.86",
                perBox: "106.32",
                salePrice: "125",
                grossProfit: "18.68"
            }
        },
        "500_R": {
            production: {
                electricity: "0",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "0",
                box: "00",
                lable: "0",
                cap: "0",
                ink: "0",
                rejection: "0"
            },
            finalCostTableByVariants: {
                "preform": "0.00",
                lable: "0",
                cap: "0",
                box: "0.00",
                ink: "0",
                rejection: "0",
                electricity: "0.00",
                wages: "0.00",
                transport: "0.00",
                miscExps: "0.00",
                loanInt: "0.00",
                perBottle: "0.00",
                perBox: "0.00",
                salePrice: "0",
                grossProfit: "0.00"
            }
        },
        "500_P": {
            production: {
                electricity: "1000",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "19.50",
                box: "14",
                lable: "0.55",
                cap: "0.25",
                ink: "0.02",
                rejection: "0.03"
            },
            finalCostTableByVariants: {
                "preform": "2.28",
                lable: "0.55",
                cap: "0.25",
                box: "0.58",
                ink: "0.02",
                rejection: "0.03",
                electricity: "0.16",
                wages: "0.43",
                transport: "0.43",
                miscExps: "0.14",
                loanInt: "0.42",
                perBottle: "5.29",
                perBox: "126.96",
                salePrice: "149.43",
                grossProfit: "22.47"
            }
        },
        "250_R": {
            production: {
                electricity: "0",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "0",
                box: "0",
                lable: "0",
                cap: "0",
                ink: "0",
                rejection: "0"
            },
            finalCostTableByVariants: {
                "preform": "0.00",
                lable: "0",
                cap: "0",
                box: "0.00",
                ink: "0",
                rejection: "0",
                electricity: "0.00",
                wages: "0.00",
                transport: "0.00",
                miscExps: "0.00",
                loanInt: "0.00",
                perBottle: "0.00",
                perBox: "0.00",
                salePrice: "0",
                grossProfit: "0.00"
            }
        },
        "250_P": {
            production: {
                electricity: "1",
                wages: "",
                transport: "",
                miscExps: "",
                loanInt: ""
            },
            variantRowMaterialCost: {
                "weight": "15",
                box: "16.25",
                lable: "0.45",
                cap: "0.25",
                ink: "0.02",
                rejection: "0.03"
            },
            finalCostTableByVariants: {
                "preform": "1.75",
                lable: "0.45",
                cap: "0.25",
                box: "0.41",
                ink: "0.02",
                rejection: "0.03",
                electricity: "0.09",
                wages: "0.26",
                transport: "0.26",
                miscExps: "0.09",
                loanInt: "0.25",
                perBottle: "3.86",
                perBox: "154.40",
                salePrice: "180.00",
                grossProfit: "25.60"
            }
        }
    }
}