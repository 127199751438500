import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css"
import { Link } from "react-router-dom";

const ShippingPolicy = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const accountBanner = (
    <div className="privacy-policy-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Shipping Policy"}</h2>
    </div>
  );

  return (
    <section className="privacy-policy-main-section">
      {accountBanner}
      <div className="privacy-policy-conatiner container py-5">
        <Row className="m-0">
          <h2>{"Shipping Policy"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
          <h6>{"What are the delivery charges?"}</h6>
          <p>{"Delivery charge varies with each Seller."}</p>
          <p>{"Sellers incur relatively higher shipping costs on low value items. In such cases, charging a nominal delivery charge helps them offset logistics costs. Please check your order summary to understand the delivery charges for individual products"}</p>
          <p>{"Why does the delivery date not correspond to the delivery timeline of X-Y business days?"}</p>
          <p>{"It is possible that the Seller or our courier partners have a holiday between the day your placed your order and the date of delivery, which is based on the timelines shown on the product page. In this case, we add a day to the estimated date. Some courier partners and Sellers do not work on Sundays and this is factored in to the delivery dates."}</p>

          <h6>{"What is the estimated delivery time?"}</h6>
          <p>{"Sellers generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays."}</p>
          <p>{"Estimated delivery time depends on the following factors:"}</p>
          <p>
            <ul>
              <li>{"The Seller offering the product"}</li>
              <li>{"Product's availability with the Seller"}</li>
              <li>{"The destination to which you want the order shipped to and location of the Seller."}</li>
            </ul>
          </p>
          <h6>{"Are there any hidden costs (local tax, octroi etc) on items sold by Sellers on Bharee.com?"}</h6>
          <p>
            {"There are NO hidden charges when you make a purchase on Bharee.com. List prices are final and all-inclusive. The price you see on the payment page is exactly what you would pay."}
          </p>
          <p>
            {"Delivery charges are not hidden charges and are charged (if at all) extra depending on the Seller's shipping policy."}
          </p>

          <h6>{"Why does the estimated delivery time vary for each seller?"}</h6>
          <p>{"You have probably noticed varying estimated delivery times for sellers of the product you are interested in. Delivery times are influenced by product availability, geographic location of the Seller, your shipping destination and the courier partner's time-to-deliver in your location."}</p>
          <p>{"Please enter your default pin code on the product page to know more accurate delivery times on the product page itself."}</p>


          <h6>{"Seller does not/cannot ship to my area. Why?"}</h6>
          <p>{"Please enter your pincode on the product page to know whether the product can be delivered to your location."}</p>
          <p>{"If you haven't provided your pincode until the checkout stage, the pincode in your shipping address will be used to check for serviceability."}</p>
          <p>{"Whether your location can be serviced or not depends on,"}</p>
          <p>{"Whether the Seller ships to your location Legal restrictions, if any, in shipping particular products to your location The availability of reliable courier partners in your location "}</p>
          <p>{"At times Sellers prefer not to ship to certain locations. This is entirely at their discretion."}</p>

          <h6>{"Why is the CoD/PoD option not offered in my location?"}</h6>
          <p>{"Availability of CoD/PoD depends on the ability of our courier partner servicing your location to accept cash or online as payment at the time of delivery."}</p>
          <p>{"Our courier partners have limits on the cash amount payable on delivery depending on the destination and your order value might have exceeded this limit. Please enter your pin code on the product page to check if CoD/PoD is available in your location."}</p>


          <h6>{"I need to return an item; how do I arrange for a pick-up?"}</h6>
          <p>{"Returns are easy. Contact Us to initiate a return. You will receive a call explaining the process, once you have initiated a return."}</p>
          <p>{"Wherever possible our courier partner will facilitate the pick-up of the item. In case, the pick-up cannot be arranged through our courier partner, you can return the item through a third-party courier service. Return fees are borne by the Seller."}</p>


          <h6>{"I did not receive my order but got a delivery confirmation SMS/Email."}</h6>
          <p>{"In case the product was not delivered and you received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation for the seller to investigate."}</p>
        </Row>
      </div>
    </section>
  );
};


export default ShippingPolicy;
