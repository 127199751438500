import React, { useEffect, useState } from "react";
import { Carousel, Image, Button, Row, Col } from "react-bootstrap";
import Banner1 from "../../assets/images/HomeBanners/Banner1.png";
import Banner2 from "../../assets/images/HomeBanners/Banner2.png";
import Banner3 from "../../assets/images/HomeBanners/Banner3.png";
import Banner4 from "../../assets/images/HomeBanners/Banner4.png";



import MobileBanner1 from "../../assets/images/MobileBanner1.png";
import fulfill from "../../assets/images/fulfill.png";
import "./home.css";
const HomeBannerCarousel = ({ onClickBtn }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  // const bannerImg = {
  //   Banner1,
  //   Banner2,
  //   Banner3,
  //   Banner4,
  // }
  window.addEventListener("resize", handleResize);

  // const carouselSlideDekTop = [];
  // const carouselSlideMobile = [];
  // for (let i = 0; i < 5; i++) {
  //   carouselSlideMobile.push(
  //     // <div className={`slideCover ${currentSlide === 0 ? 'active' : ''}`}>
  //     <Carousel.Item key={i + 154}>
  //       <Image
  //         className="d-block w-100"
  //         src={MobileBanner1}
  //         alt="Second slide"
  //       />
  //       <Carousel.Caption>
  //         <Image src={fulfill} alt="Second slide" />
  //         <p>{"Delivery in 48 hrs*"}</p>
  //         <Button onClick={onClickBtn}>{"KNOW MORE"}</Button>
  //       </Carousel.Caption>
  //     </Carousel.Item>
  //     // </div>
  //   );
  //   carouselSlideDekTop.push(
  //     <Carousel.Item key={i + 15}>
  //       <Row className="m-0">
  //         <Col xs={12} md={6}>
  //           <Carousel.Caption>
  //             <h1>{"Discover Diversity"}</h1>
  //             <p>{"Explore Multiple Brands from"}<br />{"trusted suppliers."}</p>
  //             <Button onClick={onClickBtn}>{"KNOW MORE"}</Button>
  //           </Carousel.Caption>
  //         </Col>
  //         <Col xs={12} md={6}>
  //           <Image className="d-block w-100" src={bannerImg[`Banner${1}`]} alt="Second slide" />
  //         </Col>
  //       </Row>
  //     </Carousel.Item>
  //     // </div>
  //   );
  // }
  return (
    <Carousel
      controls={false}
      // interval={8000}
      pause={false}
      indicators={windowSize > 768 ? true : false}
      autoPlay={false}
      // autoPlaySpeed={8000}
      // customTransition="transform 8000ms ease-in-out"
      // transitionDuration={8000}
      // beforeChange={handleBeforeChange}
      // additionalTransfrom={0}
      // focusOnSelect={false}
      // infinite
      // customTransition="opacity 1.5s ease-in-out"
      // transitionDuration={1500}
    >

      <Carousel.Item key={"a"}>
        <Row className="m-0">
          <Col xs={12} lg={6}>
            <Carousel.Caption>
              <h1>{"Discover Diversity"}</h1>
              <p>{"Explore Multiple Brands from"}<br />{"trusted suppliers."}</p>
              {/* <Button onClick={onClickBtn}>{"KNOW MORE"}</Button> */}
            </Carousel.Caption>
          </Col>
          <Col xs={12} lg={6} className="p-0 image-col">
            <Image className="d-block w-100" src={Banner1} alt="Second slide" />
          </Col>
        </Row>
      </Carousel.Item>

      <Carousel.Item key={"b"}>
        <Row className="m-0">
          <Col xs={12} lg={6}>
            <Carousel.Caption>
              <h1>{"Clarity in Costs"}</h1>
              <p>{"Compare prices and secure the"}<br />{"best deals for your business."}</p>
              {/* <Button onClick={onClickBtn}>{"KNOW MORE"}</Button> */}
            </Carousel.Caption>
          </Col>
          <Col xs={12} lg={6} className="p-0 image-col">
            <Image className="d-block w-100" src={Banner2} alt="Second slide" />
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item key={"c"}>
        <Row className="m-0">
          <Col xs={12} lg={6}>
            <Carousel.Caption>
              <h1>{"Timely Deliveries"}</h1>
              <p>{"Bharee prioritizes fast & efficient"}<br />{"deliveries you can rely on."}</p>
              {/* <Button onClick={onClickBtn}>{"KNOW MORE"}</Button> */}
            </Carousel.Caption>
          </Col>
          <Col xs={12} lg={6} className="p-0 image-col">
            <Image className="d-block w-100" src={Banner3} alt="Second slide" />
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item key={"d"}>
        <Row className="m-0">
          <Col xs={12} lg={6}>
            <Carousel.Caption>
              <h1>{"Trust on Every Seller"}</h1>
              <p>{"Verified Sellers Ensuring Quality"}<br />{"and Reliability."}</p>
              {/* <Button onClick={onClickBtn}>{"KNOW MORE"}</Button> */}
            </Carousel.Caption>
          </Col>
          <Col xs={12} lg={6} className="p-0 image-col">
            <Image className="d-block w-100" src={Banner4} alt="Second slide" />
          </Col>
        </Row>
      </Carousel.Item>
    </Carousel>
  );
}

export default HomeBannerCarousel;
