import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css"
import { Link } from "react-router-dom";
import CustomButton from "../common/CustomButton";
import supportImage from "../../assets/images/support.png"
import { useDispatch, useSelector } from "react-redux";
import { regex } from "../../helpers/app-constants";
import isEmpty from "../../utils/is-Empty";
import { postSupport } from "../../actions/structure.action";
const Support =  () => {

  const dispatch = useDispatch();
  const [uploadFormObj, setUploadFormObj] = useState({});

  const { requestCall } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  const onSelectValue = (key, value) => {
    // console.log("*** change ", key, value)
    let updatedObj = {};
    updatedObj[key] = value;

    setUploadFormObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };
  const createSupport = (e) => {
    e.preventDefault();
    // console.log("*****", uploadFormObj)
    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "full_name") &&
      !isEmpty(uploadFormObj.full_name) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "mobile_number") &&
      !isEmpty(uploadFormObj.mobile_number) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "message") &&
      !isEmpty(uploadFormObj.message)
    ) {
      postSupport(uploadFormObj, dispatch);
      setUploadFormObj({ full_name: "", mobile_number: "", message: "" })
    }
  };

  const accountBanner = (
    <div className="support-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Support"}</h2>
    </div>
  );

  return (
    <section className="support-main-section">
      {accountBanner}
      <Container className="support-conatiner container py-5">
        <Row className="m-0">
          <Col xs={12} md={6} className="img-col">
            <Image src={supportImage} />
          </Col>
          <Col xs={12} md={6} className="form-col">
            <Form className="my-2 modal-custom-class" onSubmit={createSupport}>

              <Form.Group className="mb-3" >
                <Form.Label>{"Name"}</Form.Label>
                <Form.Control
                  type="text"
                  value={uploadFormObj?.full_name}
                  required
                  onChange={(e) =>
                    onSelectValue("full_name", e.target.value)
                  } />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>{"Mobile Number"}</Form.Label>
                <Form.Control
                  type="text"
                  value={uploadFormObj?.mobile_number}
                  required
                  onChange={(e) => {
                    return regex.test(e.target.value) ?
                      onSelectValue("mobile_number", e.target.value) : null
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" >
                <Form.Label>{"Message"}</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Enter  your message"
                  value={uploadFormObj?.message}
                  required onChange={(e) =>
                    onSelectValue("message", e.target.value)
                  }
                />
              </Form.Group>

              <div className="action-buttons">
                <CustomButton
                  variant="primary"
                  buttonClasses={"rest-btn"}
                  onClick={() => setUploadFormObj({ full_name: "", mobile_number: "", message: "" })}
                >
                  {"reset"}
                </CustomButton>
                <CustomButton
                  variant="primary"
                  // buttonClasses="submit-btn"
                  buttonClasses={
                    !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                  }
                  type="submit"
                  loading={requestCall?.loading}
                >
                  {"Submit"}
                </CustomButton>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};


export default Support;
