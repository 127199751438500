import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { DeleteIcon, EditIcon } from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProduct,
  getApi,
} from "../../../../actions/structure.action";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import ProductCrud from "../../common/ProductCrud";
import CustomTable from "../../common/CustomTable";

const Products = () => {
  const dispatch = useDispatch();
  const [sellerProductsArray, setSellerProductsArray] = useState([]);
  const [showProductCreateMode, setShowProductCreateMode] = useState(false);
  const [selectedFormObj, setSelectedFormObj] = useState({});
  const { products, brands } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    getApi("getBrands", dispatch);
    getApi("getProducts", dispatch);
  }, []);
  useEffect(() => {
    if (products?.data?.length < 1) {
      setShowProductCreateMode(true);
    } else {
      setShowProductCreateMode(false);
    }
    if (products?.data?.length > 0) {
      const filtered = products?.data.filter(
        (item) => item.status
      ); // Replace with your desired filter condition
      setSellerProductsArray(filtered);
    }

  }, [products]);
  useEffect(() => {
    if (products?.data?.length < 1) {
      setShowProductCreateMode(true);
    } else {
      setShowProductCreateMode(false);
    }
  }, [products]);


  return (
    <Container className="slller-products p-0 ">
      <Row className="mx-0 p-0 mb-2">
        <h1 className="text-uppercase">{"Products"}</h1>
      </Row>
      {products.loading ? (<Container className="seller-product-create-form  p-0 ">
        <Spinner />
      </Container>) : brands.data?.length < 1 ?
        (<Container >
          <p>{"No brand is available for product creation."}</p>
        </Container>)
        : !showProductCreateMode && sellerProductsArray?.length > 0 ? (
          <Row className="m-0 mb-2">
            <CustomTable
              columns={tableStructures.product}
              data={sellerProductsArray}
              enableRowActions
              positionActionsColumn={"last"}
              enableTopToolbar={true}
              emptyTableMsg={''}
              enablePagination={true}
              // enableFilters={false} // remove filter icons
              enableColumnActions={false} // remove column action
              enableFullScreenToggle={false} // emove full screen option
              // enablePagination={false} //disable a default feature
              // enableHiding={false} // show hid colmuns action
              renderRowActions={({ row, table }) => (
                <div className="table-action-box">
                  {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => {
                      setShowProductCreateMode(true);
                      setSelectedFormObj(row.original);
                    }}
                  >
                    <EditIcon />
                  </CustomButton>
                  <CustomButton
                    buttonClasses="icon-action-btn"
                    onClick={() => deleteProduct(row.original.id, dispatch)}
                  >
                    <DeleteIcon />
                  </CustomButton>
                </div>
              )}
              renderTopToolbarCustomActions={({ table }) => (
                <div className="table-heading-wrap">
                  <h4>{"Your products"}</h4>

                  {/* {brandModal("icon-add-btn", "+ Add Brand", {})} */}
                  <CustomButton
                    buttonClasses="icon-add-btn"
                    onClick={() => {
                      setShowProductCreateMode(true);
                      setSelectedFormObj({});
                    }}
                  >
                    {"Add"}
                  </CustomButton>
                </div>
              )}
              initialState={{
                columnPinning: {
                  right: ["mrt-row-actions"],
                },
              }}
            //   enableColumnOrdering={false} //enable some features
            //   enableRowSelection={false}
            //   enableColumnResizing={false}
            //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
            //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
            //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
            />
          </Row>
        ) : (<Container className="seller-product-create-form  p-0 ">
          <ProductCrud product={selectedFormObj} setShowProductCreateMode={setShowProductCreateMode}
            listEmpty={sellerProductsArray?.length > 0 ? false : true} />
        </Container>)}
      {/* {(() => {
        if (!showProductCreateMode) {
          if (sellerProductsArray?.length > 0 && !products.loading) {
            return (
              <Row className="m-0 mb-2">
                <CustomTable
                  columns={tableStructures.product}
                  data={sellerProductsArray}
                  enableRowActions
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableColumnActions={false} // remove column action
                  enableFullScreenToggle={false} // emove full screen option
                  renderRowActions={({ row, table }) => (
                    <div className="table-action-box">
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => {
                          setShowProductCreateMode(true);
                          setSelectedFormObj(row.original);
                        }}
                      >
                        <EditIcon />
                      </CustomButton>
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => deleteProduct(row.original.id, dispatch)}
                      >
                        <DeleteIcon />
                      </CustomButton>
                    </div>
                  )}
                  renderTopToolbarCustomActions={({ table }) => (
                    <div className="table-heading-wrap">
                      <h4>{"Your products"}</h4>

                      <CustomButton
                        buttonClasses="icon-add-btn"
                        onClick={() => {
                          setShowProductCreateMode(true);
                          setSelectedFormObj({});
                        }}
                      >
                        {"Add"}
                      </CustomButton>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                />
              </Row>
            );
          } else if (sellerProductsArray?.length < 1 && !products.loading) {
            return (
              <Container className="seller-product-create-form  p-0 ">
                <ProductCrud product={selectedFormObj} setShowProductCreateMode={setShowProductCreateMode}
                  listEmpty={sellerProductsArray.lengh > 0 ? false : true}
                />
              </Container>
            );
          } else if (products.loading) {
            return <Spinner />;
          }
        } else if (showProductCreateMode) {
          return (
            <Container className="seller-product-create-form  p-0 ">
              <ProductCrud product={selectedFormObj} setShowProductCreateMode={setShowProductCreateMode}
                listEmpty={sellerProductsArray.length > 0 ? false : true} />
            </Container>
          );
        }
      })()} */}
    </Container>
  );
};


export default Products;
