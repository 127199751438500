import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    patchProductVariant,
    postGetCalculatedprice,
    postProductVariant,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import { appConstants, areaList, regex } from "../../../../helpers/app-constants";
import CustomDragDrop from "../../../common/CustomDragDrop";
import CustomImagesDrageDrop from "../../../common/CustomImagesDrageDrop";
import debounce from "lodash/debounce";

const VariantsCrud = ({ variant = {}, createVariant = {}, createVariantOptions = [], setShowVariantCRUD, renderInfo = {}, isViewOnly = false }) => {
    const dispatch = useDispatch();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [currentUpdateId, setCurrentUpdateId] = useState("");
    const [selectedFormObj, setSelectedFormObj] = useState({});
    const { bhareeAdminBasicTable, requestCall, calculatedPrice } = useSelector(
        (state) => {
            return state;
        }
    );

    const onSelectValue = (value, selectorType) => {
        let updatedObj = {};
        updatedObj[selectorType] = value;
        setSelectedFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };

    const handleFileChange = (files) => {
        // const files = e.target.files;
        // console.log("**********files", files)
        const newImageData = [];

        for (let i = 0; i < files?.length; i++) {
            const imageKey = `image_${i + 1}`; // Generate a unique key for each image
            newImageData.push({ key: imageKey, file: files[i] });
        }

        // setImageData(newImageData);
        // let updatedObj = {};
        // updatedObj[selectorType] = value;
        if (Object.prototype.hasOwnProperty.call(selectedFormObj, "imageData")) {
            setSelectedFormObj((prevState) => {
                const newArray = [...prevState.imageData];
                newImageData.map((item) => {
                    return newArray.push(item)
                });
                return { ...prevState, imageData: newArray };
            });
        } else {
            let updatedObj = {}
            updatedObj["imageData"] = newImageData;
            setSelectedFormObj((previousVlaues) => ({
                ...previousVlaues,
                ...updatedObj,
            }));
        }

    };

    useEffect(() => {
        if (!isEmpty(variant)) {
            setSelectedFormObj(variant);
            setIsUpdateMode(true);
            setCurrentUpdateId(variant.id);

        } else {
            setIsUpdateMode(false);
            setSelectedFormObj(createVariant);
        }
        dispatch({
            type: appConstants.CLEAR_CALCULATED_PRICE,
        });
    }, []);

    useEffect(() => {
        console.log("**** selectedVariants", selectedFormObj)
    }, [selectedFormObj])


    const objectToFormData = (obj, formData, parentKey = '') => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const formKey = parentKey ? `${parentKey}[${key}]` : key;

                if (typeof value === 'object' && !Array.isArray(value)) {
                    objectToFormData(value, formData, formKey);
                } else if (Array.isArray(value)) {
                    if (value?.length > 0) {
                        value.forEach((item, index) => {
                            const arrayFormKey = `${formKey}[${index}]`;
                            objectToFormData(item, formData, arrayFormKey);
                        });
                    } else {
                        formData.append(formKey, []);
                    }
                } else {
                    formData.append(formKey, value);
                }
            }
        }

        return formData;
    }

    const createUpdateProductVariant = (e) => {
        e.preventDefault();

        if (
            !isEmpty(selectedFormObj)
            //  &&
            // Object.prototype.hasOwnProperty.call(selectedFormObj, "brand") &&
            // !isEmpty(selectedFormObj.brand) &&
            // Object.prototype.hasOwnProperty.call(
            //     selectedFormObj,
            //     "manufactured_by"
            // ) &&
            // !isEmpty(selectedFormObj.manufactured_by) &&
            // Object.prototype.hasOwnProperty.call(selectedFormObj, "product_name") &&
            // !isEmpty(selectedFormObj.product_name) &&
            // Object.prototype.hasOwnProperty.call(selectedFormObj, "marketed_by") &&
            // !isEmpty(selectedFormObj.marketed_by) &&
            // Object.prototype.hasOwnProperty.call(
            //     selectedFormObj,
            //     "productCategory"
            // ) &&
            // !isEmpty(selectedFormObj.productCategory) &&
            // Object.prototype.hasOwnProperty.call(
            //     selectedFormObj,
            //     "selectedVariants"
            // ) &&
            // selectedFormObj.selectedVariants.length > 0 &&
            // Object.prototype.hasOwnProperty.call(selectedFormObj, "shelf_life") &&
            // !isEmpty(selectedFormObj.shelf_life)
        ) {

            const formData = new FormData();
            objectToFormData({ ...selectedFormObj, price: isUpdateMode ? selectedFormObj?.seller_price : selectedFormObj?.price }, formData,);
            if (Object.prototype.hasOwnProperty.call(selectedFormObj, "imageData") && selectedFormObj?.imageData?.length > 0) {
                selectedFormObj?.imageData.map((img) => {
                    return formData.append(img?.key, img?.file);
                })
            }
            // console.log("*****", formData)
            // postProductVariant(formData, dispatch);
            if (isUpdateMode) {

                patchProductVariant(currentUpdateId, formData, dispatch);
            } else {
                postProductVariant(formData, dispatch);
            }
        }
    };

    // useEffect(() => {
    //     console.log("**** selectedVariants", selectedFormObj)
    // }, [selectedFormObj])
    const debouncedUpdatePrice = useCallback(
        debounce((id, value) => {
            let obj = {}
            obj["variantId"] = id;
            obj["price"] = value
            postGetCalculatedprice(obj, dispatch);
        }, 1000), // Adjust delay as needed
        []
    );

    const handlePriceChange = (id, value, selectorType) => {
        console.log("*******", id)
        onSelectValue(value, selectorType);
        debouncedUpdatePrice(id, value);
    };

    return (
        <>

            <Row className="m-0 w-full  form-row ">
                <Row className="m-0 px-0 w-full px-0 mb-4 title-button-row">
                    <h2>{!isUpdateMode ? "Add Variant" : "Update Variant"}</h2>
                    <CustomButton
                        buttonClasses="view-switch-btn"
                        onClick={() => setShowVariantCRUD(false)}
                    >
                        {"List"}
                    </CustomButton>
                </Row>

                {createVariantOptions?.length < 1 && !isUpdateMode ? (<Container>
                    <p className="text-center">{"All variants have already been created. No additional variants are available."}</p>
                </Container>) : (
                    <Form
                        className="modal-custom-class  px-0"
                        onSubmit={createUpdateProductVariant}
                    >
                        <Row className="m-0 px-0">

                            <h6 className="px-3 mb-2">
                                {!isUpdateMode ? "Please Selecte " : selectedFormObj?.displayName}
                                {" Variant"}
                            </h6>
                            {!isUpdateMode && (<Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"Variant"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <CustomSelectDropdown
                                        style={{
                                            multiselectContainer: {
                                                backgroundColor: "#f8f8f8",
                                            },
                                            chips: {
                                                margin: 0,
                                            },
                                            option: {
                                                background: "#fbb040",
                                                color: "#FFFFFF",
                                                borderBottom: "1px solid #FFFFFF",
                                            },
                                        }}
                                        options={createVariantOptions}

                                        onSelect={(e) => {
                                            onSelectValue(e[0], "variant");
                                        }

                                        }
                                        // onRemove={(e) => {
                                        //     onSelectValue(e, "moq");
                                        // }}
                                        selectedValues={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj,
                                                "variant"
                                            ) ? [selectedFormObj?.variant] : []
                                        }
                                        singleSelect
                                        displayValue={"displayName"}
                                    />
                                </Form.Group>
                            </Col>)}

                            <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"MOQ's"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <CustomSelectDropdown
                                        style={{
                                            multiselectContainer: {
                                                backgroundColor: "#f8f8f8",
                                            },
                                            chips: {
                                                background: "#fbb040",
                                                margin: 0,
                                            },
                                            option: {
                                                background: "#fbb040",
                                                color: "#FFFFFF",
                                                borderBottom: "1px solid #FFFFFF",
                                            },
                                        }}
                                        options={bhareeAdminBasicTable?.data?.moqs}

                                        onSelect={(e) => {
                                            onSelectValue(e, "moq");
                                        }

                                        }
                                        onRemove={(e) => {
                                            onSelectValue(e, "moq");
                                        }}
                                        selectedValues={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj,
                                                "moq"
                                            ) ? selectedFormObj?.moq : []
                                        }
                                        displayValue={"quantity"}
                                    />
                                </Form.Group>
                            </Col>

                            {!isUpdateMode ? <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Label>
                                    {"Price A Per Case [in INR]"}
                                    <span className="redStreSpan">{"*"}</span>
                                    {!isEmpty(calculatedPrice?.data) && Object.prototype.hasOwnProperty.call(
                                        selectedFormObj,
                                        "variant"
                                    ) && Object.prototype.hasOwnProperty.call(
                                        calculatedPrice?.data, selectedFormObj?.variant.id) &&
                                        <span className="positiveMsg"> {" Final Price : " + calculatedPrice?.data[selectedFormObj?.variant?.id].offer_price}  </span>}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    maxLength={10}
                                    value={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "price"
                                        ) ? selectedFormObj?.price : ""
                                    }
                                    onChange={(e) => {
                                        return regex.test(e.target.value)
                                            ? handlePriceChange(selectedFormObj?.variant?.id, e.target.value, "price")
                                            : null;
                                    }}
                                />
                            </Col> : <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Label>
                                    {"Price B Per Case [in INR]"}
                                    <span className="redStreSpan">{"*"}</span>
                                    {!isEmpty(calculatedPrice?.data) && Object.prototype.hasOwnProperty.call(
                                        calculatedPrice?.data, selectedFormObj?.variant_id) ?
                                        <span className="positiveMsg"> {" Final Price : " + calculatedPrice?.data[selectedFormObj?.variant_id].offer_price}  </span> :
                                        <span className="positiveMsg"> {" Final Price : " + selectedFormObj?.price}  </span>
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    maxLength={10}
                                    value={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "seller_price"
                                        ) ? selectedFormObj?.seller_price : ""
                                    }
                                    onChange={(e) => {
                                        return regex.test(e.target.value)
                                            ? handlePriceChange(selectedFormObj?.variant_id, e.target.value, "seller_price")
                                            : null;
                                    }}
                                />
                            </Col>}

                            <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"Packaging Types"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <CustomSelectDropdown
                                        style={{
                                            multiselectContainer: {
                                                backgroundColor: "#f8f8f8",
                                            },
                                            chips: {
                                                margin: 0,
                                            },
                                            option: {
                                                background: "#fbb040",
                                                color: "#FFFFFF",
                                                borderBottom: "1px solid #FFFFFF",
                                            },
                                        }}
                                        options={
                                            bhareeAdminBasicTable?.data?.packagingTypes
                                        }
                                        onSelect={(e) => {
                                            onSelectValue(e[0], "packaging_type")
                                            // onSelectValue(e[0], `_${variant.quantity}.packaging_type`)
                                        }
                                        }
                                        selectedValues={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj,
                                                "packaging_type"
                                            )
                                                ? [selectedFormObj?.packaging_type]
                                                : []
                                        }
                                        displayValue={"name"}
                                        singleSelect
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"Packaging Size [Bottles]"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        maxLength={10}
                                        value={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj, "packaging_size"
                                            )
                                                ? selectedFormObj?.packaging_size
                                                : ""
                                        }
                                        onChange={(e) => {
                                            return regex.test(e.target.value)
                                                ? onSelectValue(
                                                    e.target.value,
                                                    "packaging_size",
                                                )
                                                : null;
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"Weight Per Case [in kg]"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        maxLength={10}
                                        value={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj,
                                                "weight_per_case"
                                            )
                                                ? selectedFormObj?.weight_per_case
                                                : ""
                                        }
                                        onChange={(e) => {
                                            return regex.test(e.target.value)
                                                ? onSelectValue(
                                                    e.target.value,
                                                    "weight_per_case",
                                                )
                                                : null;
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="px-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>
                                        {"Areas"}
                                        <span className="redStreSpan">{"*"}</span>
                                    </Form.Label>
                                    <CustomSelectDropdown
                                        style={{
                                            multiselectContainer: {
                                                backgroundColor: "#f8f8f8",
                                            },
                                            chips: {
                                                background: "#fbb040",
                                                margin: 0,
                                            },
                                            option: {
                                                background: "#fbb040",
                                                color: "#FFFFFF",
                                                borderBottom: "1px solid #FFFFFF",
                                            },
                                        }}
                                        options={areaList}

                                        onSelect={(e) => {
                                            onSelectValue(
                                                e,
                                                "area_list",
                                            );
                                        }

                                        }
                                        onRemove={(e) => {
                                            onSelectValue(
                                                e,
                                                "area_list",
                                            );
                                        }}
                                        selectedValues={
                                            Object.prototype.hasOwnProperty.call(
                                                selectedFormObj,
                                                "area_list"
                                            )
                                                ?
                                                selectedFormObj?.area_list
                                                : []
                                        }
                                        displayValue={"area"}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col xs={12} md={6} lg={4} className="px-3">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                    {"payment Types"}
                                    <span className="redStreSpan">{"*"}</span>
                                </Form.Label>
                                <CustomSelectDropdown
                                    style={{
                                        multiselectContainer: {
                                            backgroundColor: "#f8f8f8",
                                        },
                                        chips: {
                                            background: "#fbb040",
                                        },
                                        option: {
                                            background: "#fbb040",
                                            color: "#FFFFFF",
                                            borderBottom: "1px solid #FFFFFF",
                                        },
                                    }}
                                    options={bhareeAdminBasicTable?.data.paymentTypes}
                                    onSelect={(e) => {
                                        onSelectValue(
                                            e,
                                            "payment_types",
                                        );
                                    }

                                    }
                                    onRemove={(e) => {
                                        onSelectValue(
                                            e,
                                            "payment_types",
                                        );
                                    }}
                                    selectedValues={
                                        Object.prototype.hasOwnProperty.call(
                                            selectedFormObj,
                                            "payment_types"
                                        )
                                            ?
                                            selectedFormObj?.payment_types
                                            : []
                                    }
                                    displayValue={"name"}
                                />
                            </Form.Group>
                        </Col> */}
                            <Col xs={12} md={12} lg={12} className="px-3">
                                {/* <Form.Group className="mb-3  w-100" controlId="formBasicEmail">
                                <Form.Label>
                                    {"Upload Variant Images"}
                                    {!isUpdateMode ? (
                                        <span className="redStreSpan">{"*"}</span>
                                    ) : (
                                        ""
                                    )}
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    multiple
                                    required={!isUpdateMode ? true : false}
                                // onChange={(e) => handleFileChange(e, variant.quantity)}

                                />
                            </Form.Group>
                             */}

                                <CustomDragDrop onUplodfiles={handleFileChange} />
                                {isUpdateMode && <CustomImagesDrageDrop imagesArray={selectedFormObj?.productImages} onChange={onSelectValue} />}


                            </Col>

                        </Row>
                        <div className="action-buttons">
                            <CustomButton
                                buttonClasses="close-btn"
                                onClick={() => setShowVariantCRUD(false)}
                                disabled={requestCall?.loading}
                            >
                                {"Back"}
                            </CustomButton>
                            <CustomButton
                                variant="primary"
                                buttonClasses={
                                    !requestCall?.loading
                                        ? "submit-btn"
                                        : "submit-btn-loading"
                                }
                                type="submit"
                                loading={requestCall?.loading}
                            >
                                {!isUpdateMode ? "Add" : "update"}
                            </CustomButton>
                        </div>
                    </Form>)}



            </Row>
        </>
    );
};



export default VariantsCrud;
