import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import "./custom-select-dropdown.css";
const CustomSelectDropdown = ({
  displayValue,
  options,
  selectedValues,
  onSelect,
  onRemove,
  placeholder= "Select",
  ...resprops
}) => {
  return (
    <Multiselect
      // style={{
      //   chips: {
      //     color:
      //     background: "#ff9900",
      //   },
      // }}
      options={options} // Options to display in the dropdown
      selectedValues={selectedValues} // Preselected value to persist in dropdown
      onSelect={onSelect} // Function will trigger on select event
      onRemove={onRemove} // Function will trigger on remove event
      displayValue={displayValue} // Property name to display in the dropdown options
      placeholder={placeholder}
      {...resprops}
    />
  );
};

export default CustomSelectDropdown;
