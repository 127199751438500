import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { DeleteIcon } from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteDeliveryType,
  deleteMoq,
  deletePackagingTypes,
  deletePaymentTypes,
  deleteProductCategories,
  deleteShelfLife,
  deleteVariant,
  getApi,
  postDeliveryType,
  postMoq,
  postPackagingTypes,
  postPaymentTypes,
  postProductCategories,
  postShelfLife,
  postVariant,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import CustomModalWrap from "../../../common/CustomModalWrap";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import { appConstants } from "../../../../helpers/app-constants";
import "./index.css"
import CustomTable from "../../common/CustomTable";
import inputValidation from "../../../../utils/inputValidation";


const Basics = () => {
  const dispatch = useDispatch();
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [showMoqModal, setShowMoqModal] = useState(false);
  const [showProductCategoriesModal, setShowProductCategoriesModal] =
    useState(false);
  const [showPackagingTypesModal, setShowPackagingTypesModal] = useState(false);
  const [showDeliveryTypesModal, setShowDeliveryTypesModal] = useState(false);
  const [showPaymentTypesModal, setShowPaymentTypesModal] = useState(false);
  const [showShelfLife, setShowShelfLife] = useState(false);


  const [erroeMsg, setErroeMsg] = useState({});
  const [uploadFormObj, setUploadFormObj] = useState(0);

  const { bhareeAdminBasicTable, requestCall } = useSelector((state) => {
    return state;
  });

  const setUpdatedForm = (key, value, maxLenght = 1000, numericOnly = false) => {
    setErroeMsg({});
    let updatedObj = {};

    let validate = inputValidation(value, maxLenght, numericOnly);
    if (validate.pass) {
      updatedObj[key] = validate.finalValue;
      setErroeMsg({ [key]: validate.msg })
      setUploadFormObj((previousVlaues) => ({
        ...previousVlaues,
        ...updatedObj,
      }));
    } else {
      setErroeMsg({ [key]: validate.msg })
    }
  };

  useEffect(() => {
    getApi("getBhreeAdminBasicTables",dispatch);
  }, []);
  useEffect(() => {
    setShowVariantModal(false);
    setShowMoqModal(false);
    setShowPackagingTypesModal(false);
    setShowProductCategoriesModal(false);
    setShowDeliveryTypesModal(false);
    setShowShelfLife(false);
  }, [bhareeAdminBasicTable]);
  useEffect(() => {
    if (!isEmpty(requestCall?.errors) && !requestCall?.errors?.success) {
      setErroeMsg(requestCall.errors.message)
    } else {
      setErroeMsg("")
    }
  }, [requestCall]);
  const createVariant = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "variantValue") &&
      uploadFormObj?.variantValue > 0
    ) {
      let obj = {
        quantity: uploadFormObj.variantValue,
      };
      postVariant(obj, dispatch);
    }
  };
  const createMoq = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "moqValue") &&
      uploadFormObj?.moqValue > 0
    ) {
      let obj = {
        quantity: uploadFormObj.moqValue,
      };
      postMoq(obj, dispatch);
    }
  };
  const createProductCategories = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(
        uploadFormObj,
        "productCategoriesValue"
      ) &&
      !isEmpty(uploadFormObj?.productCategoriesValue)
    ) {
      let obj = {
        name: uploadFormObj.productCategoriesValue,
      };
      postProductCategories(obj, dispatch);
    }
  };
  const createPackagingTypes = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(
        uploadFormObj,
        "packagingTypesValue"
      ) &&
      !isEmpty(uploadFormObj?.packagingTypesValue)
    ) {
      let obj = {
        name: uploadFormObj.packagingTypesValue,
      };
      postPackagingTypes(obj, dispatch);
    }
  };
  const createDeliveryTypes = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(
        uploadFormObj,
        "deliveryTypesValue"
      ) &&
      !isEmpty(uploadFormObj?.deliveryTypesValue)
    ) {
      let obj = {
        name: uploadFormObj.deliveryTypesValue,
        subTitle: uploadFormObj.deliveryTypeSubTitle,
      };
      postDeliveryType(obj, dispatch);
    }
  };
  const createPaymentTypes = (e) => {
    e.preventDefault();
    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(
        uploadFormObj,
        "paymentTypesValue"
      ) &&
      !isEmpty(uploadFormObj?.paymentTypesValue)
    ) {
      let obj = {
        name: uploadFormObj.paymentTypesValue,
        order: bhareeAdminBasicTable?.data?.paymentTypes?.length,
      };
      postPaymentTypes(obj, dispatch);
    }
  };

  const createShelfLife = (e) => {
    e.preventDefault();

    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "shelfLifeValue") &&
      uploadFormObj?.shelfLifeValue > 0
    ) {
      let obj = {
        months: uploadFormObj.shelfLifeValue,
      };
      postShelfLife(obj, dispatch);
    }
  };



  const variantModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new variant"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showVariantModal}
        showModal={() => {
          setShowVariantModal(true);
          dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowVariantModal(false)}
      >
        <Form className="my-2" onSubmit={createVariant}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter quantity in ml"
              value={uploadFormObj?.variantValue || ""}
              onChange={(e) => setUpdatedForm("variantValue", e.target.value, 10, true)}
            />
            {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "variantValue") && <p className="error-msg">{erroeMsg.variantValue}</p>}
          </Form.Group>
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowVariantModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const moqModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new MOQ"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showMoqModal}
        showModal={() => {
          setShowMoqModal(true);
          dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowMoqModal(false)}
      >
        <Form className="my-2" onSubmit={createMoq}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter quantity "
              value={uploadFormObj?.moqValue || ""}
              onChange={(e) => setUpdatedForm("moqValue", e.target.value, 10, true)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "moqValue") && <p className="error-msg">{erroeMsg.moqValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowMoqModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const productCategoriesModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new product categories"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showProductCategoriesModal}
        showModal={() => {
          setShowProductCategoriesModal(true); dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowProductCategoriesModal(false)}
      >
        <Form className="my-2" onSubmit={createProductCategories}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter Product Categories "
              value={uploadFormObj?.productCategoriesValue || ""}
              onChange={(e) => setUpdatedForm("productCategoriesValue", e.target.value, 50,)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "productCategoriesValue") && <p className="error-msg">{erroeMsg.productCategoriesValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowProductCategoriesModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const packagingTypesModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new packaging types"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showPackagingTypesModal}
        showModal={() => {
          setShowPackagingTypesModal(true); dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowPackagingTypesModal(false)}
      >
        <Form className="my-2" onSubmit={createPackagingTypes}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter Packaging Types"
              value={uploadFormObj?.packagingTypesValue || ""}
              onChange={(e) => setUpdatedForm("packagingTypesValue", e.target.value, 50)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "packagingTypesValue") && <p className="error-msg">{erroeMsg.packagingTypesValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowPackagingTypesModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const deliveryTypesModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new delivery Type"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showDeliveryTypesModal}
        showModal={() => {
          setShowDeliveryTypesModal(true); dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowDeliveryTypesModal(false)}
      >
        <Form className="my-2" onSubmit={createDeliveryTypes}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter Delivery Types"
              value={uploadFormObj?.deliveryTypesValue || ""}
              onChange={(e) => setUpdatedForm("deliveryTypesValue", e.target.value, 50)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Sub Title"
              value={uploadFormObj?.deliveryTypeSubTitle || ""}
              onChange={(e) => setUpdatedForm("deliveryTypeSubTitle", e.target.value, 50)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "deliveryTypesValue") && <p className="error-msg">{erroeMsg.deliveryTypesValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowDeliveryTypesModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const paymentTypesModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new delivery Type"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showPaymentTypesModal}
        showModal={() => {
          setShowPaymentTypesModal(true); dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowPaymentTypesModal(false)}
      >
        <Form className="my-2" onSubmit={createPaymentTypes}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter Payment Types"
              value={uploadFormObj?.paymentTypesValue || ""}
              onChange={(e) => setUpdatedForm("paymentTypesValue", e.target.value, 50)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "paymentTypesValue") && <p className="error-msg">{erroeMsg.paymentTypesValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowPaymentTypesModal(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  const shelfLifeModal = (buttonClasses) => {
    return (
      <CustomModalWrap
        className="modal-custom-class create-variant-from"
        hedaerContent={
          <>
            <h4>{"Create new ShelfLife"}</h4>
          </>
        }
        buttonClasses={buttonClasses}
        buttonContant="Create"
        isModalOpen={showShelfLife}
        showModal={() => {
          setShowShelfLife(true);
          dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
          })
        }} //
        closeModal={() => setShowShelfLife(false)}
      >
        <Form className="my-2" onSubmit={createShelfLife}>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              required
              placeholder="Enter months "
              value={uploadFormObj?.shelfLifeValue || ""}
              onChange={(e) => setUpdatedForm("shelfLifeValue", e.target.value, 50, true)}
            />
          </Form.Group>
          {!isEmpty(erroeMsg) && Object.prototype.hasOwnProperty.call(erroeMsg, "shelfLifeValue") && <p className="error-msg">{erroeMsg.shelfLifeValue}</p>}
          <div className="action-buttons">
            <Button
              className="reset"
              variant="primary"
              onClick={() => setShowShelfLife(false)}
              disabled={requestCall?.loading}
            >
              {"Close"}
            </Button>
            <CustomButton
              type="submit"
              buttonClasses={
                !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
              }
              loading={requestCall?.loading}
            >
              {"create"}
            </CustomButton>
          </div>
        </Form>
      </CustomModalWrap>
    );
  };
  return (
    <Container className="bhree-admin-basic px-0 py-2">
      <Row className="mx-0 px-0 mb-2">
        <h1 className="text-uppercase">{"Basics"}</h1>
      </Row>
      {(() => {
        if (
          !isEmpty(bhareeAdminBasicTable?.data) &&
          !bhareeAdminBasicTable.loading
        ) {
          return (
            <Row className="m-0">
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.variants?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.variant}
                    data={bhareeAdminBasicTable?.data?.variants}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deleteVariant(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"Variants"}</h4>
                        {variantModal("icon-add-btn")}
                      </div>
                    )}
                  //   enableColumnOrdering={false} //enable some features
                  //   enableRowSelection={false}
                  //   enableColumnResizing={false}
                  //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
                  //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
                  //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
                  />
                ) : (
                  variantModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.moqs?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.moq}
                    data={bhareeAdminBasicTable?.data?.moqs}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row, table }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() => deleteMoq(row.original.id, dispatch)}
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"MOQ's"}</h4>
                        {moqModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  moqModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.productCategories?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.productCategories}
                    data={bhareeAdminBasicTable?.data?.productCategories}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row, table }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deleteProductCategories(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                      <div className="table-heading-wrap">
                        <h4>{"Product Categories"}</h4>
                        {productCategoriesModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  productCategoriesModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.packagingTypes?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.packagingTypes}
                    data={bhareeAdminBasicTable?.data?.packagingTypes}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row }) => (
                      <div className="table-action-box">

                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deletePackagingTypes(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"Packaging Types"}</h4>
                        {packagingTypesModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  packagingTypesModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.deliveryTypes?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.deliveryTypes}
                    data={bhareeAdminBasicTable?.data?.deliveryTypes}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row, table }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deleteDeliveryType(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"Delivery Types"}</h4>
                        {deliveryTypesModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  deliveryTypesModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.paymentTypes?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.paymentTypes}
                    data={bhareeAdminBasicTable?.data?.paymentTypes}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row, table }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deletePaymentTypes(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"Payment Types"}</h4>
                        {paymentTypesModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  paymentTypesModal("icon-add-btn")
                )}
              </Col>
              <Col xs={12} md={6} lg={4} className="py-2 basic-tables-col">
                {bhareeAdminBasicTable?.data?.shelfLife?.length > 0 ? (
                  <CustomTable
                    className="table-custom-class"
                    columns={tableStructures.shelfLife}
                    data={bhareeAdminBasicTable?.data?.shelfLife}
                    enableRowActions={true}
                    positionActionsColumn={"last"}
                    enableTopToolbar={true}
                    emptyTableMsg={''}
                    enablePagination={true}
                    renderRowActions={({ row, table }) => (
                      <div className="table-action-box">
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() =>
                            deleteShelfLife(row.original.id, dispatch)
                          }
                        >
                          <DeleteIcon />
                        </CustomButton>
                      </div>
                    )}
                    renderTopToolbarCustomActions={() => (
                      <div className="table-heading-wrap">
                        <h4>{"Shelf Life"}</h4>
                        {shelfLifeModal("icon-add-btn")}
                      </div>
                    )}
                  />
                ) : (
                  shelfLifeModal("icon-add-btn")
                )}
              </Col>
            </Row>
          );
        } else if (bhareeAdminBasicTable.loading) {
          return <Spinner />;
        }
      })()}
    </Container>
  );
};

export default Basics;
