import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css"
import { Link } from "react-router-dom";

const CancellationPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const accountBanner = (
    <div className="cancellation-policy-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Cancellation Policy"}</h2>
    </div>
  );

  return (
    <section className="cancellation-policy-main-section">
      {accountBanner}
      <div className="cancellation-policy-conatiner container py-5">
        <Row className="m-0">
          <h2>{"Order Cancellation and Return Policy"}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>

          <h6>{"Cancellation Policy"}</h6>
          <p>{"The customer can choose to cancel an order any time before it's dispatched. The order cannot be cancelled once it’s out for delivery. However, the customer may choose to reject it at the time of delivery."}<br />
            {"In some cases, the customer may not be allowed to cancel the order for free, post the specified time and a cancellation fee will be charged."}<br />
            {"In case of any cancellation from the seller due to unforeseen circumstances, a full refund will be initiated for prepaid orders."}<br />
            {"Bharee reserves the right to accept the cancellation of any order. Bharee also reserves the right to waive off or modify the time window or cancellation fee from time to time."}</p>
          <h6>{"Returns Policy"}</h6>
          <p>{"Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under a particular category may not have the same returns policy. For all products, the returns/replacement policy provided on the product page shall prevail over the general returns policy. Do refer the respective item's applicable return/replacement policy below mentioned."}<br />
            {"The return policy is divided into two parts: Replacement/refund (if prepaid order)"}<br />
            {"(Replacement and refund time is 5-6 working days respectively.)"}</p>

        </Row>
      </div>
    </section>
  );
};


export default CancellationPolicy;
