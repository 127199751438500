import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import {
  DeleteIcon,
  EditIcon,
} from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteProduct,
  getApi,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import ProductCrud from "../../common/ProductCrud";
import CustomTable from "../../common/CustomTable";

const Products = () => {
  const dispatch = useDispatch();
  const [showProductUpdatedMode, setShowProductUpdatedMode] = useState(false);
  const [filterAcceptedArray, setFilterAcceptedArray] = useState([]);
  const [selectedFormObj, setSelectedFormObj] = useState({});
  const { products, } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    getApi("getProducts",dispatch);
  }, []);

  useEffect(() => {
    if (products.data?.length > 0) {
      const filtered = products?.data.filter(
        (item) => item.product_status !== "pending" && item.status
      ); // Replace with your desired filter condition
      setFilterAcceptedArray(filtered);
    }
    setShowProductUpdatedMode(false);
  }, [products]);


  return (
    <Container className="bhree-admin-products px-0">
      <Row className="mx-0 px-0 mb-2">
        <h1 className="text-uppercase">{"Products"}</h1>
      </Row>
      {(() => {
        if (!showProductUpdatedMode) {
          if (filterAcceptedArray?.length > 0 && !products.loading) {
            return (
              <Row className="m-0">
                <CustomTable
                  columns={tableStructures.admin_product}
                  data={filterAcceptedArray}
                  enableRowActions={true}
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableBottomToolbar={true}
                  enableFilters={true}
                  // enablePagination={false} //disable a default feature
                  // enableHiding={false} // show hid colmuns action
                  renderRowActions={({ row }) => (
                    <div className="table-action-box">
                      {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => {
                          setShowProductUpdatedMode(true);
                          setSelectedFormObj(row.original);
                        }}
                      >
                        <EditIcon />
                      </CustomButton>
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => deleteProduct(row.original.id, dispatch)}
                      >
                        <DeleteIcon />
                      </CustomButton>
                      {/* <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() => {
                            setShowProductUpdatedMode(true);
                            setSelectedFormObj(row.original);
                            setIsUpdateMode(true);
                            setCurrentUpdateId(row.original.id);
                          }}
                        >
                          <EditIcon />
                        </CustomButton>
                        <CustomButton
                          buttonClasses="icon-action-btn"
                          onClick={() => deleteBrand(row.original.id, dispatch)}
                        >
                          <DeleteIcon />
                        </CustomButton> */}
                    </div>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <div className="table-heading-wrap">
                      <h4>{"Products"}</h4>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                //   enableColumnOrdering={false} //enable some features
                //   enableRowSelection={false}
                //   enableColumnResizing={false}
                //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
                //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
                //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
                />
              </Row>
            );
          } else if (filterAcceptedArray?.length < 1 && !products.loading) {
            return (
              <Container >
                <p>{"No product has been created or approved yet."}</p>
              </Container>
            );
          } else if (products.loading) {
            return <Spinner />;
          }
        } else if (showProductUpdatedMode) {
          return (
            <Container className="px-0">
              <ProductCrud
                product={selectedFormObj}
                setShowProductCreateMode={setShowProductUpdatedMode}
                renderInfo={<> {!isEmpty(selectedFormObj) && (
                  <Row className="m-0 px-0">
                    <Col xs={12} md={6} lg={4} className="px-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{"Seller business name"}</Form.Label>
                        <h6>{selectedFormObj?.seller.business_name}</h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="px-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{"Seller mobile number"}</Form.Label>
                        <h6>{selectedFormObj?.seller.mobile_number}</h6>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="px-3">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{"Seller email"}</Form.Label>
                        <h6>{selectedFormObj?.seller.email}</h6>
                      </Form.Group>
                    </Col>
                  </Row>
                )}</>} />
            </Container>
          );
        }
      })()}
    </Container>
  );
};

export default Products;
