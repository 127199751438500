import React, { useEffect } from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";

import BuyerBannerImage from "../../assets/images/buyerBannerImage.png";
import BhareeBlack from "../../assets/images/BhareeBlack.png";
import StepsImage from "../../assets/images/stepsImage.png";
import CreateAccount from "../../assets/images/CreateAccount.png";
import PlaceOrder from "../../assets/images/bhareeTruck.jpeg";
import BrowseAndCompare from "../../assets/images/BrowseAndCompare.png";
import CenterCircle from "../../assets/images/CenterCircle.png";
import DesktopMobile from "../../assets/images/DesktopMobile.png";
import BeforeBharee from "../../assets/images/BeforeBharee.png";
import AfterBharee from "../../assets/images/AfterBharee.png";

import "react-image-gallery/styles/css/image-gallery.css";

import "./about-us-buyer.css";
import QuoteSection from "../common/QuoteSection";
import { Link } from "react-router-dom";
import BuyerAuth from "../auth/BuyerAuth";
import { useSelector } from "react-redux";

function AboutUsBuyer() {
  const {
    auth,
  } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const faqItems = [
    {
      header: "What is BHAREE?",
      text: "Bharee is an online b2b platform for Mineral water and beverages.",
    },
    {
      header: "Do we have to pay for delivery?",
      text: "It depend on sellers offer",
    },
    {
      header: "How do I pay?",
      text: "You can choose to pay through UPI, credit card, debit card and net banking from your saving/current bank account in India. Even you can choose to pay on delivery by cash or cheque.",
    },
    {
      header: "Are all sellers verified by Bharee?",
      text: "Yes, all seller is verified by Bharee team at the time of registration.",
    },
  ];

  const AboutUsBanner = <div className="about-us-buyer"></div>;

  const BuyersSection = (
    <Container className="buyer-section">
      <Row className="buyer-text-row m-0 ">
        <Col xs={12} sm={12} md={12} lg={6}>
          <h4 className="section-title-buyer mobile">
            <Image src={BhareeBlack} alt="Bharee" />
            {" for"}
            <span>{" buyer"}</span>
          </h4>
          <Image src={BuyerBannerImage} className="buyer-section-img" />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} className="buyer-text-col">
          <div className="buyer-text-div ">
            <h4 className="section-title-buyer desktop">
              <Image
                src={BhareeBlack}
                alt="Bharee"
              />
              {" for"}
              <span>{" buyer"}</span>
            </h4>
            <div className="buyer-section-medium-text">
              <h5>
                {"Stock your business from the"} <br />{" "}
                {"comfort of your phone"}
              </h5>
            </div>
            <div className="buyer-section-small-text">
              <h6>
                {"Choose your best brand."}
                <br className="desktop" />
                {"Fast delivery."}
              </h6>
            </div>
            <div>
              {auth?.loggedIn ? <Link to="/" className="section-link">
                Order Now
              </Link> : <BuyerAuth buttonText={"Login Now"} buttonClasses={"section-link"} />}


            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );

  const StepsSection = (
    <>
      <div className="steps-section pb-5">
        <Row className="m-0">
          <Col xs={12} sm={12} md={12}>
            <h4 className="section-title-steps">
              {"Order online in"}<br className="mobile" />
              <span>{" 3 simple steps"}</span>
            </h4>
          </Col>
          <Col xs={12} sm={12} md={12} className="steps-section-logo">
            <Container className="p-0">
              <Image src={StepsImage} alt="steps" className="w-100" />
            </Container>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={4} className="steps-card">
              <div className="steps-card-div">
                <div>
                  <Image src={CreateAccount} alt="createAccount" />
                </div>
                <div>
                  <p className="text-uppercase">Create an account</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} className="steps-card">
              <div className="steps-card-div">
                <div>
                  <Image src={BrowseAndCompare} alt="browseAndCompare" />
                </div>
                <div>
                  <p className="text-uppercase">Browse and compare</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} className="steps-card">
              <div className="steps-card-div">
                <div>
                  <Image src={PlaceOrder} alt="placeOrder" />
                </div>
                <div>
                  <p className="text-uppercase">Place your order</p>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ padding: "1% 0%" }}>
            {auth?.loggedIn ? <Link to="/" className="section-link">
              Order Now
            </Link> : <BuyerAuth buttonText={"Login Now"} buttonClasses={"section-link"} />}
          </div>
        </Container>
      </div>
      <div className="grey-section desktop"></div>
    </>
  );

  const ImagesSection = (
    <Container>
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} sm={12} md={7}>
          <Image
            src={CenterCircle}
            alt="CenterCircle"
            style={{ maxWidth: "100%" }}
          />
        </Col>
        <Col xs={12} sm={12} md={5}>
          <Image
            src={DesktopMobile}
            alt="DesktopMobile"
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>
    </Container>
  );

  const BeforeAfterSection = (
    <>
      <Row className="before-after-section m-0 p-0">
        <Col xs={12} sm={12} md={12} lg={6} className="before-after-section-left">
          <div className="before-after-heading">
            <p>
              Before{" "}
              <Image
                src={BhareeBlack}
                alt="Bharee"
                style={{ width: "22%", verticalAlign: "baseline" }}
              />
            </p>
          </div>
          <div className="before-after-info">
            <Image
              src={BeforeBharee}
              alt="BeforeBharee"
              style={{ maxWidth: "100%", width: "50%" }}
            />
            <p>{`Unknown to wholesale competitive price.
              Difficult to choose quality products for your restaurant/shop.
              Not able to choose quality product.
              No assurance for on time delivery.
              Due to manual procurement process, its require time and efforts.
              Not able to find new products and brands in the market until they approach.`}</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} className="before-after-section-right">
          <div className="before-after-heading">
            <p>
              After{" "}
              <Image
                src={BhareeBlack}
                alt="Bharee"
                style={{ width: "22%", verticalAlign: "baseline" }}
              />
            </p>
          </div>
          <div className="before-after-info">
            <Image
              src={AfterBharee}
              alt="AfterBharee"
              style={{ maxWidth: "100%", width: "50%" }}
            />
            <p>{`Updated comparative prices of all product.
                You can compare all product at one click.
                Easily procure best product with verified information.
                Assured on time delivery.
                Save hours of time with easy ordering.
                Discover local, trending and early-to-market products for your store.`}</p>
          </div>
        </Col>
      </Row>
      <Container className="pt-5 pb-5">
        {/* <Link to="/" className="section-link">
          Learn More
        </Link> */}
      </Container>
    </>
  );

  const FAQSection = (
    <div className="faq-section pb-5">
      <Container>
        <Row>
          <Col xs={12} sm={12} lg={4} className="faq-section-left">
            <div className="faq-line"></div>
            <p className="faq-bigtext m-0">Learn more about</p>
            <p className="faq-bigtext m-0">our service</p>
            <p className="faq-smalltext m-0">Frequently asked</p>
            <p className="faq-smalltext m-0">questions</p>
          </Col>
          <Col xs={12} sm={12} lg={8} className="faq-section-right">
            <Accordion>
              {faqItems.map((faq, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{faq.header}</Accordion.Header>
                  <Accordion.Body>{faq.text}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );

  return (
    <section className="AboutUs-main-section">
      {AboutUsBanner}
      {BuyersSection}
      {StepsSection}
      {ImagesSection}
      {BeforeAfterSection}
      {FAQSection}
      <QuoteSection text={"Assured comparative price with on time delivery."} />
    </section>
  );
}

export default AboutUsBuyer;
