import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import {
    getApi,
    patchProduct,
    postGetCalculatedprice,
    postProduct,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import { appConstants, areaList, regex } from "../../../../helpers/app-constants";
import Spinner from "../../../common/Spinner";
import { tableStructures } from "../../../../helpers/table-structures";
import CustomTable from "../CustomTable";
import { DeleteIcon } from "../../../../assets/svg/svg-icons";
import inputValidation from "../../../../utils/inputValidation";
const ProductCrud = ({ product = {}, setShowProductCreateMode, renderInfo = {}, listEmpty = false }) => {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState({});
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [currentUpdateId, setCurrentUpdateId] = useState("");
    const [selectedFormObj, setSelectedFormObj] = useState({});
    const [filterAcceptedArray, setFilterAcceptedArray] = useState([]);

    const { requestCall, brands, bhareeAdminBasicTable, calculatedPrice } = useSelector(
        (state) => {
            return state;
        }
    );

    const onSelectValue = (value, selectorType) => {
        let updatedObj = {};
        updatedObj[selectorType] = value;
        setSelectedFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };

    const onSelectValueValidation = (key, value, maxLenght = 1000, numericOnly = false) => {
        setErrorMsg({});
        let updatedObj = {};
        let validate = inputValidation(value, maxLenght, numericOnly);
        if (validate.pass) {
            updatedObj[key] = validate.finalValue;
            setErrorMsg({ [key]: validate.msg })
            setSelectedFormObj((previousVlaues) => {
                return ({
                    ...previousVlaues,
                    ...updatedObj,
                })
            });
        } else {
            setErrorMsg({ [key]: validate.msg })
        }
    };


    const onSelectVariantsValues = (id, value, selectorType, i) => {
        let updatedObj = {};
        updatedObj[selectorType] = value;
        setSelectedFormObj((prevState) => {
            const newArray = [...prevState.selectedVariants];
            newArray.map((item, index) => {
                if (item.id === id) {

                    return (newArray[index][selectorType] = value);
                }
                return item;
            });
            return { ...prevState, selectedVariants: newArray };
        });
    };




    useEffect(() => {
        if (!isEmpty(requestCall?.errors) && !requestCall?.errors?.success) {
            setErrorMsg(requestCall.errors.message)
        } else {
            setErrorMsg("")
        }
    }, [requestCall]);




    const handleFileChange = (e, variant) => {
        const files = e.target.files;
        const newImageData = [];
        const newErrorMessages = [];

        // Define dimension constraints
        const minWidth = 0;
        const minHeight = 0;
        const maxWidth = 10000000000000;
        const maxHeight = 1000000000000;

        const validateImageDimensions = (file) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    if (
                        img.width >= minWidth &&
                        img.width <= maxWidth &&
                        img.height >= minHeight &&
                        img.height <= maxHeight
                    ) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
                img.onerror = () => {
                    reject(new Error("Invalid image file"));
                };
            });
        };

        const processFiles = async () => {
            for (let i = 0; i < files?.length; i++) {
                const isValid = await validateImageDimensions(files[i]);
                if (isValid) {
                    const imageKey = `image_${i + 1}_${variant}`; // Generate a unique key for each image
                    newImageData.push({ key: imageKey, file: files[i] });
                } else {
                    newErrorMessages.push(`Image ${files[i].name} does not meet the size requirements.  Min Width = 442 px | Min Height = 414px | Max Width = 467px | Max Height = 439px`);
                }
            }

            if (newImageData?.length > 0 && newErrorMessages?.length <= 0) {
                if (Object.prototype.hasOwnProperty.call(selectedFormObj, "imageData")) {
                    setSelectedFormObj((prevState) => {
                        const newArray = [...prevState.imageData];
                        newImageData.map((item) => newArray.push(item));
                        return { ...prevState, imageData: newArray };
                    });
                } else {
                    let updatedObj = {};
                    updatedObj["imageData"] = newImageData;
                    setSelectedFormObj((previousValues) => ({
                        ...previousValues,
                        ...updatedObj,
                    }));

                }
                setErrorMsg((prevErrors) => ({
                    ...prevErrors,
                    [`image_errors_${variant}`]: [],
                }));
            } else {
                let updatedObj = {};
                updatedObj["imageData"] = [];
                setSelectedFormObj((previousValues) => ({
                    ...previousValues,
                    ...updatedObj,
                }));
            }

            if (newErrorMessages?.length > 0) {
                setErrorMsg((prevErrors) => ({
                    ...prevErrors,
                    [`image_errors_${variant}`]: newErrorMessages,
                }));
                e.target.value = ''; // Clear the input
            }
        };

        processFiles();
    };

    useEffect(() => {
        if (!isEmpty(product)) {
            setSelectedFormObj(product);
            setIsUpdateMode(true);
            setCurrentUpdateId(product.id);

        }
        getApi("getBhreeAdminBasicTables", dispatch);
        getApi("getBrands", dispatch)
        dispatch({
            type: appConstants.REQUEST_FAIL,
            payload: {}
        });
        dispatch({
            type: appConstants.CLEAR_CALCULATED_PRICE,
        });
    }, []);
    useEffect(() => {

        if (brands.data?.length > 0) {
            const filtered = brands?.data.filter(
                (item) => item.brand_status === "accept" && item.status
            ); // Replace with your desired filter condition
            setFilterAcceptedArray(filtered);
        }
    }, [brands]);

    const objectToFormData = (obj, formData, parentKey = '') => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                const formKey = parentKey ? `${parentKey}[${key}]` : key;

                if (typeof value === 'object' && !Array.isArray(value)) {
                    objectToFormData(value, formData, formKey);
                } else if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        const arrayFormKey = `${formKey}[${index}]`;
                        objectToFormData(item, formData, arrayFormKey);
                    });
                } else {
                    formData.append(formKey, value);
                }
            }
        }

        return formData;
    }

    const createUpdateProduct = (e) => {
        e.preventDefault();

        if (
            !isEmpty(selectedFormObj) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "brand") &&
            !isEmpty(selectedFormObj.brand) &&
            Object.prototype.hasOwnProperty.call(
                selectedFormObj,
                "manufactured_by"
            ) &&
            !isEmpty(selectedFormObj.manufactured_by) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "product_name") &&
            !isEmpty(selectedFormObj.product_name) &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "marketed_by") &&
            !isEmpty(selectedFormObj.marketed_by) &&
            Object.prototype.hasOwnProperty.call(
                selectedFormObj,
                "productCategory"
            ) &&
            !isEmpty(selectedFormObj.productCategory) &&
            Object.prototype.hasOwnProperty.call(
                selectedFormObj,
                "selectedVariants"
            ) &&
            selectedFormObj.selectedVariants.length > 0 &&
            Object.prototype.hasOwnProperty.call(selectedFormObj, "shelf_life") &&
            !isEmpty(selectedFormObj.shelf_life)
        ) {

            const formData = new FormData();
            objectToFormData(selectedFormObj, formData,);
            if (Object.prototype.hasOwnProperty.call(selectedFormObj, "imageData") && selectedFormObj?.imageData?.length > 0) {
                selectedFormObj?.imageData.map((img) => {
                    return formData.append(img?.key, img?.file);
                })
            }
            // console.log("*****", formData)
            if (isUpdateMode) {
                patchProduct(currentUpdateId, formData, dispatch);
            } else {
                postProduct(formData, dispatch);
            }
        }
    };

    // useEffect(() => {
    //     console.log("**** selectedVariants", selectedFormObj)
    // }, [selectedFormObj])


    // useEffect(() => {
    //     console.log("**** errorMsg", errorMsg)
    // }, [errorMsg])

    const debouncedUpdatePrice = useCallback(
        debounce((id, value) => {
            let obj = {}
            obj["variantId"] = id;
            obj["price"] = value
            postGetCalculatedprice(obj, dispatch);
        }, 1000), // Adjust delay as needed
        []
    );

    const handlePriceChange = (id, value, selectorType, i) => {
        onSelectVariantsValues(id, value, selectorType, i);
        debouncedUpdatePrice(id, value);
    };
    return (


        <>
            {/* {brands.loading && bhareeAdminBasicTable.loading ? <Spinner /> : (

            )} */}

            {!brands.loading && !bhareeAdminBasicTable.loading ? (
                <Row className="m-0 w-full  form-row ">
                    <Row className="m-0 px-0 w-full px-0 mb-4 title-button-row">
                        <h2>{!isUpdateMode ? "Add product" : "Update product"}</h2>
                        {!listEmpty && (<CustomButton
                            buttonClasses="view-switch-btn"
                            onClick={() => setShowProductCreateMode(false)}
                        >
                            {"List"}
                        </CustomButton>)}

                    </Row>
                    <Form
                        className="modal-custom-class  px-0"
                        onSubmit={createUpdateProduct}
                    >
                        {!isEmpty(renderInfo) && (renderInfo)}
                        {filterAcceptedArray?.length > 0 && (
                            <Row className="m-0 px-0">
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            {"Select Brand"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>

                                        <CustomSelectDropdown
                                            style={{
                                                multiselectContainer: {
                                                    backgroundColor: "#f8f8f8",
                                                },
                                                chips: {
                                                    margin: 0,
                                                },
                                                option: {
                                                    background: "#fbb040",
                                                    color: "#FFFFFF",
                                                    borderBottom: "1px solid #FFFFFF",
                                                },
                                            }}
                                            options={filterAcceptedArray}
                                            onSelect={(e) => onSelectValue(e[0], "brand")}
                                            selectedValues={
                                                Object.prototype.hasOwnProperty.call(
                                                    selectedFormObj,
                                                    "brand"
                                                )
                                                    ? [selectedFormObj?.brand]
                                                    : []
                                            }
                                            displayValue={"brand_name"}
                                            disable={isUpdateMode ? true : false}
                                            singleSelect
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            {"Product Name"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={selectedFormObj?.product_name || ""}
                                            maxLength={50}
                                            onChange={(e) =>
                                                onSelectValue(e.target.value, "product_name")
                                            }
                                        // onChange={(e) => onSelectValueValidation("product_name", e.target.value, 50)}
                                        />
                                        {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "product_name") && <p className="error-msg">{errorMsg?.product_name}</p>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            {"Manufactured By"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={
                                                Object.prototype.hasOwnProperty.call(
                                                    selectedFormObj,
                                                    "manufactured_by"
                                                )
                                                    ? selectedFormObj?.manufactured_by
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                onSelectValue(e.target.value, "manufactured_by")
                                            }
                                            maxLength={100}
                                        // onChange={(e) => onSelectValueValidation("manufactured_by", e.target.value, 100)}
                                        />
                                        {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, "manufactured_by") && <p className="error-msg">{errorMsg?.manufactured_by}</p>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    {bhareeAdminBasicTable?.data?.shelfLife?.length > 0 && (
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>
                                                {"Select Self life"}
                                                <span className="redStreSpan">{"*"}</span>
                                            </Form.Label>

                                            <CustomSelectDropdown
                                                style={{
                                                    multiselectContainer: {
                                                        backgroundColor: "#f8f8f8",
                                                    },
                                                    chips: {
                                                        margin: 0,
                                                    },
                                                    option: {
                                                        background: "#fbb040",
                                                        color: "#FFFFFF",
                                                        borderBottom: "1px solid #FFFFFF",
                                                    },
                                                }}
                                                options={bhareeAdminBasicTable?.data?.shelfLife}
                                                onSelect={(e) =>
                                                    onSelectValue(e[0], "shelf_life")
                                                }
                                                // onRemove={(e) => onSelectValue(e[0], "selfLife")}
                                                selectedValues={
                                                    Object.prototype.hasOwnProperty.call(
                                                        selectedFormObj,
                                                        "shelf_life"
                                                    )
                                                        ? [selectedFormObj["shelf_life"]]
                                                        : []
                                                }
                                                singleSelect
                                                displayValue={"displayName"}
                                            />
                                        </Form.Group>
                                    )}
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            {"Product Category"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <CustomSelectDropdown
                                            style={{
                                                multiselectContainer: {
                                                    backgroundColor: "#f8f8f8",
                                                },
                                                chips: {
                                                    margin: 0,
                                                },
                                                option: {
                                                    background: "#fbb040",
                                                    color: "#FFFFFF",
                                                    borderBottom: "1px solid #FFFFFF",
                                                },
                                            }}
                                            options={bhareeAdminBasicTable?.data?.productCategories}
                                            onSelect={(e) => onSelectValue(e[0], "productCategory")}
                                            selectedValues={
                                                Object.prototype.hasOwnProperty.call(
                                                    selectedFormObj,
                                                    "productCategory"
                                                )
                                                    ? [selectedFormObj["productCategory"]]
                                                    : []
                                            }
                                            displayValue={"name"}
                                            singleSelect
                                            disable={isUpdateMode ? true : false}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            {"Marketed By"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={selectedFormObj?.marketed_by || ""}
                                            maxLength={100}
                                            onChange={(e) =>
                                                onSelectValue(e.target.value, "marketed_by")
                                            }
                                        // onChange={(e) => onSelectValueValidation("marketed_by", e.target.value, 100)}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>
                                            {"HNS Code"}
                                            <span className="redStreSpan">{"*"}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={
                                                Object.prototype.hasOwnProperty.call(
                                                    selectedFormObj,
                                                    "hns"
                                                )
                                                    ? selectedFormObj?.hns
                                                    : "2201"
                                            }
                                            onChange={(e) =>
                                                onSelectValue(e.target.value, "hns")
                                            }
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="px-3">
                                    {bhareeAdminBasicTable?.data?.variants?.length > 0 && (
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>
                                                {"Select Variants"}
                                                <span className="redStreSpan">{"*"}</span>
                                            </Form.Label>

                                            <CustomSelectDropdown
                                                style={{
                                                    multiselectContainer: {
                                                        backgroundColor: "#f8f8f8",
                                                    },
                                                    chips: {
                                                        background: "#fbb040",
                                                        margin: "0 2px",
                                                    },
                                                    option: {
                                                        background: "#fbb040",
                                                        color: "#FFFFFF",
                                                        borderBottom: "1px solid #FFFFFF",
                                                    },
                                                }}
                                                options={bhareeAdminBasicTable?.data?.variants}
                                                onSelect={(e) => onSelectValue(e, "selectedVariants")}
                                                onRemove={(e) => onSelectValue(e, "selectedVariants")}
                                                selectedValues={selectedFormObj?.selectedVariants}
                                                displayValue={"displayName"}
                                                disable={isUpdateMode}
                                            />
                                        </Form.Group>
                                    )}
                                </Col>
                            </Row>
                        )}
                        {brands?.data?.length < 1 && (
                            <p key={"empty text"} className="m-0 px-0 text-center">{"No brand is available for product creation."}</p>
                        )}
                        {Object.prototype.hasOwnProperty.call(
                            selectedFormObj,
                            "selectedVariants"
                        ) && selectedFormObj?.selectedVariants?.length > 0 && !isUpdateMode ? (
                            <>{selectedFormObj?.selectedVariants?.length > 0 ? (
                                selectedFormObj?.selectedVariants?.map((variant, index) => {
                                    let fullRow = (
                                        <Row className="m-0 px-0 py-4 variant-options-row" key={variant?.displayName + index}>
                                            <h6 className="px-3 mb-2">
                                                {variant?.displayName}
                                                {" Variant"}
                                            </h6>

                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"MOQ's"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <CustomSelectDropdown
                                                        style={{
                                                            multiselectContainer: {
                                                                backgroundColor: "#f8f8f8",
                                                            },
                                                            chips: {
                                                                background: "#fbb040",
                                                                margin: "0 2px",
                                                            },
                                                            option: {
                                                                background: "#fbb040",
                                                                color: "#FFFFFF",
                                                                borderBottom: "1px solid #FFFFFF",
                                                            },
                                                        }}
                                                        options={bhareeAdminBasicTable?.data?.moqs}

                                                        onSelect={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "moq",
                                                                index
                                                            );
                                                        }

                                                        }
                                                        onRemove={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "moq",
                                                                index
                                                            );
                                                        }}
                                                        selectedValues={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "moq"
                                                            )
                                                                ?
                                                                selectedFormObj?.selectedVariants[index][
                                                                "moq"
                                                                ]

                                                                : []
                                                        }
                                                        displayValue={"quantity"}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6} lg={4} className="px-3">

                                                <Form.Label>
                                                    {"Price Per Case [₹] "}
                                                    <span className="redStreSpan">{"*"}</span>
                                                    {!isEmpty(calculatedPrice?.data) && Object.prototype.hasOwnProperty.call(
                                                        calculatedPrice?.data, variant.id) &&
                                                        <span className="positiveMsg"> {" Final Price : " + calculatedPrice?.data[variant.id].offer_price}  </span>}

                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={Object.prototype.hasOwnProperty.call(
                                                        selectedFormObj?.selectedVariants[index],
                                                        "price")
                                                        ? selectedFormObj?.selectedVariants[index]["price"]
                                                        : ""}
                                                    maxLength={10}
                                                    onChange={(e) => {


                                                        return regex.test(e.target.value)
                                                            ? handlePriceChange(variant.id,
                                                                e.target.value,
                                                                "price",
                                                                index)
                                                            : null;
                                                    }}
                                                />

                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"Packaging Types"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <CustomSelectDropdown
                                                        style={{
                                                            multiselectContainer: {
                                                                backgroundColor: "#f8f8f8",
                                                            },
                                                            chips: {
                                                                margin: 0,
                                                            },
                                                            option: {
                                                                background: "#fbb040",
                                                                color: "#FFFFFF",
                                                                borderBottom: "1px solid #FFFFFF",
                                                            },
                                                        }}
                                                        options={
                                                            bhareeAdminBasicTable?.data?.packagingTypes
                                                        }
                                                        onSelect={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e[0],
                                                                "packaging_type",
                                                                index
                                                            );
                                                            // onSelectValue(e[0], `_${variant.quantity}.packaging_type`)
                                                        }
                                                        }
                                                        selectedValues={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "packaging_type"
                                                            )
                                                                ? [
                                                                    selectedFormObj?.selectedVariants[index][
                                                                    "packaging_type"
                                                                    ],
                                                                ]
                                                                : []
                                                        }
                                                        displayValue={"name"}
                                                        singleSelect
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"Packaging Size [Bottles]"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        value={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "packaging_size"
                                                            )
                                                                ? selectedFormObj?.selectedVariants[index][
                                                                "packaging_size"
                                                                ]
                                                                : ""
                                                        }
                                                        maxLength={10}
                                                        onChange={(e) => {
                                                            return regex.test(e.target.value)
                                                                ? onSelectVariantsValues(
                                                                    variant.id,
                                                                    e.target.value,
                                                                    "packaging_size",
                                                                    index
                                                                )
                                                                : null;
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"Weight Per Case [in kg]"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        maxLength={10}
                                                        value={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "weight_per_case"
                                                            )
                                                                ? selectedFormObj?.selectedVariants[index][
                                                                "weight_per_case"
                                                                ]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            return regex.test(e.target.value)
                                                                ? onSelectVariantsValues(
                                                                    variant.id,
                                                                    e.target.value,
                                                                    "weight_per_case",
                                                                    index
                                                                )
                                                                : null;
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"Areas"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <CustomSelectDropdown
                                                        style={{
                                                            multiselectContainer: {
                                                                backgroundColor: "#f8f8f8",
                                                            },
                                                            chips: {
                                                                background: "#fbb040",
                                                                margin: "0 2px",
                                                            },
                                                            option: {
                                                                background: "#fbb040",
                                                                color: "#FFFFFF",
                                                                borderBottom: "1px solid #FFFFFF",
                                                            },
                                                        }}
                                                        options={areaList}

                                                        onSelect={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "area_list",
                                                                index
                                                            );
                                                        }

                                                        }
                                                        onRemove={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "area_list",
                                                                index
                                                            );
                                                        }}
                                                        selectedValues={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "area_list"
                                                            )
                                                                ?
                                                                selectedFormObj?.selectedVariants[index][
                                                                "area_list"
                                                                ]

                                                                : []
                                                        }
                                                        displayValue={"area"}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {/* <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"payment Types"}
                                                        <span className="redStreSpan">{"*"}</span>
                                                    </Form.Label>
                                                    <CustomSelectDropdown
                                                        style={{
                                                            multiselectContainer: {
                                                                backgroundColor: "#f8f8f8",
                                                            },
                                                            chips: {
                                                                background: "#fbb040",
                                                            },
                                                            option: {
                                                                background: "#fbb040",
                                                                color: "#FFFFFF",
                                                                borderBottom: "1px solid #FFFFFF",
                                                            },
                                                        }}
                                                        options={bhareeAdminBasicTable?.data.paymentTypes}

                                                        onSelect={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "payment_types",
                                                                index
                                                            );
                                                        }

                                                        }
                                                        onRemove={(e) => {
                                                            onSelectVariantsValues(
                                                                variant.id,
                                                                e,
                                                                "payment_types",
                                                                index
                                                            );
                                                        }}
                                                        selectedValues={
                                                            Object.prototype.hasOwnProperty.call(
                                                                selectedFormObj?.selectedVariants[index],
                                                                "payment_types"
                                                            )
                                                                ?
                                                                selectedFormObj?.selectedVariants[index][
                                                                "payment_types"
                                                                ]

                                                                : []
                                                        }
                                                        displayValue={"name"}
                                                    />
                                                </Form.Group>
                                            </Col> */}
                                            <Col xs={12} md={6} lg={4} className="px-3">
                                                <Form.Group className="mb-3  w-100" controlId="formBasicEmail">
                                                    <Form.Label>
                                                        {"Upload Variant Images"}
                                                        {!isUpdateMode ? (
                                                            <span className="redStreSpan">{"*"}</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        multiple
                                                        required={!isUpdateMode ? true : false}
                                                        onChange={(e) => handleFileChange(e, variant.quantity)}

                                                    />

                                                </Form.Group>

                                            </Col>
                                            {!isEmpty(errorMsg) && Object.prototype.hasOwnProperty.call(errorMsg, `image_errors_${variant.quantity}`) && errorMsg[`image_errors_${variant.quantity}`].map((error) => <p className="error-msg">{error}</p>
                                            )
                                            }
                                        </Row>
                                    );
                                    return fullRow;
                                })) : (
                                <p className="text-center py-4">
                                    {"Please select variants of product "}
                                </p>
                            )}

                                {/* {errorMsg && <p className="error-msg">{errorMsg}</p>} */}

                            </>
                        ) : (isUpdateMode &&
                            <Row className="variant-options-table-row">
                                <CustomTable
                                    CustomTopClass="mb-0"
                                    columns={tableStructures.crud_variant}
                                    data={selectedFormObj?.selectedVariants}
                                    enableRowActions={false}
                                    positionActionsColumn={"last"}
                                    // enableFilters={false} // remove filter icons
                                    enableDensityToggle={false} // remove density action
                                    enableColumnActions={false} // remove column action
                                    enableFullScreenToggle={false} // emove full screen option
                                    // enablePagination={false} //disable a default feature
                                    // enableHiding={false} // show hid colmuns action
                                    emptyTableMsg={''}
                                    renderRowActions={({ row, table }) => (
                                        <div className="table-action-box">
                                            {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                                            {/* <CustomButton
                                            buttonClasses="icon-action-btn"
                                            onClick={() => {
                                                // setShowProductUpdatedMode(true);
                                                // setSelectedFormObj(row.original);
                                            }}
                                        >
                                            <EditIcon />
                                        </CustomButton> */}
                                            <CustomButton
                                                buttonClasses="icon-action-btn"
                                            // onClick={() => deleteProduct(row.original.id, dispatch)}
                                            >
                                                <DeleteIcon />
                                            </CustomButton>
                                        </div>
                                    )}
                                    renderTopToolbarCustomActions={({ table }) => (
                                        <div className="table-heading-wrap">
                                            <h4>{"Products"}</h4>
                                        </div>
                                    )}
                                    initialState={{
                                        columnPinning: {
                                            right: ["mrt-row-actions"],
                                        },
                                    }}
                                //   enableColumnOrdering={false} //enable some features
                                //   enableRowSelection={false}
                                //   enableColumnResizing={false}
                                //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
                                //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
                                //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
                                />
                            </Row>
                        )}
                        <div className="action-buttons">
                            {!listEmpty && (<CustomButton
                                buttonClasses="close-btn"
                                onClick={() => setShowProductCreateMode(false)}
                                disabled={requestCall?.loading}
                            >
                                {"Close"}
                            </CustomButton>)}

                            <CustomButton
                                variant="primary"
                                buttonClasses={
                                    !requestCall?.loading
                                        ? "submit-btn"
                                        : "submit-btn-loading"
                                }
                                type="submit"
                                loading={requestCall?.loading}
                            >
                                {!isUpdateMode ? "Add" : "update"}
                            </CustomButton>
                        </div>

                    </Form>
                </Row>
            ) : (
                <Spinner />
            )}
        </>
    );
};



export default ProductCrud;
