import { appConstants } from "../helpers/app-constants";

// let user = JSON.parse(localStorage.getItem("user"));
// const initialState = user ? { loggedIn: true, user } : {};
const initialState = {
  loading: false,
  data: {},
  errors: {},
};

export function addresses(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOADING_ADDRESSES:
      return {
        ...state,
        loading: true,
        data: {},
        errors: {},
      };

    case appConstants.GET_ADDRESSES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };

    case appConstants.ERRORS_ADDRESSES:
      return {
        ...state,
        loading: false,
        data: {},
        errors: action.payload,
      };

    default:
      return state;
  }
}


