import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RefferSection from "../../../common/RefferSection";

import "react-image-gallery/styles/css/image-gallery.css";

import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { getApi } from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../../common/Spinner";
import TimestampConverter from "../../../../utils/TimestampConverter";
import OrderSummaryCard from "../../../Cart/OrderSummaryCard";

const DashbordOrder = ({ id }) => {
  const dispatch = useDispatch();
  // const { id } = useParams();
  const { currentOrder } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    getApi("getOrder", dispatch, "/" + id)
  }, [id]);

  const SummaryBox = () => {
    if (!isEmpty(currentOrder.data)) {
      return (
        <>
          <p className="m-0">{"Order Summary"}</p>
          <Row className="price-box mx-0 my-2">
            <Col xs={4}></Col>
            <Col xs={5} className="title p-0">
              {"Subtotal:"}
            </Col>
            <Col xs={3} className="amount p-0">
              {/* {parseFloat(checkoutItems?.data.totalAmount).toFixed(2) || 0}&nbsp;{"₹"} */}
              {currentOrder?.data?.totalAmount} &nbsp;{"₹"}
            </Col>
          </Row>
          <Row className="price-box mx-0 my-2">
            <Col xs={4}></Col>
            <Col xs={5} className="title p-0">
              {"Delivery Charges:"}
            </Col>
            <Col xs={3} className="amount p-0">
              {/* {parseFloat(checkoutItems?.data.deliveryCharges).toFixed(2) || 0}&nbsp;{"₹"} */}
              {currentOrder?.data?.totalDelivery} &nbsp;{"₹"}
            </Col>
          </Row>
          <Row className="price-box mx-0 my-2">
            <Col xs={4}></Col>
            <Col xs={8} className="title p-0">
              <hr className="mx-0 cutomeHR" />
            </Col>
          </Row>
          <Row className="price-box mx-0 my-2">
            <Col xs={4}></Col>
            <Col xs={5} className="amount p-0">
              {"Payable amount"}
            </Col>
            <Col xs={3} className="amount p-0">
              {/* {parseFloat(checkoutItems?.data.payableAmount).toFixed(2)|| 0}&nbsp;{"₹"} */}
              {currentOrder?.data?.payableAmount} &nbsp;{"₹"}
            </Col>
          </Row>
          {/* <div className="text-uppercase button-div mx-0 my-4">
            <Button className="text-uppercase">
              {"Place your Order"}
            </Button>
          </div> */}
        </>
      )
    }

  }
  const orderBanner = (
    <div className="order-banner-main-div text-uppercase desktop">
      <p> <Link to={`/`} >{"Home "}</Link></p>
      <h2>{"Order"}</h2>
    </div>
  );

  const tempAddress = {
    id: '1c4af987-1546-4b15-a55b-687fdd672c14',
    user_id: 'ec1d3640-4254-4c07-abf2-78156bd8a859',
    full_name: 'Test 2',
    primary_number: 9876543219,
    alternate_number: 9876543219,
    address_type_id: {
      id: '2a43b8c6-5c3a-4438-b55b-6a85f1f64135',
      type_name: 'Delivery'
    },
    address_line_1: 'Test 2',
    address_line_2: 'dfasdfas',
    address_line_3: 'asdfasdf',
    pin_code: 123123,
    city: 'South Delhi',
    state: 'Delhi',
    country: 'India',
    set_default: 1,
    status: 1,
    x_coordinate: 0,
    y_coordinate: 0,
    createdAt: '2024-03-12T03:09:00.000Z',
    updatedAt: '2024-04-25T08:51:45.000Z'
  }
  return (
    <section className="order-main-section">
      {(() => {
        if (!currentOrder.loading && !isEmpty(currentOrder?.data)) {
          return (<div className="py-4">
            <div className="order-section-title-div">
              <h4 className="order-section-title m-0">{"Order Details"}</h4>
            </div>
            <div id="borderLeft"></div>

            <Row className="m-0 py-2 order-info">
              <Col xs={12} sm={12} md={8} lg={8} className="p-0 text-start">
                <div><p className="m-0">Order ID: <span># {currentOrder?.data?.orderId}</span> </p></div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="p-0  left-col">
                <div><p className="m-0">Order Placement Date: <span>{TimestampConverter(currentOrder?.data?.order_date)}</span> </p></div>

                {/* <div><p>Order Placement Date: <span>03-05-2024</span> </p></div> */}
              </Col>
            </Row>
            <Row className="m-0 py-2">

              <Col xs={12} sm={12} md={8} lg={8} className="p-0">
                {/* <OrderSummaryCard card={currentOrder?.data?.product_variant_id[0]} crud={false} /> */}
                {/* {!isEmpty(cardItem) && } */}
                <OrderSummaryCard card={currentOrder?.data} crud={false} currentOrderView={true} />

              </Col>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="order-summery-price p-0"
              >
                {SummaryBox()}

              </Col>
            </Row>
            <div className="order-section-title-div">
              <h4 className="order-section-title m-0">{"Delivery Address"}</h4>
            </div>
            <div id="borderLeft"></div>
            <Row className="m-0 py-2 order-info">
              <Col xs={12} sm={12} md={8} lg={8} className="p-0 text-start">
                <div><p className="m-0"> <span> {currentOrder?.data?.delivery_address_id?.address_line_1},{tempAddress.address_line_2},{currentOrder?.data?.delivery_address_id?.address_line_3} </span></p></div>
                <div><p className="m-0">Citi :<span> {currentOrder?.data?.delivery_address_id?.city}</span></p></div>
                <div><p className="m-0">State:<span> {currentOrder?.data?.delivery_address_id?.state}  </span></p></div>
                <div><p className="m-0">Country:<span> {currentOrder?.data?.delivery_address_id?.country} </span></p></div>

                <div><p className="m-0">Pin code: <span>{currentOrder?.data?.delivery_address_id?.pin_code}</span></p></div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="p-0  left-col">
                <div><p className="m-0">Constact person name : <span>{currentOrder?.data?.delivery_address_id?.full_name}</span></p></div>
                <div><p className="m-0">Constact person Number : <span>{currentOrder?.data?.delivery_address_id?.primary_number}</span></p></div>
                {/* <div><p>Order Placement Date: <span>03-05-2024</span> </p></div> */}
              </Col>
            </Row>
            <div className="order-section-title-div">
              <h4 className="order-section-title m-0">{"Pick Up Address"}</h4>
            </div>
            <div id="borderLeft"></div>
            <Row className="m-0 py-2 order-info">
              <Col xs={12} sm={12} md={8} lg={8} className="p-0 text-start">
                <div><p className="m-0"> <span> {currentOrder?.data?.seller_address_id?.address_line_1},{currentOrder?.data?.seller_address_id?.address_line_2},{currentOrder?.data?.seller_address_id?.address_line_3} </span></p></div>
                <div><p className="m-0">Citi :<span> {currentOrder?.data?.seller_address_id?.city}</span></p></div>
                <div><p className="m-0">State:<span> {currentOrder?.data?.seller_address_id?.state}  </span></p></div>
                <div><p className="m-0">Country:<span> {currentOrder?.data?.seller_address_id?.country} </span></p></div>

                <div><p className="m-0">Pin code: <span>{currentOrder?.data?.seller_address_id?.pin_code}</span></p></div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="p-0  left-col">
                <div><p className="m-0">Seller : <span>{currentOrder?.data?.seller_address_id?.full_name}</span></p></div>
                <div><p className="m-0">Seller contact Number : <span>{currentOrder?.data?.seller_address_id?.primary_number}</span></p></div>
                {/* <div><p>Order Placement Date: <span>03-05-2024</span> </p></div> */}
              </Col>
            </Row>
          </div>)
        } else if (currentOrder.loading) {
          return <Spinner />;
        }
      })()}

    </section>
  );
}

export default DashbordOrder;
