import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { DeleteIcon, EditIcon } from "../../../../assets/svg/svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBrand,
  getApi
} from "../../../../actions/structure.action";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import BrandCrud from "../../common/BrandCrud";
import CustomTable from "../../common/CustomTable";

const Brands = () => {
  const dispatch = useDispatch();
  const [showBrandCreateMode, setShowBrandCreateMode] = useState(false);
  const [filterAcceptedArray, setFilterAcceptedArray] = useState([]);
  const [currentBrandObj, setCurrentBrandObj] = useState({});
  const { brands } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    getApi("getBrands", dispatch)
  }, []);
  useEffect(() => {
    if (brands?.data?.length < 1) {
      setShowBrandCreateMode(true);
    } else {
      setShowBrandCreateMode(false);
    }
    if (brands.data?.length > 0) {
      const filtered = brands?.data.filter(
        (item) => item.brand_status !== "pending" && item.status
      ); // Replace with your desired filter condition
      setFilterAcceptedArray(filtered);
    }
  }, [brands]);


  return (
    <Container className="bhree-admin-brand mb-3 px-0">
      <Row className="mx-0 mb-2 px-0 ">
        <h1 className="text-uppercase">{"Brands"}</h1>
      </Row>

      {brands.loading ? (<Container className="seller-product-create-form px-0">
        <Spinner />
      </Container>) : filterAcceptedArray?.length > 0 && !showBrandCreateMode ? (<Row className="m-0">
        <CustomTable
          columns={tableStructures.brand}
          data={filterAcceptedArray}
          enableRowActions={true}
          positionActionsColumn={"last"}
          enableTopToolbar={true}
          emptyTableMsg={''}
          enablePagination={true}
          enableBottomToolbar={true}
          enableFilters={true}
          renderRowActions={({ row }) => (
            <div className="table-action-box">
              {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
              <CustomButton
                buttonClasses="icon-action-btn"
                onClick={() => {
                  setShowBrandCreateMode(true);
                  setCurrentBrandObj(row.original);
                }}
              >
                <EditIcon />
              </CustomButton>
              <CustomButton
                buttonClasses="icon-action-btn"
                onClick={() => deleteBrand(row.original.id, dispatch)}
              >
                <DeleteIcon />
              </CustomButton>
            </div>
          )}
          renderTopToolbarCustomActions={() => (
            <div className="table-heading-wrap">
              <h4>{"Brands"}</h4>

              <CustomButton
                buttonClasses="icon-add-btn"
                onClick={() => {
                  setShowBrandCreateMode(true);
                  setCurrentBrandObj({});
                }}
              >
                {"Add"}
              </CustomButton>
            </div>
          )}
          initialState={{
            columnPinning: {
              right: ["mrt-row-actions"],
            },
          }}
        //   enableColumnOrdering={false} //enable some features
        //   enableRowSelection={false}
        //   enableColumnResizing={false}
        //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
        //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
        //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
        />
      </Row>) : <Container className="seller-product-create-form px-0">
        <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setShowBrandCreateMode} listEmpty={filterAcceptedArray?.length > 0 ? false : true} setCurrentBrandObj={setCurrentBrandObj} />
      </Container>}


      {/* {(() => {
        if (!showBrandCreateMode) {
          if (filterAcceptedArray?.length > 0 && !brands.loading) {
            return (
              <Row className="m-0">
                <CustomTable
                  columns={tableStructures.brand}
                  data={filterAcceptedArray}
                  enableRowActions={true}
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableBottomToolbar={true}
                  enableFilters={true}
                  renderRowActions={({ row }) => (
                    <div className="table-action-box">
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => {
                          setShowBrandCreateMode(true);
                          setCurrentBrandObj(row.original);
                        }}
                      >
                        <EditIcon />
                      </CustomButton>
                      <CustomButton
                        buttonClasses="icon-action-btn"
                        onClick={() => deleteBrand(row.original.id, dispatch)}
                      >
                        <DeleteIcon />
                      </CustomButton>
                    </div>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <div className="table-heading-wrap">
                      <h4>{"Brands"}</h4>

                      <CustomButton
                        buttonClasses="icon-add-btn"
                        onClick={() => {
                          setShowBrandCreateMode(true);
                          setCurrentBrandObj({});
                        }}
                      >
                        {"Add"}
                      </CustomButton>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                />
              </Row>
            );
          } else if (filterAcceptedArray?.length < 1 && !brands.loading) {
            return (
              <Container className="seller-product-create-form px-0">
                <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setShowBrandCreateMode} istEmpty={filterAcceptedArray.length > 0 ? false : true} setCurrentBrandObj={setCurrentBrandObj} />
              </Container>
            );
          } else if (brands.loading) {
            return <Spinner />;
          }
        } else if (showBrandCreateMode) {
          return (
            <Container className="seller-product-create-form px-0">
              <BrandCrud brand={currentBrandObj} setShowBrandCreateMode={setShowBrandCreateMode} istEmpty={filterAcceptedArray.length > 0 ? false : true} setCurrentBrandObj={setCurrentBrandObj} />
            </Container>
          );
        }
      })()} */}
    </Container>
  );
};


export default Brands;
