import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Button,
  Accordion,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { InfoIcon, DeliveryVanIcon } from "../../assets/svg/svg-icons";
import { CartIcon, DotIcon } from "../../assets/svg/svg-icons";
import fulfill from "../../assets/images/fulfill.png";
import RefferSection from "../common/RefferSection";

import plastic1 from "../../assets/images/plastic-1.png";
import { Link, useNavigate } from "react-router-dom";
import BrandCard from "../Brands/BrandCard";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./product.css";
import { useDispatch, useSelector } from "react-redux";
import { getApi,  postCart } from "../../actions/structure.action";
import { useParams } from 'react-router-dom';
import convertToLiters from "../../utils/convertToLiters";
import isEmpty from "../../utils/is-Empty";
import Spinner from "../common/Spinner";
import CustomButton from "../common/CustomButton";
import CustomSelectDropdown from "../common/CustomSelectDropdown";
import { deliverytypesString } from "../../helpers/table-structures";
import { appConstants } from "../../helpers";
import FilterObjectsByKeyValue from "../../utils/FilterObjectsByKeyValue";
function Product() {
  const navigate = useNavigate();
  // const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const params = useParams();

  const params = new URLSearchParams(window.location.search);
  const refFilter = useRef([]);
  const refMobileFilter = useRef([]);
  const { currentProduct, auth, requestCall } = useSelector(
    (state) => {
      return state;
    }
  );

  const [overlayPosition, setOverlayPosition] = useState("right")

  // const [selectedFormObj, setSelectedFormObj] = useState({});
  const [selectedSize, setSelectedSize] = useState("1L");
  const [selectedMOQ, setSelectedMOQ] = useState(200);
  const [selectedPaymentOption, setSelectedMOQPaymentOption] = useState({});
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState({});
  const [deliveryCharges, setDeliveryCharges] = useState(0);



  useEffect(() => {
    getApi("getProductDetails",dispatch, "/" + id);
  }, [id]);
  const handleScrollView = () => {
    if (window.scrollY !== 0) {
      if (window.innerWidth < 768) {
        refMobileFilter.current?.scrollIntoView({ behavior: "smooth", block: "end" });

      } else {
        refFilter.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  };
  const addToCart = (data, moq) => {
    let postData = {}
    postData["product_variant_id"] = data.id;
    postData["buyer_id"] = auth.user.id;
    postData["moq"] = moq.quantity;
    postData["payment_types"] = selectedPaymentOption;
    postCart(postData, dispatch)
  }
  const addToCartCurrentProduct = (data) => {
    let postData = {}
    postData["product_variant_id"] = data.id;
    postData["buyer_id"] = auth.user.id;
    postData["moq"] = selectedMOQ;
    postData["payment_types_id"] = selectedPaymentOption.id;
    postData["delivery_types_id"] = selectedDeliveryOption.id;
    postCart(postData, dispatch)
  }
  useEffect(() => {
    if (!isEmpty(currentProduct.data)) {
      handleScrollView();
      setSelectedSize(currentProduct?.data?.productDetails?.variant);

      setSelectedMOQPaymentOption(FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.payment_types, "name", "Pay Now")[0]);
      if (!isEmpty(FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.delivery_types, "name", "Bharee"))) {
        setSelectedDeliveryOption(FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.delivery_types, "name", "Bharee")[0]);
      } else {
        setSelectedDeliveryOption(FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.delivery_types, "name", "Standard")[0]);
      }
      if (isEmpty(params.get('moq'))) {
        setSelectedMOQ(currentProduct?.data?.productDetails?.moq_id[0].quantity);

      } else {
        setSelectedMOQ(parseInt(params.get('moq')))
      }
    }
  }, [currentProduct.data]);


  useEffect(() => {
    if (window.innerWidth < 768) {
      setOverlayPosition("bottom")
    }

  }, [])


  useEffect(() => {
    if (selectedDeliveryOption?.name === "Standard") {
      setDeliveryCharges(parseFloat(currentProduct?.data?.productDetails?.standardDeliveryCharge))
    } else {
      setDeliveryCharges(parseFloat(currentProduct?.data?.productDetails?.bhareeDeliveryCharge))

    }
  }, [selectedDeliveryOption])


  const handleClick = (e, value) => {
    navigate(`/product/${value.id}`);
  }
  // const onSelectValue = (value, selectorType) => {
  //   let updatedObj = {};
  //   updatedObj[selectorType] = value;
  //   setSelectedFormObj((previousVlaues) => ({
  //     ...previousVlaues,
  //     ...updatedObj,
  //   }));
  // };

  const openLoginModal = () => {
    const targetButton = document.getElementById('loginModal');
    if (targetButton) {
      targetButton.click();
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2.2,
    },
  };

  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        All discounts, promotional schemes & Bharee delivery option available only on advance payment.
      </Popover.Body>
    </Popover>
  );

  const ProductBanner = (
    <>
      <div ref={refFilter} className="product-banner-main-div text-uppercase desktop">
        <p> <Link to={`/`} >{"Home "}</Link>{<DotIcon />} <Link to={`/brands`} >{" Brand"}</Link></p>
        <h2>{currentProduct?.data?.productDetails?.brand_name}</h2>
      </div>
      <div ref={refMobileFilter} className="mobile " style={{ visibility: "hidden", position: "absolute" }}>xyz
      </div>
    </>
  );

  const blandsSections = () => {
    let properties = {
      useBrowserFullscreen: false,
      showPlayButton: false,
      showBullets: false,
      showNav: false,
      showFullscreenButton: false,
      autoPlay: currentProduct?.data?.productDetails?.productImages?.length > 3 ? true : false,
      originalClass: "galaryBigImage",
      items: currentProduct?.data?.productDetails?.productImages?.length > 0 ? currentProduct?.data?.productDetails?.productImages.map((item) => {
        let tempItem = {}
        tempItem["originalClass"] = "galaryBigImage";
        tempItem["originalHeight"] = "auto";
        tempItem["originalWidth"] = "auto";
        tempItem["original"] = item.url;
        tempItem["thumbnail"] = item.url;
        return tempItem;
      }) : [
        {
          original: plastic1,
          originalClass: "galaryBigImage",
          originalHeight: "auto",
          originalWidth: "auto",
          thumbnail: plastic1,
        },],
    };
    return (
      <div className="container product-div">
        <Row className="pt-5">
          <Col xs={12} sm={12} md={9} lg={9}>
            <Row className="m-0">
              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="product-details-section"
              >
                <div className="desktop">
                  <ImageGallery {...properties} />
                </div>
                <Container className="p-0 mobile">
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    // ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlay={false}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    customTransition="transform 1000ms ease-in-out"
                    transitionDuration={500}
                    // containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    // deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                  // itemClass="carousel-item-padding-40-px"
                  >
                    {currentProduct?.data?.productDetails?.productImages?.length > 0 &&
                      currentProduct?.data?.productDetails?.productImages?.map((image, index) => {
                        return (<div key={index} className="product-mobile-carousal-item">
                          <Image
                            src={image?.url}
                            style={{ maxWidth: "100%", height: "auto" }}
                          ></Image>
                        </div>)
                      })}

                  </Carousel>
                </Container>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={7}
                lg={7}
                className="poduct-custom-section"
              >
                <div className="product-variant-div">
                  <div className="small-image-heading">
                    <p className="text-uppercase  m-0">{currentProduct?.data?.productDetails?.brand_name}</p>
                  </div>
                  <div className="small-image-heading">
                    <h4 style={{ textTransform: "uppercase" }}>
                      {currentProduct?.data?.productDetails?.brand_name}&nbsp;
                      {currentProduct?.data?.productDetails?.product_category}
                    </h4>
                    {deliverytypesString(currentProduct?.data?.productDetails?.delivery_types).toLowerCase().includes(appConstants.BHAREE.toLowerCase()) && <Image
                      src={fulfill}
                      alt="fulfill"
                    />}
                    {/* <Image src={fulfill} alt="fulfill" /> */}
                  </div>

                  <div className="product-rating">
                    <Rating
                      className="rating-star-container"
                      size="20"
                      initialValue={4}
                      readonly
                    />
                    <p>{"24 ratings"}</p>
                  </div>
                  <div className="variants-div  ">
                    <Row className="variant m-0">
                      <Col xs={12} sm={12} md={2} lg={2} className="p-0">
                        <p
                          className="text-uppercase "
                          style={{ marginRight: "10%" }}
                        >
                          {"size:"}
                        </p>
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10} className="p-0">
                        {currentProduct?.data?.sizeArr?.map((product, i) => {
                          return (
                            <Link to={`/product/${product.id}`} key={i} >
                              <Button
                                type="button"
                                className={`product-quntity ${selectedSize === product?.variant && "active"}`}

                              > {convertToLiters(product?.variant)}</Button>
                            </Link>
                          )
                        })}

                      </Col>
                    </Row>
                    <Row className="variant m-0">
                      <Col xs={12} sm={12} md={2} lg={2} className="p-0">
                        <p className="text-uppercase">
                          {"moq(case):"} <br />
                          <span className="extra-small-text ">
                            {"Minimum Order"}
                            {" Quantity"}
                          </span>
                        </p>

                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10} className="p-0">
                        {currentProduct?.data?.moqArr?.map((moq, i) => {
                          return (
                            <Button
                              type="button"
                              className={`product-moq ${selectedMOQ === moq.quantity && "active"}`}
                              onClick={() => setSelectedMOQ(moq.quantity)}
                              key={i}
                            >{moq.quantity}</Button>
                          )
                        })}


                      </Col>
                    </Row>
                  </div>
                  {auth.loggedIn && currentProduct?.data?.productDetails?.offers?.length > 0 && (
                    <Row className="m-0">
                      <p className="m-0 p-0 "> {"Available offers"}</p>
                      <ul className="px-3">
                        {currentProduct?.data?.productDetails?.offers.map((offer) => {
                          return <li>{`Rs.`}<strong>{`${offer?.offerPrice}/case`}</strong>{` for ${offer?.quantity} MOQ`}</li>
                        })}
                      </ul>
                    </Row>
                  )}
                  <div className="w-100">
                    <div className="type-box">
                      <span>{convertToLiters(selectedSize)} &nbsp;</span> <p className="border-p "></p>
                      <span>&nbsp;{`  ${selectedMOQ} Case`}</span>

                      {auth.loggedIn && <>

                        {(() => {
                          let offer = !isEmpty(currentProduct?.data) ? FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.offers, "quantity", selectedMOQ) : [];
                          // [0].offerPrice}
                          if (currentProduct?.data?.productDetails?.offers?.length > 0 && !isEmpty(offer)) {
                            return (<>

                              <h1>
                                {"₹ "}{offer[0]?.offerPrice}{"/-"}
                                <span>{"Per Case"}</span>
                                &nbsp;
                                <br className="mobile" />
                                <span>
                                  &nbsp;
                                  &nbsp;
                                  <s>{`₹ ${currentProduct?.data?.productDetails?.price} / -`}
                                    <span>{"Per Case"}</span>
                                  </s>
                                </span>
                              </h1>
                            </>
                            )
                          } else {
                            return (<h1>
                              {"₹ "}{currentProduct?.data?.productDetails?.price}{"/-"}
                              <span>{"Per Case"}</span>
                            </h1>)
                          }
                        })()}
                      </>}
                    </div>
                  </div>


                  <Form.Group className="payment-option-check-box">
                    <Form.Label className="me-2">{"PAYMENT OPTION:"}</Form.Label>
                    {!isEmpty(currentProduct?.data.productDetails) &&
                      !isEmpty(currentProduct?.data.productDetails.payment_types) && currentProduct?.data?.productDetails?.payment_types?.length > 0 && (<CustomSelectDropdown
                        style={{
                          multiselectContainer: {
                            backgroundColor: "#ffffff",
                          },
                          chips: {
                            margin: 0,
                          },
                          option: {
                            background: "#fbb040",
                            color: "#FFFFFF",
                            borderBottom: "1px solid #FFFFFF",
                          },
                        }}

                        options={currentProduct?.data?.productDetails?.payment_types}
                        onSelect={(e) => setSelectedMOQPaymentOption(e[0])}
                        selectedValues={
                          !isEmpty(selectedPaymentOption)
                            ? [selectedPaymentOption]
                            : []
                        }
                        displayValue={"name"}
                        singleSelect
                      />)}

                    {/* <Form.Select size="sm">
                      <option>{"Pay Now"}</option>
                      <option>{"Pay Later"}</option>
                      <option>{"Pay on Delivery"}</option>
                    </Form.Select> */}
                    <OverlayTrigger trigger="click" placement={overlayPosition} overlay={popover}>
                      <Button className="button-popover"><InfoIcon /></Button>
                    </OverlayTrigger>
                    {/* <InfoIcon /> */}
                  </Form.Group>
                  <div className="delivery">
                    <Form.Label className="me-2 delivery-lable">{"DELIVERY OPTION:"}</Form.Label>
                    {currentProduct?.data?.productDetails?.delivery_types?.length > 0 &&
                      currentProduct?.data?.productDetails?.delivery_types.map((delivery) => {
                        return <div onClick={() => setSelectedDeliveryOption(delivery)} className={`delivery-option ${delivery == selectedDeliveryOption ? "active" : ""}`} >
                          <div className="delivery-icon">
                            <DeliveryVanIcon />
                          </div>
                          <div className="px-2">
                            <h5 className="text-uppercase">{delivery?.name}</h5>
                            <p>{delivery?.subTitle}</p>
                          </div>
                        </div>
                      })}

                  </div>

                  {auth.loggedIn && <>
                    <h6>{"Delivery Charge: Rs"} {deliveryCharges} {"/- per case"}</h6>

                    <div className="total-amt">
                      <h6 className="text-uppercase">{"Total Amount:"}</h6>

                      {(() => {
                        let offer = !isEmpty(currentProduct?.data) ? FilterObjectsByKeyValue(currentProduct?.data?.productDetails?.offers, "quantity", selectedMOQ) : [];
                        if (currentProduct?.data?.productDetails?.offers?.length > 0 && !isEmpty(offer)) {
                          return (<>
                            <h2>
                              {`₹ ${selectedMOQ * parseFloat(offer[0]?.offerPrice) + (selectedMOQ * deliveryCharges)} /-`}
                              &nbsp;
                              &nbsp;
                              <span><s>{"₹ "}{selectedMOQ * currentProduct?.data?.productDetails?.price}</s>
                                {"/-"}</span>

                            </h2>
                          </>)
                        } else {
                          return (<>
                            <h2>
                              {`₹ ${(selectedMOQ * currentProduct?.data?.productDetails?.price) + (selectedMOQ * deliveryCharges)}/-`}
                            </h2>
                          </>)
                        }
                      })()}

                    </div>
                    <div className="cart-order-div">
                      <CustomButton className="text-uppercase cart-btn"
                        loading={requestCall?.loading}
                        onClick={() => { addToCartCurrentProduct(currentProduct?.data?.productDetails) }}>
                        {"ADD TO CART"}
                      </CustomButton>
                      <CustomButton className="text-uppercase order-btn " onClick={() => navigate('/cart')}>
                        {"ORDER NOW"}
                      </CustomButton>
                    </div></>}
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="product-details-section"
              >
                <div className="product-discription-div desktop">
                  <h4 className="text-uppercase">{"Product Description"}</h4>
                  <hr />
                  <p>{currentProduct?.data?.productDetails?.description}</p>
                </div>
                <Accordion
                  defaultActiveKey="0"
                  className="product-discription-div mobile"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4 className="text-uppercase">{"Product Description"}</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>{currentProduct?.data?.productDetails?.description}</p>

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={7}
                lg={7}
                className="poduct-custom-section"
              >
                <div className="product-variant-div desktop">
                  <div className="additional-info">
                    <h4 className="text-uppercase">{"additional information"}</h4>
                    <hr />
                    <p>
                      <span>{"Manufactured by: "}</span>
                      {currentProduct?.data?.productDetails?.manufactured_by}
                    </p>
                    <p>
                      <span>{"Marketed by: "}</span>
                      {currentProduct?.data?.productDetails?.marketed_by}
                    </p>
                    <p>
                      <span>{"Net Quantity: "}</span>
                      {convertToLiters(currentProduct?.data?.productDetails?.variant)}
                    </p>
                    <p>
                      <span>{"Customer care: "}</span>
                      {currentProduct?.data?.productDetails?.customer_care}
                    </p>
                    <p>
                      <span>{"Country of Origin: "}</span>
                      {currentProduct?.data?.productDetails?.country_of_origin}
                    </p>
                    <p>
                      <span>{"Shelf life: "}</span>
                      {currentProduct?.data?.productDetails?.shelf_life_name}
                    </p>
                    <p>
                      <span>{"Generic Name: "}</span>
                      {currentProduct?.data?.productDetails?.product_category}
                    </p>
                  </div>
                </div>
                <Accordion
                  defaultActiveKey="0"
                  className="product-discription-div mobile"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4 className="text-uppercase">
                        {"additional information"}
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span>{"Manufactured by: "}</span>
                        {currentProduct?.data?.productDetails?.manufactured_by}
                      </p>
                      <p>
                        <span>{"Marketed by: "}</span>
                        {currentProduct?.data?.productDetails?.marketed_by}
                      </p>
                      <p>
                        <span>{"Net Quantity: "}</span>
                        {convertToLiters(currentProduct?.data?.productDetails?.variant)}
                      </p>
                      <p>
                        <span>{"Customer care: "}</span>
                        {currentProduct?.data?.productDetails?.customer_care}
                      </p>
                      <p>
                        <span>{"Country of Origin: "}</span>
                        {currentProduct?.data?.productDetails?.country_of_origin}
                      </p>
                      <p>
                        <span>{"Shelf life: "}</span>
                        {currentProduct?.data?.productDetails?.shelf_life_name}
                      </p>
                      <p>
                        <span>{"Generic Name: "}</span>
                        {currentProduct?.data?.productDetails?.product_category}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={12} md={3} lg={3} className="desktop">
            <div className="other-poduct-section">
              <h2 className="text-uppercase">{"Other Products"}</h2>
              {(() => {
                if (currentProduct?.data?.otherProductsData?.length > 0) {
                  return currentProduct?.data?.otherProductsData.map((product, index) => {
                    if (index < 4) {
                      return (<>
                        <hr />
                        <Row className="other-card">
                          <Col
                            sm={2} md={4} lg={3} style={{ display: "flex", alignItems: "center", justifyContent: "center", maxHeight: "6rem" }} className="p-0 flex">
                            <Image
                              onClick={(e) => handleClick(e, product)}
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              src={product?.productImages[0]?.url}
                              alt={product?.brand_name}
                            />
                          </Col>
                          <Col xs={8} sm={8} md={8} lg={9} className="p-0">
                            <Link to={`/product/${product.id}`} >
                              <h5 >{product?.brand_name}&nbsp;{product?.product_category}&nbsp;{convertToLiters(product.variant)}{" | "}{product.moq}&nbsp;{"Case"}</h5>
                            </Link>
                            <p>{"Pack of"}&nbsp;{product?.packaging_size}&nbsp;{"Bottles"}</p>

                            <div className="brand-card-product">

                              {auth.loggedIn && (
                                <>
                                  <h3>
                                    {`₹  ${product.price} `}
                                    <span>{"per case"}</span>
                                  </h3>
                                  {product?.moq_id?.length > 1 && (<OverlayTrigger trigger="focus" placement="top" overlay={
                                    <Popover className="card-popper " id={`${product?.id}`}>
                                      <Popover.Header as="h3">{"Select Moq"}</Popover.Header>
                                      <Popover.Body>
                                        <Container >
                                          <Row className="moq-row">
                                            {product?.moq_id?.length > 0 && (<>{product?.moq_id.map((m) => {
                                              return <CustomButton key={m.quantity} buttonClasses="moq-btn" loading={requestCall?.loading} onClick={() => { addToCart(product, m); }}>
                                                {m.quantity}
                                              </CustomButton>
                                            })}</>)}

                                          </Row>
                                        </Container>
                                      </Popover.Body>
                                    </Popover>
                                  }>
                                    <Button><CartIcon />
                                      &nbsp;{"ADD"}</Button>
                                  </OverlayTrigger>)}
                                  {product?.moq_id?.length === 1 && (
                                    <CustomButton loading={requestCall?.loading} onClick={() => { addToCart(product, product?.moq_id[0]); }}>
                                      <CartIcon />
                                      &nbsp;{"ADD"}
                                    </CustomButton>
                                  )}
                                  {/* <CustomButton loading={requestCall?.loading} onClick={() => { addToCart(product, item?.moq_id[0]); changeStateModal() }}>
                                    <CartIcon />
                                    &nbsp;{"ADD"}
                                  </CustomButton>
                                  <CustomButton>
                                    <CartIcon />
                                    &nbsp;{"ADD"}
                                  </CustomButton> */}
                                </>)}
                              {!auth.loggedIn && (
                                <>
                                  <h3>
                                    <span>{"Please Login to Buy..."}</span>
                                  </h3>
                                  <CustomButton onClick={openLoginModal}>
                                    <CartIcon />
                                    &nbsp;{"LOGIN"}
                                  </CustomButton>
                                </>)
                              }
                            </div>
                          </Col>
                        </Row></>)
                    } else {
                      // for removing waring  Array.prototype.map() expects a value to be returned at the end of arrow function
                      return <></>
                    }
                  })
                } else {
                  return <p className="p-3">Products not found </p>
                }
              })()}
            </div>
          </Col>
        </Row>
      </div>
    )
  };

  const otherVariants = () => (
    <div className=" section-other-variant container ">
      <hr />
      <div className="text-uppercase section-other-variant-title-div">
        <h4 className="text-uppercase section-title">
          {"Other Variant"}
          <div id="borderLeft" style={{ width: "15%" }}></div>
        </h4>
        {(<Link to={`/brands?brand=${currentProduct?.data?.productDetails.brand_name + "|" + currentProduct?.data?.productDetails.brand_id}`}>{"View More"}</Link>)}
      </div>
      <Row className="m-0">
        {(() => {
          if (currentProduct?.data?.otherVariantsData?.length > 0) {
            return currentProduct?.data?.otherVariantsData.map((variant, index) => {
              if (index < 4) {
                return <Col xs={6} sm={6} md={6} lg={3} className="brand-card-col">
                  <BrandCard isLoggedIn={auth.loggedIn} item={variant} addToCart={addToCart} />
                </Col>
              } else {
                // for removing waring  Array.prototype.map() expects a value to be returned at the end of arrow function
                return <></>
              }
            })
          } else {
            return <p>No variant found</p>
          }
        })()}
      </Row>
    </div>
  );

  return (
    <section className="product-main-section">
      {(() => {
        if (!currentProduct.loading && !isEmpty(currentProduct?.data)) {
          return (<> {ProductBanner}
            {blandsSections()}
            {otherVariants()}
            {<RefferSection />}</>)
        } else {
          return <Container style={{ minHeight: "500px", padding: "5rem" }}><Spinner /></Container>;
        }
      })()}

    </section>
  );
}


export default Product;
