import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";

import { useSelector, useDispatch } from "react-redux";
import {
  getApi,
  updateUserStatus,
} from "../../../../actions/structure.action";
import { tableStructures } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";
import CustomTable from "../../common/CustomTable";

const UsersList = () => {
  const dispatch = useDispatch();

  const { userList, requestCall } =
    useSelector((state) => {
      return state;
    });

  useEffect(() => {
    getApi("getUserList",dispatch);
  }, []);



  return (
    <Container className="bhree-admin-user-list px-0 py-2">
      <Row className="mx-0 px-0 mb-4">
        <h1 className="text-uppercase">{"User List"}</h1>
      </Row>
      {(() => {
        if (userList?.data?.length > 0 && !userList.loading) {
          return (
            <Row className="m-0">
              <Col xs={12} className="px-0">
                <CustomTable
                  columns={tableStructures.user_list}
                  data={userList?.data}
                  enableRowActions={true}
                  positionActionsColumn={"last"}
                  enableTopToolbar={true}
                  emptyTableMsg={''}
                  enablePagination={true}
                  enableBottomToolbar={true}
                  enableFilters={true}
                  renderRowActions={({ row }) => (
                    <div className="table-action-box">
                      {/* {brandModal("icon-action-btn", <EditIcon />, row.original)} */}
                      {row.original.status === 1 ? (
                        <CustomButton
                          buttonClasses="action-btn accept-btn"
                          onClick={() =>
                            updateUserStatus(row.original.id, dispatch)
                          }
                          disabled={requestCall?.loading}
                        >
                          {"Activated"}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          buttonClasses="action-btn reject-btn "
                          onClick={() =>
                            updateUserStatus(row.original.id, dispatch)
                          }
                          disabled={requestCall?.loading}
                        >
                          {"Disabled"}
                        </CustomButton>
                      )}
                    </div>
                  )}
                  renderTopToolbarCustomActions={() => (
                    <div className="table-heading-wrap">
                      <h4>{"Users "}</h4>
                    </div>
                  )}
                  initialState={{
                    columnPinning: {
                      right: ["mrt-row-actions"],
                    },
                  }}
                //   enableColumnOrdering={false} //enable some features
                //   enableRowSelection={false}
                //   enableColumnResizing={false}
                //   onRowSelectionChange={setRowSelection} //hoist internal state to your own state (optional)
                //   state={{ rowSelection }} //manage your own state, pass it back to the table (optional)
                //   tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
                />
              </Col>
            </Row>
          );
        } else if (userList?.data?.length < 1 && !userList.loading) {
          return (
            <Container className="seller-product-create-form ">
              <p>{"No user to display... "}</p>
            </Container>
          );
        } else if (userList.loading) {
          return <Spinner />;
        }
      })()}
    </Container>
  );
};

export default UsersList;
