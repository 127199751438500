import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import loadingImage from "./loading.gif";
import "./App.css";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { store, persistor } from "./helpers";
import { logoutUser, setCurrentUser } from "./actions";
import {
  Homepage,
  PagetNotFound,
  Navbar,
  Footer,
  Brands,
  Product,
  Cart,
  Account,
  Seller,
  AboutUs,
  AdminPanel,
} from "./helpers/import-constant";
import AboutUsBuyer from "./component/AboutUs/AboutUsBuyer";
import AboutUsSeller from "./component/AboutUs/AboutUsSeller";
import AboutUsBecomeASeller from "./component/AboutUs/AboutUsBecomeASeller";
import PrivateRoutes from "./config/PrivateRoutes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from "./component/PrivacyPolicy";
import CancellationPolicy from "./component/CancellationPolicy";
import FAQ from "./component/FAQ";
import Terms from "./component/Terms";
import Support from "./component/Support";
import Checkout from "./component/CheckOut";
import Order from "./component/Order/indes";
import CalculationPage from "./component/AdminPanel/AdminPanelSeller/Calculations/CalculationPage";
import ShippingPolicy from "./component/ShippingPolicy";
import EmailVerification from "./component/pages/EmailVerification";
function App() {
  // const dispatch = useDispatch();

  if (localStorage.jwtToken) {
    // set the off token header auth
    setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and Authenticated
    store.dispatch(setCurrentUser(decoded));

    //check fo rexpied token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(logoutUser());
      // clear current classrom
      window.location.href = "/";
    }
  }
  const MyLoadingScreen = () => {
    return (
      <img
        src={loadingImage}
        alt="loader"
        className="mx-auto d-block align-items-center"
      />
    );
  };

  return (
    <div>
      <Router>
        <div className="App">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            pauseOnFocusLoss
            newestOnTop={true}
            draggable={false}
            pauseOnHover />
          <Navbar />
          <Routes maxLoadingTime={700} loadingScreen={MyLoadingScreen}>
            <Route exact path="/" element={<Homepage />} loading />
            <Route exact path="/about-us/home" element={<AboutUs />} loading />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} loading />
            <Route exact path="/cancellation-policy" element={<CancellationPolicy />} loading />
            <Route exact path="/faq" element={<FAQ />} loading />
            <Route exact path="/terms" element={<Terms />} loading />
            <Route exact path="/shipping-policy" element={<ShippingPolicy />} loading />
            <Route exact path="/support" element={<Support />} loading />
            <Route
              exact
              path="/about-us/buyer"
              element={<AboutUsBuyer />}
              loading
            />

            <Route
              exact
              path="/about-us/seller"
              element={<AboutUsSeller />}
              loading
            />
            <Route
              exact
              path="/about-us/become-a-seller"
              element={<AboutUsBecomeASeller />}
              loading
            />
            <Route exact path="/seller-page" element={<Seller />} loading />
            <Route exact path="/brands" element={<Brands />} loading />
            <Route exact path="/product/:id" element={<Product />} loading />

            {/*  PRIVATE ROUTES  */}
            <Route
              exact
              path="/cart"
              element={
                <PrivateRoutes path="/cart">
                  <Cart />
                </PrivateRoutes>
              }
              loading
            />
            <Route
              exact
              path="/account"
              element={
                <PrivateRoutes path="/account">
                  <Account />
                </PrivateRoutes>
              }
              loading
            />
            <Route
              path="/admin/profile-panel"
              element={
                <PrivateRoutes path="/admin/profile-panel">
                  <AdminPanel />
                </PrivateRoutes>
              }
            />
            <Route
              path="/seller/profile-panel"
              element={
                <PrivateRoutes path="/seller/profile-panel">
                  <AdminPanel />
                </PrivateRoutes>
              }
            />
            <Route
              path="/checkout"
              element={
                <PrivateRoutes path="/checkout">
                  <Checkout />
                </PrivateRoutes>
              }
            />
            <Route
              path="/order/:id"
              element={
                <PrivateRoutes path="/order">
                  <Order />
                </PrivateRoutes>
              }
            />
            <Route
              path="/calculations"
              element={
                <PrivateRoutes path="/calculations">
                  <CalculationPage />
                </PrivateRoutes>
              }
            />
            {/*  {PAGE NOT FINDE } */}
            <Route path="/verify" element={<EmailVerification />} loading />
            <Route path="*" element={<PagetNotFound />} loading />
          </Routes>

          {/*  Later  */}
          {/* <Route element={<PrivateRoutes />}>
            <Route path="/cart" element={<Cart />} />
          </Route> */}

          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
