import React from "react";
import { Container } from "react-bootstrap";
import Products from "./Products";
import { stringConstants } from "../../../helpers/app-constants";
import Brands from "./Brands";
// import BasicInformation from "../common/BasicInformation";
import Address from "../common/Address";
import FactoryInfo from "./FactoryInfo";
import BankingAndTaxation from "../common/BankingAndTaxation";
import Dashbord from "./Dashbord";
import Companyinformation from "./CompanyInfo";
import Variants from "./Variants";

const AdminPanelSeller = ({ currentView }) => {
  switch (currentView) {
    case stringConstants.DASHBOAD:
      return (
        <Dashbord />
      );
    case stringConstants.REPORTS:
      return (
        <Container className="py-5 px-2">
          {
            "Report will appear and also download or export reports will be here"
          }
        </Container>
      );

    case stringConstants.PRODUCTS:
      return <Products />;
    case stringConstants.VARIANTS:
      return <Variants />;
    case stringConstants.BRANDS:
      return <Brands />;
    case stringConstants.LOGISTIC_PARTNER:
      return <Container>{"LOGISTIC_PARTNER in process.."}</Container>;
    case stringConstants.COMPANY_INFORMATION:
      return <Companyinformation />;
    case stringConstants.ADDRESS:
      return <Address />;
    case stringConstants.FACTORY_INFO:
      return <FactoryInfo />;
    case stringConstants.BANKING_AND_TAXATION:
      return <BankingAndTaxation />;
    default:
      return <Container>{"work in process.."}</Container>;
  }
};



export default AdminPanelSeller;
