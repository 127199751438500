import React from "react";
import { Button } from "react-bootstrap";
import "./custom-button.css";

const CustomButton = ({
  buttonClasses = "",
  onClick,
  loading = false,
  children,
  lodaerDark = false,
  ...restprops
}) => {
  return (
    <>
      {children &&
        (!loading ? (
          <Button onClick={onClick} className={buttonClasses} {...restprops}>
            {children}
          </Button>
        ) : (
          <Button onClick={onClick} className={buttonClasses} {...restprops}>
            {lodaerDark ? <div className="loader dark"></div> : <div className="loader"></div>}

          </Button>
        ))}
    </>
  );
};

export default CustomButton;
