export default function FilterObjectsByKeyValue(arrayOfObjects, key, value) {
    // console.log("********** arrayOfObjects",arrayOfObjects, key, value)
    if (!Array.isArray(value)) {
        // If value is not an array, convert it to an array with a single element
        value = [value];
    }

    return arrayOfObjects.filter(object => {
        // Check if object has the key and its value is in the value array
        return value.includes(object[key]);
    });
}