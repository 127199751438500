import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone'

import { Image } from "react-bootstrap";
import "./CustomDragDrop.css";
import CustomButton from "./CustomButton";

const CustomDragDrop = ({ onUplodfiles }) => {
  const [tempFiles, setTempFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setTempFiles([]);
    acceptedFiles.map(file => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setTempFiles(prevItems => [...prevItems, reader.result]);

        };
      }
    })
    onUplodfiles(acceptedFiles)

  }, [])
  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    // Disable click and keydown behavior
    // maxFiles:1,
    onDrop,
    noClick: true,
    noKeyboard: true
  });
  useEffect(() => {
    // setTempFiles([]);
    // acceptedFiles.map(file => {
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onloadend = () => {
    //       setTempFiles(prevItems => [...prevItems, reader.result]);

    //     };
    //   }
    // })
    // onUplodfiles(acceptedFiles)
  }, [acceptedFiles])

  return (
    <section className="container drag-drop-section mb-4">
      {acceptedFiles?.length > 0 && (
        <div className="uploded-file-div ">
          <h2>{"Files"}</h2>
          <div className="uploded-images-div">{tempFiles?.length > 0 && tempFiles.map((url) => <div className="img-container"> <Image src={url} alt="img" /></div>)} </div>
        </div>
      )}
      <div {...getRootProps({ className: 'dropzone drag-drop' })}>
        <input {...getInputProps()} />
        <h6>{"Drag 'n' drop some Images here,"} <br className="mobile" />{" or click to select files"}</h6>
        <div className="action-buttons">
          <CustomButton buttonClasses={"submit-btn drag-drop-btn"} type="button" onClick={open}>
            {"Upload"}
          </CustomButton>
        </div>
      </div>


    </section>
  );
};

export default CustomDragDrop;
