import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import "./index.css";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
    getApi,
    patchCompanyInfo,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import { capitalize } from "lodash"
import CustomSelectDropdown from "../../../common/CustomSelectDropdown";
import FilterObjectsByKeyValue from "../../../../utils/FilterObjectsByKeyValue";
import { paymenttypesString } from "../../../../helpers/table-structures";
import Spinner from "../../../common/Spinner";

const Companyinformation = ({ title = "Comapny INFO" }) => {
    const dispatch = useDispatch();
    const [erroeMsg, setErroeMsg] = useState("");
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [uploadFormObj, setUploadFormObj] = useState({});
    const { requestCall, auth, companyInfo, bhareeAdminBasicTable } = useSelector(
        (state) => {
            return state;
        }
    );
    useEffect(() => {
        getApi("getCompanyInfo", dispatch);
    }, [])
    useEffect(() => {
        setUploadFormObj(companyInfo.data)
        setUpdatedForm("gst_no", companyInfo.data?.business_info?.gst_no)
        setUpdatedForm("deliveryCharge", companyInfo.data?.business_info?.deliveryCharge)

        setIsUpdateMode(false);
    }, [companyInfo])

    const setUpdatedForm = (key, value) => {
        let updatedObj = {};
        updatedObj[key] = value;

        setUploadFormObj((previousVlaues) => ({
            ...previousVlaues,
            ...updatedObj,
        }));
    };


    const updateCompanyInfo = (e) => {
        e.preventDefault();
        if (
            !isEmpty(uploadFormObj) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "contact_person_name") &&
            !isEmpty(uploadFormObj.contact_person_name) &&
            Object.prototype.hasOwnProperty.call(uploadFormObj, "gst_no") &&
            !isEmpty(uploadFormObj.gst_no)
        ) {
            let tempObj = {}
            tempObj["contactPerson"] = uploadFormObj.contact_person_name;
            tempObj["gst"] = uploadFormObj.gst_no;
            tempObj["deliveryCharge"] = uploadFormObj.deliveryCharge;
            tempObj["payment_types"] = uploadFormObj.payment_types;

            if (isUpdateMode) {
                patchCompanyInfo(tempObj, dispatch);
            }
        }
    };


    return (
        <Container className="p-0 company-info-container">

            {
                companyInfo?.loading ? <Spinner /> : !isEmpty(companyInfo?.data) && !isUpdateMode ? (
                    <Row className="company-info m-0" >
                        <Row className="heading-div ">
                            <h5 className="text-uppercase p-0 m-0">{title}</h5>
                            <p style={{ cursor: "pointer" }} onClick={() => { setIsUpdateMode(true); }}>{"Edit"}</p>
                        </Row>
                        <hr />
                        <Row className="m-0 p-0 ">
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Bussiness Name:"}</span>
                                    <span className="mx-2 bold-span"> {companyInfo?.data?.business_info?.business_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Contact Person:"}</span>
                                    <span className="mx-2 bold-span"> {companyInfo?.data?.contact_person_name}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Email:"}</span>
                                    <span className="mx-2 bold-span"> {companyInfo?.data?.email}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Mobile Number:"}</span>
                                    <span className="mx-2 bold-span">{companyInfo?.data?.mobile_number}</span>

                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{`${capitalize(companyInfo?.data?.roleName)} category`}</span>
                                    <span className="mx-2 bold-span">{capitalize(companyInfo?.data?.roleTypeName)}</span>
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"GST:"}</span>
                                    <span className="mx-2 bold-span">{companyInfo?.data?.business_info?.gst_no}</span>

                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Delivery Charge"}</span>
                                    <span className="mx-2 bold-span">{companyInfo?.data?.business_info?.deliveryCharge}</span>

                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-0" >
                                <p>
                                    <span>{"Payment Types"}</span>
                                    <span className="mx-2 bold-span">{paymenttypesString(companyInfo?.data?.business_info?.payment_types)}</span>

                                </p>
                            </Col>
                        </Row>

                    </Row>
                ) : (
                    <Row className="m-0 w-full  form-row ">
                        <Form className=" modal-custom-class" onSubmit={updateCompanyInfo}>
                            <Row className="m-0 mb-2 title-button-row">
                                <h2>{!isUpdateMode ? `${title}` : `Update ${title}`}</h2>
                                <CustomButton
                                    buttonClasses="view-switch-btn"
                                    onClick={() => setIsUpdateMode(!isUpdateMode)}
                                >
                                    {!isUpdateMode ? "Edit" : "View"}
                                </CustomButton>
                            </Row>
                            <Row className="m-0">
                                <Col xs={12} sm={12} md={6} className="form-col ">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"Bussiness Name"}</Form.Label>
                                        <Form.Control type="txt" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj?.business_info?.business_name
                                                : "*_*"
                                        } disabled />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"Mobile Number"}</Form.Label>
                                        <Form.Control type="text" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj.mobile_number
                                                : "*_*"
                                        } disabled />
                                    </Form.Group>

                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"GST"}</Form.Label>
                                        <Form.Control type="text" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj?.gst_no
                                                : "*_*"
                                        }
                                            onChange={(e) => setUpdatedForm("gst_no", e.target.value)}
                                            disabled={!isUpdateMode} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"Delivery Charge"}</Form.Label>
                                        <Form.Control type="text" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj?.deliveryCharge
                                                : ""
                                        }
                                            onChange={(e) => setUpdatedForm("deliveryCharge", e.target.value)}
                                            disabled={!isUpdateMode} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} className="form-col ">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"Email"}</Form.Label>
                                        <Form.Control type="email" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj.email
                                                : "*_*"
                                        } disabled />
                                    </Form.Group>

                                    <Form.Group className="mb-3" >
                                        <Form.Label>{`${capitalize(uploadFormObj?.roleName)} category`}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={
                                                !isEmpty(uploadFormObj)
                                                    ? capitalize(uploadFormObj.roleTypeName)
                                                    : "*_*"
                                            } disabled
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{"Contact Person"}</Form.Label>
                                        <Form.Control type="text" value={
                                            !isEmpty(uploadFormObj)
                                                ? uploadFormObj["contact_person_name"]
                                                : "*_*"
                                        }
                                            onChange={(e) => setUpdatedForm("contact_person_name", e.target.value)}
                                            disabled={!isUpdateMode} />
                                    </Form.Group>
                                    {bhareeAdminBasicTable?.data?.paymentTypes?.length > 0 && (
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>
                                                {"Select Payment Types"}
                                                <span className="redStreSpan">{"*"}</span>
                                            </Form.Label>

                                            <CustomSelectDropdown
                                                // disablePreSelectedValues
                                                style={{
                                                    multiselectContainer: {
                                                        backgroundColor: "#f8f8f8",
                                                    },
                                                    chips: {
                                                        background: "#fbb040",
                                                        margin: 0,
                                                    },
                                                    option: {
                                                        background: "#fbb040",
                                                        color: "#FFFFFF",
                                                        borderBottom: "1px solid #FFFFFF",
                                                    },
                                                }}
                                                options={bhareeAdminBasicTable?.data?.paymentTypes}
                                                onRemove={(e) => setUpdatedForm("payment_types", e)}
                                                onSelect={(e) => setUpdatedForm("payment_types", e)}
                                                selectedValues={uploadFormObj?.business_info?.payment_types}
                                                displayValue={"name"}
                                            />
                                        </Form.Group>
                                    )}

                                </Col>
                            </Row>

                            {erroeMsg && <p className="error-msg">{erroeMsg}</p>}
                            <div className="action-buttons">
                                {isUpdateMode && (<><CustomButton
                                    variant="primary"
                                    buttonClasses={"close-btn"}
                                    loading={requestCall?.loading}
                                    onClick={() => setIsUpdateMode(false)}
                                >
                                    {"close"}
                                </CustomButton>
                                    <CustomButton
                                        variant="primary"
                                        buttonClasses={
                                            !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                                        }
                                        type="submit"
                                        loading={requestCall?.loading}
                                    >
                                        {"update"}
                                    </CustomButton></>)}
                            </div>
                        </Form>
                    </Row>
                )
            }

            {/* {(() => {
                if (!isUpdateMode && !isEmpty(companyInfo.data)) {
                    return
                }
            })()} */}


        </Container>
    );
};



export default Companyinformation;
