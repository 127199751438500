import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getApi,
  postCalculations,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import Spinner from "../../../common/Spinner";
import "./index.css"
import { expensesProduction, finalCostTableByVariants, totalCalculation, variantRowMaterialCost } from "../../../../utils/staticData";
import { calVarinats, expensesList, numberOfbottlesByVariants, perBottlecostList, perBoxList, regex, rowMatrialList } from "../../../../helpers/app-constants";
// import ExcelJS from "exceljs"
// import { saveAs } from "file-saver";
import FilterObjectsByKeyValue from "../../../../utils/FilterObjectsByKeyValue";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BHAREE_BASE_URL_LOCAL } from "../../../../config/config.url";
const Calculations = () => {
  const dispatch = useDispatch();
  const [calculationObj, setCalculationObj] = useState({});
  const [showPDFDownload, setShowPDFDownload] = useState(false);

  // const { toPDF, targetRef } = usePDF({ filename: 'calculations.pdf' });
  const { auth, requestCall, bhareeAdminBasicTable, calculationsFile } = useSelector(
    (state) => {
      return state;
    }
  );

  useEffect(() => {
    getApi("getBhreeAdminBasicTables",dispatch)
  }, [])

  const onChangeExpenses = (value, property) => {
    if (regex.test(value)) {
      setCalculationObj({
        ...calculationObj,
        ["expenses"]: {
          ...calculationObj["expenses"],
          [property]: value
        }
      })
    }
  };
  const onChangeTotalCalculation = (value, property) => {
    if (regex.test(value)) {
      setCalculationObj({
        ...calculationObj,
        ["totalCalculation"]: {
          ...calculationObj["totalCalculation"],
          [property]: value
        }
      })
    }
  };
  const onChangeVariantsValues = (variant, subTitle, property, value,) => {
    if (regex.test(value)) {
      return setCalculationObj({
        ...calculationObj,
        ["variants"]: {
          ...calculationObj["variants"],
          [variant]: {
            ...calculationObj["variants"][variant],
            [subTitle]: {
              ...calculationObj["variants"][variant][subTitle],
              [property]: value
            }
          }
        }
      })
    }
  };



  useEffect(() => {
    let newVarinatObj = []
    calVarinats?.map((item, i) => {
      newVarinatObj[item.quantity] = { production: expensesProduction, variantRowMaterialCost: variantRowMaterialCost, finalCostTableByVariants: { ...finalCostTableByVariants.perBottleCost, ...finalCostTableByVariants.perBoxCost } };
      return item;
    })
    if (Object.prototype.hasOwnProperty.call(localStorage, `newCalucation_${auth?.user?.id}`) && !isEmpty(localStorage[`newCalucation_${auth?.user?.id}`])) {

      setCalculationObj(JSON.parse(localStorage[`newCalucation_${auth?.user?.id}`]));
    } else {
      setCalculationObj({ totalCalculation: { ...totalCalculation, ...expensesProduction }, expenses: { ...expensesProduction }, variants: { ...newVarinatObj } });
    }
  }, [bhareeAdminBasicTable.data])
  // useEffect(() => {
  //   console.log("***caluclation obj", calculationObj, document.getElementById('content-id'))

  // }, [calculationObj])




  const resteCalulation = () => {
    localStorage.setItem(`newCalucation_${auth?.user?.id}`, "");

    let newVarinatObj = []
    calVarinats?.map((item, i) => {
      newVarinatObj[item.quantity] = { production: expensesProduction, variantRowMaterialCost: variantRowMaterialCost, finalCostTableByVariants: { ...finalCostTableByVariants.perBottleCost, ...finalCostTableByVariants.perBoxCost } };
      return item;
    })
    setCalculationObj({ totalCalculation: { ...totalCalculation, ...expensesProduction }, expenses: { ...expensesProduction }, variants: { ...newVarinatObj } });
    setShowPDFDownload(false);
  }

  const caluclationFunc = async (e) => {
    let calObj = JSON.parse(JSON.stringify(calculationObj));
    e.preventDefault();
    // console.log("*********", calObj);
    let finalGrossProfit = 0;

    /// FIST SET RAW METRIAL
    let preformPerKg = calculationObj.totalCalculation?.preformPerKg;

    try {
      rowMatrialList.map((matrial) => {
        if (!isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0) {
          calVarinats.map((variant) => {
            let value = (calculationObj.variants[variant.quantity].variantRowMaterialCost[matrial.key]) || 0;
            if (!isEmpty(value) && value !== undefined && matrial.key === "weight") {

              // console.log("*********", variant.quantity, preformPerKg, value);
              calObj.variants[variant.quantity].finalCostTableByVariants.preform = ((parseFloat(value) / 1000) * parseFloat(preformPerKg)).toFixed(2).toString();
            } else if (!isEmpty(value) && value !== undefined && matrial.key === "box") {

              // console.log("*********", variant.quantity, preformPerKg, value);
              calObj.variants[variant.quantity].finalCostTableByVariants.box = (parseFloat(value) / FilterObjectsByKeyValue(numberOfbottlesByVariants, "name", variant.quantity)[0].bottles).toFixed(2).toString();
            } else if (!isEmpty(value) && value !== undefined) {
              calObj.variants[variant.quantity].finalCostTableByVariants[matrial.key] = calculationObj.variants[variant.quantity].variantRowMaterialCost[matrial.key]
              // console.log("*********", variant.quantity, matrial.key, value);
            }
          })

        }
      })

      expensesList.map((expenses) => {
        let productionVariantSum = 0;

        calVarinats.map((variant) => {
          if (!isEmpty(productionVariantSum) && productionVariantSum !== undefined) {
            productionVariantSum = (parseFloat(productionVariantSum) + parseInt(calculationObj.variants[variant.quantity].production["electricity"])) || 0
          } else {
            productionVariantSum = (parseInt(calculationObj.variants[variant.quantity].production["electricity"])) || 0

          }

        })
        calObj.totalCalculation[expenses.key] = productionVariantSum.toFixed(2).toString();
      })

      expensesList.map((expenses) => {
        calVarinats.map((variant) => {
          let costExp = calObj.expenses[expenses.key] || 0;
          let VariantPrductionExp = calObj.variants[variant.quantity].production["electricity"] || 0;
          let totalVariantPrductionExp = calObj.totalCalculation["electricity"];
          let perBottleVariantPrductionExp = 0

          if (!isEmpty(totalVariantPrductionExp) && totalVariantPrductionExp !== undefined) {
            perBottleVariantPrductionExp = (((parseFloat(costExp) / (parseFloat(totalVariantPrductionExp)) * parseFloat(VariantPrductionExp))) / (parseFloat(VariantPrductionExp) * FilterObjectsByKeyValue(numberOfbottlesByVariants, "name", variant.quantity)[0].bottles)) || 0;

          }
          calObj.variants[variant.quantity].finalCostTableByVariants[expenses.key] = perBottleVariantPrductionExp.toFixed(2).toString();
        })
      })

      calVarinats.map((variant) => {
        let variantobj = calObj.variants[variant.quantity].finalCostTableByVariants || 0;
        let perBottle = (parseFloat(variantobj.preform) + parseFloat(variantobj.box) + parseFloat(variantobj.lable) + parseFloat(variantobj.cap) + parseFloat(variantobj.ink) + parseFloat(variantobj.rejection) + parseFloat(variantobj.electricity) + parseFloat(variantobj.wages) + parseFloat(variantobj.transport) + parseFloat(variantobj.miscExps) + parseFloat(variantobj.loanInt)) || 0;
        // console.log("******* total", variant.quantity, perBottle);
        calObj.variants[variant.quantity].finalCostTableByVariants.perBottle = (perBottle).toFixed(2).toString();
        calObj.variants[variant.quantity].finalCostTableByVariants.perBox = (perBottle * FilterObjectsByKeyValue(numberOfbottlesByVariants, "name", variant.quantity)[0].bottles).toFixed(2).toString();
        if (!isEmpty(variantobj.salePrice)) {
          calObj.variants[variant.quantity].finalCostTableByVariants.grossProfit = (((parseFloat(variantobj.salePrice)) - (perBottle * FilterObjectsByKeyValue(numberOfbottlesByVariants, "name", variant.quantity)[0].bottles)) || 0).toFixed(2).toString();
        }
        if (!isEmpty(variantobj.grossProfit)) {
          finalGrossProfit = ((parseFloat(finalGrossProfit) + ((parseFloat(variantobj.grossProfit) * parseFloat(calObj.variants[variant.quantity].production.electricity)))) || 0).toFixed(2).toString();
        }
        //grossProfit calObj.variants[variant.quantity].finalCostTableByVariants[expenses.key] = perBottleVariantPrductionExp.toFixed(2).toString();
      })
      calObj.totalCalculation.totalGrossProfit = finalGrossProfit;
      setCalculationObj(calObj);
      setShowPDFDownload(true);
      localStorage.setItem(`newCalucation_${auth?.user?.id}`, JSON.stringify(calObj));
      // postCalculations(calObj, dispatch);
      // await CreateWorkBook(calObj);
    } catch (e) {
      toast.error(`Somthing went wrong ${e}`);
    }
  }

  const handleDownload = async () => {
    postCalculations(calculationObj, dispatch);
    // const pdfUrl = BHAREE_BASE_URL_LOCAL + calculationsFile.data; // Replace with your PDF URL
    // const link = document.createElement("a");
    // link.href = pdfUrl;
    // link.setAttribute("target", "_blank");
    // link.setAttribute("download", "ritesh.pdf"); // Specify the file name
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };



  return (
    <>
      <Container className=" 2 new-calculation mb-4" id="content-id" >
        {/* <Row className="mx-0 px-1 mb-4">
          <h4 className="p-0 ">{"Cost Calculations"}</h4>
        </Row> */}
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header><Row className="mx-0 ">
              <h4 className="p-0 m-0">{"Cost Calculations"}</h4>
            </Row></Accordion.Header>
            <Accordion.Body>
              {!isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && (<Form className="my-2 calculation-form" onSubmit={caluclationFunc}>
                <Row className="m-0">
                  <Col xs={12} className="scrollXsection">
                    <Row className="m-0 pb-2 coverRow">
                      <Col xs={6} className="p-0">
                        <h4 className="px-2">{"Production"}</h4>
                        <Row className="m-0" style={{ flexWrap: "nowrap" }}>
                          {
                            !isEmpty(bhareeAdminBasicTable?.data) && !isEmpty(calculationObj) && calVarinats.length > 0 && calVarinats.map((variant) => {
                              return <Col xs={3} lg={2} ><h6 className="text-start">{variant.displayName}</h6>
                                {
                                  expensesList.map((expenses) => {
                                    if (expenses.key === "electricity") {
                                      return (<Form.Group className="mb-3 input-grop-calculation " >
                                        <Form.Control
                                          type="text"
                                          placeholder="0"
                                          className="w-100"
                                          required
                                          disabled={expenses.key !== "electricity"}
                                          value={!isEmpty(calculationObj.variants) && calculationObj.variants[variant.quantity].production["electricity"] ? calculationObj.variants[variant.quantity].production["electricity"] : ""}
                                          onChange={(e) => onChangeVariantsValues(variant.quantity, "production", expenses.key, e.target.value)}
                                        />
                                      </Form.Group>)
                                    }
                                  })
                                }
                              </Col>

                            })}
                        </Row>
                      </Col>


                    </Row>
                  </Col>
                  <Col xs={12} className="mobile" >
                    <h4 className="mb-3">{"Expenses"}</h4>
                    <Row className="m-0">
                      <Col xs={6}></Col>
                      <Col xs={6} className="p-0" ><h6 className="text-start">{"Cost"}</h6></Col>
                    </Row>
                    {
                      expensesList.map((expenses) => {
                        return (<Form.Group className="mb-3 input-grop-calculation" >
                          <Form.Label>
                            {expenses.name}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="0"
                            required
                            value={!isEmpty(calculationObj?.expenses) && calculationObj?.expenses[expenses.key] !== "" ? calculationObj?.expenses[expenses.key] : ""}
                            onChange={(e) => onChangeExpenses(e.target.value, expenses.key)}
                          />
                        </Form.Group>)
                      })
                    }
                  </Col>
                  <Col xs={12} className="col-padding scrollXsection" >
                    <Row className="m-0 coverRow">
                      <Col xs={3} className="desktop" style={{ order: 1 }}>
                        <h4 className="mb-3">{"Expenses"}</h4>
                        <Row className="m-0">
                          <Col xs={6}></Col>
                          <Col xs={6} className="p-0" ><h6 className="text-start">{"Cost"}</h6></Col>
                        </Row>
                        {
                          expensesList.map((expenses) => {
                            return (<Form.Group className="mb-3 input-grop-calculation" >
                              <Form.Label>
                                {expenses.name}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="0"
                                required
                                value={!isEmpty(calculationObj?.expenses) && calculationObj?.expenses[expenses.key] !== "" ? calculationObj?.expenses[expenses.key] : ""}
                                onChange={(e) => onChangeExpenses(e.target.value, expenses.key)}
                              />
                            </Form.Group>)
                          })
                        }

                      </Col>
                      <Col xs={9} lg={6} className="p-0">
                        <Row className="m-0 ">
                          <Row className="m-0 mb-3 p-0 raw-matrial-cost-cover">
                            <h4 className="m-0">{"Raw Matrial Cost"}</h4>
                            <Form.Group className=" input-grop-calculation" >
                              <Form.Label>
                                {"Preform Per Kg"}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="0"
                                required
                                value={!isEmpty(calculationObj?.expenses) && calculationObj?.totalCalculation?.preformPerKg ? calculationObj?.totalCalculation["preformPerKg"] : ""}
                                onChange={(e) => onChangeTotalCalculation(e.target.value, "preformPerKg")}
                              />
                            </Form.Group>
                          </Row>

                          <Col xs={12} className=" p-0">
                            <Row className="m-0 pb-3 coverRow" style={{ flexWrap: "nowrap" }}>
                              <Col xs={2} sx={1} md={2} lg={2} className="pe-0">
                                <h6 className="text-start">{"Varinats"}</h6>

                                {!isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {
                                  return (<Form.Group className="mb-3 input-grop-calculation " >
                                    {/* <Form.Label>
                          {variant.displayName}
                        </Form.Label> */}
                                    <Form.Control
                                      type="text"
                                      placeholder="0"
                                      className="w-100 m-0"
                                      value={variant.displayName}

                                      disabled
                                    // onChange={(e) => onChangeTotalCalculation(e.target.value, "preformPerKg")}
                                    />
                                  </Form.Group>
                                  )
                                })}
                              </Col>
                              {
                                rowMatrialList.map((matrial) => {
                                  return (<Col xs={2} md={1} className="pe-0"><h6 className="text-start">{matrial.name}</h6>
                                    {
                                      !isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {
                                        return (<Form.Group className="mb-3 input-grop-calculation " >
                                          <Form.Control
                                            type="text"
                                            placeholder="0"
                                            className="w-100 m-0"
                                            required
                                            value={!isEmpty(calculationObj?.variants) && calculationObj?.variants[variant.quantity].variantRowMaterialCost[matrial?.key] ? calculationObj?.variants[variant?.quantity].variantRowMaterialCost[matrial?.key] : ""}
                                            onChange={(e) => onChangeVariantsValues(variant.quantity, "variantRowMaterialCost", matrial?.key, e.target.value)}
                                          />
                                        </Form.Group>)
                                      })}
                                  </Col>)
                                })
                              }
                            </Row>

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* ref={scrollContainerRef} onWheel={handleScroll} */}
                  <Col xs={12} className="scrollXsection p-0 my-4" style={{ overflowX: "scroll" }}>

                    <Row className="m-0 p-2 coverRow" style={{ flexWrap: "nowrap" }}>
                      <h4 className="m-0 p-0 ">{"Selling Price :"}</h4>

                      {
                        !isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {

                          return (<Col xs={3} lg={2} ><Form.Group className=" input-grop-calculation " >
                            <Form.Label >
                              {variant.displayName}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="0"
                              className="m-0"
                              required
                              value={!isEmpty(calculationObj.variants) && calculationObj.variants[variant.quantity].finalCostTableByVariants["salePrice"] ? calculationObj.variants[variant.quantity].finalCostTableByVariants["salePrice"] : ""}
                              onChange={(e) => onChangeVariantsValues(variant.quantity, "finalCostTableByVariants", "salePrice", e.target.value)}
                            />
                          </Form.Group></Col>)

                        })}

                    </Row>
                  </Col>
                  <Col xs={12} className="scrollXsection p-0" style={{ overflowX: "scroll" }}>
                    <Row className="m-0  " >
                      <Col xs={12} className="col-padding">
                        {/* <h4>{"Per Bottle cost"}</h4> */}
                        <Row className="m-0 coverRow" style={{ flexWrap: "nowrap" }}>
                          <Col xs={2} md={1} className="setColInputs" >
                            <h4>{"Per Bottle cost"}</h4>
                            <h6 className="text-start">{"Varinats"}</h6>

                            {!isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {
                              return (<Form.Group className="mb-3 input-grop-calculation " >
                                {/* <Form.Label>
                          {variant.displayName}
                        </Form.Label> */}
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  className="w-100 m-0"
                                  value={variant.displayName}
                                  disabled
                                // onChange={(e) => onChangeMonthlyExpenses(e.target.value, expenses.key)}
                                />
                              </Form.Group>
                              )
                            })}
                          </Col>
                          {
                            perBottlecostList.map((perBox) => {
                              return (<Col xs={2} md={1} className="setColInputs"><h6 className="text-start">{perBox.name}</h6>
                                {
                                  !isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {
                                    return (<Form.Group className="mb-3 input-grop-calculation " >
                                      <Form.Control
                                        type="text"
                                        placeholder="0"
                                        className="w-100 m-0"
                                        value={!isEmpty(calculationObj.variants) && calculationObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] ? calculationObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] : ""}
                                        disabled
                                      // onChange={(e) => onChangeMonthlyExpenses(e.target.value, expenses.key)}
                                      />
                                    </Form.Group>)
                                  })}
                              </Col>)
                            })
                          }
                          {
                            perBoxList.map((perBox, index) => {
                              return (<Col xs={3} lg={1} className="setColInputs">
                                {index === 0 && (<h4>{"Per Box"}</h4>)}

                                <h6 className="text-start">{perBox.name}</h6>
                                {
                                  !isEmpty(bhareeAdminBasicTable?.data) && calVarinats?.length > 0 && calVarinats.map((variant) => {
                                    return (<Form.Group className="mb-3 input-grop-calculation " >
                                      <Form.Control
                                        type="text"
                                        placeholder="0"
                                        className="w-100 m-0"
                                        required
                                        value={!isEmpty(calculationObj.variants) && calculationObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] ? calculationObj.variants[variant.quantity].finalCostTableByVariants[perBox.key] : ""}
                                        disabled={true}
                                        onChange={(e) => onChangeVariantsValues(variant.quantity, "finalCostTableByVariants", perBox.key, e.target.value)}
                                      />
                                    </Form.Group>)
                                  })}
                              </Col>)
                            })
                          }
                          <Col xs={3} lg={2} className="setColInputs">
                            <h6>{"GROSS PROFIT"}</h6>
                            <div style={{
                              display: "flex",
                              minHeight: "12rem"
                            }}>
                              <Form.Group className="mb-3 input-grop-calculation " >
                                <Form.Control
                                  type="text"
                                  placeholder="0"
                                  className="w-100 m-0"
                                  value={calculationObj?.totalCalculation?.totalGrossProfit}
                                  disabled
                                // onChange={(e) => onChangeMonthlyExpenses(e.target.value, expenses.key)}
                                />

                              </Form.Group>
                            </div>
                          </Col>
                        </Row>

                      </Col>
                    </Row>
                  </Col>


                </Row>
                <div className="action-buttons">

                  <CustomButton
                    buttonClasses="reset-btn"
                    onClick={() => resteCalulation()}
                    disabled={requestCall?.loading}
                  >
                    {"Clear"}
                  </CustomButton>
                  {showPDFDownload   && (<CustomButton
                    className="pdf-download-btn"
                    onClick={() => handleDownload()}
                    disabled={requestCall?.loading}
                    loading={calculationsFile?.loading}

                  >
                    {"Download PDF"}
                  </CustomButton>)}
                  <CustomButton
                    variant="primary"
                    buttonClasses={
                      !requestCall?.loading ? "calcualte-btn" : "calcualting"
                    }
                    type="submit"
                  >
                    {"Calculate"}
                  </CustomButton>

                </div>
              </Form >)}
              {(isEmpty(bhareeAdminBasicTable?.data) || calVarinats?.length <= 0) && (
                <Spinner />
              )}


            </Accordion.Body>
          </Accordion.Item>

        </Accordion>


      </Container >
    </>
  );
};


export default Calculations;
