import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import CustomButton from "../../../common/CustomButton";

import { useSelector, useDispatch } from "react-redux";
import {
  getApi, postSaveServiceTaxDetails,
} from "../../../../actions/structure.action";
import isEmpty from "../../../../utils/is-Empty";
import Spinner from "../../../common/Spinner";
import { regex } from "../../../../helpers/app-constants";
import "./index.css"
const ServiceTaxDetails = () => {
  const dispatch = useDispatch();
  const [uploadFormObj, setUploadFormObj] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const { requestCall, serviceTaxDetails } =
    useSelector((state) => {
      return state;
    });
  const setUpdatedForm = (key, value) => {
    let updatedObj = {};
    updatedObj[key] = value;

    setUploadFormObj((previousVlaues) => ({
      ...previousVlaues,
      ...updatedObj,
    }));
  };
  const updateServiceTaxDetails = (e) => {
    e.preventDefault();
    if (
      !isEmpty(uploadFormObj) &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "bhareeComm") &&
      uploadFormObj.bhareeComm > -1 &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "transactionFee") &&
      uploadFormObj.transactionFee > -1 &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "gst") &&
      uploadFormObj.gst > -1 &&
      Object.prototype.hasOwnProperty.call(uploadFormObj, "deliveryCharge") &&
      uploadFormObj.gst > -1

    ) {

      if (isUpdateMode) {
        postSaveServiceTaxDetails(uploadFormObj, dispatch);
      }
    }
  }
  useEffect(() => {
    getApi("getServiceTaxDetails", dispatch)
  }, []);
  useEffect(() => {
    if (!isEmpty(serviceTaxDetails)) {
      setUploadFormObj(serviceTaxDetails?.data);
    } else {
      setIsUpdateMode(true)
    }
    setIsUpdateMode(false);
  }, [serviceTaxDetails]);
  return (
    <>
      {serviceTaxDetails.loading ? <Spinner /> : (
        <Row className="m-0 w-full  form-row">
          <Row className="mx-0 title-button-row">
            <h1 className="text-uppercase">{!isUpdateMode ? "Service Tax Details" : "Update Service Tax Details "}</h1>
            <CustomButton
              buttonClasses="back-btn view-switch-btn"
              onClick={() => { !isUpdateMode ? setIsUpdateMode(true) : setIsUpdateMode(false); setUploadFormObj(serviceTaxDetails?.data) }}
            >
              {!isUpdateMode ? "Edit" : "Cancel "}
            </CustomButton>
          </Row>
          <Form className="  modal-custom-class" onSubmit={updateServiceTaxDetails}>
            <Row className="m-0">
              <Col xs={12} md={6} lg={4}>

                <Form.Group className="mb-3" controlId="bhareeCommission">
                  <Form.Label>
                    {"Bharee Commission"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      required
                      value={uploadFormObj?.bhareeComm}
                      onChange={(e) => {
                        return regex.test(e.target.value)
                          ? setUpdatedForm("bhareeComm", e.target.value)
                          : null;
                      }}
                      disabled={!isUpdateMode}
                    />
                    <InputGroup.Text id="bhareeCommissionAddon">%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="gst">
                  <Form.Label>
                    {"GST"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      required
                      value={uploadFormObj?.gst}
                      onChange={(e) => {
                        return regex.test(e.target.value)
                          ? setUpdatedForm("gst", e.target.value)
                          : null;
                      }}
                      disabled={!isUpdateMode}
                    />
                    <InputGroup.Text id="gstAddon">%</InputGroup.Text>
                  </InputGroup>

                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="transactionFee">
                  <Form.Label>
                    {"Transaction Fee"}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      required
                      value={uploadFormObj?.transactionFee}
                      onChange={(e) => {
                        return regex.test(e.target.value)
                          ? setUpdatedForm("transactionFee", e.target.value)
                          : null;
                      }}

                      disabled={!isUpdateMode}
                    />
                    <InputGroup.Text id="transactionFeeAddon">%</InputGroup.Text>
                  </InputGroup>

                  {/* <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text> */}
                </Form.Group>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="deliveryCharges">
                  <Form.Label>
                    {"Delivery Charges (Per Case) "}
                    <span className="redStreSpan">{"*"}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={uploadFormObj?.deliveryCharge}
                    onChange={(e) => {
                      return regex.test(e.target.value)
                        ? setUpdatedForm("deliveryCharge", e.target.value)
                        : null;
                    }}

                    disabled={!isUpdateMode}
                  />
                </Form.Group>
              </Col>
            </Row>

            {isUpdateMode && (<div className="action-buttons">
              {isUpdateMode && (<CustomButton
                variant="primary"
                buttonClasses={
                  !requestCall?.loading ? "submit-btn" : "submit-btn-loading"
                }
                type="submit"
                loading={requestCall?.loading}
              >
                {"update"}
              </CustomButton>)}

            </div>)}

          </Form>

        </Row>
      )}
    </>
  );
};

export default ServiceTaxDetails;
