import React, {  useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import BhareeBlack from "../../assets/images/BhareeBlack.png";

import bhareeLogo from "../../assets/images/bhareeLogo.png";
import KinleyLogo from "../../assets/images/KinleyBWLogo.png";
import BisleriLogo from "../../assets/images/BisleriBWLogo.png";
import BailleyLogo from "../../assets/images/BailleyBWLogo.png";
import AquafinaLogo from "../../assets/images/AquafinaBWLogo.png";
import TruckImage from "../../assets/images/Homepagetruck.png";

//dummmy
import SellerAboutUSBanner from "../../assets/images/about-seller-banner.png";
import SellerAboutUSBannerMobile from "../../assets/images/about-seller-banner-mobile.png";

import Fulfillment from "../../assets/images/Fulfillment.png";
import RetailNetwork from "../../assets/images/RetailNetwork.png";
import Devices from "../../assets/images/Devices.png";
import TruckMap from "../../assets/images/truck-map.png";
import KpiImage from "../../assets/images/kpi-image.png";
import KpiImageLaunch from "../../assets/images/LaunchNtech.png";

import Tick from "../../assets/images/tick.png";

import "react-image-gallery/styles/css/image-gallery.css";

import "./about-us-seller.css";
import { Link } from "react-router-dom";
import QuoteSection from "../common/QuoteSection";
import { useSelector } from "react-redux";
import SellerAuth from "../auth/SellerAuth";

const AboutUsSeller = () => {

  const {
    auth,
  } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const AboutUsBanner = (
    <>
      <div className="about-us-seller"></div>
      <Container className="aboutus-seller-banner">
        <Row className="m-0 aboutus-seller-banner-row">
          <Col xs={12} lg={7} className="aboutus-seller-banner-img-col">
            <h4 className="section-title-seller mobile">
              <Image src={BhareeBlack} alt="Bharee" />
              {" For"}
              <span>{" Seller"}</span>
            </h4>
            <Image
              className="desktop"
              style={{ height: "auto", maxWidth: "100%" }}
              src={SellerAboutUSBanner}
              alt="SellerAboutUSBanner"
            />
            <Image
              className="mobile"
              style={{ height: "auto", width: "100%" }}
              src={SellerAboutUSBannerMobile}
              alt="SellerAboutUSBannerMobile"
            />
          </Col>
          <Col xs={12} lg={5} className="aboutus-seller-banner-text-col right">
            <h4 className="section-title-seller desktop">
              <Image src={BhareeBlack} alt="Bharee" />
              {" For"}
              <span>{" Seller"}</span>
            </h4>

            <h5>
              {"Data-driven, technology-enabled"}
              <br />
              {"last mile sales & distribution."}
            </h5>
            <p>
              {
                "Become a Bharee seller and we will focus on the sales and distribution  of your brand in Retail Network 24 hours a day, 7 days a week."
              }
              <br />
              <br />
              {
                "We will take care of the entire process, from sales, warehouse receiving, deliveries, promotions and customer service."
              }
            </p>
            {/* <Link to="/" className="section-link ">
              {"Start selling"}
            </Link> */}
            {auth?.loggedIn ? <Link to="/seller/profile-panel" className="section-link">
              {"Start selling"}
            </Link> : <SellerAuth buttonText={"Start selling"} buttonClasses={"section-link"} registerMode={true} />}
          </Col>
        </Row>
      </Container>
      <div className="about-us-seller-reverse"></div>
    </>
  );
  const BrandsSection = (
    <div className="about-seller-brand">
      <Container className="pb-5 ">
        <Row className="m-0">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="brand-section-title-logo-col"
          >
            <Image src={bhareeLogo} alt="bhareeLogo" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="brand-section-title-text-col"
          >
            <h4>{" works with best brands"}</h4>
          </Col>
        </Row>
        <div className="brands-section-img-div">
          <Image src={AquafinaLogo} alt="AquafinaLogo" />
          <Image src={BisleriLogo} alt="BisleriLogo" />
          <Image src={BailleyLogo} alt="BailleyLogo" />
          <Image src={KinleyLogo} alt="KinleyLogo" />
        </div>
      </Container>
    </div>
  );

  // const FloatingSection = (
  //   <Container className="floating-section">
  //     <Row className="floating-text-row m-0 p-5">
  //       <Col xs={12} sm={12} md={6} className="floating-text-col">
  //         <div className="floating-text-div text-left">
  //           <h4 className="text-uppercase section-title">
  //             {"For "}
  //             <span>{"Buyer"}</span>
  //             <div id="borderLeft"></div>
  //           </h4>
  //           <p>
  //             {
  //               "At Bharee, we believe in providing transparency and reliability to all our Buyers. We ensure that all our suppliers/manufactures go through strict verification process, ensuring that only genuine and high-quality product are available on our platform. We also ensure that our buyers have access to a wide variety of mineral water products at competitive prices. Bharee platform features a comprehensive product listing, where you can browse and compare different brands and types of mineral water, from still to sparkling, from natural to flavoured."
  //             }
  //           </p>
  //         </div>
  //       </Col>
  //       <Col xs={12} sm={12} md={6}>
  //         <Image src={float1} style={{ maxWidth: "100%" }} />
  //       </Col>
  //     </Row>
  //     <Row className="floating-text-row m-0 p-5">
  //       <Col xs={6} sm={6} md={6}>
  //         <Image src={float2} style={{ maxWidth: "100%" }} />
  //       </Col>
  //       <Col xs={6} sm={6} md={6} className="floating-text-col">
  //         <div className="floating-text-div text-right">
  //           <h4 className="text-uppercase section-title">
  //             {"For "}
  //             <span>{"Seller"}</span>
  //             <div id="borderLeft"></div>
  //           </h4>
  //           <p>
  //             {
  //               "At Bharee, we believe in providing transparency and reliability to all our Buyers. We ensure that all our suppliers/manufactures go through strict verification process, ensuring that only genuine and high-quality product are available on our platform. We also ensure that our buyers have access to a wide variety of mineral water products at competitive prices. Bharee platform features a comprehensive product listing, where you can browse and compare different brands and types of mineral water, from still to sparkling, from natural to flavoured."
  //             }
  //           </p>
  //         </div>
  //       </Col>
  //     </Row>
  //   </Container>
  // );
  const growWithBrands = (
    <div className="about-seller-grow-wrap">
      <Container className="about-seller-grow py-5">
        <h1 className="grow-title-seller ">
          {"Grow your brand"} <br className="mobile" /> {" with"}
          <Image src={bhareeLogo} alt="Bharee" />
        </h1>
        <Row className="m-0 py-5">
          <Col xs={12} lg={4} className="about-seller-grow-col">
            <div className="about-seller-grow-card">
              <Image src={Devices} alt="AquafinaLogo" />
              <h6>{"Online Platform"}</h6>
              <p>
                {
                  "Whenever require, you can update your product's sales offers to the buyers of every corners of your region."
                }
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4} className="about-seller-grow-col">
            <div className="about-seller-grow-card">
              <Image src={RetailNetwork} alt="AquafinaLogo" />
              <h6>{"Retail Network"}</h6>
              <p>
                {
                  "We map, identify, know and digitize thousands of buyers to which we connect."
                }
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4} className="about-seller-grow-col">
            <div className="about-seller-grow-card">
              <Image src={TruckImage} alt="AquafinaLogo" />
              <h6>{"Fulfillment"}</h6>
              <p>
                {
                  "Increases the productivity of warehouses  and delivery partners so you can handle many orders each day."
                }
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
  const HelpMapTruckSection = (
    <div className="aboutus-seller-help-map">
      <Row className="m-0 help-map-row">
        <Col xs={12} lg={5} className="help-map-text-col">
          <div className="line-item desktop"></div>
          <h1>
            {
              "We help to position your brand and get your product to every corner of the region."
            }
          </h1>
        </Col>
        <Col xs={12} lg={7} className="help-map-img-col right">
          <Image src={TruckMap} alt="TruckMap" />
        </Col>
      </Row>
      <div className="about-us-help-map "></div>
    </div>
  );
  const kpiDetaiks = (
    <div className="aboutus-seller-kpi">
      {/* <Container> */}
      <Row className="m-0 kpi-row">
        <Col xs={12} lg={6} className="kpi-text-col left">
          <h1>{"Giving you access to real time data and KPI’s"}</h1>
          <Image className="mobile" src={KpiImage} alt="KpiImage" />
          <Row className="kpi-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="KpiImage" />
            </Col>
            <Col xs={10}>
              <h4>{"Get to know your buyer"}</h4>
              <p>
                {
                  "Analyze your sales performance in real time. You'll have access to data you've never had the opportunity to know before. Follow market trends and compare your product against the market standard."
                }
              </p>
            </Col>
          </Row>
          <Row className="kpi-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="KpiImage" />
            </Col>
            <Col xs={10}>
              <h4>{"Launch & follow campaigns"}</h4>
              <p>
                {
                  "Hire and follow online and offline advertising campaigns with our clients to improve your brand positioning."
                }
              </p>
            </Col>
          </Row>
          <Row className="kpi-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="KpiImage" />
            </Col>
            <Col xs={10}>
              <h4>{"Understand the depths of your sales & distribution"}</h4>
              <p>
                {
                  "You will be able to see on a real-time map how your sales are evolving and which are the hotspots of your products."
                }
              </p>
            </Col>
          </Row>
          {/* <Link to="/" className="section-link get-started">
            {"Get started"}
          </Link> */}
          {auth?.loggedIn ? <Link to="/seller/profile-panel" className="section-link">
            {"Get started"}
          </Link> : <SellerAuth buttonText={"Get started"} registerMode={true} buttonClasses={"section-link get-started"} />}
        </Col>
        <Col xs={12} lg={6} className="kpi-img-col right ">
          <Image className="desktop" src={KpiImage} alt="KpiImage" />
        </Col>
      </Row>
      {/* </Container> */}
    </div>
  );
  const techDetails = (
    <div className="aboutus-seller-tech-details">
      {/* <Container> */}
      <Row className="m-0 kpi-row">
        <Col xs={12} lg={6} className="tech-details-img-col  left">
          <Image className="desktop" src={KpiImageLaunch} alt="KpiImageLunch" />
        </Col>
        <Col xs={12} lg={6} className="tech-details-text-col right">
          <h1>{"Launch a tech-first, branded distribution"}</h1>
          <Image className="mobile" src={KpiImageLaunch} alt="KpiImageLunch" />
          <Row className="tech-details-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="tick" />
            </Col>
            <Col xs={10}>
              <h4>{"Premium positioning of your brand"}</h4>
              <p>
                {
                  "Position your brand in areas, media and channels where you have never been able to reach before."
                }
              </p>
            </Col>
          </Row>
          <Row className="tech-details-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="tick" />
            </Col>
            <Col xs={10}>
              <h4>{"Run smart promotions and advertising campaigns"}</h4>
              <p>
                {
                  "Hire advertising campaigns powered by real-time data on the sales performance of your products."
                }
              </p>
            </Col>
          </Row>
          <Row className="tech-details-list-row">
            <Col xs={2}>
              <Image src={Tick} alt="tick" />
            </Col>
            <Col xs={10}>
              <h4>{"Customized reports and analysis"}</h4>
              <p>
                {
                  "Get access to customized reports and analytics to evaluate the kpi's that matter to your brand."
                }
              </p>
            </Col>
          </Row>
          {/* <Link to="/" className="section-link get-started">
            {"Launch now"}
          </Link> */}
          {auth?.loggedIn ? <Link to="/seller/profile-panel" className="section-link get-started">
          {"Launch now"}
          </Link> : <SellerAuth buttonText={"Launch now"} registerMode={true} buttonClasses={"section-link get-started"} />}
        </Col>
      </Row>
      {/* </Container> */}
    </div>
  );
  return (
    <section className="AboutUs-main-section">
      {AboutUsBanner}
      {BrandsSection}
      {growWithBrands}
      {HelpMapTruckSection}
      {kpiDetaiks}
      {techDetails}
      <QuoteSection
        text={"Let's explore the limitless online market for your brand."}
      />
      {/* {FloatingSection} */}
    </section>
  );
}

export default AboutUsSeller;
